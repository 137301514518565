import { Component } from 'react';
import PropTypes from 'prop-types';
import Router from 'sites/routes/router';
import { currentLocationPathSelector } from 'sites/state/modules/router/selectors';
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { bypassCheck } from 'sites/api/MixApi';
import Auth from 'sites/utils/Auth';
import { resetCommonDetails } from 'sites/state/modules/common/actions';
import LocalStorage from 'sites/utils/LocalStorage';
import moment from 'moment';


class BypassChecker extends Component {
   static propTypes = {
      goTo: PropTypes.func.isRequired,
      logout: PropTypes.func.isRequired,
      match: PropTypes.object,
   };

   componentDidMount() {
      const token = this.props.match.params.token;
      const { goTo, logout } = this.props;
      logout();
      setTimeout(() => {
         bypassCheck({
            token,
         }).then((response) => {
            const token = response.data.token;
            if(token) {
               Auth.setToken(token);
               LocalStorage.setItem('bypassSetTime', moment().utc().unix());
               goTo(Router.route('JOIN').getMask())
            } else {
               goTo(Router.route('HOMEPAGE').getMask())
            }
         }).catch((err) => {
            goTo(Router.route('HOMEPAGE').getMask())
         });
      }, 100);
   }

   render() {
      return null;
   }
}

const mapStateToProps = state => ({
   locationPath: currentLocationPathSelector(state),
});

const mapDispatchToProps = dispatch => ({
   goTo: location => {
      dispatch(push(location))
   },
   logout: () => {
      Auth.logout();
      dispatch(resetCommonDetails());
   },
});

export default connect(mapStateToProps, mapDispatchToProps)(BypassChecker);
