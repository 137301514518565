import React from 'react';

const PrivacyPolicy = () => (
   <div style={ { lineHeight: '28px' } }>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Personally-Identifying <br />Information</p>
         <p>
            We disclose personally-identifying information only to our employees and contractors that (i) need to know that information in order
            to process it on our behalf or to provide services available on our application, and (ii) that have agreed not to disclose it to others.
            Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using our application,
            you consent to the transfer of such information to them.
            <br /><br />
            We will not rent or sell personally-identifying information to anyone. Other than to our employees or contractors,
            as described above, we disclose personally-identifying information only in response to a subpoena, court order or other governmental
            request, or when we believe in good faith that disclosure is reasonably necessary to protect our property or rights, third parties or
            the public at large. We take all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction
            of potentially personally-identifying and personally-identifying information.
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Cookies</p>
         <p>
            A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the
            website each time the visitor returns. We use cookies to help us identify and track visitors and how they use our application. If you
            do not want cookies placed on your computer, you can set your browser to refuse cookies. Please note that certain features may not
            function properly as a result.
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Business Transfers</p>
         <p>
            If we, or substantially all of our assets, were acquired, or in the unlikely event that we went out of business or entered bankruptcy,
            user information would be one of the assets that would be transferred or acquired by the third party. You acknowledge that such transfers
            may occur, and that any acquirer may continue to use your personal information as set forth in this policy.
         </p>
      </div>

      <div>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Privacy Policy Changes</p>
         <p>
            Although most changes are likely to be minor, we may change our Privacy Policy from time to time, and in our sole discretion.
         </p>
      </div>

   </div>
);

export default PrivacyPolicy;
