import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';
import { isEmpty } from 'lodash';

let reducersMap = {
   [types.FETCH_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
         isFetchingById: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { videosData, emptyFilters } } = action;
      let newState = {
         isEmpty: videosData.data.length === 0,
         isEmptyByFilter: false,
      }
      if(!emptyFilters) {
         newState = {
            isEmptyByFilter: videosData.data.length === 0,
         }
      }
      return {
         ...state,
         ...newState,
         isFetching: false,
         videosData,
         isInited: true,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // filter videos data
   [types.FETCH_BY_FILTER_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
      }
   },
   [types.FETCH_BY_FILTER_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { videosData } } = action;
      return {
         ...state,
         isFetchingByFilter: false,
         videosData,
         isEmptyByFilter: videosData.data.length === 0,

      }
   },
   [types.FETCH_BY_FILTER_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingByFilter: action?.payload?.error?.message === 'canceled' ? true : false,
      }
   },

   // go to next page

   [types.FETCH_NEXT_DATA_REQUEST]: (state) => {
      return {
         ...state,
         isNextFetching: true,
      }
   },
   [types.FETCH_NEXT_DATA_COMPLETED]: (state, action) => {
      const  { payload: { videosData } } = action;
      return {
         ...state,
         isNextFetching: false,
         videosData: {
            ...videosData,
            data: [...state.videosData.data, ...videosData.data],
         },

      }
   },
   [types.FETCH_NEXT_DATA_FAILED]: (state, action) => {
      return {
         ...state,
         isNextFetching: false,
      }
   },

   // like this videos
   [types.TOGGLE_LIKE_REQUEST]: (state) => {
      return {
         ...state,
      }
   },
   [types.TOGGLE_LIKE_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const data = state.videosData.data || []
      const findIndex = data.findIndex(story => story.id === payload.id);
      if(findIndex >= 0){
         data[findIndex].is_liked = !data[findIndex].is_liked;
      }
      if(typeof action.payload.isLike === 'boolean' && !!state.activeVideo) {
         if(action.payload.isLike) {
            state.activeVideo.likes_count += 1
         } else {
            state.activeVideo.likes_count -= 1
         }
         state.activeVideo.is_liked = action.payload.isLike
      }
      return {
         ...state,
         videosData: {
            ...state.videosData,
            data: [...data],
         },
         activeVideo: {
            ...state.activeVideo,
            is_liked: (state.activeVideo.id === Number(payload)) ? !state.activeVideo.is_liked : state.activeVideo.is_liked,
         },
      }
   },
   [types.TOGGLE_LIKE_FAILED]: (state, action) => {
      return {
         ...state,
      }
   },
   [types.TOGGLE_WATCHED_COMPLETED]: (state, action) => {
      const id = action.payload;
      const data = state.videosData.data || []
      const findIndex = data.findIndex(vid => vid.id === id);
      if(findIndex !== -1) {
         data[findIndex].watched = !data[findIndex].watched
         data[findIndex].new = false
      }
      return {
         ...state,
         videosData: {
            ...state.videosData,
            data: [...data],
         },
      }
   },

   // get by id
   [types.FETCH_VIDEO_BY_ID_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingById: true,
         activeVideo: {},
         downloadButtonLoading: false,
         isInited: !isEmpty(state.videosData),
      }
   },
   [types.FETCH_VIDEO_BY_ID_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingById: false,
         activeVideo: payload,

      }
   },
   [types.FETCH_VIDEO_BY_ID_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingById: false,
         isInited: false,
      }
   },
   [types.CLEAR_VIDEO_BY_ID]: (state, action) => {
      return {
         ...state,
         activeVideo: {},
      }
   },
   [types.SET_FILTERS]: (state, action) => {
      return {
         ...state,
         filters: action.payload,
      }
   },
   [types.CLEAR_STATE]: (state, action) => {
      return {
         ...state,
         isInited: false,
         filters: action.payload || {},
      }
   },
   [types.UPDATE_VIDEO_BY_ID]: (state, action) => {
      const { data, id } = action.payload;
      return {
         ...state,
         videosData: {
            ...state.videosData,
            data: [...state.videosData.data].map(el => {
               let { ...item } = el
               if(id === item.id) item = { ...item, ...data }
               return item
            }),
         },
      }
   },
   [types.UPDATE_DATA_AFTER_PURCHASE]: (state, action) => {
      const updatableData = action.payload;
      updatableData.watched = true;
      updatableData.new = false;
      let newData = []
      if(state.videosData?.data) {
         newData = state.videosData.data.map(item => {
            if(item.id === updatableData.id) {
               return { ...updatableData }
            }
            return item
         })
      }
      return {
         ...state,
         videosData: {
            ...state.videosData,
            data: newData,
         },
      }
   },
   [types.GET_UPGRADE_PRICING_LIST]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         pricingUpgradeList: [...payload],
      }
   },
   [types.DELETE_UPGRADE_PRICING_LIST]: (state) => {
      return {
         ...state,
         pricingUpgradeList: [],
      }
   },
   [types.SET_IS_INITED_STATE]: (state, action) => {
      let newState = {}
      if(!action.payload){
         newState = {
            videosData: {},
         }
      }
      return {
         ...state,
         ...newState,
         isInited: action.payload,
      }
   },
   [types.ON_CHANGE_FAVORITE_ITEM_COMPLETED]: (state, action) => {
      const  { payload: { id, isDetailsPage } } = action;
      let tempData = state.videosData.data || []
      let activeVideo = state?.activeVideo

      if(isDetailsPage) {
         activeVideo = {
            ...activeVideo,
            is_favorite: !activeVideo.is_favorite,
         }
      }

      if(!!tempData?.length) {
         const findIndex = tempData.findIndex(item => item.id === id)
         if(findIndex >= 0){
            tempData[findIndex].is_favorite = !tempData[findIndex].is_favorite
         }
      }

      return {
         ...state,
         videosData: {
            ...state.videosData,
            data: tempData,
         },
         activeVideo,
      }
   },
};

export default createReducer(initialState)(reducersMap);
