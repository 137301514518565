import React from "react";
import PropTypes from "prop-types";
import InformationAndPolicies from "common/components/modules/information-and-policies/admin";

const InformationAndPoliciesLayoutContent = ({
   children,
   viewType,
   textCenter,
   showFooter,
   isbutton,
   isHeader,
   isFixed,
}) => {
   return (
      <div style={ { height: isHeader ? 'calc(100% - 48px)' : '100%' } } className='flex flex-col'>
         <div className='flex h-full flex-col'>{children}</div>
         {!showFooter && (
            <div style={ {  marginBottom: isbutton && '54px' } }>
               <InformationAndPolicies isFixed={ isFixed } textCenter={ textCenter } viewType={ viewType } />
            </div>
         )}
      </div>
   );
};

InformationAndPoliciesLayoutContent.defaultProps = {
   textCenter: false,
   viewType: "desktop",
   showFooter: false,
   isbutton: false,
   isHeader: false,
   isFixed: false,
};

InformationAndPoliciesLayoutContent.propTypes = {
   children: PropTypes.any,
   viewType: PropTypes.string,
   textCenter: PropTypes.bool,
   showFooter: PropTypes.bool,
   isbutton: PropTypes.bool,
   isHeader: PropTypes.bool,
   isFixed: PropTypes.bool,
};

export default InformationAndPoliciesLayoutContent;
