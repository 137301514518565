
export const MORE_INFO_ON_WALLET = 'more-info-on-wallet';
export const MORE_INFO_ON_WALLET_TOP_UP = 'more-info-on-wallet-top-up';
export const MORE_INFO_ON_WALLET_AND_FEES = 'more-info-on-wallet-and-fees';
export const MORE_INFO_ON_PAYMENT_DECLINES = 'more-info-on-payment-declines';
export const MEMBER_SUPPORT = 'member-support';
export const CONTACT_SUPPORT = 'contact-support';
export const MEMBER_PAYMENT_FAQS = 'member-payment-faqs';
export const FILE_A_COMPLAINT = 'file-a-complaint';

// const IntercomMoreInfo = (paramValue) => {

// }
const IntercomMoreInfo = (paramValue) => {
   const paramName = 'trigger';
   const searchParams = new URLSearchParams(window.location.search);
   searchParams.set(paramName, paramValue);
   const queryParam = searchParams.toString();
   const currentUrl = window.location.pathname;
   const newUrl = `${ currentUrl }?${ queryParam }`;
   window.history.replaceState({}, '', newUrl);

   if(window.Intercom) {
      setTimeout(() => {
         window.Intercom('show');
         setTimeout(() => {
            window.Intercom('update');
         }, 100);
      }, 100);
   }
}
export default IntercomMoreInfo;
