import LocalStorage from "sites/utils/LocalStorage";
import QueryParams from "sites/utils/QueryParams";

export default  () => {
    
   const affiliateId = LocalStorage.getItem('affiliateId')
   const isSocialAuthSuccessful =  QueryParams.getParam('social-auth') && QueryParams.getParam('social-auth') === 'success'

   if(isSocialAuthSuccessful && affiliateId) {
      LocalStorage.removeItem('affiliateId')
   }

};
 