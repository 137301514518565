const initialState = {
   isFetchingNotifications: true,
   isFetchingNewNotifications: false,
   isFetchingByFilterNotifications: false,
   isEmptyNotifications: false,
   isEmptyByFilterNotifications: false,
   unseenCount: 0,
   unreadCount: 0,
   list: {},
   notificationsPanel: {
      isOpen: false,
      withAnimation: false,
   },
};

export default initialState;
