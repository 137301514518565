import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './style.css';

const Icon = ({
   className,
   color,
   size,
   name,
   disabled,
   link,
   rotated,
   style,
   primaryColor,
   darkColor,
   ...rest
}) => {

   let iconStyle = { ...style, width: 'max-content' };
   if(primaryColor) {
      iconStyle = {
         ...iconStyle,
         color: primaryColor,
      };
   }
   if(rotated) {
      iconStyle = {
         ...iconStyle,
         transform: `rotate(${ rotated }deg)`,
      };
   }

   return (
      <span
         { ...rest }
         className={ cx({
            [`ams-icon ams-icon-${ name } select-none text-${ color } text-${ size } ${ className } ${ darkColor ? `dark:text-${ darkColor }` : `dark:text-${ color }-dark` }`]: true,
            'opacity-50': disabled,
            'opacity-1': !disabled,
            'ams-icon-link': link,
            'ams-icon-rotated': rotated,
         }) }
         style={ iconStyle }
      />
   );
};

Icon.defaultProps = {
   className: '',
   size: 'base',
   color: 'major',
};

Icon.propTypes = {
   className: PropTypes.string,
   primaryColor: PropTypes.string,
   color: PropTypes.string,
   size: PropTypes.string,
   name: PropTypes.string.isRequired,
   rotated: PropTypes.string,
   disabled: PropTypes.bool,
   link: PropTypes.bool,
   style: PropTypes.object,
   darkColor: PropTypes.string,
};

export default Icon;
