const initialState = {
   isFetching: true,
   isFetchingChangePassword: false,
   isCancelFetching: false,
   isSendFetching: false,
   emailVerified: false,
   accountInfo: {},
   errors: {},
   passwordChangeError: {},
   newEmail: '',
   openEditUsername: { isOpne: false, error: {} },
   notificationPreferences: {},
   isFetchingNotificationPreferences: true,
   updatingNotificationPreferences: { key: null },
};

export default initialState;
