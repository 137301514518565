const initialState = {
   messageData: { data: [] },
   isFetching: true,
   initData: {},
   isFetchingMessages: true,

   scheduleData: {},
   recordedStreams: {},
   isFetchingRecord: true,
   isFetchingRecordFilter: false,
   isEmptyByFilterRecord: false,
   isEmptyRecord: false,
   isNewFetchingRecords: false,
   isFetchingSchedule: true,
   isFetchingCreateSchedule: false,
   isFetchingStartStream: false,
   updateErrorsData: null,
   ivs: null,
   streamConversation: {
      typers: {},
      chat_room_id: 'live_stream',
      conversation_name: 'Livestream chat',
      username: 'Livestream chat',
      members_count: 10,
      unread_messages_count: 0,
   },
   conversationSatet: {

   },
   isConnected: false,
   onlineUsers: [],
   //////
   likedMemberByMessage: {
   },
   ////
   isFetchNewMessages: false,
   isOpenActiveUpsellModal: false,
   showLargeTipAnimation: {
      isOpen: false,
      username: null,
      price: null,
   },
   offlineStateSelector: {},
   tipGoalAchievedModal: false,
   tipGoalActionsAchievedModal: { isOpen: false, actions: [] },
   openEndedModal: false,
   disabledLikeButton: false,
   watchingUsers: [],

}

export default initialState
