const initialState = {
   isFetching: true,
   isFetchingByFilter: false,
   isNextFetching: false,
   isEmpty: false,
   isEmptyByFilter: false,
   unlocksData: [],
   errors: null,
};

export default initialState;
