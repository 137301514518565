import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';
import { isEmpty } from 'lodash'

let reducersMap = {
   [types.SEND_START]: (state) => {
      return {
         ...state,
         isFetching: true,
         oneClickInfo: {},
      }
   },

   [types.SEND_COMPLETED]: (state, action) => {
      const  { data, modalData } = action.payload;
      let type = data.type;
      if(data.type === 'photoset_download') type = 'photoset'
      if(data.type === 'video_download') type = 'video'
      let modalState = {
         modalType: type,
         isOpenSendModal: true,
         isNotEnoughtBalance: false,
         isSend: true,
         paymentError: null,
         isOpenChoosePaymentModal: false,

      }
      if(modalData && !isEmpty(modalData)) {
         modalState = modalData
      }
      return {
         ...state,
         ...modalState,
         isFetching: false,

      }
   },

   [types.SEND_FAILED]: (state, action) => {
      const  { payload: { data, error } } = action;
      let type = data.type;
      if(data.type === 'photoset_download') type = 'photoset'
      if(data.type === 'video_download') type = 'video'
      return {
         ...state,
         isFetching: false,
         isOpenSendModal: true,
         isNotEnoughtBalance: true,
         modalType: type,
         modalKey: data.modalKey || state.modalKey,
         paymentError: error,
      }
   },

   [types.SET_SEND_MODAL_STATE]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         ...payload,
      }
   },
   [types.SET_UPDATE_METHODS]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         methods: {
            ...state.methods,
            ...payload,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
