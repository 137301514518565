import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import Router from 'sites/routes/router';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
   initDataSelector as initLiveStreamDataSelector,
   openEndedModalSelector,
} from 'sites/state/modules/livestream/selectors';
import {
   fetchInitCompleted as updateLiveStreamState,
   setOpenEndedModal,
} from 'sites/state/modules/livestream/actions';
import {
   initLiveStreamOperation,
} from 'sites/state/modules/livestream/operations';
import {
   currentLocationPathSelector,
} from 'sites/state/modules/router/selectors';
import {
   isLouisianaBlockedSelector,
} from 'sites/state/modules/common/selectors';

import NotifiactionCard from 'common/components/modules/live-stream/notification-card';
import { setReferrerUrl } from 'sites/utils/utils.js';
import EndedModal from 'sites/views/live-stream/modules/ended-modal';
class LiveStreamPopup extends Component {
   static propTypes = {
      initLiveStreamData: PropTypes.object,
      locationPath: PropTypes.string,
      updateLiveStreamState: PropTypes.func,
      goTo: PropTypes.func,
      openEndedModal: PropTypes.bool,
      isLouisianaBlocked: PropTypes.bool,
      setOpenEndedModal: PropTypes.func,
      initLiveStream: PropTypes.func,
   };

   onEndStream = (callback) => {
      const { initLiveStream } = this.props;
      initLiveStream(false, () => {
         if(callback){
            callback()
         }
      })
   }

   render() {
      const {  initLiveStreamData, locationPath, updateLiveStreamState, goTo, openEndedModal, setOpenEndedModal, isLouisianaBlocked } = this.props;
      let isLiveStreamRoutes = false;
      if(isLouisianaBlocked){
         return null
      }
      let guestRoute = [
         'LIVE_STREAM_STUDIO',
         'LIVE_STREAM_RECORDS_STREAM',
         'LIVE_STREAM_RECORD',
         'SIGN_IN',
         'SIGN_UP',
         'SIGN_IN_EMAIL',
         'SIGN_UP_EMAIL',
         'SIGN_IN_NOT_RECOGNIZED',
         'PASSWORD_RESET',
         'PASSWORD_RESET_TOKEN',
         'EMAIL_VERIFICATION',
         'ERROR_PAGE',
         'JOIN',
         'PURCHASE_MEMBERSHIP',
         'PURCHASE_LIVE_STREAM',
         'AGE_VERIFICATION',
         'AGE_VERIFICATION_PROCESS',
      ];

      for(let index = 0; index < guestRoute.length; index++) {
         const route = guestRoute[index];
         if(Router.route(route).match(locationPath)) {
            isLiveStreamRoutes = true;
            break;
         }
      }
      return (
         <>
            {
               createPortal(
                  <NotifiactionCard
                     data={  initLiveStreamData }
                     onCloseAction={ _ => {
                        updateLiveStreamState({ is_show_banner: false })
                     } }
                     link={ Router.route('LIVE_STREAM_STUDIO').getCompiledPath() }
                     show={ !!initLiveStreamData?.is_show_banner && !initLiveStreamData.is_stream_offline && !isLiveStreamRoutes }
                     goToGetMembership={ () => {
                        const pageInfo = {
                           route_name: 'LIVE_STREAM_STUDIO',
                           page_name: 'livestream',
                           route_id: null,
                           goBack: 'livestream',
                        }

                        setReferrerUrl(pageInfo)
                        goTo(Router.route('JOIN').getCompiledPath(), `?liveStreamId=stream`)
                     } }
                     onStreamEnd={ () => setOpenEndedModal(true) }
                     isEnded={ openEndedModal }
                  />,
                  document.getElementById('root')
               )
            }
            {
               openEndedModal && !Router.route('LIVE_STREAM_STUDIO').match(locationPath) && (
                  <EndedModal
                     onEnded={ callBack => {
                        this.onEndStream(() => {
                           setOpenEndedModal(false)
                           if(callBack){
                              callBack()
                           }
                        })
                     } }
                  />
               )
            }
         </>
      )
   }
}
const mapStateToProps = (state) => {
   return {
      initLiveStreamData: initLiveStreamDataSelector(state),
      locationPath: currentLocationPathSelector(state),
      openEndedModal: openEndedModalSelector(state),
      isLouisianaBlocked: isLouisianaBlockedSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      updateLiveStreamState: data => dispatch(updateLiveStreamState(data)),
      goTo: (path, search) => {
         dispatch(push({
            pathname: path,
            search,
         }))
      },
      setOpenEndedModal: bool =>  dispatch(setOpenEndedModal(bool)),
      initLiveStream: (bool, callBack) => { dispatch(initLiveStreamOperation(bool, false, callBack)) },

   }
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamPopup);
