import { createContext } from 'react';
const { Provider, Consumer } = createContext({
   type: 'mobile',
   activeGroup: '',
   changeGroup: () => {},
});

export {
   Provider,
   Consumer,
}
