import checkJwtToken from './checkJwtToken';
import initIntercom from './initIntercom';
import initSharedData from './initSharedData';
import initAffiliateId from './initAffiliateId';
import { isMobileAndTablet } from 'common/utils/utils';
import checkAffiliateId from './checkAffiliateId';

export default () => {
   initSharedData();
   initIntercom();
   initAffiliateId();
   checkAffiliateId()
   checkJwtToken();
   if(isMobileAndTablet()) {
      document.body.classList.add('select-none')
   }
};
