import React from 'react';
import { NavLink } from 'react-router-dom';

import Icon from 'common/components/elements/icons';
import PropTypes from 'prop-types';
import { Consumer } from '../context';
import classnames from 'classnames';

const Link = ({
   icon,
   title,
   active,
   to,
   onClick,
   font,
   showTitle,
   className,
   avatarURL,
   unreadCount,
   existPath,
   isLogout,
   isMobile,
   useAvatar,
   isSimple,
   isSubLink,
   iconClassName,
   count,
   showCount,
   isDemoMode,
   ...rest
}) => {
   return (
      <Consumer>
         { ({ type }) => {
            const hyperlinkClasses = classnames(
               className,
               'w-full',
               'h-full',
               'flex',
               'items-center',
               'hover:bg-hover dark:hover:bg-hover-dark',
               'z-30 bg-panel dark:bg-panel-dark',
               {
                  'justify-center gap-3': type === 'desktop',
                  'pl-4': type === 'mobile' && !useAvatar,
                  'text-major dark:text-major-dark': (to && !window.location.pathname.startsWith(to)),
                  'intercom-new-subgroup': icon === 'membership',
                  'intercom-new-promotions': title === 'Promotions',
                  'intercom-new-messaging-subgroup': icon === 'messaging',
                  'intercom-new-messaging': title === 'Mass message',
                  'intercom-new-stats': icon === 'fundraiser',
                  'intercom-new-stats-acquisition': title === 'Acquisition',
                  'intercom-affiliates-and-referals': icon === 'campaign',
                  'intercom-affiliate-program': title === 'My affiliate program',
                  'intercom-referrals': title === 'Refer creators & earn',
                  'intercom-store': title === 'Store',
                  'intercom-chat': title === 'Chat',
                  'intercom-my-unlocks': title === 'My unlocks',
                  'intercom-my-favorites-member': title === 'My favorites',
                  [`skin-primary-text`]: type === 'mobile' && active && isSimple,
               });
            const itemProps = {
               onClick: (e) => { e.preventDefault(); onClick() },
               to: to || '',
               className: hyperlinkClasses,
               style: {
                  minHeight: '48px',
               },
               activeClassName: `skin-primary-text ${ !isSimple ? `bg-hover dark:bg-hover-dark [&>span]:!text-major dark:[&>span]:!text-major-dark [&>div>span.ams-icon]:!text-secondary dark:[&>div>span.ams-icon]:!text-secondary-dark` : `` }  `,
               exact: 'home' === title.toLowerCase(),
            };
            if(rest['data-tooltip-content'] && type === 'desktop') {
               itemProps['data-tooltip-content'] = rest['data-tooltip-content'];
               itemProps['data-tooltip-id'] = 'ams-right-tooltip';
            }

            const navLinkContentProps = {
               icon: icon,
               title: title,
               active: active,
               showTitle: showTitle,
               avatarURL: avatarURL,
               unreadCount: unreadCount,
               isLogout: isLogout,
               isMobile: isMobile,
               type: type,
               isSimple: isSimple,
               isSubLink: isSubLink,
               iconClassName: iconClassName,
               count,
               showCount,
               isDemoMode,
            }
            if(isLogout){
               return (
                  <div
                     role='presentation'
                     onClick={ (e) => { e.preventDefault(); onClick() } }
                     className={ `${ hyperlinkClasses } ${ isDemoMode ? ' !cursor-default' : 'cursor-pointer' } nav-logout-button` }
                     data-tooltip-content={ isDemoMode ? 'Not available in demo' : '' }
                     data-tooltip-id='ams-top-tooltip'
                     style={ {
                        minHeight: '48px',
                     } }
                  >
                     <NavLinkContent
                        { ...navLinkContentProps }
                     />
                  </div>
               )
            }

            return (
               <NavLink
                  isActive={ (match, location) => {
                     return !!match && location.pathname.startsWith(to) && !location.pathname.startsWith(existPath);
                  } }
                  { ...itemProps }
               >
                  <NavLinkContent
                     { ...navLinkContentProps }
                  />
               </NavLink>
            );
         } }
      </Consumer>
   )
}

const NavLinkContent = ({
   icon,
   title,
   active,
   showTitle,
   avatarURL,
   unreadCount,
   isLogout,
   isMobile,
   type,
   isSubLink,
   iconClassName,
   count,
   showCount,
   isDemoMode,
}) => {
   return (
      <>
         {
            icon && (
               <div className={ `relative flex items-center justify-center ${ isSubLink ? `w-5 h-5` : `w-6 h-6` }` }>
                  <Icon
                     name={ icon }
                     size={ isSubLink ? `xl` : '2xl' }
                     className={ classnames({
                        '': true,
                        'skin-primary-text': active,
                        'text-secondary dark:text-secondary-dark': !active,
                        '!text-error dark:text-error-dark': !isDemoMode && isLogout,
                        [`${ iconClassName }`]: iconClassName,
                        '!text-disabled': isDemoMode && isLogout,
                     }) }
                  />

                  {
                     true === Boolean(unreadCount) && (
                        <div
                           className='absolute h-5 w-5 flex items-center justify-center bg-error dark:bg-error-dark rounded-full right-[-8px] top-[-8px] circle-shandow z-1'
                        >
                           <span className='leading-[14px] text-major-dark text-xs whitespace-nowrap'>{unreadCount}</span>
                        </div>

                     )
                  }
               </div>
            )
         }
         {
            avatarURL && (
               <div
                  className='w-full h-12 flex items-center justify-center hover:bg-hover dark:hover:bg-hover-dark cursor-pointer'
               >
                  <img
                     src={ avatarURL }
                     alt='Avatar'
                     style={ {
                        border: '1px solid #0000002e',
                     } }
                     className='object-center object-cover rounded-full w-10 h-10'
                  />
               </div>
            )
         }
         {
            showTitle && (
               <>
                  <span
                     className={ classnames({
                        'font-medium pl-4 pr-6 truncate': true,
                        // 'text-error dark:text-error-dark': isLogout && !isMobile,
                        'text-major dark:text-major-dark': !isLogout || isMobile,
                        'skin-primary-text': active,
                        'pb-[1.5px]': !isSubLink,
                        '!pr-2': count,
                        '!text-disabled': isDemoMode && isLogout,
                     }) }
                  >{title}</span>
                  {
                     (Boolean(count) && !showCount) &&
                     <div
                        className={
                           classnames({
                              ' h-5 min-w-[20px] grid place-items-center bg-error dark:bg-error-dark rounded-full z-1 px-[5px]': true,
                              // 'opacity-0': !isSubLink && active && type === 'mobile',
                           })
                        }
                     >
                        <span className='leading-[14px] text-major-dark text-[11px] text-center whitespace-nowrap font-medium'>{count > 99 ? '99+' : count}</span>
                     </div>
                  }
               </>
            )
         }
         {
            !icon && true === Boolean(unreadCount) && (
               <div
                  className='h-5 w-5 flex items-center justify-center bg-error dark:bg-error-dark rounded-full ml-1 circle-shandow z-1'
               >
                  <span className='leading-[14px] text-major-dark text-xs whitespace-nowrap'>{unreadCount}</span>
               </div>

            )
         }
      </>
   )
}

Link.propTypes = {
   title: PropTypes.string,
   icon: PropTypes.string,
   to: PropTypes.string,
   font: PropTypes.string,
   existPath: PropTypes.string,
   active: PropTypes.any,
   onClick: PropTypes.func,
   showTitle: PropTypes.bool,
   isLogout: PropTypes.bool,
   isMobile: PropTypes.bool,
   type: PropTypes.oneOf([
      'mobile',
      'desktop',
   ]),
   className: PropTypes.string,
   avatarURL: PropTypes.string,
   unreadCount: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
   ]),
   useAvatar: PropTypes.bool,
   isSimple: PropTypes.bool,
   isSubLink: PropTypes.bool,
   iconClassName: PropTypes.string,
   count: PropTypes.number,
   showCount: PropTypes.bool,
   isDemoMode: PropTypes.bool,
};
NavLinkContent.propTypes = {
   title: PropTypes.string,
   icon: PropTypes.string,
   active: PropTypes.any,
   showTitle: PropTypes.bool,
   isLogout: PropTypes.bool,
   isMobile: PropTypes.bool,
   avatarURL: PropTypes.string,
   unreadCount: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
   ]),
   type: PropTypes.oneOf([
      'mobile',
      'desktop',
   ]),
   isSubLink: PropTypes.bool,
   iconClassName: PropTypes.string,
   count: PropTypes.number,
   showCount: PropTypes.bool,
   isDemoMode: PropTypes.bool,
};

Link.defaultProps = {
   active: false,
   showTitle: true,
   type: 'mobile',
   className: '',
   count: 0,
   isDemoMode: false,
};

Link.displayName = 'Link';

export default Link;
