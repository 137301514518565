import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import IntercomMoreInfo, { CONTACT_SUPPORT } from 'sites/utils/IntercomMoreInfo';
import OnlineMembersCounter from '../online-members-counter';
import Router from 'sites/routes/router';

import './style.scss'
import IconLabel from 'common/components/elements/icon-label';

const IconSizes = {
   tripleLarge: '3xl',
   doubleLarge: '2xl',
   extraLarge: 'xl',
   large: 'lg',
   base: 'base',
   small: 'sm',
};

const PageHeader = ({
   className,
   title,
   fontIconSize,
   fontIconColor,
   leftIconName,
   rightIconName,
   rightSecondIconName,
   leftIconAction,
   rightIconAction,
   rightSecondIconAction,
   count,
   isHelpIcon,
   rightSecondIconShow,
   guest,
   guestAction,
   zIndex,
   children,
   truncateHeaderText,
   isFixed,
   leftOffset,
   user,
   showUser,
   onClickMyAccount,
   uploadsIconAction,
   uploads,
   siteLogo,
   chat,
   parents,
   onClickLogOut,
   toMyAccount,
   isWhite,
   menuIconColor,
   siteLogoSize,
   tipAction,
   isMobile,
   siteStatus,
   viewAs,
   notifications,
   baseFontSize,
   transparentHeader,
   goToJoin,
   isPublicMember,
   isAdmin,
   onlineMembers,
   openOnlineMembersModal,
   hasAvailablePricings,
   showNewStyles,
   modalheaderText,
   extraTitle,
   lastItemCallback,
   topHelpIconTooltip,
   locationPath,
   signInLink,
   isUploadsPage,
   memberSupportUnreadCount,
}) => {
   // const counterRef = useRef(onlineMembers?.length)
   // const [animationClass, setAnimationClass] = useState('')

   // useEffect(() => {
   //    if(!isAdmin || !Boolean(onlineMembers)) return;
   //    if(onlineMembers.length > counterRef.current) {
   //       setAnimationClass('animate')
   //       setTimeout(() => {
   //          setAnimationClass('')
   //       }, 1000)
   //    }
   //    counterRef.current = onlineMembers.length
   // }, [onlineMembers?.length, isAdmin, onlineMembers])

   const wrapperStyles = {};
   if(leftOffset) {
      wrapperStyles['width'] = `calc(100% - ${ leftOffset })`;
   }
   let widthDiff = 0;
   if(leftIconName) {
      widthDiff = widthDiff + 40
   }
   if(!!showUser) {
      widthDiff = widthDiff + 52
   }
   if(!!isHelpIcon) {
      widthDiff = widthDiff + 48

   }
   function converHexToString(color) {
      let res = color
      if(res && res.includes('#')){
         switch (color.toLowerCase()) {
            case '#000':
            case '#000000':
               res = 'major'
               break;
            case '#fff':
            case '#ffffff':
               res = 'major-dark'
               break;
            case '#041527':
               res = 'major'
               break;

            default:
               break;
         }
      }
      return res
   }

   return (
      <Fragment>
         <div
            className={ classNames({
               [`w-full flex-col flex items-center justify-between top-0 page__header z-${ zIndex } ${ className }`]: true,
               'bg-transparent shadow-none dark:shadow:none header-icon-colors': transparentHeader,
               'bg-panel dark:bg-panel-dark': !transparentHeader,
               'header-bottom-shadow': isAdmin,
               // 'header-shadow': !isAdmin,
               'fixed': isFixed,
            }) }
            style={ wrapperStyles }
         >
            {
               children && (
                  children
               )
            }
            <div
               className={ classNames({
                  'flex items-center justify-between pl-4 h-[56px] w-full select-none': true,
                  'pr-4': showUser,
                  'pr-2': !showUser,
                  'flex !flex-col !h-auto !p-0': showNewStyles,
               }) }
            >
               {
                  leftIconName && showNewStyles &&
                     <div className='h-14 w-full flex p-4 justify-end'>
                        <PageHeaderIcon
                           fontIconColor={ converHexToString(menuIconColor) || converHexToString(fontIconColor) }
                           fontIconSize={ fontIconSize }
                           onClickAction={ leftIconAction }
                           fontIconName={ leftIconName }
                           className='w-6 h-6'
                        />
                     </div>
               }
               {
                  leftIconName && !showNewStyles && (
                     <PageHeaderIcon
                        fontIconColor={ converHexToString(menuIconColor) || converHexToString(fontIconColor) }
                        fontIconSize={ fontIconSize }
                        onClickAction={ leftIconAction }
                        fontIconName={ leftIconName }
                        className='mx-0'
                     />
                  )
               }
               <div
                  className={ classNames({
                     'flex items-center  relative justify-between flex-1': true,
                     'justify-between': guest,
                  }) }
                  style={ { minWidth: `calc(100% - ${ widthDiff }px)` } }
               >
                  {
                     siteLogo && (
                        <Link
                           to={ Router.route('HOMEPAGE').getCompiledPath() }
                        >
                           <div
                              style={ {
                                 maxWidth: '252px',
                              } }
                              className={ `flex items-center mx-2 ${ siteLogoSize } ` }
                           >
                              <img src={ siteLogo } alt='logo' className='object-center object-contain w-auto h-full ' />
                           </div>
                        </Link>
                     )
                  }
                  <div
                     className={ `flex items-center justify-center pl-2 truncate ${ showNewStyles ? 'flex-col gap-3 mx-auto !p-0' : '' }` }
                  >
                     {
                        parents && !!parents.length && parents.map((parent, index) => {
                           const parentTruncateHeaderText = truncateHeaderText && ['MY_SITE_OUTGOING_NOTIFICATIONS_AND_AUTO_TWEETS'].includes(parent.parentKey);
                           return (
                              <div
                                 className={ classNames({
                                    'flex items-center justify-center ': true,
                                    'truncate': parentTruncateHeaderText,
                                 }) }
                                 key={ index.toString() } >
                                 <Link
                                    to={ parent.link }
                                    key={ index.toString() }
                                    className={ classNames({
                                       'flex items-center justify-center ': true,
                                       'truncate': parentTruncateHeaderText,
                                    }) }
                                 >
                                    <div
                                       className={ classNames({
                                          'flex items-center justify-center cursor-pointer border-b-2 hover-header-border border-solid ': true,
                                          'truncate': parentTruncateHeaderText,
                                       }) }
                                    >
                                       <span
                                          className={ classNames({
                                             'text-xl select-none  font-bold max-h-full dark:text-major-dark': true,
                                             'truncate': truncateHeaderText,
                                             'white-space-no-wrap': truncateHeaderText,
                                             'text-major': !isWhite,
                                             'text-major-dark': isWhite,
                                          }) }
                                       >{parent.title}</span>
                                    </div>
                                 </Link>
                                 <FontIcon
                                    name='arrow-right'
                                    size={ IconSizes['doubleLarge'] }
                                    color='secondary'
                                    darkcolor='secondary-dark'
                                 />
                              </div>
                           )
                        })
                     }
                     {
                        showNewStyles && (
                           <IconLabel
                              name='lock'
                              size='2xl'
                              color='action'
                              className='!w-10 !h-10'
                              isPrimary={ true }
                              wrrapperBgColor='bg-action-12'
                           />
                        )
                     }
                     <span
                        className={ classNames({
                           'select-none  font-bold max-h-full dark:text-major-dark border-b-2 border-transparent': true,
                           'text-base': baseFontSize,
                           'truncate': truncateHeaderText,
                           'white-space-no-wrap': truncateHeaderText,
                           'text-major': !isWhite,
                           'text-major-dark': isWhite,
                           'text-xl': !baseFontSize,
                           'font-semibold': showNewStyles,
                           'cursor-pointer hover-header-border border-solid': !!lastItemCallback,

                        }) }
                        role='presentation'
                        onClick={ () => {
                           if(!!lastItemCallback) {
                              lastItemCallback()
                           }
                        } }
                     > {title}</span>
                     {
                        showNewStyles && (
                           <div className={ `${ modalheaderText?.textStyles }` }>{modalheaderText?.headerText}</div>
                        )
                     }
                     {
                        !!extraTitle &&
                           <div className='flex items-center'>
                              <FontIcon
                                 name='arrow-right'
                                 size={ IconSizes['doubleLarge'] }
                                 color='secondary'
                                 darkcolor='secondary-dark'
                              />
                              <div
                                 className={ classNames({
                                    'flex items-center justify-center **cursor-pointer **border-b-2 **hover-header-border **border-solid ': true,
                                    // 'truncate': parentTruncateHeaderText,
                                 }) }
                              >
                                 <span
                                    className={ classNames({
                                       'text-xl select-none  font-bold max-h-full dark:text-major-dark': true,
                                       'truncate': truncateHeaderText,
                                       'white-space-no-wrap': truncateHeaderText,
                                       'text-major': !isWhite,
                                       'text-major-dark': isWhite,
                                    }) }
                                 >{extraTitle}</span>
                              </div>
                           </div>
                     }
                  </div>
                  <div className='flex w-auto items-center'>
                     {
                        !isMobile && viewAs && (
                           <div className='relative flex items-center'>
                              {viewAs.component}
                           </div>
                        )
                     }
                     {
                        isAdmin && Boolean(onlineMembers) && Boolean(openOnlineMembersModal) && (
                           <OnlineMembersCounter
                              count={ onlineMembers.length }
                              onClick={ openOnlineMembersModal }
                              isMobile={ isMobile }
                           />
                        )
                     }
                     {
                        isPublicMember && (
                           <div className={ `flex items-center mx-3 gap-2 sm:gap-1 header_icon_color_${ converHexToString(fontIconColor) }` }>
                              {
                                 guest && (
                                    <>
                                       <Link
                                          to={ signInLink }
                                       >
                                          <PageHeaderIcon
                                             fontIconColor={ converHexToString(fontIconColor) }
                                             fontIconSize={ fontIconSize }
                                             // onClickAction={ guestAction }
                                             text={ isMobile ? null : 'Sign in' }
                                             fontIconName='account'
                                             className={ isMobile ? 'w-10' : 'w-auto decoration decoration-major dark:decoration-white disable-icon-hover' }
                                             nonPadding
                                             textMarginX='0'
                                             minWidth=''
                                          />
                                       </Link>
                                       {
                                          !isMobile && hasAvailablePricings && (
                                             <span className={ `font-medium text-${ converHexToString(fontIconColor) } dark:text-major-dark` }>&nbsp;or&nbsp;</span>
                                          )
                                       }
                                    </>
                                 )
                              }
                              {
                                 hasAvailablePricings && (
                                    <Link
                                       to={ Router.route('JOIN').getCompiledPath() }
                                    >
                                       <PageHeaderIcon
                                          fontIconColor={ converHexToString(fontIconColor) }
                                          // onClickAction={ goToJoin }
                                          text={ guest ? 'Join' : 'Join' }
                                          className='w-auto whitespace-nowrap hover:underline decoration dark:decoration-white'
                                          nonPadding
                                          textMarginX='0'
                                          minWidth=''
                                       />
                                    </Link>
                                 )
                              }
                           </div>
                        )
                     }
                     {
                        !!tipAction && user && (
                           <div
                              data-tooltip-content='Tip'
                              data-tooltip-id='ams-bottom-tooltip'
                           >
                              <PageHeaderIcon
                                 fontIconColor={ converHexToString(fontIconColor) }
                                 fontIconSize={ fontIconSize }
                                 onClickAction={ () => tipAction() }
                                 fontIconName='tip'
                                 className='w-10'
                              />
                           </div>
                        )
                     }
                     {/* {
                        uploads.show && (
                           <PageHeaderIcon
                              fontIconColor={ converHexToString(fontIconColor) }
                              fontIconSize={ fontIconSize }
                              onClickAction={ uploadsIconAction }
                              badgeCount={ uploads.count }
                              fontIconName='upload-cloud'
                              className='upload-cloud-icon-container px-2'
                           />
                        )
                     } */}
                     {
                        uploads.show && (
                           <div
                              className='h-10 w-10 flex relative'
                           >
                              <div
                                 data-tooltip-content={ !isMobile ?  'Uploads' : '' }
                                 data-tooltip-id='ams-bottom-tooltip'
                                 role='presentation'
                                 onClick={ uploadsIconAction }
                                 className='h-10 w-10 flex items-center justify-center cursor-pointer relative upload-cloud-icon-container'
                              >
                                 <FontIcon
                                    name='upload-cloud'
                                    size={ IconSizes[fontIconSize] }
                                    color={ isUploadsPage ? 'action' : fontIconColor }
                                 />
                                 {
                                    Boolean(uploads.count) && (
                                       <span className='circle '>
                                          {uploads.count}
                                       </span>
                                    )
                                 }
                              </div>
                           </div>
                        )
                     }
                     {
                        notifications?.show && (
                           <div
                              className='h-10 w-10 flex relative'
                           >
                              <div
                                 data-tooltip-content={ !isMobile ? 'Notifications' : '' }
                                 data-tooltip-id='ams-bottom-tooltip'
                                 role='presentation'
                                 onClick={ notifications.onClick }
                                 className={ `h-10 w-10 flex items-center justify-center cursor-pointer relative upload-cloud-icon-container header_icon_color_${ converHexToString(fontIconColor) }` }
                              >
                                 <FontIcon
                                    // fontIconColor={ converHexToString(fontIconColor) }
                                    name='notifications'
                                    size={ IconSizes[fontIconSize] }
                                    color={ converHexToString(fontIconColor) }
                                    // color={ converHexToString(fontIconColor) }
                                    // darkColor={ converHexToString(fontIconColor) }
                                    // className={
                                    //    classNames({
                                    //       [`header_icon_color_${ converHexToString(fontIconColor) }`]: true,
                                    //    })
                                    // }
                                 />
                                 {
                                    !!notifications.count && (
                                       <span className='circle '>
                                          {notifications.count}
                                       </span>
                                    )
                                 }
                              </div>
                              {notifications.component}
                           </div>
                        )
                     }
                     {
                        chat && chat.show && (
                           <div
                              ref={ chat.ref }
                              className='relative'
                              style={
                                 chat.hiddeChatIcon ? {
                                    display: 'none',
                                 } : { display: 'flex' }
                              }
                           >

                              <div
                                 id={ `${ chat.id }-header-icon` }
                                 data-tooltip-id={ chat.id }
                                 data-tooltip-events='click'
                                 onClick={ () => {
                                    chat.onClickChat()
                                 } }
                                 role='presentation'
                                 className={ classNames({
                                    [`h-10 w-10 flex items-center relative justify-center cursor-pointer upload-cloud-icon-container header_icon_color_${ converHexToString(fontIconColor) }`]: true,
                                    'intercom-header-chat': chat.hasIntercomClass && isAdmin,
                                    'intercom-member-chat': chat.hasIntercomClass && !isAdmin,
                                 }) }

                              >
                                 <FontIcon
                                    data-tooltip-content={ !isMobile ? 'Chat' : '' }
                                    data-tooltip-id='ams-bottom-tooltip'
                                    name='chat'
                                    size={ IconSizes[fontIconSize] }
                                    color={ converHexToString(fontIconColor) }
                                 />
                                 {
                                    (!!chat.count && chat.count !== 0) && (
                                       <span className='circle '>
                                          {chat.count}
                                       </span>
                                    )
                                 }
                              </div>

                              {chat.component}
                           </div>
                        )
                     }
                     {
                        rightIconName && (
                           <PageHeaderIcon
                              fontIconColor={ converHexToString(fontIconColor) }
                              fontIconSize={ fontIconSize }
                              onClickAction={ rightIconAction }
                              fontIconName={ rightIconName }
                           />
                        )
                     }
                     {
                        rightSecondIconName && !!rightSecondIconShow && (
                           <div
                              role='presentation'
                              onClick={ rightSecondIconAction }
                              className='h-10 w-10 flex header-icon-min-w items-center justify-center mx-1 cursor-pointer relative'
                           >
                              {!!count && (
                                 <Fragment>
                                    <FontIcon
                                       name={ rightSecondIconName }
                                       size={ IconSizes[fontIconSize] }
                                       color={ converHexToString(fontIconColor) }
                                    />
                                    <div
                                       className='absolute
                                    top-0 right-0 w-5 h-5
                                    rounded-full text-major-dark
                                    flex justify-center
                                    items-center'
                                       style={ { backgroundColor: '#F79009' } }
                                    >
                                       {count}
                                    </div>
                                 </Fragment>
                              )}
                           </div>
                        )
                     }
                  </div>
               </div>
               {
                  isHelpIcon && !isAdmin && !showNewStyles && (
                     <div
                        data-tooltip-content='Contact support'
                        data-tooltip-id={ topHelpIconTooltip ? 'ams-top-tooltip' : 'ams-bottom-tooltip' }
                        className='h-10 w-10 flex header-icon-min-w items-center justify-center cursor-pointer relative member_contact_support'
                     >
                        <PageHeaderIcon
                           fontIconColor={ converHexToString(fontIconColor) }
                           fontIconSize={ fontIconSize }
                           onClickAction={ () => !isMobile && IntercomMoreInfo(CONTACT_SUPPORT) }
                           className={ `mx-1 ${ isMobile ? 'launch-intercom' : '' }` }
                           fontIconName='help'
                        />
                        {
                           Boolean(memberSupportUnreadCount) && (
                              <span className='circle '>
                                 {memberSupportUnreadCount}
                              </span>
                           )
                        }
                     </div>
                  )
               }
               {
                  showUser && !isAdmin && (
                     <>
                        <div className=' header-icon-min-w p-0 cursor-pointer'>
                           <img
                              src={ user.avatar }
                              alt={ user.screen_name }
                              style={ {
                                 border: '1px solid #0000002e',
                              } }
                              className='object-center object-cover rounded-full w-10 h-10'
                              data-tooltip-content=''
                              data-tooltip-id={ user.screen_name }
                           />
                           <Tooltip
                              className='avatar-buttons'
                              delayHide={ 100 }
                              id={ user.screen_name }
                              // effect='solid'
                              // aria-haspopup='true'
                              // role='example'
                              place='bottom'
                              clickable
                              style={ {
                                 color: '#041527',
                                 background: '#ffffff',
                              } }
                           >
                              <Link to={ toMyAccount } onClick={ onClickMyAccount } >
                                 <Button
                                    text='My account'
                                    backgroundColor='transparent'
                                    textColor='major'
                                    iconName='account'
                                    fontIconColor='major'
                                    darkIconcolor='major-dark'
                                    iconPosition='left'
                                    classNames='h-12'
                                    waveColor='rgba(33, 33, 33, 0.8)'
                                    alignment='end'
                                    textSize='base'
                                    fontIconSize='extraLarge'
                                    onClick={ () => {} }
                                 />
                              </Link>
                              <Button
                                 text='Log out'
                                 backgroundColor='transparent'
                                 textColor='major'
                                 iconName='logout'
                                 fontIconColor='major'
                                 darkIconcolor='major-dark'
                                 iconPosition='left'
                                 classNames='h-12'
                                 waveColor='rgba(33, 33, 33, 0.8)'
                                 alignment='end'
                                 textSize='base'
                                 fontIconSize='extraLarge'
                                 onClick={ onClickLogOut }
                              />
                           </Tooltip >
                        </div>
                     </>
                  )
               }
            </div>
         </div>
         {/* {
            isAdmin && onlineMembersModal && Boolean(goToPrivateChat) && (
               <Modal
                  contentPosition={ isMobile ? 'bottom' : 'center' }
                  onClose={ closeOnlineMembersModal }
                  contentWidth={ window?.innerWidth > 700 ? '375px' : '100%' }
                  animate={ isMobile ? animate : '' }
               >
                  <div className='min-h-[420px] pb-6'>
                     <div className='flex p-2 h-14'>
                        <div className='w-10'>
                           <Button
                              iconName='close'
                              fontIconColor='major'
                              classNames='w-full h-full'
                              padding='none'
                              backgroundColor='transparent'
                              onClick={ closeOnlineMembersModal }
                              waveColor='rgba(33, 33, 33, 0.8)'
                              fontIconSize='doubleLarge'
                              waveActive={ false }
                           />
                        </div>
                     </div>
                     <div className='w-full flex flex-col items-center justify-center gap-3'>
                        <div
                           className='flex justify-center items-center w-10 h-10 rounded-full'
                           style={ {
                              backgroundColor: GenerateColor.generateRGBColor(window.skinPrimaryColor, 0.12),
                           } }
                        >
                           <Icon
                              name='group'
                              style={ {
                                 color: window.skinPrimaryColor,
                              } }
                              size='2xl'
                           />
                        </div>
                        <span className='text-xl font-semibold'>Online members</span>
                        <div
                           className={ cx({
                              'w-full min-h-[270px] overflow-y-auto mt-3 max-h-[240px] ams-custom-scrool': true,
                              'flex flex-col items-center justify-center': !Boolean(onlineMembers.length),
                           }) }
                        >
                           {
                              Boolean(onlineMembers.length) ? (
                                 onlineMembers.map(user => (
                                    <div
                                       key={ user.uuid }
                                       className={ cx({
                                          'h-10 w-full pl-6 flex items-center justify-between hover:bg-hover cursor-pointer group': true,
                                          'pr-2': onlineMembers.length > 6,
                                          'pr-6': onlineMembers.length <= 6,
                                       }) }
                                       role='presentation'
                                       onClick={ () => {
                                          closeOnlineMembersModal()
                                          goToMemberProfile(user?.id)
                                       } }
                                    >
                                       <div className='flex items-center gap-3'>
                                          <img
                                             alt={ user.username }
                                             src={ user.avatar }
                                             className='w-7 h-7 rounded-full'
                                          />
                                          <TruncateText
                                             text={ user.username }
                                             textClass='group-hover:underline'
                                          />
                                       </div>
                                       <div>
                                          <Button
                                             iconName='messaging'
                                             fontIconSize='extraLarge'
                                             primaryColor
                                             backgroundColor='transparent'
                                             padding='none'
                                             waveActive={ false }
                                             classNames='group'
                                             onClick={ (e) => {
                                                e.stopPropagation()
                                                closeOnlineMembersModal()
                                                goToPrivateChat(user)
                                             } }
                                          />
                                       </div>
                                    </div>
                                 ))
                              ) : (
                                 <span className='text-placeholder dark:text-placeholder-dark'>No members online</span>
                              )
                           }
                        </div>
                     </div>
                  </div>
               </Modal>
            )
         } */}
      </Fragment>
   );
};

const PageHeaderIcon = ({
   fontIconColor,
   fontIconSize,
   onClickAction,
   badgeCount,
   text,
   fontIconName,
   className = 'mx-1',
   style = {},
   primaryColor = false,
   nonPadding = false,
   textMarginX = '2',
   minWidth = 'min-w-10',
}) => {


   return (
      <div
         style={ { ...style } }
         className={ `h-10 header-icon-min-w flex items-center justify-center cursor-pointer relative ${ className } header_icon_color_${ fontIconColor } ${ minWidth }` }
         onClick={ onClickAction }
         role='presentation'
      >
         <Button
            classNames={ `h-full w-full ${ text && !nonPadding ? 'px-3' : '' }` }
            backgroundColor='transparent'
            padding='none'
            iconName={ fontIconName }
            fontIconColor={ fontIconColor }
            textColor={ `${ fontIconColor } dark:text-major-dark` }
            fontIconSize={ fontIconSize }
            textSize='base'
            text={ text }
            iconPosition='left'
            primaryColor={ primaryColor }
            textMarginX={ textMarginX }
         />
         {
            badgeCount && (
               <span className='circle '>
                  {badgeCount}
               </span>
            )
         }
      </div>
   )
}

PageHeader.defaultProps = {
   fontIconSize: 24,
   fontIconColor: 'major',
   isHelpIcon: true,
   className: '',
   guest: false,
   zIndex: '15',
   truncateHeaderText: true,
   isFixed: true,
   showUser: false,
   onClickMyAccount: () => { console.log('onClickMyAccount') },
   uploadsIconAction: () => { console.log('uploadsIconAction') },
   uploads: {
      show: false,
      count: 0,
   },
   siteLogoSize: 'h-13 min-h-[52px]',
   // siteLogoSize: 'h-9',
   transparentHeader: false,
   showNewStyles: false,
   modalheaderText: {},
   extraTitle: '',
};

PageHeader.propTypes = {
   className: PropTypes.string,
   title: PropTypes.string,
   leftIconName: PropTypes.string,
   rightIconName: PropTypes.string,
   rightSecondIconName: PropTypes.string,
   uploads: PropTypes.object,
   fontIconSize: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
   ]),
   fontIconColor: PropTypes.string,
   leftIconAction: PropTypes.func,
   rightIconAction: PropTypes.func,
   rightSecondIconAction: PropTypes.func,
   count: PropTypes.number,
   isHelpIcon: PropTypes.bool,
   rightSecondIconShow: PropTypes.bool,
   guest: PropTypes.bool,
   guestAction: PropTypes.func,
   onClickMyAccount: PropTypes.func,
   uploadsIconAction: PropTypes.func,
   siteStatus: PropTypes.string,
   zIndex: PropTypes.string,
   children: PropTypes.any,
   truncateHeaderText: PropTypes.bool,
   isFixed: PropTypes.bool,
   leftOffset: PropTypes.any,
   user: PropTypes.object,
   showUser: PropTypes.bool,
   siteLogo: PropTypes.string,
   chat: PropTypes.object,
   parents: PropTypes.array,
   onClickLogOut: PropTypes.func,
   toMyAccount: PropTypes.string,
   menuIconColor: PropTypes.string,
   isWhite: PropTypes.bool,
   siteLogoSize: PropTypes.string,
   isMobile: PropTypes.bool,
   tipAction: PropTypes.func,
   goToJoin: PropTypes.func,
   viewAs: PropTypes.object,
   notifications: PropTypes.object,
   baseFontSize: PropTypes.bool,
   transparentHeader: PropTypes.bool,
   isPublicMember: PropTypes.bool,
   isAdmin: PropTypes.bool,
   onlineMembers: PropTypes.array,
   openOnlineMembersModal: PropTypes.func,
   hasAvailablePricings: PropTypes.bool,
   showNewStyles: PropTypes.bool,
   modalheaderText: PropTypes.object,
   extraTitle: PropTypes.string,
   lastItemCallback: PropTypes.func,
   topHelpIconTooltip: PropTypes.bool,
   locationPath: PropTypes.string,
   signInLink: PropTypes.string,
   isUploadsPage: PropTypes.bool,
   memberSupportUnreadCount: PropTypes.number,
};
PageHeaderIcon.propTypes = {
   className: PropTypes.string,
   fontIconSize: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
   ]),
   fontIconColor: PropTypes.string,
   onClickAction: PropTypes.func,
   badgeCount: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   text: PropTypes.string,
   fontIconName: PropTypes.string,
   textMarginX: PropTypes.string,
   style: PropTypes.object,
   minWidth: PropTypes.string,
   primaryColor: PropTypes.bool,
   nonPadding: PropTypes.bool,
};

export default PageHeader;
