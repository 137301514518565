import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';
import RadioLabel from 'common/components/elements/inputs/radio-label';
import ViewAsSelect from './view-as-select';
import { useEffect } from 'react';
import './styles.scss'
import Icon from 'common/components/elements/icons';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import TruncateText from 'common/components/elements/truncate-text';
import IconLabel from 'common/components/elements/icon-label';

const ViewAsModal  = ({
   pricings,
   isMobile,
   goToView,
   onCloseModal,
   isSelect,
   isOpenModal,
   setOpenModal,
   onCloseViewAs,
   id,
   type,
   isShowVisitor,
}) => {
   const getMembershipType = (type) => {
      const memberStatus = {};
      switch (type) {
         case 'active':
            memberStatus.name = 'Active';
            memberStatus.color = 'bg-success-12 dark:bg-success-dark-12 !text-success !dark:text-success-dark';
            memberStatus.textColor = '!text-success !dark:text-success-dark';
            break;
         case 'guest':
            memberStatus.name = 'Guest';
            memberStatus.color = 'bg-pending-12 dark:bg-pending-dark-12 !text-warning !dark:text-warning-dark';
            memberStatus.textColor = '!text-warning !dark:text-warning-dark';
            break;
         case 'cancelled':
            memberStatus.name = 'Cancelled';
            memberStatus.color = 'bg-error-12 dark:bg-error-dark-12 !text-error !dark:text-error-dark';
            memberStatus.textColor = '!text-error !dark:text-error-dark';
            break;
         case 'free':
            memberStatus.name = 'Complimentary';
            memberStatus.color = 'bg-action-12 dark:bg-action-dark-12 !text-action !dark:text-action-dark';
            memberStatus.textColor = '!text-action !dark:text-action-dark';
            break;
         case 'expired':
            memberStatus.name = 'Expired';
            memberStatus.color = 'bg-placeholder-12 dark:bg-placeholder-dark-12 text-secondary dark:text-secondary-dark ';
            memberStatus.textColor = 'text-secondary dark:text-secondary-dark ';
            break;
         case 'visitor':
            memberStatus.name = 'Visitor';
            memberStatus.color = 'bg-secondary-12 !text-major';
            memberStatus.textColor = '!text-major';
            break;
         default:
            break;
      }
      return memberStatus;
   };

   const getFiltersList = () => {
      let slug = 0;
      let filtersList = [];
      let activeData = [];
      let cancelledData = [];
      let expiredData = [];

      if(pricings && !!pricings.length) {
         activeData = pricings.map(item => {
            return {
               slug: slug++,
               name: item.name,
               value: 'active',
               pricing_id: item.id,
               status: { ...getMembershipType('active') },
               is_active: item?.is_active,
            }
         });
         cancelledData = pricings.map(item => {
            return {
               slug: slug++,
               name: item.name,
               value: 'cancelled',
               pricing_id: item.id,
               status: { ...getMembershipType('cancelled') },
               is_active: item?.is_active,
            }
         });
         expiredData = pricings.map(item => {
            return {
               slug: slug++,
               name: item.name,
               value: 'expired',
               pricing_id: item.id,
               status: { ...getMembershipType('expired') },
               is_active: item?.is_active,
            }
         });
      }


      filtersList = [
         {
            slug: slug++,
            name: 'Complimentary members',
            value: 'free',
            status: { ...getMembershipType('free') },
            is_active: true,
         },
         {
            slug: slug++,
            name: 'Guest members',
            value: 'guest',
            status: { ...getMembershipType('guest') },
            is_active: true,
         },
         ...activeData,
         ...cancelledData,
         ...expiredData,
      ];
      if(isShowVisitor) {
         filtersList = [
            {
               slug: slug++,
               name: 'Complimentary members',
               value: 'free',
               status: { ...getMembershipType('free') },
               is_active: true,
            },
            {
               slug: slug++,
               name: 'Guest members',
               value: 'guest',
               status: { ...getMembershipType('guest') },
               is_active: true,
            },
            {
               slug: slug++,
               name: 'Visitor',
               value: 'visitor',
               status: { ...getMembershipType('visitor') },
               is_active: true,
            },
            ...activeData,
            ...cancelledData,
            ...expiredData,
         ];

      }
      return filtersList;
   }
   const [previewBtn, setPreviewBtn] = useState(false)
   const [filters, setFilters] = useState({
      name: 'Select member type to preview',
      value: 'none',
      slug: '',
      status: {},
   });
   const [activeSelectedValue, setActiveSelectedValue] = useState({
      status: getMembershipType(filters.value),
      name: '',
      value: filters.value,
      slug: filters.slug,
   })

   const [isChecked, setIsChecked] = useState(true)

   useEffect(() => {
      let data = getFiltersList();
      data = data.filter(item => item.value === type);
      if(['active', 'cancelled', 'expired'].includes(type) && !!id) {
         // eslint-disable-next-line eqeqeq
         data = data.filter(item => item.pricing_id == id)
      }
      setActiveSelectedValue({ ...data[0], name: '' })
      setFilters({ ...data[0] })
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [pricings, id, type])

   function onSimpleFilter(value) {
      const data = getFiltersList();
      setFilters({ ...data.filter(item => item.slug === value)[0] });
      setActiveSelectedValue({ ...data.filter(item => item.slug === value)[0] })
      setPreviewBtn(true)
   }
   return (
      <>
         {
            !!isSelect && (
               <ViewAsSelect
                  isMobile={ isMobile }
                  goToView={ () => goToView({ id: filters.pricing_id, type: filters.value  }) }
                  data={ getFiltersList() }
                  onCloseViewAs={ onCloseViewAs }
                  previewBtn={ previewBtn }
                  name={ filters.name }
                  onSimpleFilter={ onSimpleFilter }
                  onClickSelect={ () => setOpenModal(true) }
                  activeSelectedValue={ activeSelectedValue }
                  filterClassNames={ `${ getMembershipType(type).textColor }` }
                  isPublished={ filters?.is_active }
                  iconColor={ filters?.status?.textColor }
               />
            )
         }
         {
            !!isOpenModal && (
               <Modal
                  onClose={ onCloseModal }
                  contentPosition={ isMobile ? 'bottom' : 'center' }
                  isCloseAction={ false }
                  contentWidth={ window?.innerWidth > 700 ? '375px' : '100%' }
               >
                  <div className='view-as-sites-modal'>
                     <div className='flex items-center py-4 px-4'>
                        <div className='w-6 h-6 mr-4'>
                           <Button
                              iconName='close'
                              fontIconColor='major'
                              classNames='w-full h-full'
                              padding='none'
                              backgroundColor='transparent'
                              onClick={ onCloseModal }
                              waveColor='rgba(33, 33, 33, 0.8)'
                              fontIconSize='extraLarge'
                           />
                        </div>
                        {/* <span className='leading-tight text-major dark:text-major-dark'> View as...</span> */}
                     </div>
                     <IconLabel
                        color='action'
                        name='views'
                        size='2xl'
                        className='h-10 w-10 mx-auto'
                     />
                     <span className='leading-8 mt-3 flex items-center justify-center text-major dark:text-major-dark text-xl font-semibold select-none' style={ {
                        lineHeight: '30px',
                     } }>View my site</span>
                     <p className='py-4 text-center px-6 leading-6 text-secondary dark:text-secondary-dark '>Select a viewer type below to see your site as it would appear to them</p>
                     <div className='pb-4 px-6'>
                        <CheckboxLabel
                           label='Only show published memberships '
                           onChange={ (name, value) => setIsChecked(!isChecked) }
                           checked={ isChecked }
                           name='agree'
                           textColor='secondary dark:text-secondary-dark '
                           className='text-sm leading-5 font-medium !p-0'
                           checkboxSize='4'
                           margin='3'
                        />
                     </div>
                     <div className=' pb-6 overflow-auto max-h-[360px] view-as-labels'>
                        {
                           getFiltersList().map(item => {
                              if(isChecked && item?.is_active === false) return null
                              return (
                                 <Item
                                    key={ item.slug }
                                    id={ item.slug }
                                    checked={ item.slug === filters.slug }
                                    onChange={ () => goToView({ id: item.pricing_id, type: item.value  }) }
                                    status={ item.status }
                                    name={ item.name }
                                    textColor
                                    isPublished={ item?.is_active === true }
                                    filterType={ item.value }
                                 />
                              )
                           })
                        }
                     </div>
                  </div>
               </Modal>
            )
         }
      </>
   )
}



const Item = ({
   id,
   checked,
   onChange,
   status,
   name,
   isPublished,
   filterType,
}) => {


   return (
      <RadioLabel
         id={ id }
         checked={ checked }
         onChange={ onChange }
         className='view-as-label hover:bg-hover dark:hover:bg-hover-dark px-6'
      >
         <div className='flex w-full pr-2'>
            {
               status ? (
                  <div className={ cx(
                     'flex items-center justify-center w-[100px] h-6 rounded-full text-xs mr-3 ',
                     status.color,
                     status.textColor
                  ) }>
                     {
                        !isPublished && filterType !== 'free' && filterType !== 'guest' && filterType !== 'visitor' && <Icon
                           name='unpublish'
                           className={ `h-3 w-3 text-xs flex items-center mr-2 ${ status.textColor }`  }
                        />
                     }
                     <p className=''
                     >
                        {status.name}
                     </p>
                  </div>
               ) : ''
            }
            <TruncateText
               className='mt-px pt-px items-center'
               text={ name }
               textClass='font-normal text-sm text-major leading-5 dark:text-major-dark view-name'
               textSize='14px'
               fontWeight='400'
               width='calc(100% - 100px)'
            />
         </div>
      </RadioLabel>
   )
}

ViewAsModal.defaultProps = {
   isOpenModal: true,
}

ViewAsModal.propTypes = {
   pricings: PropTypes.array,
   goToView: PropTypes.func,
   isMobile: PropTypes.bool,
   isSelect: PropTypes.bool,
   onCloseModal: PropTypes.func,
   isOpenModal: PropTypes.bool,
   setOpenModal: PropTypes.func,
   onCloseViewAs: PropTypes.func,
   id: PropTypes.number,
   type: PropTypes.string,
   isShowVisitor: PropTypes.bool,
}

Item.propTypes = {
   id: PropTypes.number,
   checked: PropTypes.bool,
   onChange: PropTypes.func,
   status: PropTypes.object,
   name: PropTypes.string,
   isPublished: PropTypes.bool,
   filterType: PropTypes.string,
}

export default ViewAsModal
