import PullToRefresh from 'pulltorefreshjs';
class SyncPageScoll {
   scrolledPosition = 0;
   innerHeight = 0


   static disableScroll = () => {
      this.scrolledPosition = window.pageYOffset;
      this.innerHeight = window.innerHeight;
      const body = document.querySelector('body');
      const html = document.querySelector('html');
      body.classList.add('body_non_scroll-v2');
      body.style.height = `${ this.innerHeight }px`
      body.style.maxHeight = `${ this.innerHeight }px`
      body.style.minHeight = `${ this.innerHeight }px`
      body.style.top = `-${ this.scrolledPosition }px`
      html.classList.add('body_non_scroll');
      html.style.height = `${ this.innerHeight }px`
      html.style.maxHeight = `${ this.innerHeight }px`
      html.style.minHeight = `${ this.innerHeight }px`
   }

   static enableScroll = () => {
      const body = document.querySelector('body');
      const html = document.querySelector('html');
      body.classList.remove('body_non_scroll-v2');
      body.style.top = ''
      body.style.height = `100%`
      body.style.maxHeight = ''
      body.style.minHeight = ''
      html.classList.remove('body_non_scroll');
      html.style.top = ''
      html.style.height = `100%`
      html.style.maxHeight = ''
      html.style.minHeight = ''
      window.scrollTo(0, this.scrolledPosition);
      this.scrolledPosition = 0
   }

   static scrollDiff = () => {
      let diff = this.topMenuRef.clientHeight;
      if(this.mainContentTopTabsRef) {
         diff += this.mainContentTopTabsRef.clientHeight;
      }
      return diff;
   }
   static syncScroll(isOpenModals) {
      if(!isOpenModals) {
         PullToRefresh.setPassiveMode(false)
         this.enableScroll();
      } else {
         PullToRefresh.setPassiveMode(true)
         this.disableScroll();
      }
   }
}
export default SyncPageScoll;
