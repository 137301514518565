import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgeVerification from 'sites/views/age-verification';
import LocalStorage from 'sites/utils/LocalStorage';
import {
   screenWidthSelector,
   siteInfoSelector,
   isSiteInitedSelector,
   isAdultVerifiedSelector,
} from 'sites/state/modules/common/selectors'

class AgeVerificationContainerContainer extends Component {
   static propTypes = {
      screenWidth: PropTypes.number,
      siteInfo: PropTypes.object,
      isSiteInited: PropTypes.bool,
      isAdultVerified: PropTypes.string,
   };
   state = {
      showAgeVerifiedModal: LocalStorage.getItem('age_verified') !== 'true',
   }
   agreeVerified = () => {
      LocalStorage.setItem('age_verified', true);
      this.setState({ showAgeVerifiedModal: false })
   }

   render() {
      const { showAgeVerifiedModal } = this.state;
      const {
         siteInfo,
         screenWidth,
         isSiteInited,
         isAdultVerified,
      } = this.props
      let component = null;
      if(showAgeVerifiedModal && isSiteInited && !siteInfo.is_site_in_temporary_mode && isAdultVerified === 'unnecessary') {
         component =
            <AgeVerification
               agree={ this.agreeVerified }
               isMobile={ screenWidth < 701 }
               isUtahState={ siteInfo.utah_state }
            />
      }

      return component;
   }
}
const mapStateToProps = state => ({
   screenWidth: screenWidthSelector(state),
   siteInfo: siteInfoSelector(state),
   isSiteInited: isSiteInitedSelector(state),
   isAdultVerified: isAdultVerifiedSelector(state),
});
export default connect(mapStateToProps)(AgeVerificationContainerContainer);
