import React from 'react';
import PropTypes from 'react';
import { getSiteUrl } from 'sites/utils/Helpers';


const PrivacyPolicy = ({
   legalPages,
}) => {

   let currentInfo

   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'privacy_policy')
   }
   
   return (
      currentInfo?.html_content ?
         <>
            <div dangerouslySetInnerHTML={ {
               __html: currentInfo?.html_content,
            } } className='text-major dark:text-major-dark policy-content'
            />   
         </>
         :
         <>
            <p className='text-2xl text-major dark:text-major-dark mb-3' style={ { lineHeight: '28px' } }>Personally-Identifying Information</p>
            <p className='dark:text-secondary-dark '>
               {getSiteUrl()} only discloses personally-identifying information to employees, contractors and affiliated organizations that (i) need to know that information in order to process it on {getSiteUrl()}’s behalf, or to provide services available on {getSiteUrl()}, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using {getSiteUrl()}, you consent to the transfer of such information to them. {getSiteUrl()} will not rent or sell personally-identifying information to ANYONE. Other than to {getSiteUrl()}’s employees, contractors or affiliated organizations, as described above, {getSiteUrl()} will disclose personally-identifying information only in response to a subpoena, court order or other governmental request, or when {getSiteUrl()} believes in good faith that disclosure is reasonably necessary to protect its property or rights, third parties or the public at large. {getSiteUrl()} takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>Cookies</p>
            <p className='dark:text-secondary-dark '>
               A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. {getSiteUrl()} uses cookies to help identify and track visitors, and usage patterns. If you do not wish to have cookies placed on your computer by {getSiteUrl()}, you must set your browser to refuse cookies, with the drawback that certain features may not function properly.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>Business Transfers</p>
            <p className='dark:text-secondary-dark '>
               If {getSiteUrl()}, or substantially all of its assets, was acquired, or in the unlikely event that {getSiteUrl()} went out of business or entered bankruptcy, user information would be one of the assets that would be transferred or acquired by the third party. You acknowledge that such transfers may occur, and that any acquirer may continue to use your personal information as set forth in this policy.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6' style={ { lineHeight: '28px' } }>Privacy Policy Changes</p>
            <p className='dark:text-secondary-dark '>
               Although most changes are likely to be minor, {getSiteUrl()} may change its Privacy Policy from time to time at its sole discretion.
            </p>
         </>
   ) 
};

PrivacyPolicy.propTypes = {
   legalPages: PropTypes.array,
}


export default PrivacyPolicy;
