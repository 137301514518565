export const FETCH_DETAILS_START = "store/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "store/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "store/FETCH_DETAILS_FAILED";
export const CLEAR_STATE = "store/CLEAR_STATE"

export const FETCH_NEXT_DETAILS_START = "store/FETCH_NEXT_DETAILS_START";
export const FETCH_NEXT_DETAILS_COMPLETED = "store/FETCH_NEXT_DETAILS_COMPLETED";
export const FETCH_NEXT_DETAILS_FAILED = "store/FETCH_NEXT_DETAILS_FAILED";

export const FETCH_BY_FILTER_DETAILS_START = "store/FETCH_BY_FILTER_DETAILS_START";
export const FETCH_BY_FILTER_DETAILS_COMPLETED = "store/FETCH_BY_FILTER_DETAILS_COMPLETED";
export const FETCH_BY_FILTER_DETAILS_FAILED = "store/FETCH_BY_FILTER_DETAILS_FAILED";
export const FETCH_DATA_BY_FILTERS = "store/FETCH_DATA_BY_FILTERS"

export const FETCH_STORE_BY_ID_START = "store/FETCH_STORE_BY_ID_START";
export const FETCH_STORE_BY_ID_COMPLETED = "store/FETCH_STORE_BY_ID_COMPLETED";
export const FETCH_STORE_BY_ID_FAILED = "store/FETCH_STORE_BY_ID_FAILED";

export const FETCH_CATEGORIES_COMPLETED = "store/FETCH_CATEGORIES_COMPLETED";
export const UPDATE_STORE_BY_ID = 'store/UPDATE_STORE_BY_ID';

export const CHANGE_SUCCESS_MODAL_VALUES = 'store/CHANGE_SUCCESS_MODAL_VALUES'

export const SUBSCRIBE_OR_UNSUBSCRIBE_FUNCTION = 'store/SUBSCRIBE_OR_UNSUBSCRIBE_FUNCTION'

//orders

export const FETCH_ORDERS_DATA_START = 'store/FETCH_ORDERS_DATA_START'
export const FETCH_ORDERS_DATA_COMPLETED = 'store/FETCH_ORDERS_DATA_COMPLETED'
export const FETCH_ORDERS_BY_FILTER_START = 'store/FETCH_ORDERS_BY_FILTER_START'
export const FETCH_ORDERS_BY_FILTER_COMPLETED = 'store/FETCH_ORDERS_BY_FILTER_COMPLETED'

export const FETCH_NEW_ORDERS_START = 'store/FETCH_NEW_ORDERS_START'
export const FETCH_NEW_ORDERS_COMPLETED = 'store/FETCH_NEW_ORDERS_COMPLETED'
export const FETCH_NEW_ORDERS_FAILED = 'store/FETCH_NEW_ORDERS_FAILED'
export const MAKE_BUNDLE_ITEMS_WATCHED = 'store/MAKE_BUNDLE_ITEMS_WATCHED'

export const FETCH_ORDER_REQUEST_BY_ID_START = "store/FETCH_ORDER_REQUEST_BY_ID_START"
export const FETCH_ORDER_REQUEST_BY_ID_COMPLETED = "store/FETCH_ORDER_REQUEST_BY_ID_COMPLETED"
export const UPDATE_ACTIVE_ORDER_REQUEST = "store/UPDATE_ACTIVE_ORDER_REQUEST"
export const UPDATE_ORDER_REQUESTS_DATA = "store/UPDATE_ORDER_REQUESTS_DATA"
export const UPDATE_STORE_LIST = "store/UPDATE_STORE_LIST_DATA"
export const ON_CHANGE_FAVORITE_ITEM_COMPLETED = "store/ON_CHANGE_FAVORITE_ITEM_COMPLETED";
