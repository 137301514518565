const initialState = {
   data: {},
   isInProgress: false,
   emailVerified: false,
   errors: {},
   verificationStatus: {},
   openAgreementModal: false,
};

export default initialState;
