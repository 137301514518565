import React from "react";
import Button from 'common/components/elements/buttons/primary';
import PropTypes from 'prop-types';

import { onClickAddToCalendar } from 'common/utils/utils';


const  AddToCalendar = ({
   date,
   title,
   description,
   className,
   buttonState,
}) => {
   return (

      <div className={ className }>
         <Button
            text='Add to calendar'
            classNames='h-full px-3'
            padding='none'
            primaryColor
            onClick={ () => onClickAddToCalendar(title, description, date) }
            borderRadius='label'
            iconName='calendar'
            iconPosition='left'
            darkIconColor=''
            { ...buttonState }
         />
      </div>
   );
}
AddToCalendar.defaultProps = {
   className: 'h-5 mt-4 w-40',
   buttonState: {
      textSize: 'small',
      fontIconSize: 'small',
      backgroundColor: 'transparent',
   },
};
AddToCalendar.propTypes = {
   title: PropTypes.string,
   date: PropTypes.string,
   description: PropTypes.string,
   buttonState: PropTypes.object,
   className: PropTypes.string,
};
export default AddToCalendar
