import * as types from './types';

const onClickChat = bool => ({
   type: types.OPEN_CHAT_NOTIFICATION_MODAL,
   payload: bool,
});


const chatInitRequest = () => ({
   type: types.CHAT_INIT_REQUEST,
});
const chatInitCompleted = data => ({
   type: types.CHAT_INIT_COMPLETED,
   payload: data,
});
const chatInitFailed = () => ({
   type: types.CHAT_INIT_FAILED,
});


const fetchMessagesRequest = data => ({
   type: types.FETCH_MESSAGES_REQUEST,
   payload: data,
});
const fetchMessagesCompleted = data => ({
   type: types.FETCH_MESSAGES_COMPLETED,
   payload: data,
});
const fetchMessagesFailed = () => ({
   type: types.FETCH_MESSAGES_FAILED,
});


const fetchNextMessagesRequest = () => ({
   type: types.FETCH_NEXT_MESSAGES_REQUEST,
});
const fetchNextMessagesCompleted = data => ({
   type: types.FETCH_NEXT_MESSAGES_COMPLETED,
   payload: data,
});
const fetchNextMessagesFailed = () => ({
   type: types.FETCH_NEXT_MESSAGES_FAILED,
});


const fetchMessageLikedMembersRequest = bool => ({
   type: types.FETCH_MESSAGE_LIKED_MEMBERS_REQUEST,
   payload: bool,
});
const fetchMessageLikedMembersCompleted = data => ({
   type: types.FETCH_MESSAGE_LIKED_MEMBERS_COMPLETED,
   payload: data,
});
const fetchMessageLikedMembersFailed = () => ({
   type: types.FETCH_MESSAGE_LIKED_MEMBERS_FAILED,
});
const setOnlineUsersAction = (data, action, fetch) => ({
   type: types.SET_ONLINE_USERS_ACTIONS,
   payload: { data, action, fetch },
});
const setSocketConnectedAction = bool => ({
   type: types.SET_SOCKET_CONNECTED_ACTION,
   payload: bool,
});

const setMessagLike = data => ({
   type: types.SET_MESSAGE_LIKE_ACTIONS,
   payload: data,
});

const deleteMessage = data => ({
   type: types.DELETE_MESSAGE,
   payload: data,
});

const addNewMessage = data => ({
   type: types.ADD_NEW_MESSAGE,
   payload: data,
});

const updateMessageData = data => ({
   type: types.UPDATE_MESSAGE_DATA,
   payload: data,
});
const updateConverstionData = data => ({
   type: types.UPDATE_CONVERSTION_DATA,
   payload: data,
});
const updatePrivateConversation = id => ({
   type: types.UPDATE_PRIVATE_CONVERSATION_ID,
   payload: id,
});
const setMutedMemberInfo = data => ({
   type: types.SET_MUTED_MEMBER,
   payload: data,
});
const setChatPanelAction = data => ({
   type: types.SET_CHAT_PANEL,
   payload: data,
});
const clearChatInfo = () => ({
   type: types.CLEAR_CHAT_INFO,
});
const setActiveTab = (data) => ({
   type: types.SET_ACTIVE_TAB,
   payload: data,
});
const setChatSettings = (data) => ({
   type: types.SET_CHAT_SETTINGS,
   payload: { data },
});
const setChatIconState = (data) => ({
   type: types.SET_CHAT_ICON_STATE,
   payload: data,
});
const openClosedConversationCompleted = () => ({
   type: types.OPEN_CLOSED_CONVERSATION_COMPLETED,
})
const showLoaderAfterTranslationAction = (payload) => ({
   type: types.SHOW_LOADER_DURING_THE_TRANSLATION,
   payload,
});

export {
   onClickChat,

   chatInitRequest,
   chatInitCompleted,
   chatInitFailed,

   fetchMessagesRequest,
   fetchMessagesCompleted,
   fetchMessagesFailed,

   fetchNextMessagesRequest,
   fetchNextMessagesCompleted,
   fetchNextMessagesFailed,

   fetchMessageLikedMembersRequest,
   fetchMessageLikedMembersCompleted,
   fetchMessageLikedMembersFailed,
   setOnlineUsersAction,
   setSocketConnectedAction,
   setMessagLike,
   deleteMessage,
   addNewMessage,
   updateMessageData,
   updateConverstionData,
   updatePrivateConversation,

   setMutedMemberInfo,

   setChatPanelAction,
   clearChatInfo,
   setActiveTab,
   setChatSettings,
   setChatIconState,
   openClosedConversationCompleted,

   showLoaderAfterTranslationAction,
};
