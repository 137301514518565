import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'common/components/elements/icons';
import cx from 'classnames';

import './index.css';

export const Radio = ({
   disabled, text, value, checked, className, name, id, onChange, otherCheckboxIcon, defaultCursor, type, hasError, ...props
}) => {
   const optional = {};
   optional.checked = checked;

   return (
      <label htmlFor={ id } className={ ` w-5 h-5 block relative cursor-pointer radio select-none ${ className }` }>
         <input
            { ...props }
            disabled={ disabled }
            checked={ optional.checked }
            type='radio'
            name={ name }
            onChange={ (e) => onChange(e.target.name, e.target.value) }
            className='absolute opacity-0 cursor-pointer'
            id={ id }
         />
         {
            !otherCheckboxIcon && (
               <div
                  className={
                     cx({
                        [`select-none border  absolute rounded-full flex items-center justify-center radio-checkmark w-5 h-5 min-w-[20px] min-h-[20px]`]: true,
                        'skin-primary-border bg-hover dark:bg-grey-hover-dark': optional.checked && !disabled && !hasError,
                        'shadow-inner': !optional.checked || disabled || hasError,
                        'border-error dark:border-error-12': !disabled && hasError,
                        'bg-panel dark:bg-black-field': !optional.checked && !disabled,
                        'bg-grey-hover dark:bg-grey-hover-dark border-major-12 dark:border-major-dark-12': disabled,
                        'border-secondary-12 dark:border-secondary-dark-12': !hasError && !optional.checked,
                        'cursor-default': !!defaultCursor,
                        'cursor-pointer': !defaultCursor,
                     })
                  }
               >
                  {
                     optional.checked && (
                        <span
                           className={
                              cx({
                                 [`rounded-full radio-circle h-[10px] w-[10px]`]: true,
                                 'skin-primary-background': !disabled && !hasError,
                                 'bg-error dark:bg-error-dark': !disabled && hasError,
                                 'bg-disabled dark:bg-disabled-dark': disabled,
                              })
                           }
                        >
                        </span>
                     )
                  }
               </div>

            )
         }
         {
            otherCheckboxIcon && (
               <div className='h-5 w-5 flex items-center justify-center'>
                  <Icon
                     name={ optional.checked ? 'circle-checked' : 'circle-unchecked' }
                     size='2xl'
                     primaryColor={ window.skinSecondaryColor }
                  />
               </div>
            )
         }
      </label>
   );
};

Radio.defaultProps = {
   className: '',
   otherCheckboxIcon: false,
   type: '',
};

Radio.propTypes = {
   disabled: PropTypes.bool,
   text: PropTypes.string,
   value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
   ]),
   checked: PropTypes.bool,
   className: PropTypes.string,
   name: PropTypes.string,
   id: PropTypes.string,
   onChange: PropTypes.func,
   otherCheckboxIcon: PropTypes.bool,
   type: PropTypes.string,
   hasError: PropTypes.bool,
   defaultCursor: PropTypes.bool,
};

Radio.contextTypes = {
   radioGroup: PropTypes.object,
};


export default Radio;
