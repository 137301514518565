export const FETCH_REQUEST = "myUnlocks/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "myUnlocks/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "myUnlocks/FETCH_DETAILS_FAILED";

export const FETCH_BY_FILTER_REQUEST = "myUnlocks/FETCH_BY_FILTER_REQUEST";
export const FETCH_BY_FILTER_DETAILS_COMPLETED = "myUnlocks/FETCH_BY_FILTER_DETAILS_COMPLETED";
export const FETCH_BY_FILTER_DETAILS_FAILED = "myUnlocks/FETCH_BY_FILTER_DETAILS_FAILED";

export const FETCH_NEXT_DATA_REQUEST = "myUnlocks/FETCH_NEXT_DATA_REQUEST";
export const FETCH_NEXT_DATA_COMPLETED = "myUnlocks/FETCH_NEXT_DATA_COMPLETED";
export const FETCH_NEXT_DATA_FAILED = "myUnlocks/FETCH_NEXT_DATA_FAILED";
export const TOGGLE_LIKE = "myUnlocks/TOGGLE_LIKE";
