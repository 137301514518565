const initialState = {
   isFetching: true,
   resources: {},

   mediaDownloadButton: false,

   wrongDownloadModal: {
      isOpen: false,
      header: null,
      body: null,
   },
};

export default initialState;
