
class ChatHepler {
   static syncDialogueScroll = (behavior = 'auto', id, top) => {
      let elm = document.querySelector(`.messages-content`)
      // let elm = document.querySelector(`#messages-content-${ id }`)
      // if(!id) {
      // }
      if(!elm) {
         return
      }
      const { scrollHeight, offsetHeight, scrollTop } =  elm
      if(scrollTop === scrollHeight - offsetHeight) {
         return
      }
      let timeout = setTimeout(() => {
         const lastElement = elm.querySelector('.ams-chat-last-message-item')
         let lastelmH = 34
         if(lastElement){
            lastelmH = lastElement.offsetHeight
         }
         elm.scrollTo({
            top: (scrollHeight - offsetHeight) + lastelmH + 34,
            behavior: 'auto',
         })
         clearTimeout(timeout)
      }, 10)

   }
   static inputState = (bool = true) => {
      const input = document.querySelector('.newMessageFieldBox__input')
      if(input) {
         if(bool) {
            input.focus()
         } else {
            input.blur()
         }
      }

   }
   static isCursorPagination(){
      return false;
   }
}
export default ChatHepler;
