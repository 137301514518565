import { toast } from 'react-toastify';

const positionToasterContainer = () => {
   let header = null;
   let topOffset = 12;
   const elem = document.getElementsByClassName('page__header');
   if(elem) {
      header = elem[0]
   }
   if(header) {
      topOffset += header.clientHeight;
      const toasterContainer = document.getElementsByClassName('ams-toast-container')[0];
      if(toasterContainer) {
         toasterContainer.style.top = `${ topOffset }px`;
      }
   } else {
      topOffset += 44;
      const toasterContainer = document.getElementsByClassName('ams-toast-container')[0];
      if(toasterContainer) {
         toasterContainer.style.top = `${ topOffset }px`;
         toasterContainer.style.marginRight = `16px`;
         toasterContainer.style.marginLeft = `16px`;
      }
   }
}

export default {
   remove(text, options = {}) {
      toast(text, {
         className: 'ams-toast bg-success dark:bg-success-dark !w-fit',
         closeButton: false,
         draggable: false,
         onOpen: positionToasterContainer,
         ...options,
      });
   },
   success(text, options = {}) {
      toast(text, {
         className: 'ams-toast bg-success dark:bg-success-dark !w-fit',
         closeButton: false,
         draggable: false,
         onOpen: positionToasterContainer,
         ...options,
      });
   },
   error(text, options = {}) {
      toast(text, {
         className: 'ams-toast bg-error dark:bg-error-dark !w-fit',
         closeButton: false,
         draggable: false,
         onOpen: positionToasterContainer,
         ...options,
      });
   },
   info(text, options = {}) {
      toast(text, {
         className: 'ams-toast bg-success dark:bg-success-dark !w-fit',
         closeButton: false,
         draggable: false,
         onOpen: positionToasterContainer,
         ...options,
      });
   },
};
