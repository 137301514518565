import socketIOClient from 'socket.io-client';
import Auth from 'sites/utils/Auth'
import { getProtocol } from 'common/utils/utils';
let chatSocket = null;
let liveStreamSocket = null
let notificationSocket = null
let orderRequsetsSocket = null

const getSocketBaseUrl = (siteName) => {
   let url;
   url = `${ getProtocol() }${ siteName }.${ process.env.REACT_APP_AMS_DOMAIN }`;

   return url
}
const initChatSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/chat`
   if(!chatSocket) {
      chatSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(chatSocket.disconnected) {
         chatSocket.connect()
         window.siteChatSocket = chatSocket
      }
   }
   return chatSocket;
}
const initLiveStreamSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/live-stream`
   if(!liveStreamSocket) {
      liveStreamSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(liveStreamSocket.disconnected) {
         liveStreamSocket.connect()
         window.siteLiveStreamSocket = liveStreamSocket
      }
   }
   return liveStreamSocket;
}
const initNotificationsSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/notifications`
   if(!notificationSocket) {
      notificationSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(notificationSocket.disconnected) {
         notificationSocket.connect()
         window.siteNotificationSocket = notificationSocket
      }
   }
   return notificationSocket;
}

const initOrderRequestsSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/order-requests`
   if(!orderRequsetsSocket) {
      orderRequsetsSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
         reconnectionDelay: 50,
         auth: (cb) => {
            cb({
               token: Auth.getToken(),
            });
         },
      });
   }
   if(autoConnect) {
      if(orderRequsetsSocket.disconnected) {
         orderRequsetsSocket.connect()
      }
   }
   return orderRequsetsSocket;
}

export {
   initChatSocket,
   initLiveStreamSocket,
   initNotificationsSocket,
   initOrderRequestsSocket,
}
