export const FETCH_REQUEST_START = "purchase/FETCH_REQUEST_START";
export const FETCH_DETAILS_COMPLETED = "purchase/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "purchase/FETCH_DETAILS_FAILED";

export const FETCH_PAYMENT_OPTIONS_START = "purchase/FETCH_PAYMENT_OPTIONS_START";
export const FETCH_PAYMENT_OPTIONS_COMPLETED = "purchase/FETCH_PAYMENT_OPTIONS_COMPLETED";
export const FETCH_PAYMENT_OPTIONS_FAILED = "purchase/FETCH_PAYMENT_OPTIONS_FAILED";

export const PAYMENT_PURCHASE_START = "purchase/PAYMENT_PURCHASE_START";
export const PAYMENT_PURCHASE_COMPLETED = "purchase/PAYMENT_PURCHASE_COMPLETED";
export const PAYMENT_PURCHASE_FAILED = "purchase/PAYMENT_PURCHASE_FAILED";

export const CHOOSE_PURCHASE_COMPLETED = "purchase/CHOOSE_PURCHASE_COMPLETED";


export const PAYMENT_DECLINED_START = "purchase/PAYMENT_DECLINED_START";
export const PAYMENT_DECLINED_COMPLETED = "purchase/PAYMENT_DECLINED_COMPLETED";

export const CLEAR_ERROR_ACTION = "purchase/CLEAR_ERROR_ACTION";
export const PAYMENT_PURCHASE_USING_WALLET = "purchase/PAYMENT_PURCHASE_USING_WALLET";
export const SET_CONTENT_AVILABLE_MEMBERSHIP = "purchase/SET_CONTENT_AVILABLE_MEMBERSHIP";

export const VALIDATE_PROMO_CODE_START = "purchase/VALIDATE_PROMO_CODE_START";
export const VALIDATE_PROMO_CODE_COMPLETED = "purchase/VALIDATE_PROMO_CODE_COMPLETED";
export const VALIDATE_PROMO_CODE_FAILED = "purchase/VALIDATE_PROMO_CODE_FAILED";
export const UPDATE_PROMO_CODE_ERROR = "purchase/UPDATE_PROMO_CODE_ERROR";
export const CLEAR_APPLIED_STATE = "purchase/CLEAR_APPLIED_STATE"
export const PAYMENT_PURCHASE_UPDATE_DATA = "purchase/PAYMENT_PURCHASE_UPDATE_DATA"
export const SET_FREE_TRAIL_MODAL = "purchase/SET_FREE_TRAIL_MODAL"
