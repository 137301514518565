import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';
import SyncPageScoll from 'common/utils/pageScrollSync';

const reducersMap = {
   [types.UPDATE_NOTIFICATIONS_DATA]: (state, action) => {
      const { data }  = action.payload;
      let unseenCount = state.unseenCount;
      if(!state.list?.data) {
         return {
            ...state,
            unseenCount: state.unseenCount + 1,
         }
      }
      let newData = [...state.list?.data];
      if(!!newData.filter(item => item.id === data.id).length) {
         newData = newData.map(item => {
            if(item.id === data.id) {
               return {
                  ...item,
                  ...data,
               }
            }
            return item;
         })
      } else {
         unseenCount++;
         newData = [
            data,
            ...newData,
         ];
      }
      return {
         ...state,
         list: {
            ...state.list,
            data: newData,
         },
         unseenCount,
         isEmptyNotifications: newData?.length === 0,
      };
   },

   [types.MAKE_ALL_AS_SEEN_NOTIFICATIONS]: (state, action) => {
      return {
         ...state,
         unseenCount: 0,
      };
   },

   [types.MAKE_ALL_AS_READ_NOTIFICATIONS]: (state, action) => {
      const { id, bool } = action.payload;
      let data = [...state.list.data];
      let unreadCount = state.unreadCount || 0;
      if(bool){
         unreadCount = !!id ? ++unreadCount  : 0;
      } else {
         unreadCount = !!id ? --unreadCount  : 0;
      }
      data = data.map(item => {
         if(!id || (!!id && id === item.id)) {
            return {
               ...item,
               is_read: !bool,
            };
         }
         return item;
      })
      return {
         ...state,
         list: {
            ...state.list,
            data: [...data],
         },
         unreadCount: unreadCount,
      };
   },


   [types.FETCH_NOTIFICATIONS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingNotifications: true,
         isEmptyByFilterNotifications: false,
         isFetchingNewNotifications: false,
         isFetchingByFilterNotifications: false,
         isEmptyNotifications: false,
      };
   },
   [types.FETCH_NOTIFICATIONS_COMPLETED]: (state, action) => {
      const { data } = action.payload;
      return {
         ...state,
         list: data?.notifications,
         unseenCount: data?.badge_count,
         unreadCount: data?.unread_count,
         isFetchingNotifications: false,
         isEmptyNotifications: data?.notifications?.data?.length === 0,
      };
   },
   [types.FETCH_NOTIFICATIONS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingNotifications: false,
      };
   },

   [types.FETCH_NEW_NOTIFICATIONS_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingNewNotifications: true,
      };
   },
   [types.FETCH_NEW_NOTIFICATIONS_COMPLETED]: (state, action) => {
      const data  = action.payload;
      let list = {
         ...state.list,
         ...data?.notifications,
         data: [
            ...state.list.data,
            ...data?.notifications?.data,
         ],
      };
      return {
         ...state,
         list: list,
         unseenCount: data.badge_count,
         isFetchingNewNotifications: false,
      };
   },
   [types.FETCH_NEW_NOTIFICATIONS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingNewNotifications: false,
      };
   },

   [types.FETCH_NOTIFICATIONS_BY_FILTER_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingByFilterNotifications: true,
      };
   },
   [types.FETCH_NOTIFICATIONS_BY_FILTER_COMPLETED]: (state, action) => {
      const data = action.payload;
      return {
         ...state,
         list: data?.notifications,
         unseenCount: data?.badge_count,
         isFetchingByFilterNotifications: false,
         isEmptyByFilterNotifications: data?.notifications?.data?.length === 0,
      };
   },
   [types.FETCH_NOTIFICATIONS_BY_FILTER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingByFilterNotifications: false,
      };
   },
   [types.SET_UNREAD_COUNT]: (state, action) => {
      const unreadCount  = action.payload
      return {
         ...state,
         unreadCount,
      }
   },
   [types.SET_UNSEEN_COUNT]: (state, action) => {
      const unseenCount  = action.payload
      return {
         ...state,
         unseenCount,
      }
   },


   [types.SET_NOTIFICATIONS_PANEL]: (state, action) => {
      const { payload } = action;
      if(payload.isMobile) {
         setTimeout(() => {
            SyncPageScoll.syncScroll(payload.isOpen)
         }, 100);
      }

      return {
         ...state,
         notificationsPanel: {
            ...state.notificationsPanel,
            ...payload,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
