import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_RESOURCES_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },

   [types.FETCH_RESOURCES_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetching: false,
         resources: payload,
      }
   },

   [types.FETCH_RESOURCES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },
   [types.SET_DOWNLOAD_BUTTON_LOADING]: (state, action) => {
      return {
         ...state,
         mediaDownloadButton: action.payload,
      }
   },
   [types.SET_WRONG_DOWNLOAD_MODAL]: (state, action) => {
      return {
         ...state,
         wrongDownloadModal: action.payload,
      }
   },
};

export default createReducer(initialState)(reducersMap);
