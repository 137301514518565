const initialState = {
   isFetching: true,
   isNewFetching: false,
   comments: {},
   commentsCount: 0,
   hideReply: false,
   storyItem: {},
};

export default initialState;
