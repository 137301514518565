import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const OnlineMembersCounter = ({
   count,
   onClick,
   isMobile,
}) => {

   const counterRef = useRef(count)
   const [animationClass, setAnimationClass] = useState('')

   useEffect(() => {
      if(count > counterRef.current) {
         setAnimationClass('animate')
         setTimeout(() => {
            setAnimationClass('')
         }, 1000)
      }
      counterRef.current = count
   }, [count])

   return (
      <div
         className={ `h-full ${ isMobile ? 'ml-2 mr-4' : 'mx-4' } flex items-center cursor-pointer group` }
         role='presentation'
         onClick={ onClick }
      >
         <div className='w-[22px] h-[22px] flex items-center justify-center'>
            <div className={ `online-cyrcle-animated ${ animationClass } rounded-full flex items-center justify-center bg-success-12 dark:bg-success-dark-12` }>
               <div className='w-2 h-2 rounded-full bg-success dark:bg-success-dark'></div>
            </div>
         </div>
         <span className='text-xs text-secondary dark:text-secondary-dark whitespace-nowrap group-hover:underline'>
            { count } { isMobile ? '' : 'online' }
         </span>
      </div>
   )
}

OnlineMembersCounter.propTypes = {
   count: PropTypes.number,
   onClick: PropTypes.func,
   isMobile: PropTypes.bool,
}

export default OnlineMembersCounter
