const initialState = {
   isFetching: false,
   isFetchingHistory: true,
   isNextFetching: false,
   amountData: [],
   history: {},
   isEmptyHistory: false,
   purchaseStatus: 'default',
   modalState: {
      isOpen: false,
      type: null,
      isPaidMessage: false,
      success: {},
      buttonText: '',
      description: '',
   },
   walletLimitModalData: {
      isLimitModal: false,
      isFetching: false,
      header: '',
      body: '',
   },
};

export default initialState;
