function getConstructedKey(key) {
   const metaTag = document.querySelector('meta[name=appKey]');
   return metaTag ? `${ metaTag.content }:${ key }` : key;
}

export default  {
   getItem: (key) => {
      return localStorage.getItem(getConstructedKey(key));
   },
   setItem: (key, value) => {
      return localStorage.setItem(getConstructedKey(key), value);
   },
   removeItem: (key) => {
      return localStorage.removeItem(getConstructedKey(key));
   },
};
