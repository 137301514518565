import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   [types.JOIN_MAILING_LIST_START]: (state) => {
      return {
         ...state,
         isButtonFetching: true,
      };
   },
   [types.JOIN_MAILING_LIST_COMPLETED]: (state) => {
      return {
         ...state,
         isButtonFetching: false,
         isSubscribe: true,
      };
   },
   [types.JOIN_MAILING_LIST_FAILED]: (state, action) => {
      return {
         ...state,
         isButtonFetching: false,
         errors: action.payload ? action.payload.data : {},
      };
   },
   [types.FETCH_DATA_START]: (state) => {
      return {
         ...state,
         isFetching: true,
         isSubscribe: false,
      };
   },
   [types.FETCH_DATA_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
         data: action.payload,
      };
   },
   [types.FETCH_DATA_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      };
   },
};

export default createReducer(initialState)(reducersMap);
