import React from 'react';
import { Consumer } from '../context';
import classnames from 'classnames';


const Separator = () => {
   return (
      <Consumer>
         { ({ type }) => {
            const classes = classnames(
               'border-t', 'dark:border-divider-dark border-divider', 'my-2',
               {
                  'mx-1': type === 'desktop',
                  'mx-4': type === 'mobile',
               }
            )
            return (
               <div className={ classes } />
            )
         } }
      </Consumer>
   )
}

Separator.displayName = 'Separator'

export default Separator;
