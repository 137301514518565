import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   [types.SET_INPUT]: (state, action) => {
      const { payload: { key, value } } = action;
      return {
         ...state,
         inputs: {
            ...state.inputs,
            [key]: value,
         },
      };
   },
   [types.LOGIN_START]: (state) => {
      return {
         ...state,
         errors: {},
         isInProgress: true,
      };
   },
   [types.LOGIN_COMPLETED]: (state) => {
      return {
         ...state,
         isInProgress: false,
         openWrongLoginPageModalState: {
            isOpen: false,
            token: null,
            searchType: null,
         },
      };
   },
   [types.LOGIN_FAILED]: (state, action) => {
      const data = action?.payload?.data;
      return {
         ...state,
         isInProgress: false,
         errors: data,
      };
   },
   [types.MAGIC_LOGIN_USING_CREDENTIALS_START]: (state) => {
      return {
         ...state,
         magicLoginUCErrors: {},
         magicLoginUCProgrees: true,
      };
   },
   [types.MAGIC_LOGIN_USING_CREDENTIALS_COMPLETED]: (state) => {
      return {
         ...state,
         magicLoginUCProgrees: false,
      };
   },
   [types.MAGIC_LOGIN_USING_CREDENTIALS_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         magicLoginUCProgrees: false,
         magicLoginUCErrors: payload,
      };
   },
   [types.SET_OPEN_WRONG_LOGIN_PAGE_MODAL]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         openWrongLoginPageModalState: payload,
      };
   },
   [types.CLEAR_ERROR_FIELD]: (state, action) => {
      const { field, isAll } = action.payload;
      let newErrors = {}
      if(!isAll && field) {
         newErrors = { ...state.errors }
         delete newErrors[field]
      }
      return {
         ...state,
         errors: newErrors,
      }
   },
};

export default createReducer(initialState)(reducersMap);
