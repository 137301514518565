import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import * as reducers from "./modules";
import { createLogger } from "./middlewares";
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';

export const history =  createBrowserHistory({
   /* pass a configuration object here if needed */
});

let pastLocations = [history.location];

function updatePastLocations(location, action) {
   switch (action) {
      case 'PUSH':
         pastLocations.push(location);
         break;
      case 'REPLACE':
         pastLocations[pastLocations.length - 1] = location;
         break;
      case 'POP': {
         if(pastLocations.length !== 1) {
            pastLocations.pop();
         }
         const appLocation = pastLocations[pastLocations.length - 1];
         if(!(appLocation && appLocation.key === location.key)) {
            pastLocations = [location];
         }
         break;
      }
      default:
   }
}
history.listen(updatePastLocations);


export function isPreviousLocationWithinApp(){
   return pastLocations.length > 1
}

function configureStore(initialState) {
   const rootReducer = combineReducers({
      ...reducers,
      router: connectRouter(history),
   });

   return createStore(
      rootReducer,
      initialState,
      applyMiddleware(
         thunkMiddleware,
         createLogger(true),
         routerMiddleware(history)
      )
   );
}

export default configureStore();
