import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         storeData: payload,
         isFetching: false,
         isEmpty: payload.data.length === 0,
         inited: payload?.data?.length > 0,
      }
   },
   [types.CLEAR_STATE]: (state, action) => {
      return {
         ...state,
         inited: false,
         filters: action.payload,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.FETCH_NEXT_DETAILS_START]: (state) => {
      return {
         ...state,
         isNextFetching: true,
      }
   },
   [types.FETCH_NEXT_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         storeData: {
            ...payload,
            data: [
               ...state.storeData.data,
               ...payload.data,
            ],
         },
         isNextFetching: false,
      }
   },
   [types.FETCH_NEXT_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isNextFetching: false,
      }
   },

   [types.FETCH_BY_FILTER_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
      }
   },
   [types.FETCH_BY_FILTER_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         storeData: {
            ...payload,
            data: [
               ...payload.data,
            ],
         },
         isFetchingByFilter: false,
         isEmptyByFilter: payload.data.length === 0,
      }
   },
   [types.FETCH_BY_FILTER_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingByFilter: false,
      }
   },

   [types.FETCH_STORE_BY_ID_START]: (state) => {
      return {
         ...state,
         isFetchingById: true,
      }
   },
   [types.FETCH_STORE_BY_ID_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         storeDetails: { ...payload },
         isFetchingById: false,
         inited: state?.storeData?.data?.length > 0,
      }
   },
   [types.FETCH_STORE_BY_ID_FAILED]: (state) => {
      return {
         ...state,
         isFetchingById: false,
      }
   },

   [types.FETCH_CATEGORIES_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         categories: payload,
      }
   },
   [types.UPDATE_STORE_BY_ID]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         storeDetails: { ...payload?.storeDetails },
         inited: !!payload.inited,
      }
   },
   [types.CHANGE_SUCCESS_MODAL_VALUES]: (state, action) => {
      return {
         ...state,
         successModal: { ...action.payload },
      }
   },
   [types.FETCH_DATA_BY_FILTERS]: (state) => {
      return {
         ...state,
         isFetching: false,
         isEmpty: false,
      }
   },
   [types.SUBSCRIBE_OR_UNSUBSCRIBE_FUNCTION]: (state) => {
      return {
         ...state,
         storeDetails: {
            ...state.storeDetails,
            member_already_subscribed: !state.storeDetails.member_already_subscribed,
         },
      }
   },


   //orders
   [types.FETCH_ORDERS_DATA_START]: (state) => {
      return {
         ...state,
         ordersData: {
            ...state.ordersData,
            isFetching: true,
         },
      }
   },
   [types.FETCH_ORDERS_DATA_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         ordersData: {
            ...state.ordersData,
            orders: payload,
            isEmpty: payload?.data?.length === 0,
            isFetching: false,
         },
      }
   },
   [types.FETCH_NEW_ORDERS_START]: (state) => {
      return {
         ...state,
         ordersData: {
            ...state.ordersData,
            isNewFetching: true,
         },
      }
   },
   [types.FETCH_NEW_ORDERS_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         ordersData: {
            ...state.ordersData,
            isNewFetching: false,
            orders: {
               ...payload,
               data: [
                  ...state.ordersData.orders.data,
                  ...payload.data,
               ],
            },
         },
      }
   },
   [types.FETCH_NEW_ORDERS_FAILED]: (state) => {
      return {
         ...state,
         ordersData: {
            ...state.ordersData,
            isNewFetching: false,
         },
      }
   },
   [types.FETCH_ORDERS_BY_FILTER_START]: (state) => {
      return {
         ...state,
         ordersData: {
            ...state?.ordersData,
            isFetchingByFilter: true,
         },
      }
   },
   [types.FETCH_ORDERS_BY_FILTER_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         ordersData: {
            ...state.ordersData,
            orders: payload,
            isEmptyByFilter: payload?.data?.length === 0,
            isFetchingByFilter: false,
            isFetching: false,
            isEmpty: false,
         },
      }
   },
   [types.MAKE_BUNDLE_ITEMS_WATCHED]: (state, action) => {
      return {
         ...state,
         storeDetails: {
            ...state.storeDetails,
            content: action.payload,
         },
      }
   },

   [types.FETCH_ORDER_REQUEST_BY_ID_START]: (state) => {
      return {
         ...state,
         activeOrderRequestPageLoading: true,
      }
   },
   [types.FETCH_ORDER_REQUEST_BY_ID_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         activeOrderRequest: {
            ...payload,
            ...payload?.item_details,
            image_full_src: payload?.item_details?.img,
            order_statuses: payload?.statuses?.reverse(),
            order_details: {
               ...payload?.order_details,
               ...payload?.item_details,
               address: payload?.shipping_info?.address,
            },
            purchaseType: payload?.order_details?.type,
         },
         activeOrderRequestPageLoading: false,
      }
   },
   [types.UPDATE_ACTIVE_ORDER_REQUEST]: (state, action) => {
      return {
         ...state,
         activeOrderRequest: action.payload,
         activeOrderRequestPageLoading: !Object.keys(action?.payload).length,
      }
   },
   [types.UPDATE_ORDER_REQUESTS_DATA]: (state, action) => {
      const { payload } = action

      let requests = state?.ordersData?.orders?.data
      let activeOrderRequest = state?.activeOrderRequest?.order_details?.id === payload?.id ? state?.activeOrderRequest : null

      if(!!requests?.length) {
         let index = state?.ordersData?.orders?.data?.findIndex(it => it.id === payload?.id)
         if(index !== -1) {
            requests[index] = {
               ...requests[index],
               transaction: {
                  ...requests[index].transaction,
                  status: payload?.product_status_name,
                  date: payload?.product_status_created_at,
               },
               product: {
                  ...requests[index].product,
                  status: payload?.product_status_name,
                  date: payload?.product_status_created_at,
               },
            }
         }
      }

      if(!!activeOrderRequest) {
         activeOrderRequest = {
            ...activeOrderRequest,
            order_details: {
               ...activeOrderRequest?.order_details,
               failure_reason: payload?.product_status_name === 'waiting' ? payload?.failure_reason : null,
            },
            order_statuses: [
               {
                  created_at: payload?.product_status_created_at,
                  id: payload?.product_status_id,
                  is_current: !!payload?.product_status_is_current,
                  is_member: !!payload?.product_status_is_member,
                  name: payload?.product_status_name,
                  order_request_payment_id: payload?.id,
                  updated_at: payload?.product_status_created_at,
               },
               ...activeOrderRequest?.order_statuses,
            ],
            item_details: {
               ...activeOrderRequest?.item_details,
               attachment_src: payload?.attachment_src,
            },
         }
      }

      return {
         ...state,
         activeOrderRequest: activeOrderRequest ?? state?.activeOrderRequest,
         ordersData: {
            ...state.ordersData,
            orders: {
               ...state.ordersData.orders,
               data: requests,
            },
         },
      }
   },
   [types.UPDATE_STORE_LIST]: (state, action) => {
      const { payload: { data, actionType } } = action

      let tempData = state?.storeData?.data || []

      if(!tempData?.length) return state

      switch (actionType) {
         case 'delete':
            let isExistsItem = !!tempData?.find(it => it.id === data?.id)
            if(isExistsItem) {
               tempData = tempData.filter(item => item.id !== data.id)
            }

            break;
         case 'update':
            let index = tempData?.findIndex(it => it.id === data?.id)
            if(!!index) {
               tempData[index] = data
            }
            break;

         default:
            break;
      }

      return {
         ...state,
         storeData: {
            ...state.storeData,
            data: tempData,
         },
      }
   },
   [types.ON_CHANGE_FAVORITE_ITEM_COMPLETED]: (state, action) => {
      const  { payload: { id, isDetailsPage } } = action;
      let tempData = state?.storeData?.data || []
      let storeDetails = state?.storeDetails

      if(isDetailsPage) {
         storeDetails = {
            ...storeDetails,
            is_favorite: !storeDetails.is_favorite,
         }
      }

      if(!!tempData?.length) {
         const findIndex = tempData.findIndex(item => item.id === id)
         if(findIndex >= 0){
            tempData[findIndex].is_favorite = !tempData[findIndex].is_favorite
         }
      }

      return {
         ...state,
         storeData: {
            ...state.storeData,
            data: tempData,
         },
         storeDetails,
      }
   },
}

export default createReducer(initialState)(reducersMap);
