import React from 'react';
import store, { history } from "sites/state/store";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import bootsrap from 'sites/bootsrap';
import * as serviceWorker from './service-worker/serviceWorker';
// import modules style
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'assets/css/index.scss';

// Styles start
import 'assets/fonts/icons/style.css';
import 'assets/css/video-style.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
// Styles end
import { screenResize } from 'sites/state/modules/common/actions';
import { isLocalhost, isStaging, isAllowedSites } from 'sites/utils/Helpers';

window.addEventListener('resize', () => {
   store.dispatch(screenResize(window.innerWidth));
});
const isCanOpenInspect = () => {
   return isLocalhost() || isStaging() || isAllowedSites()
}

document.onkeydown = function(e) {
   const isProduction = !isCanOpenInspect();
   if(isProduction && !!e.key) {
      if(e.key.toLowerCase() === 'f12') {
         return false;
      }
      if(e.ctrlKey && e.shiftKey && (e.key.toLowerCase() === 'i' || e.key.toLowerCase() === 'j')){
         return false;
      }
      if(e.ctrlKey && e.key === 'u') {
         return false;
      }
   }
};

window.oncontextmenu = function(e) {
   if(e?.target && e?.target?.id === 'promo_code_field') {
      return true
   }
   const isProduction = !isCanOpenInspect();
   return !isProduction;
};

bootsrap();

const app = document.getElementById('root');
const root = createRoot(app);

root.render(
   <Provider store={ store }>
      <ConnectedRouter history={ history }>
         <App />
      </ConnectedRouter>
   </Provider>)

serviceWorker.unregister();
