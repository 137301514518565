
export default class Intercom {
   static init() {
      window.intercomSettings = Intercom.getDefaultIntercomSettings();
      return (function() {
         var w = window;
         var ic = w.Intercom;
         if(typeof ic === "function") {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
         } else {
            var d = document;
            var i = function() {
               i.c(arguments);
            };
            i.q = [];
            i.c = function(args) {
               i.q.push(args);
            };
            w.Intercom = i;
            var l = function() {
               var s = d.createElement('script');
               s.type = 'text/javascript';
               s.async = true;
               s.src = `https://widget.intercom.io/widget/${ process.env.REACT_APP_INTERCOM_APP_ID }`;
               var x = d.getElementsByTagName('script')[0];
               x.parentNode.insertBefore(s, x);
            };
            if(w.attachEvent) {
               w.attachEvent('onload', l);
            } else {
               w.addEventListener('load', l, false);
            }
         }
      })();
   }

   static getDefaultIntercomSettings() {
      const intercomSettings = {
         app_id: process.env.REACT_APP_INTERCOM_APP_ID,
         custom_launcher_selector: '.launch-intercom',
         hide_launcher_selector: true,
         hide_default_launcher: true,
      };
      return intercomSettings;
   }

   static boot(_intercomSettings = {}) {
      if(!window.Intercom) return;
      const intercomSettings = {
         ...Intercom.getDefaultIntercomSettings(),
         ..._intercomSettings,
      };
      window.intercomSettings = intercomSettings;
      if(window.Intercom) return   window.Intercom('boot', intercomSettings)
   }

   static shutDown() {
      if(!window.Intercom) return;
      window.Intercom('shutdown');
   }

   static showArticle(articleId) {
      if(!window.Intercom || !Boolean(articleId)) return;
      window.Intercom('showArticle', articleId)
   }
}
