export const FETCH_REQUEST = "myDownloads/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "myDownloads/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "myDownloads/FETCH_DETAILS_FAILED";

export const FETCH_BY_FILTER_REQUEST = "myDownloads/FETCH_BY_FILTER_REQUEST";
export const FETCH_BY_FILTER_DETAILS_COMPLETED = "myDownloads/FETCH_BY_FILTER_DETAILS_COMPLETED";
export const FETCH_BY_FILTER_DETAILS_FAILED = "myDownloads/FETCH_BY_FILTER_DETAILS_FAILED";

export const FETCH_NEXT_DATA_REQUEST = "myDownloads/FETCH_NEXT_DATA_REQUEST";
export const FETCH_NEXT_DATA_COMPLETED = "myDownloads/FETCH_NEXT_DATA_COMPLETED";
export const FETCH_NEXT_DATA_FAILED = "myDownloads/FETCH_NEXT_DATA_FAILED";

export const TOGGLE_LIKE_REQUEST = "myDownloads/TOGGLE_LIKE_REQUEST";
export const TOGGLE_LIKE_COMPLETED = "myDownloads/TOGGLE_LIKE_COMPLETED";
export const TOGGLE_LIKE_FAILED = "myDownloads/TOGGLE_LIKE_FAILED";
