export const FETCH_DETAILS_START = "myFavorites/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "myFavorites/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "myFavorites/FETCH_DETAILS_FAILED";
export const CLEAR_STATE = "myFavorites/CLEAR_STATE"

export const FETCH_NEXT_DETAILS_START = "myFavorites/FETCH_NEXT_DETAILS_START";
export const FETCH_NEXT_DETAILS_COMPLETED = "myFavorites/FETCH_NEXT_DETAILS_COMPLETED";
export const FETCH_NEXT_DETAILS_FAILED = "myFavorites/FETCH_NEXT_DETAILS_FAILED";

export const FETCH_BY_FILTER_DETAILS_START = "myFavorites/FETCH_BY_FILTER_DETAILS_START";
export const FETCH_BY_FILTER_DETAILS_COMPLETED = "myFavorites/FETCH_BY_FILTER_DETAILS_COMPLETED";
export const FETCH_BY_FILTER_DETAILS_FAILED = "myFavorites/FETCH_BY_FILTER_DETAILS_FAILED";
export const FETCH_DATA_BY_FILTERS = "myFavorites/FETCH_DATA_BY_FILTERS"

export const FETCH_CATEGORIES_COMPLETED = "myFavorites/FETCH_CATEGORIES_COMPLETED";

export const UPDATE_STORE_LIST = "myFavorites/UPDATE_STORE_LIST_DATA"

export const TOGGLE_LIKE_COMPLETED = "myFavorites/TOGGLE_LIKE_COMPLETED";
export const TOGGLE_WATCHED_COMPLETED = "myFavorites/TOGGLE_WATCHED_COMPLETED";
export const ON_CHANGE_FAVORITE_ITEM_COMPLETED = "myFavorites/ON_CHANGE_FAVORITE_ITEM_COMPLETED";
