// export const SET_STREAM_INFO = "livestream/SET_STREAM_INFO";
export const FETCH_INIT_REQUEST = "livestream/FETCH_INIT_REQUEST";
export const FETCH_INIT_COMPLETED = "livestream/FETCH_INIT_COMPLETED";

// record
export const FETCH_RECORDS_REQUEST = "livestream/FETCH_RECORDS_REQUEST";
export const FETCH_RECORDS_COMPLETED = "livestream/FETCH_RECORDS_COMPLETED";
export const FETCH_NEX_RECORDS_REQUEST = "livestream/FETCH_NEX_RECORDS_REQUEST";
export const FETCH_NEX_RECORDS_COMPLETED = "livestream/FETCH_NEX_RECORDS_COMPLETED";
//
// export const UPDATE_DATA_COMPLETED = "livestream/UPDATE_DATA_COMPLETED";
export const FETCH_LIVE_STREAM_MESSAGES = "livestream/FETCH_LIVE_STREAM_MESSAGES";
export const FETCH_LIVE_STREAM_MESSAGES_COMPLETED = "livestream/FETCH_LIVE_STREAM_MESSAGES_COMPLETED";
export const FETCH_LIVE_STREAM_NEW_MESSAGES = "livestream/FETCH_LIVE_STREAM_NEW_MESSAGES";
export const FETCH_LIVE_STREAM_NEW_MESSAGES_COMPLETED = "livestream/FETCH_LIVE_STREAM_NEW_MESSAGES_COMPLETED";
export const SET_LIVE_STREAM_CONVERSATION_STATE = "livestream/SET_LIVE_STREAM_CONVERSATION_STATE";
export const UPDATE_MESSAGE = "livestream/UPDATE_MESSAGE";
export const ADD_MESSAGE = "livestream/ADD_MESSAGE";
export const SET_TYPER_ACTION = "livestream/SET_TYPER_ACTION";
export const DELETE_MESSAGE_ACTION = "livestream/DELETE_MESSAGE_ACTION";
export const LIKE_MESSAGE_ACTION = "livestream/LIKE_MESSAGE_ACTION";
export const SET_IS_CONNECTED_ACTION = "livestream/SET_IS_CONNECTED_ACTION";
export const SET_ONLINE_USERS_ACTION = "livestream/SET_ONLINE_USERS_ACTION";
///////////////////////////////////////////////////////////////
export const FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST = 'livestream/FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST';
export const FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED = 'livestream/FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED';
///
export const FETCH_NEW_MESSAGES_REQUEST = 'livestream/FETCH_NEW_MESSAGES_REQUEST';
export const FETCH_NEW_MESSAGES_COMPLETED = 'livestream/FETCH_NEW_MESSAGES_COMPLETED';
export const FETCH_NEW_MESSAGES_FAILED = 'livestream/FETCH_NEW_MESSAGES_FAILED';

export const SET_STREAM_CONVERASTION_INFO = 'livestream/SET_STREAM_CONVERASTION_INFO';
export const SET_OPEN_ACTIVE_UPSELL_MODAL = 'livestream/SET_OPEN_ACTIVE_UPSELL_MODAL';
export const SET_LOTTIE_MODAL = 'livestream/SET_LOTTIE_MODAL';
export const SET_OFFLINE_STATE = 'livestream/SET_OFFLINE_STATE';
export const SET_TIP_GOALL_ACHIEVED_MODAL = 'livestream/SET_TIP_GOALL_ACHIEVED_MODAL';
export const SET_TIP_ACTIONS_GOALL_ACHIEVED_MODAL = 'livestream/SET_TIP_ACTIONS_GOALL_ACHIEVED_MODAL';
export const UPDATE_RECORD_BY_ID = 'livestream/UPDATE_RECORD_BY_ID';
export const SET_OPEN_ENDED_MODAL = 'livestream/SET_OPEN_ENDED_MODAL';
export const LIVE_STREAM_LIKE_REQUEST = 'livestream/LIVE_STREAM_LIKE_REQUEST';
export const LIVE_STREAM_LIKE_COMPLETED = 'livestream/LIVE_STREAM_LIKE_COMPLETED';
export const SET_WARCHING_USERS = 'livestream/SET_WARCHING_USERS';
export const UPDATE_INITED_DATA = 'livestream/UPDATE_INITED_DATA';
export const LIVE_RECORD_STREAM = 'livestream/LIVE_RECORD_STREAM';

export const TOGGLE_WATCHED_COMPLETED = "videos/TOGGLE_WATCHED_COMPLETED";
export const UPDATE_DATA_AFTER_PURCHASE_COMPLETED = "videos/UPDATE_DATA_AFTER_PURCHASE_COMPLETED";
export const ON_CHANGE_FAVORITE_ITEM_COMPLETED = "livestream/ON_CHANGE_FAVORITE_ITEM_COMPLETED";
