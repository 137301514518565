export const FETCH_REQUEST = "stories/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "stories/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "stories/FETCH_DETAILS_FAILED";

export const FETCH_NEXT_REQUEST = 'stories/FETCH_NEXT_REQUEST';
export const FETCH_NEXT_COMPLETED = 'stories/FETCH_NEXT_COMPLETED';
export const FETCH_NEXT_FAILED = 'stories/FETCH_NEXT_FAILED';

export const FETCH_BY_FILTER_REQUEST = 'stories/FETCH_BY_FILTER_REQUEST';
export const FETCH_BY_FILTER_COMPLETED = 'stories/FETCH_BY_FILTER_COMPLETED';
export const FETCH_BY_FILTER_FAILED = 'stories/FETCH_BY_FILTER_FAILED';


export const STORY_LIKE_REQUEST = 'stories/STORY_LIKE_REQUEST';
export const STORY_LIKE_COMPLETED = 'stories/STORY_LIKE_COMPLETED';
export const STORY_LIKE_FAILED = 'stories/STORY_LIKE_FAILED';


export const FETCH_STORY_BY_ID_REQUEST = 'stories/FETCH_STORY_BY_ID_REQUEST';
export const FETCH_STORY_BY_ID_COMPLETED = 'stories/FETCH_STORY_BY_ID_COMPLETED';
export const FETCH_STORY_BY_ID_FAILED = 'stories/FETCH_STORY_BY_ID_FAILED';

export const STORY_ADD_VIEW_START = 'stories/STORY_ADD_VIEW_START';
export const STORY_ADD_VIEW_COMPLETED = 'stories/STORY_ADD_VIEW_COMPLETED';
export const STORY_ADD_VIEW_FAILED = 'stories/STORY_ADD_VIEW_FAILED';


// fetch stories count type
export const FETCH_STORIES_COUNT_REQUEST = "stories/FETCH_STORIES_COUNT_REQUEST";
export const FETCH_STORIES_COUNT_COMPLETED = "stories/FETCH_STORIES_COUNT_COMPLETED";
export const FETCH_STORIES_COUNT_FAILED = "stories/FETCH_STORIES_COUNT_FAILED";

export const FETCH_SITE_INFO = "stories/FETCH_SITE_INFO"