import React from 'react';

import { getSiteUrl } from 'sites/utils/Helpers';
import { getSiteNameAbbreviatur } from 'common/utils/helper';
import PropTypes from 'react';

const TermsOfUse = ({
   legalPages,
}) => {

   let currentInfo

   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'term_of_use')
   }

   return (
      currentInfo?.html_content ?
         <>
            <div dangerouslySetInnerHTML={ {
               __html: currentInfo?.html_content,
            } } className='text-major dark:text-major-dark policy-content'
            />
         </>
         :
         <>
            <p className='text-2xl text-major dark:text-major-dark mb-3'>Copyright Notice</p>
            <p className='dark:text-secondary-dark '>
               All images and videos on, or downloaded from this web site are Copyright © 2019 by {getSiteUrl()} and are protected by Canadian and United States copyright laws and international treaty provisions. Materials contained in any part of this website may not be reproduced, copied, edited, published, transmitted or uploaded in any way without expressed written permission from {getSiteUrl()}. Except as expressly stated in the Limited License provision in these Terms and Conditions, {getSiteUrl()} does not grant any express or implied right to you under any of its trademarks, service marks, copyrights or other proprietary information.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Notice</p>
            <p className='dark:text-secondary-dark '>
               These terms MUST be read thoroughly and agreed to prior to joining {getSiteUrl()}. The acceptance of these terms signifies a legal and binding contract between YOU and {getSiteUrl()}, and may not be revoked during the term of your membership. By subscribing to {getSiteUrl()}, you certify that you are eighteen (18) or twenty-one (21) years of age or older (whichever is applicable in your area); that you are familiar with all local laws in your area affecting your legal right to access erotica, or adult-oriented materials; and that you have the legal right to access adult-oriented materials, and that {getSiteUrl()} has the legal right to transmit them to you. {getSiteUrl()} makes no representation that materials in the site are appropriate or available for use in other locations. Those who choose to access this site from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. You certify that are voluntarily requesting adult-oriented materials for your own private enjoyment and that you will never share these materials with a minor or make these materials available to a minor.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Limited License</p>
            <p className='dark:text-secondary-dark '>
               {getSiteUrl()} provides the materials on this site for the personal, non-commercial use by visitors of {getSiteUrl()}. {getSiteUrl()} reserves the right to limit the amount of materials downloaded. Commercial use of {getSiteUrl()}, or any material located on it, is strictly prohibited. In addition, users of {getSiteUrl()} may not: (i) modify any of the materials found in any area of {getSiteUrl()}; (ii) use any of the materials found in any area of {getSiteUrl()} for any public display, performance, sale or rental; (iii) remove, modify or alter any copyright or other proprietary notice, or trademarks from any of the materials found in any area of {getSiteUrl()}; or (iv) otherwise transfer any material located in any area of {getSiteUrl()} to any other person or entity. {getSiteUrl()} reserves the right to terminate this license at any time if you breach or violate any provision of this agreement, in which case you will be obligated to immediately destroy any information or materials you have downloaded, printed or otherwise copied from this site. {getSiteUrl()} reserves all other rights coincident or related to this site. All photographs or videos purchased from {getSiteUrl()} are copyright protected and may not be reproduced, reprinted, electronically scanned or posted, used for advertising or any other purpose without the written permission of {getSiteUrl()}. Selling, reselling and/or trading these items through personal transactions, on eBay or other auction venues is going against copyright policies and in doing so you will be liable in a court of law.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Disclaimer</p>
            <p className='dark:text-secondary-dark '>
               No warranty is made by {getSiteUrl()} regarding any information, services, materials or products provided through or in connection with {getSiteUrl()}, and {getSiteUrl()} hereby expressly disclaims any and all warranties, including without limitation: (i) any warranties as to the availability, accuracy or content of materials, information, products or services; (ii) any warranties of merchantability or fitness for a particular purpose. Some states do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. {getSiteUrl()} offers no assurance of an uninterrupted or error-free service. {getSiteUrl()} does not warrant the accuracy or completeness of any information, text, graphics, videos, links or other items contained on the web site. {getSiteUrl()} may change any of the information found on the web site at any time without prior notice. {getSiteUrl()} makes no commitment to update the information found on the web site.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Links to Third Party Sites</p>
            <p className='dark:text-secondary-dark '>
               This site contains links to sites that are controlled by third parties. Those linked sites are not under the control of {getSiteUrl()}, and {getSiteUrl()} is not responsible for the contents of any linked site or any link contained in a link site. The inclusion of any link does not imply endorsement by {getSiteUrl()} of any linked site.
            </p>

            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Membership Cancellation</p>
            <p className='dark:text-secondary-dark '>
               Memberships can be cancelled any time in your <b>My account</b> page.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Refunds</p>
            <p className='dark:text-secondary-dark '>
               Refunds will be handled on a case-by-case basis. Should a refund be issued, all funds will be credited solely to the payment method used in the original transaction.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Harassment</p>
            <p className='dark:text-secondary-dark '>
               {getSiteUrl()} has the right to terminate a subscription of a member who acts in a harassing manner towards any  {getSiteUrl()} staff or any other member. The grounds for termination can be purely subjective and based on the views of  {getSiteUrl()}.
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Credit Card Statements</p>
            <p className='dark:text-secondary-dark '>
               Charges through CCBill will appear on your statement as CCBill.com.
               Charges through SegPay will appear on your statement as {getSiteNameAbbreviatur()}.
               {getSiteNameAbbreviatur()} 80 SW 8 ST SUITE 2000, MIAMI FL 33130
            </p>
         </>
   )
};


TermsOfUse.propTypes = {
   legalPages: PropTypes.array,
}

export default TermsOfUse;
