import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export const validCountries = ["AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AR", "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ-1", "BQ-2", "BQ", "BR", "BS", "BT", "BW", "BY", "BZ", "CA", "CC", "CD-1", "CD", "CF", "CH", "CK", "CL", "CM", "CN", "CO", "CR", "CU", "CW", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "DS", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB-2", "GB", "GD", "GE", "GG", "GH", "GI", "GL", "GM", "GN", "GQ", "GR", "GT", "GU", "GW", "GY", "HK", "HN", "HR", "HT", "HU", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT", "International", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "ME", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PN", "PR", "PS", "PT", "PW", "PY", "QA", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SE", "SG", "SI", "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TC", "TD", "TG", "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "US", "UY", "UZ", "VC", "VE", "VG", "VI", "VN", "VU", "WS", "YE", "ZA", "ZM", "ZW",
   // "UM", "BV", "CV", "GF", "TF", "GP", "HM", "VA", "YT", "NC", "RE", "SH", "MF", "PM", "GS", "SD", "SJ", "WF", "CI", "CG", "AQ",
]

let publicUrl = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : ''

if(publicUrl[publicUrl.length - 1] !== '/') {
   publicUrl += '/';
} 

const Flag = ({
   className,
   country,
   styles,
   height,
   width,
}) => {

   let countryName = country

   if(!validCountries.includes(country)) {
      countryName = 'International'
   }

   return (
      <span
         className={ `px-1 ${ className }` }
         style={ styles }
      >
         <svg style={ {
            height,
            width,
         } } className={ `flag flags-${ countryName }` }>
            <use href={ `/icons/flags_1.svg#flags-${ countryName }` }></use>
         </svg>
      </span>
   );
};

Flag.defaultProps = {
   className: '',
   country: 'US',
   styles: {},
   height: '24px',
   width: '24px',
};

Flag.propTypes = {
   className: PropTypes.string,
   country: PropTypes.oneOf(validCountries),
   styles: PropTypes.object,
   height: PropTypes.string,
   width: PropTypes.string,
};

export default Flag;
