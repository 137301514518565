const initialState = {
   isFetching: true,
   stories: [],
   videos: [],
   photosets: [],
   siteHeader: {},
   store: [],

   aboutMe: {},
   recordedStreams: [],
};

export default initialState;
