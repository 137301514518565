import React from 'react';
import PropTypes from 'prop-types';
import CircleLoader from 'common/components/elements/loader/circle';
import logoGIF from './mms_logo_loop.gif';
import './style.scss'

const PageLoader = ({
   type,
   customStyle,
   withLoader,
   gifWrapperClassName,
}) => {
   if(withLoader){
      return (
         <div className={ ` ${ !customStyle ? `loading-height-${ type }` : `${ customStyle }` } flex items-center justify-center` }>
            <CircleLoader color='blue' />
         </div>
      )
   }
   return (
      <div
         className={ `h-full w-full flex items-center justify-center ${ gifWrapperClassName }` }
      >
         <img src={ logoGIF } alt='this slowpoke moves' width={ 200 } />
      </div>
   );
};

PageLoader.defaultProps = {
   type: 'default',
   gifWrapperClassName: '',
};

PageLoader.propTypes = {
   type: PropTypes.string,
   customStyle: PropTypes.string,
   withLoader: PropTypes.bool,
   gifWrapperClassName: PropTypes.string,
};

export default PageLoader;
