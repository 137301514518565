export const UPDATE_NOTIFICATIONS_DATA = 'notifications/UPDATE_NOTIFICATIONS_DATA';
export const MAKE_ALL_AS_SEEN_NOTIFICATIONS = 'notifications/MAKE_ALL_AS_SEEN_NOTIFICATIONS';
export const MAKE_ALL_AS_READ_NOTIFICATIONS = 'notifications/MAKE_ALL_AS_READ_NOTIFICATIONS';

export const FETCH_NOTIFICATIONS_REQUEST = 'notifications/FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_COMPLETED = 'notifications/FETCH_NOTIFICATIONS_COMPLETED';
export const FETCH_NOTIFICATIONS_FAILED = 'notifications/FETCH_NOTIFICATIONS_FAILED';

export const FETCH_NEW_NOTIFICATIONS_REQUEST = 'notifications/FETCH_NEW_NOTIFICATIONS_REQUEST';
export const FETCH_NEW_NOTIFICATIONS_COMPLETED = 'notifications/FETCH_NEW_NOTIFICATIONS_COMPLETED';
export const FETCH_NEW_NOTIFICATIONS_FAILED = 'notifications/FETCH_NEW_NOTIFICATIONS_FAILED';

export const FETCH_NOTIFICATIONS_BY_FILTER_REQUEST = 'notifications/FETCH_NOTIFICATIONS_BY_FILTER_REQUEST';
export const FETCH_NOTIFICATIONS_BY_FILTER_COMPLETED = 'notifications/FETCH_NOTIFICATIONS_BY_FILTER_COMPLETED';
export const FETCH_NOTIFICATIONS_BY_FILTER_FAILED = 'notifications/FETCH_NOTIFICATIONS_BY_FILTER_FAILED';

export const SET_NOTIFICATIONS_PANEL = 'notifications/SET_NOTIFICATIONS_PANEL';
export const SET_UNREAD_COUNT = 'notifications/SET_UNREAD_COUNT';
export const SET_UNSEEN_COUNT = 'notifications/SET_UNSEEN_COUNT';
