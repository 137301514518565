export const FETCH_TIP_START = "tip/FETCH_TIP_START";
export const FETCH_TIP_COMPLETED = "tip/FETCH_TIP_COMPLETED";
export const FETCH_TIP_FAILED = "tip/FETCH_TIP_FAILED";

export const CHECK_TIP_LIMIT_START = "tip/CHECK_TIP_LIMIT_START";
export const CHECK_TIP_LIMIT_COMPLETED = "tip/CHECK_TIP_LIMIT_COMPLETED";
export const CHECK_TIP_LIMIT_FAILED = "tip/CHECK_TIP_LIMIT_FAILED";

export const SET_MODAL_STATE = "tip/SET_MODAL_STATE";
export const SET_TIP_DATA_STATE = "tip/SET_TIP_DATA_STATE";
