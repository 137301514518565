export const FETCH_WALLET_START = "wallet/FETCH_WALLET_START";
export const FETCH_WALLET_COMPLETED = "wallet/FETCH_WALLET_COMPLETED";
export const FETCH_WALLET_FAILED = "wallet/FETCH_WALLET_FAILED";

export const FETCH_WALLET_PAYMENT_HISTORY_START = "wallet/FETCH_WALLET_PAYMENT_HISTORY_START";
export const FETCH_WALLET_PAYMENT_HISTORY_COMPLETED = "wallet/FETCH_WALLET_PAYMENT_HISTORY_COMPLETED";
export const FETCH_WALLET_PAYMENT_HISTORY_FAILED = "wallet/FETCH_WALLET_PAYMENT_HISTORY_FAILED";

export const FETCH_NEXT_WALLET_PAYMENT_HISTORY_START = "wallet/FETCH_NEXT_WALLET_PAYMENT_HISTORY_START";
export const FETCH_NEXT_WALLET_PAYMENT_HISTORY_COMPLETED = "wallet/FETCH_NEXT_WALLET_PAYMENT_HISTORY_COMPLETED";
export const FETCH_NEXT_WALLET_PAYMENT_HISTORY_FAILED = "wallet/FETCH_NEXT_WALLET_PAYMENT_HISTORY_FAILED";

export const WALLET_PURCHASE_START = "wallet/WALLET_PURCHASE_START";
export const WALLET_PURCHASE_COMPLETED = "wallet/WALLET_PURCHASE_COMPLETED";
export const WALLET_PURCHASE_START_FAILED = "wallet/WALLET_PURCHASE_START_FAILED";

export const CHECK_WALLET_LIMIT_START = "wallet/CHECK_WALLET_LIMIT_START";
export const CHECK_WALLET_LIMIT_COMPLETED = "wallet/CHECK_WALLET_LIMIT_COMPLETED";
export const CHECK_WALLET_LIMIT_FAILED = "wallet/CHECK_WALLET_LIMIT_FAILED";
export const SET_WALLET_LIMIT_MODAL_STATE = "wallet/SET_WALLET_LIMIT_MODAL_STATE";

export const SET_WALLET_PURCHASE_STATUS = "wallet/SET_WALLET_PURCHASE_STATUS";
export const SET_MODAL_STATE = "wallet/SET_MODAL_STATE";
