import { createAffiliateStatistics } from "sites/api/GuestApi";
import LocalStorage from "sites/utils/LocalStorage";
import QueryParams from "sites/utils/QueryParams";

export default  () => {

   const affiliateId = QueryParams.getParam('aff')

   if(affiliateId) {
      LocalStorage.setItem('affiliateId', affiliateId)
      try {
         createAffiliateStatistics(affiliateId)
      } catch (error) {
         console.log(error);
      }
      QueryParams.removeQueryParam('aff')
   }

};
