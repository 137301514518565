const initialState = {
   isFetching: true,
   isFetchingByFilter: false,
   isNextFetching: false,
   isEmpty: true,
   isEmptyByFilter: false,
   isFetchingById: true,
   emptyData: false,
   storeData: {},
   categories: [],
   storeDetails: {},
   successModal: {},
   inited: false,
   ordersData: {
      orders: {},
      isFetching: true,
      isNewFetching: false,
      isEmpty: true,
      isFetchingByFilter: false,
      isEmptyByFilter: false,
   },
   activeOrderRequestPageLoading: true,
   activeOrderRequest: {},
   filters: {},
};

export default initialState;
