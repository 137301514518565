
import { createSelector } from 'reselect'

const innerStateSelector = state => state.router

const locationSelector = createSelector(
   innerStateSelector,
   (state) => (state.location)
);

const currentLocationPathSelector = createSelector(
   locationSelector,
   (state) => (state.pathname)
);

export {
   currentLocationPathSelector,
}
