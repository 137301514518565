import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import DateFormatter from 'common/utils/DateFormatter';
import AddCalendarButton from 'common/components/modules/add-calendar';
import './style.scss'

const UpcomingLiveStream = ({
   date,
   className,
   data,
   showAddToCalendar,
}) => {
   return (
      <div
         className={ `rounded-lg flex z-10 sm:items-center px-3 py-4 w-full upcoming-content ${ className } sm:flex-row flex-col` }
      >
         <div className='flex items-center h-full'>
            <div className='h-6 w-6 flex items-center justify-center mr-2'>
               <Icon
                  name='clock'
                  size='lg'
               />

            </div>

            <span className='leading-tight text-major dark:text-major-dark '>
               <span className='font-medium'>Upcoming livestream:</span>&nbsp;{ DateFormatter.getDateByTimzeonFormat(date) }
            </span>

         </div>
         {
            showAddToCalendar && data && (
               <AddCalendarButton
                  title={ data.title }
                  description={ data.description }
                  date={ data.scheduled_livestream_date }
                  className='h-5 w-40 lg:ml-3 mt-2 lg:mt-0 ml-0'
                  buttonState={ {
                     textSize: 'small',
                     fontIconSize: 'small',
                     backgroundColor: 'transparent',
                     classNames: 'truncate h-full',
                  } }
               />
            )
         }
      </div>
   )
}


UpcomingLiveStream.propTypes = {
   date: PropTypes.string,
   className: PropTypes.string,
   data: PropTypes.object,
   showAddToCalendar: PropTypes.bool,
};
UpcomingLiveStream.defaultProps = {
   className: 'mx-4 mb-4',
   data: null,
}


export  default UpcomingLiveStream
