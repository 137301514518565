import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';
import PageHeader from 'common/components/modules/page-header';

import './style.scss';
export default class ResponsiveModal extends React.Component {
   static defaultProps = {
      closeOnClickOutside: true,
      blurColor: 'modal',
      className: '',
      marginX: '8',
      title: '',
      fontIconSize: 'doubleLarge',
      fontIconColor: 'major dark:text-major-dark',
      leftIconName: 'close',
      truncateHeaderText: false,
      isFixed: false,
      showAcceptButton: true,
      headerLeftIconName: 'close',
      backgroundColor: 'action',
      textColor: 'major-dark',
      primaryColor: true,
      isDisabled: false,
      isCloseButtonVisible: true,
      contentClass: 'p-5',
      contentPosition: 'bottom',
      animate: null,
      modalContentClassName: '',
      showNewStyles: false,
      modalheaderText: {},
      wrapperClassName: '',
      contentInlineStyles: {
         overflow: 'auto',
      },
      dataToolTipContent: '',
   };

   static propTypes = {
      children: PropTypes.node,
      // blurColor: PropTypes.string,
      // contentBgColor: PropTypes.string,
      // roundedModal: PropTypes.string,
      // marginX: PropTypes.string,
      // contentWidth: PropTypes.any,
      headerTitle: PropTypes.string,
      headerLeftIconAction: PropTypes.func,
      // isEditebl: PropTypes.bool,
      height: PropTypes.string,
      width: PropTypes.string,
      footerButtonText: PropTypes.string,
      footerIconName: PropTypes.string,
      footerIconPosition: PropTypes.string,
      foooterButtonAction: PropTypes.func,
      headerLeftIconName: PropTypes.string,
      withoutFooter: PropTypes.bool,
      isHelpIcon: PropTypes.bool,
      onClose: PropTypes.func,
      backgroundColor: PropTypes.string,
      textColor: PropTypes.string,
      primaryColor: PropTypes.bool,
      isDisabled: PropTypes.bool,
      isLoading: PropTypes.bool,
      isCloseButtonVisible: PropTypes.bool,
      contentClass: PropTypes.string,
      className: PropTypes.string,
      animate: PropTypes.string,
      contentPosition: PropTypes.string,
      isCloseAction: PropTypes.bool,
      headerClassName: PropTypes.string,
      modalContentClassName: PropTypes.string,
      showNewStyles: PropTypes.bool,
      modalheaderText: PropTypes.object,
      contentInlineStyles: PropTypes.object,
      wrapperClassName: PropTypes.string,
      dataToolTipContent: PropTypes.string,
   };

   constructor(props) {
      super(props);
      this.state = {
         screenWidth: document.documentElement.clientWidth,
      }

   }

   componentDidMount = () => {
      window.addEventListener("resize", this.onScreenResize);
   }

   componentWillUnmount(){
      window.removeEventListener("resize", this.onScreenResize);
   }

   onScreenResize = () => {
      const screenWidth = document.documentElement.clientWidth;
      this.setState({
         screenWidth,
      });
   }

   render() {
      const {
         children,
         width,
         // blurColor,
         // contentBgColor,
         // roundedModal,
         // className,
         // marginX,
         animate,
         contentPosition,
         // contentWidth,
         headerTitle,
         headerLeftIconAction,
         headerLeftIconName,
         // isEditebl,
         // photosCount,
         height,
         footerButtonText,
         footerIconName,
         footerIconPosition,
         foooterButtonAction,
         withoutFooter,
         isHelpIcon,
         onClose,
         backgroundColor,
         textColor,
         primaryColor,
         isDisabled,
         isLoading,
         isCloseButtonVisible,
         contentClass,
         className,
         isCloseAction,
         headerClassName,
         modalContentClassName,
         showNewStyles,
         modalheaderText,
         wrapperClassName,
         contentInlineStyles,
         dataToolTipContent,
      } = this.props;
      const { screenWidth } = this.state;
      let modalHeigth = '100%';
      if(!!height) {
         if(screenWidth > 700) {
            modalHeigth = height;
         }
      }

      return (
         <Modal
            contentPosition={ screenWidth > 700 ? 'center' : contentPosition }
            onClose={ onClose }
            contentWidth={ screenWidth > 700 ? width  : undefined }
            className={ `h-full ${ className }` }
            animate={ screenWidth > 700 ? null : animate }
            isCloseAction={ isCloseAction }
            otherClassName={ modalContentClassName }
         >
            <div
               className={ `container-wrapper flex flex-col ${ wrapperClassName }` }
               style={ {
                  height: modalHeigth,
               } }
            >
               <header className=' pb-6'>
                  <PageHeader
                     title={ headerTitle }
                     fontIconSize='doubleLarge'
                     menuIconColor='major'
                     fontIconColor='secondary'
                     leftIconName={ isCloseButtonVisible ? headerLeftIconName : '' }
                     truncateHeaderText={ false }
                     leftIconAction={ () => { headerLeftIconAction() } }
                     isFixed={ false }
                     isHelpIcon={ isHelpIcon }
                     className={ headerClassName }
                     showNewStyles={ showNewStyles }
                     modalheaderText={ modalheaderText }
                  />
               </header>
               <main
                  className={ contentClass }
                  style={ contentInlineStyles }
               >
                  {children}
               </main>
               { !withoutFooter &&
               <footer
                  className='z-10 shadow dark:shadow-dark'
               >
                  <Button
                     onClick={ () => { foooterButtonAction() } }
                     classNames={
                        `h-full
                        ${ showNewStyles ? `!h-9 ${ modalheaderText.type === 'different' ? '!w-[327px]' : 'w-full' } max-[327px]:w-full mx-auto  !py-2 !px-[14px]` : '' }`
                     }
                     text={ footerButtonText }
                     disabled={ isDisabled }
                     backgroundColor={ backgroundColor }
                     textColor={ textColor }
                     isLoading={ isLoading }
                     primaryColor={ primaryColor }
                     secondIconName={ footerIconName }
                     iconPosition={ footerIconPosition }
                     textSize={ showNewStyles ? 'small' : '' }
                     textMarginX={ showNewStyles ? '0' : '' }
                     dataToolTipContent={ dataToolTipContent }
                  />
               </footer>}
            </div>
         </Modal>
      );
   }
}
