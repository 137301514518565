import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         stories: payload.stories,
         videos: payload.videos,
         photosets: payload.photosets,
         siteHeader: payload.site_header,
         store: payload.store,
         isFetching: false,
         aboutMe: payload.about_me,
         recordedStreams: payload.recorded_streams,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.TOGGLE_LIKE_COMPLETED]: (state, action) => {
      const  { payload: { id, type } } = action;
      let data = [];
      if(type === 'videos') {
         data = state.videos;
      }
      if(type === 'photosets') {
         data = state.photosets
      }
      if(type === 'recordedStreams') {
         data = state.recordedStreams
      }
      const findIndex = data.findIndex(item => item.id === id);
      if(findIndex >= 0){
         data[findIndex].is_liked = !data[findIndex].is_liked;
      }
      return {
         ...state,
         [type]: [...data],
      }
   },

   [types.UPDATE_MEDIA_ITEM]: (state, action) => {
      const  { payload: { type, data } } = action;
      let newData = [...state[type]].map((el) => {
         let { ...item } = el;
         if(item.id === data.id){
            item = {
               ...item,
               ...data,
            }
         }
         return item
      })
      return {
         ...state,
         [type]: [...newData],
      }
   },

   [types.TOGGLE_WATCHED_COMPLETED]: (state, action) => {
      const  { payload: { id, type } } = action;
      let data = [];
      if(type === 'videos') {
         data = state.videos;
      }
      if(type === 'photosets') {
         data = state.photosets
      }
      if(type === 'recordedStreams') {
         data = state.recordedStreams
      }
      const findIndex = data.findIndex(item => item.id === id);
      if(findIndex >= 0){
         data[findIndex].watched = !data[findIndex].watched;
      }
      return {
         ...state,
         [type]: [...data],
      }
   },

   [types.ON_CHANGE_FAVORITE_ITEM_COMPLETED]: (state, action) => {
      const  { payload: { id, type } } = action;

      let data = [];
      if(type === 'videos') {
         data = state.videos;
      }
      if(type === 'photosets') {
         data = state.photosets
      }
      if(type === 'recordedStreams') {
         data = state.recordedStreams
      }
      if(type === 'store') {
         data = state.store;
      }

      const findIndex = data.findIndex(item => item.id === id);
      if(findIndex >= 0){
         data[findIndex].is_favorite = !data[findIndex].is_favorite;
      }

      return {
         ...state,
         [type]: [...data],
      }
   },
}

export default createReducer(initialState)(reducersMap);
