import React, { useState, useEffect } from 'react';
import Modal from 'common/components/modules/modal';
import PrimaryButton from 'common/components/elements/buttons/primary';
import PropTypes from 'prop-types';
import SecondModal from '../../modal';
import { types } from '../data';
import { getCapitalizeSiteName, getSiteLink } from 'common/utils/helper';

import '../../style.scss';

const moment = require('moment');

const InformationPopup = ({
   showButton, isOpen, onClose, isDemoMode,
}) => {
   const [openModal, setOpenModal] = useState(isOpen);
   const [secondModal, setSecondModal] = useState('');
   const [animate, setAnimate] = useState('up');

   useEffect(() => {
      isOpen ? onOpenModal() : onCloseModal();
   }, [isOpen]);

   const onOpenModal = () => {
      setAnimate('up');
      setTimeout(() => {
         setOpenModal(true)
      }, 100);
   }

   const onCloseModal = () => {
      setAnimate('down');
      setTimeout(() => {
         setOpenModal(false)
      }, 800);
   }
   return (
      <>
         {
            showButton && (
               <p
                  className='font-medium text-secondary dark:text-secondary-dark text-center py-4 information-and-policies-button cursor-pointer select-none'
                  onClick={ onOpenModal }
                  role='presentation'
               >
                  INFORMATION & POLICIES
               </p>
            )
         }
         { openModal  && (
            <Modal
               blurColor='modal'
               contentPosition='bottom'
               closeOnClickOutside={ false }
               onClose={ onCloseModal }
               animate={ animate }
            >
               <div className='select-none'>
                  <div className='flex justify-between items-center px-4 py-3'>
                     <p className='cursor-pointer'>Information & Policies</p>
                     <div className='inline-flex'>
                        <PrimaryButton
                           iconName='close'
                           onClick={ () => {
                              setTimeout(() => {
                                 onCloseModal();
                                 onClose()
                              }, 100)
                           } }
                           backgroundColor='transparent'
                           fontIconColor='major'
                           padding='none'
                        />
                     </div>
                  </div>
                  { types.map((type, index) => {
                     if(isDemoMode && type === 'Site Administrator Agreement') return null
                     return (
                        <div className='border-t border-solid dark:border-divider-dark border-divider px-4 py-3' key={ index.toString() }>
                           <PrimaryButton
                              text={ type }
                              textPosition='left'
                              textSize='base'
                              iconName='arrow-right'
                              padding='none'
                              alignment='between'
                              backgroundColor='transparent'
                              textColor='action'
                              fontIconColor='major'
                              onClick={  type === 'Content Policy' ? () => window.open('https://help.mymember.site/en/articles/3512988-content-policy', '_blank') : () => {
                                 setSecondModal(type)
                                 onCloseModal()
                              } }
                              fontWeight='normal'
                           />
                        </div>
                     )
                  }
                  ) }
                  <p className='text-secondary dark:text-secondary-dark px-4 py-3 border-t border-solid dark:border-divider-dark border-divider'>
                     Copyright notice { moment().year() } <a className='text-action dark:text-action-dark  font-medium' target='_blank' rel='noopener noreferrer' href={ getSiteLink() }><span dangerouslySetInnerHTML={ { __html: getCapitalizeSiteName() } } /></a>
                  </p>
               </div>
            </Modal>)
         }
         {
            !!secondModal && (
               <SecondModal
                  title={ secondModal }
                  onClose={ () => setSecondModal('') }
                  type='admin'
               />
            )
         }
      </>
   )
}
InformationPopup.defaultProps = {
   showButton: true,
   isOpen: false,
   onClose: () => {},
   isDemoMode: false,
}
InformationPopup.propTypes = {
   showButton: PropTypes.bool,
   isOpen: PropTypes.bool,
   onClose: PropTypes.func,
   isDemoMode: PropTypes.bool,
}
export default InformationPopup;
