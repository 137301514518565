import React from 'react';
import Button from 'common/components/elements/buttons/primary';
// import intercomMoreInfo, { FILE_A_COMPLAINT } from 'sites/utils/IntercomMoreInfo';
import PropTypes from 'prop-types';


const Dmca = ({
   legalPages,
}) => {

   let currentInfo

   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'dmca')
   }

   return (
      <>
         {
            currentInfo?.html_content ?
               <>
                  <div dangerouslySetInnerHTML={ {
                     __html: currentInfo?.html_content,
                  } } className='text-major dark:text-major-dark policy-content'
                  />
               </>
               :
               <>
                  <div className='text-major dark:text-major-dark policy-content'
                  >
                     <p>It is our policy to respond to complaints and/or notices of alleged copyright infringement that comply with the Digital Millennium Copyright Act. </p>
                     <br />
                     <ul>
                        <li>All complaints are responded to within seven business days (but usually within a few hours).</li>
                        <li>If illegal content is identified, it will be removed immediately.</li>
                        <li>All complaints will be responded to, and will be investigated.</li>
                     </ul>
                     <br />
                     <strong><p>Process</p></strong>
                     <p>
                        To file a complaint, click the “File a complaint” button below. You will then be prompted to supply additional information to facilitate our investigation. Once we have received the necessary information, your complaint will be reviewed. Within seven business days,
                        we will remove the content in question and ensure the content does not resurface. We may also take action against the poster. Should we decline your complaint, you will have the option to restart the process.
                     </p>
                     <br />
                     <strong><p>Required information</p></strong>
                     <p>
                        If you have found content that features illegal activity or minors, we will require links to where the content is hosted.
                     </p>
                     <br />
                     <p>
                        If you are featured in content that is being distributed without your permission, we will require links to where content is hosted, and you will be required to prove your identity.
                     </p>
                     <br />
                     <p>
                        If you are a copyright owner or an agent thereof, and you believe that any content hosted on our network infringes your copyrights, then you may submit a notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by providing us with the following information:
                     </p>
                     <br />
                     <ul>
                        <li>A physical or electronic signature of a person authorized to act on behalf of the copyright owner of the work in question (17 U.S.C § 512 (c) (3)(A)(i))</li>
                        <li>Identification of the copyrighted work or works claimed to have been infringed, e.g., a copy of the work, or, in the case of multiple works a representative set of samples (17 U.S.C § 512 (c) (3)(A)(ii));</li>
                        <li>Information reasonably sufficient to permit us to locate the material (e.g., the web address of the infringement)(17 U.S.C § 512 (c) (3)(A)(iii));</li>
                        <li>Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an email address (17 U.S.C § 512 (c) (3)(A)(iv));</li>
                        <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or law (17 U.S.C § 512 (c) (3)(A)(v)); and</li>
                        <li>A statement, under penalty of perjury, that the information in the notification is accurate and that the complaining party is authorized to act on behalf of the owner of an exclusive copyright that is allegedly infringed (17 U.S.C § 512 (c) (3)(A)(v)).</li>
                     </ul>
                  </div>

               </>}
         <div className='mx-4 w-46 mt-4'>
            <Button
               primaryColor
               borderRadius='large'
               // onClick={ () => intercomMoreInfo(FILE_A_COMPLAINT) }
               text='File a complaint'
               classNames='intercom-file-a-complaint'
            />
         </div>

      </>
   )
};

Dmca.propTypes = {
   legalPages: PropTypes.array,
}

export default Dmca;
