import * as types from "./types";

const fetchResourcesRequest = () => ({
   type: types.FETCH_RESOURCES_REQUEST,
});

const fetchResourcesCompleted = (data) => ({
   type: types.FETCH_RESOURCES_COMPLETED,
   payload: data,
})

const fetchResourcesFailed = (err) => ({
   type: types.FETCH_RESOURCES_FAILED,
   payload: {
      err,
   },
})
const setDownloadButtonLoading = bool => ({
   type: types.SET_DOWNLOAD_BUTTON_LOADING,
   payload: bool,
})
const setWrongDownloadButtonModal = data => ({
   type: types.SET_WRONG_DOWNLOAD_MODAL,
   payload: data,
})

export {
   fetchResourcesRequest,
   fetchResourcesCompleted,
   fetchResourcesFailed,

   setDownloadButtonLoading,
   setWrongDownloadButtonModal,
};
