export const TERMS_OF_USE = 'Terms of use';
export const RECORD_KEEPING = '2257 Compliance';
export const DMCA = 'Complaints & Content Removal';
export const PRIVACY_POLICY = 'Privacy Policy';
export const MEMBER_SUPPORT = 'Member support';
export const CONTACT_US = 'Contact Us';
export const AFFILIATES  = 'Affiliates';

export const types = [
   TERMS_OF_USE,
   PRIVACY_POLICY,
   RECORD_KEEPING,
   DMCA,
   AFFILIATES,
   MEMBER_SUPPORT,
   CONTACT_US,
];
