import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';
import { isEmpty } from 'lodash'

let reducersMap = {

   [types.FETCH_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
         isFetchingById: false,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { photosetsData, emptyFilter } } = action;
      let newState = {
         isEmpty: photosetsData.data.length === 0,
         isEmptyByFilter: false,
      }
      if(!emptyFilter) {
         newState = {
            isEmptyByFilter: photosetsData.data.length === 0,
         }
      }
      return {
         ...state,
         ...newState,
         isFetching: false,
         photosetsData,
         isInited: true,

      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   ////////////////////////////
   [types.FETCH_BY_FILTER_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
      }
   },
   [types.FETCH_BY_FILTER_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingByFilter: false,
         photosetsData: payload,
         isEmptyByFilter: payload.data.length === 0,

      }
   },
   [types.FETCH_BY_FILTER_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingByFilter: action?.payload?.error?.message === 'canceled' ? true : false,
      }
   },

   ///////////////////////////////////
   [types.FETCH_PHOTOSET_BY_ID_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingById: true,
         isInited: !isEmpty(state.photosetsData),
      }
   },
   [types.FETCH_PHOTOSET_BY_ID_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingById: false,
         activePhotoset: payload,

      }
   },
   [types.FETCH_PHOTOSET_BY_ID_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingById: false,
         isInited: false,
      }
   },

   /////////////////////////
   [types.FETCH_NEXT_PHOTOSETS_REQUEST]: (state, action) => {
      return {
         ...state,
         isNextFetching: true,
      }
   },
   [types.FETCH_NEXT_PHOTOSETS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isNextFetching: false,
         photosetsData: {
            ...payload,
            data: [...state.photosetsData.data, ...payload.data],
         },
      }
   },
   [types.FETCH_NEXT_PHOTOSETS_FAILED]: (state, action) => {
      return {
         ...state,
         isNextFetching: false,
      }
   },
   //////////////////
   [types.TOGGLE_LIKE_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const data = state.photosetsData.data || []
      const findIndex = data.findIndex(story => story.id === payload.id);
      if(findIndex >= 0){
         data[findIndex].is_liked = !data[findIndex].is_liked;
      }
      if(typeof action.payload.isLike === 'boolean' && !!state.activePhotoset) {
         if(action.payload.isLike) {
            state.activePhotoset.likes_count += 1
         } else {
            state.activePhotoset.likes_count -= 1
         }
         state.activePhotoset.is_liked = action.payload.isLike
      }
      return {
         ...state,
         isNextFetching: false,
         photosetsData: {
            ...state.photosetsData,
            data: [...data],
         },
         activePhotoset: {
            ...state.activePhotoset,
            is_liked: (state.activePhotoset.id === Number(payload)) ? !state.activePhotoset.is_liked : state.activePhotoset.is_liked,
         },
      }
   },

   [types.SET_FILTERS]: (state, action) => {
      return {
         ...state,
         filters: action.payload,
      }
   },
   [types.CLEAR_STATE]: (state, action) => {
      return {
         ...state,
         isInited: false,
         filters: action.payload || {},
      }
   },
   [types.UPDATE_PHOTOSET_BY_ID]: (state, action) => {
      const { data, id } = action.payload
      return {
         ...state,
         photosetsData: {
            ...state.photosetsData,
            data: [...state.photosetsData.data].map(el => {
               let { ...item } = el
               if(id === item.id) item = { ...item, ...data }
               return item
            }),
         },
      }
   },
   [types.TOGGLE_WATCHED_COMPLETED]: (state, action) => {
      const id = action.payload;
      const data = state.photosetsData.data || []
      const findIndex = data.findIndex(set => set.id === id);
      if(findIndex !== -1) {
         data[findIndex].watched = !data[findIndex].watched
         data[findIndex].new = false
      }
      return {
         ...state,
         photosetsData: {
            ...state.photosetsData,
            data: [...data],
         },
      }
   },
   [types.RESET_ACTIVE_PHOTOSET]: (state, action) => {
      return {
         ...state,
         activePhotoset: {},
      }
   },
   [types.UPDATE_DATA_AFTER_PURCHASE_COMPLETED]: (state, action) => {
      const updatableData = action.payload;
      updatableData.watched = true;
      updatableData.new = false;
      let newData = []
      if(state.photosetsData?.data) {
         newData = state.photosetsData.data.map(item => {
            if(item.id === updatableData.id) {
               return { ...updatableData }
            }
            return item
         })
      }
      return {
         ...state,
         photosetsData: {
            ...state.photosetsData,
            data: newData,
         },
      }
   },
   [types.SET_IS_INITED_STATE]: (state, action) => {
      let newState = {};
      if(!action.payload){
         newState = {
            photosetsData: {},
         }
      }
      return {
         ...state,
         ...newState,
         isInited: action.payload,
      }
   },
   [types.ON_CHANGE_FAVORITE_ITEM_COMPLETED]: (state, action) => {
      const  { payload: { id, isDetailsPage } } = action;
      let tempData = state.photosetsData.data || []
      let activePhotoset = state?.activePhotoset

      if(isDetailsPage) {
         activePhotoset = {
            ...activePhotoset,
            is_favorite: !activePhotoset.is_favorite,
         }
      }

      if(!!tempData?.length) {
         const findIndex = tempData.findIndex(item => item.id === id)
         if(findIndex >= 0){
            tempData[findIndex].is_favorite = !tempData[findIndex].is_favorite
         }
      }

      return {
         ...state,
         photosetsData: {
            ...state.photosetsData,
            data: tempData,
         },
         activePhotoset,
      }
   },
};

export default createReducer(initialState)(reducersMap);
