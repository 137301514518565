const initialState = {
   isFetching: true,
   isEmpty: false,
   isEmptyByFilter: false,
   photosetsData: {},
   activePhotoset: {},
   isFetchingById: true,
   isFetchingByFilter: false,
   isNextFetching: false,
   errors: null,
   isInited: false,
   filters: {},
};

export default initialState;
