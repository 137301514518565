import Router from 'sites/routes/router';
import { history } from 'sites/state/store';
import Auth from 'sites/utils/Auth';
import { isLocalhost, getSiteUrl } from 'sites/utils/Helpers';
import LocalStorage from 'sites/utils/LocalStorage';
import Intercom from 'common/utils/Intercom';
import moment from 'moment';
import QueryParams from './QueryParams';
import { getInformationAndPoliciesType } from 'common/components/modules/information-and-policies/sites/data.js';
import { getMemberTotalSpent } from 'common/utils/utils';

const getUserStatusType = (bool) => {
   let result = 'public'
   if(bool){
      result = 'active'
   }
   return result
};


const goToJoin = (id, page) => {
   if(!Auth.getToken()) {
      const domainType = document.querySelector('meta[name=domainType]') && document.querySelector('meta[name=domainType]').content;
      const subdomain = document.querySelector('meta[name=subdomain]') && document.querySelector('meta[name=subdomain]').content;
      let prefix = '';
      if(!isLocalhost() && 'site_name_prefix' === domainType) {
         prefix = `/${ subdomain }`;
      }
      if(page && typeof page === 'string') {
         history.push({
            pathname: Router.route('SIGN_UP').getCompiledPath(),
            search: page === '/' ? `go-back=${ prefix }&goTo=join` : `go-back=${ prefix }/${ page }&goTo=join`,
         });
      } else {
         history.push({
            pathname: Router.route('SIGN_UP').getCompiledPath(),
            search: id ? `go-back=${ prefix }/purchase/membership/${ id }&goTo=join` : 'goTo=join',
         });
      }

   } else {
      if(id) {
         history.push({
            pathname: Router.route('PURCHASE_MEMBERSHIP').getCompiledPath({ id }),
         });
      } else {
         history.push({
            pathname: Router.route('JOIN').getCompiledPath(),
         });
      }
   }
}

const setReferrerUrl = (pageInfo) => {
   const referrer_url = {
      ...pageInfo,
      url: window.location.href,
   }
   window.referrer_url = referrer_url;

}

const gotToAfterSignIn = (search, goTo) => {
   if(!!search && !!search.includes('goTo')){
      if('?goTo=join' === search) {
         goTo({ pathname: Router.route('JOIN').getCompiledPath() });
      } else {
         const params = search.split('&');
         const isOpenChoosePaymentModal = !!params.find(i => i.includes('choosePurchase=1'))
         let paymentType = null;
         if(!!params.find(i => i.includes('payment_type=unlock_content'))) {
            paymentType = 'unlock_content'
         }
         if(!!params.find(i => i.includes('payment_type=download_content'))) {
            paymentType = 'download_content'
         }
         let path = null
         let type = null;
         if(search.includes('video')) {
            type = 'video';
            path = !isOpenChoosePaymentModal ? 'PURCHASE_VIDEO' : 'VIDEOS_INDIVIDUAL'
         }
         if(search.includes('photoset')) {
            type = 'photoset';
            path = !isOpenChoosePaymentModal ? 'PURCHASE_PHOTOSET' : 'PHOTOSETS_INDIVIDUAL'
         }
         if(search.includes('store')) {
            type = 'store';
            path = 'PURCHASE_STORE'
         }
         if(search.includes('membership')){
            type = 'join'
            path = 'PURCHASE_MEMBERSHIP'
         }
         if(path) {
            let id = search.replace(`?goTo=${ type }_`, '')
            if(type === 'join'){
               // split url by slashes and get last item as id
               const num = params[0].split("/").at(-1)
               id = Number(num)
            }
            if(isOpenChoosePaymentModal) {
               let item = params.find(i => i.includes(`goTo=${ type }_`))
               id = item.replace(`?goTo=${ type }_`, '')
            }
            let  searchP = type === 'join' ? '' : `?openChoosePurchase=${ isOpenChoosePaymentModal ? 1 : 0 }`
            if(paymentType) {
               searchP = `${ searchP }&payment_type=${ paymentType }`
            }
            goTo({
               pathname: Router.route(path).getCompiledPath({ id }),
               search: searchP,
            });
         } else {
            goTo({
               pathname: Router.route('HOMEPAGE').getCompiledPath(),
            });
         }
      }
   } else {
      goTo({
         pathname: Router.route('HOMEPAGE').getCompiledPath(),
      });
   }
}

const isGuestRoute = (locationPath) => {
   let res = false;
   let route = [
      'SIGN_IN',
      'SIGN_IN_NOT_RECOGNIZED',
      'SIGN_IN_EMAIL',
      'SIGN_UP',
      'SIGN_UP_EMAIL',
      'PASSWORD_RESET',
      'PASSWORD_RESET_TOKEN',
      'EMAIL_VERIFICATION',
   ];
   route.forEach(element => {
      if(Router.route(element).match(locationPath)) {
         res = true;
      }
   });
   return res
}

const onInitIntercomeData = (data, additionalSettings = {}) => {
   const subdomain = data.site_info.subdomain;
   const intercomSettings = {
      member_of: subdomain,
      member_current_page_url: window.location.href,
      member_relative_root_url: `${ window.location.protocol }//${ getSiteUrl() }`,
      ssl_working: data.site_info.ssl_working,
   };
   if(data.user) {
      intercomSettings.member_logged_in = true;
      if(data.user.email){
         intercomSettings.member_email = data.user.email;
         // intercomSettings.email = data.user.email;
      }
      if(data.user.member_ip_address) {
         intercomSettings.member_ip_address = data.user.member_ip_address;
      }
      if(data.user.membership_status){
         intercomSettings.member_membership_status = data.user.membership_status === 'incomplete' ? 'guest' : data.user.membership_status;
      }
      if(data.user.member_membership_rebill_date){
         intercomSettings.member_membership_rebill_date = data.user.member_membership_rebill_date;
      }
      if(data?.user && ('total_spent_sum' in data.user || 'total_spent' in data.user)){
         intercomSettings.member_total_spent = getMemberTotalSpent(data.user);
      }
      if(data.user.member_score){
         intercomSettings.member_trust_score = data.user.member_score;
      }
      if(data.user.site_using_vpn_blocking){
         intercomSettings.site_using_vpn_blocking = true;
      } else {
         intercomSettings.site_using_vpn_blocking = false;
      }
      if(data.user.banned){
         intercomSettings.member_blocked = true;
      } else {
         intercomSettings.member_blocked = false;
      }
      if(data.user.country_or_region_blocked){
         intercomSettings.member_region_blocked = true;
      } else {
         intercomSettings.member_region_blocked = false;
      }
      if(data.site_info.site_long_name){
         intercomSettings.site_long_name = data.site_info.site_long_name;
      }
      if(data.user.member_had_chargeback){
         intercomSettings.member_had_chargeback = data.user.member_had_chargeback;
      }
      if(data.site_info.admin_screen_name){
         intercomSettings.member_admin_screen_name = data.site_info.admin_screen_name;
      }
   }
   if(data.user && data.user.hasOwnProperty('logged_in') && data.user.logged_in === false) {
      intercomSettings.member_logged_in = false;
   }
   Intercom.shutDown();
   Intercom.boot({ ...intercomSettings, ...additionalSettings });
}


const expiredLastReCAPTCHADay = () => {
   const lastReCAPTCHADay = LocalStorage.getItem('ReCAPTCHADay');
   if(!lastReCAPTCHADay) {
      return true;
   }
   const diff = moment().utc().diff(moment(lastReCAPTCHADay), 'days');
   return (isNaN(diff) || diff > 30);
}
const isNonHaveAdultContent = (isSiteAdult) => {
   const body = document.querySelector('body');
   const isSearchBoot = Boolean(body?.classList?.contains && body.classList.contains('hideTermsOfUseModal'));
   if(isSearchBoot){
      return true;
   }
   return !isSiteAdult;
}

const isAllowedRouteIfNeedAgeVerification = (locationPath) => {
   const ALLOWED_ROUTE_CODES = [
      'SIGN_IN',
      'SIGN_IN_NOT_RECOGNIZED',
      'SIGN_IN_EMAIL',
      'PASSWORD_RESET',
      'PASSWORD_RESET_TOKEN',
      'AGE_VERIFICATION',
      'AGE_VERIFICATION_PROCESS',
   ]

   return ALLOWED_ROUTE_CODES.some(code => Router.route(code).getCompiledPath() === locationPath)
}

const isBlockedSignUp = () => {
   return (localStorage?.getItem(getMemberBlockType()) === 'true' || QueryParams.getParsedSearchParams()?.showSignIn === 'true' || QueryParams.getParsedSearchParams()?.[getMemberBlockTypeValue()] === 'true')
}
const getMemberBlockType = () => {
   let blockType = ''

   switch (window?.blockedAdditionalData?.error) {
      case 'vpn_blocked':
         blockType = 'v_block'
         break;
      case 'ip_blocked':
         blockType = 'ip_block'
         break;
      case 'profile_blocked':
         blockType = 'p_block'
         break;
      case 'region_blocked':
         blockType = 'r_block'
         break;
      case 'country_blocked':
         blockType = 'c_block'
         break;

      default:
         break;
   }
   return blockType
}

const getMemberBlockTypeValue = () => {
   let blockType = ''

   switch (window?.blockedAdditionalData?.error) {
      case 'vpn_blocked':
         blockType = 'vpn_blocked'
         break;
      case 'ip_blocked':
         blockType = 'ip_blocked'
         break;
      case 'profile_blocked':
         blockType = 'profile_blocked'
         break;
      case 'region_blocked':
         blockType = 'region_blocked'
         break;
      case 'country_blocked':
         blockType = 'country_blocked'
         break;

      default:
         break;
   }
   return blockType
}

const updateContentPageTitle = (contentTitle) => {
   const splitArr = document.title.split('-')
   let siteScreenName = splitArr?.[0] || '';
   if(siteScreenName){
      siteScreenName = siteScreenName.trim();
   }
   if(contentTitle){
      document.title = siteScreenName + ' - ' + contentTitle;
   }
}
const updateContentPageTitleForInformationAndPolicies = (type) => {
   if(getInformationAndPoliciesType(type)){
      document.querySelector('meta[name=savedTitle]').content = document.title;
      updateContentPageTitle(getInformationAndPoliciesType(type))
   } else if(document.querySelector('meta[name=savedTitle]').content){
      document.title = document.querySelector('meta[name=savedTitle]').content;
      document.querySelector('meta[name=savedTitle]').content = null;
   }
}

export {
   getUserStatusType,
   goToJoin,
   setReferrerUrl,
   gotToAfterSignIn,
   isGuestRoute,
   onInitIntercomeData,
   expiredLastReCAPTCHADay,
   isNonHaveAdultContent,
   isAllowedRouteIfNeedAgeVerification,
   isBlockedSignUp,
   getMemberBlockType,
   getMemberBlockTypeValue,
   updateContentPageTitle,
   updateContentPageTitleForInformationAndPolicies,
};
