import Intercom from 'common/utils/Intercom';
import LocalStorage from 'sites/utils/LocalStorage';

export default  {
   setToken: (token) => {
      return LocalStorage.setItem('authToken', token);
   },
   getToken: () => {
      return LocalStorage.getItem('authToken');
   },
   isTokenExists: () => {
      return !!LocalStorage.getItem('authToken');
   },
   logout: () => {
      if(!!window.blockedAdditionalData){
         return
      }
      Intercom.shutDown();
      const intercomSettings = {};
      const chatSocket = window.siteChatSocket
      const liveStreamSocket = window.siteLiveStreamSocket
      if(chatSocket && chatSocket.connected) {
         chatSocket.disconnect()
         window.siteChatSocket = null
      }
      if(liveStreamSocket && liveStreamSocket.connected) {
         liveStreamSocket.disconnect()
         window.siteLiveStreamSocket = null
      }
      if(window.intercomSettings && window.intercomSettings.member_of) {
         intercomSettings.member_of = window.intercomSettings.member_of;
      }
      Intercom.boot(intercomSettings);
      return LocalStorage.removeItem('authToken');
   },
}
