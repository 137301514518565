import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
         isEmpty: false,
         isEmptyByFilter: false,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         favoritesData: payload,
         isFetching: false,
         isEmpty: payload.data.length === 0,
         inited: payload?.data?.length > 0,
      }
   },
   [types.CLEAR_STATE]: (state, action) => {
      return {
         ...state,
         inited: false,
         filters: action.payload,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.FETCH_NEXT_DETAILS_START]: (state) => {
      return {
         ...state,
         isNextFetching: true,
      }
   },
   [types.FETCH_NEXT_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         favoritesData: {
            ...payload,
            data: [
               ...state.favoritesData.data,
               ...payload.data,
            ],
         },
         isNextFetching: false,
      }
   },
   [types.FETCH_NEXT_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isNextFetching: false,
      }
   },

   [types.FETCH_BY_FILTER_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
      }
   },
   [types.FETCH_BY_FILTER_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         favoritesData: {
            ...payload.data,
            data: [
               ...payload.data.data,
            ],
         },
         isFetchingByFilter: false,
         isEmptyByFilter: payload.data.data.length === 0,
         isEmpty: !!payload.isClearFilters && payload.data.data.length === 0 ? true : false,
      }
   },
   [types.FETCH_BY_FILTER_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetchingByFilter: false,
      }
   },

   [types.FETCH_CATEGORIES_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         categories: payload,
      }
   },
   [types.FETCH_DATA_BY_FILTERS]: (state) => {
      return {
         ...state,
         isFetching: false,
         isEmpty: false,
      }
   },
   [types.UPDATE_STORE_LIST]: (state, action) => {
      const { payload: { data, actionType } } = action

      let tempData = state?.favoritesData?.data || []

      if(!tempData?.length) return state

      switch (actionType) {
         case 'delete':
            let isExistsItem = !!tempData?.find(it => it.id === data?.id)
            if(isExistsItem) {
               tempData = tempData.filter(item => item.id !== data.id)
            }

            break;
         case 'update':
            let index = tempData?.findIndex(it => it.id === data?.id)
            if(!!index) {
               tempData[index] = data
            }
            break;

         default:
            break;
      }

      return {
         ...state,
         favoritesData: {
            ...state.favoritesData,
            data: tempData,
         },
      }
   },
   [types.TOGGLE_LIKE_COMPLETED]: (state, action) => {
      const  { payload: { id, type } } = action;

      let data = state?.favoritesData?.data
      const findIndex = data.findIndex(item => item.id === id && item.resource_type === type)
      if(findIndex >= 0){
         data[findIndex].is_liked = !data[findIndex].is_liked;
      }
      return {
         ...state,
         favoritesData: {
            ...state.favoritesData,
            data: [...data],
         },
      }
   },
   [types.TOGGLE_WATCHED_COMPLETED]: (state, action) => {
      const  { payload: { id, type } } = action;
      let data = state?.favoritesData?.data
      const findIndex = data.findIndex(item => item.id === id && item.resource_type === type)
      if(findIndex >= 0){
         data[findIndex].watched = !data[findIndex].watched;
      }
      return {
         ...state,
         favoritesData: {
            ...state.favoritesData,
            data: [...data],
         },
      }
   },
   [types.ON_CHANGE_FAVORITE_ITEM_COMPLETED]: (state, action) => {
      const  { payload: { id, type, isFiltered } } = action;
      let data = state?.favoritesData?.data
      let isEmptyByFilter = false
      let isEmpty = false
      const findIndex = data.findIndex(item => item.id === id && item.resource_type === type)
      if(findIndex >= 0){
         // data[findIndex].is_favorite = !data[findIndex].is_favorite;

         data = data.filter(it => it.id !== id)
      }

      if(!data?.length) {
         if(isFiltered) {
            isEmptyByFilter = true
         } else {
            isEmpty = true
         }
      }

      return {
         ...state,
         favoritesData: {
            ...state.favoritesData,
            data: [...data],
         },
         isEmptyByFilter,
         isEmpty,
      }
   },
}

export default createReducer(initialState)(reducersMap);
