const initialState = {
   isFetching: false,
   isOpenSendModal: false,
   isSend: false,
   isNotEnoughtBalance: false,
   isOpenChoosePaymentModal: false,
   modalKey: '',
   modalType: '',
   savedPaymentData: {
      isSaved: false,
      payment_method: null,
      id: null,
      payment_system: null,
      type: null,
   },
   price: null,
   methods: {},
   paymentButtonLoading: false,
   onlyWalletPay: false,
   oneClickInfo: {},
   contentData: {},
   paymentButtonResourceId: null,
   purchaseData: null,
   isFetchingModal: false,
   pageType: '',
   mainMessageId: null,
};

export default initialState;
