import React from 'react';
import { getSiteName, getSiteNameAbbreviatur } from 'common/utils/helper';

const EndUserTermsOfService = () => (
   <div style={ { lineHeight: '28px' } }>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Copyright Notice</p>
         <p>
            All images and videos on, or downloaded from this web site are Copyright © 2017 by <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> and are protected by Canadian
            and United States copyright laws and international treaty provisions. Materials contained in any part of this website may not be
            reproduced, copied, edited, published, transmitted or uploaded in any way without expressed written permission from <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />.
            Except as expressly stated in the Limited License provision in these Terms and Conditions, <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> does not grant any express or
            implied right to you under any of its trademarks, service marks, copyrights or other proprietary information.
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Notice</p>
         <p>
            These terms MUST be read thoroughly and agreed to prior to joining <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />. The acceptance of these terms signifies a legal and
            binding contract between YOU and <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />, and may not be revoked during the term of your membership. By subscribing to <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />,
            you certify that you are eighteen (18) or twenty-one (21) years of age or older (whichever is applicable in your area); that you are
            familiar with all local laws in your area affecting your legal right to access erotica, or adult-oriented materials; and that you have
            the legal right to access adult-oriented materials, and that <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> has the legal right to transmit them to you. <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> makes
            no representation that materials in the site are appropriate or available for use in other locations. Those who choose to access this
            site from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local
            laws are applicable. You certify that are voluntarily requesting adult-oriented materials for your own private enjoyment and that you will
            never share these materials with a minor or make these materials available to a minor
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Limited License</p>
         <p>
            <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> provides the materials on this site for the personal, non-commercial use by visitors of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />. <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> reserves the right to limit the amount of materials downloaded. Commercial use of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />, or
            any material located on it, is strictly prohibited. In addition, users of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> may not: (i) modify any of
            the materials found in any area of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />; (ii) use any of the materials found in any area of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> for any public
            display, performance, sale or rental; (iii) remove, modify or alter any copyright or other proprietary notice, or trademarks
            from any of the materials found in any area of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />; or (iv) otherwise transfer any material located in any area of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> to any other person or entity. <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> reserves the right to terminate this license at any time if you breach or violate any provision
            of this agreement, in which case you will be obligated to immediately destroy any information or materials you have downloaded, printed or
            otherwise copied from this site. <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> reserves all other rights coincident or related to this site. All photographs or videos purchased
            from <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> are copyright protected and may not be reproduced, reprinted, electronically scanned or posted, used for advertising or any
            other purpose without the written permission of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />. Selling, reselling and/or trading these items through personal transactions, on
            eBay or other auction venues is going against copyright policies and in doing so you will be liable in a court of law.
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Disclaimer</p>
         <p>
            No warranty is made by <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> regarding any information, services, materials or products provided through or in connection with <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />, and <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> hereby expressly disclaims any and all warranties, including without limitation: (i) any warranties as to
            the availability, accuracy or content of materials, information, products or services; (ii) any warranties of merchantability or fitness
            for a particular purpose. Some states do not allow the exclusion of implied warranties, so the above exclusion may not apply to you. <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> offers no assurance of an uninterrupted or error-free service. <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> does not warrant the accuracy or completeness of
            any information, text, graphics, videos, links or other items contained on the web site. <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> may change any of the information
            found on the web site at any time without prior notice. <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> makes no commitment to update the information found on the web site.
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Links to Third Party Sites</p>
         <p>
            This site contains links to sites that are controlled by third parties. Those linked sites are not under the control of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />, and <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> is not responsible for the contents of any linked site or any link contained in a link site. The inclusion of any link does
            not imply endorsement by <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> of any linked site.
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Membership Cancellation</p>
         <p>
            Memberships can be cancelled any time in your <b>My account</b> page.
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Refunds</p>
         <p>
            Refunds will be handled on a case-by-case basis. Should a refund be issued, all funds will be credited solely to the payment method
            used in the original transaction.
         </p>
      </div>

      <div className='mb-6'>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Harassment</p>
         <p>
            <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> has the right to terminate a subscription of a member who acts in a harassing manner towards any <span dangerouslySetInnerHTML={ { __html: getSiteName() } } /> staff or any
            other member. The grounds for termination can be purely subjective and based on the views of <span dangerouslySetInnerHTML={ { __html: getSiteName() } } />.
         </p>
      </div>

      <div>
         <p className='text-2xl text-major dark:text-major-dark mb-3 '>Credit Card Statements</p>
         <p>
            Charges through CCBill will appear on your statement as CCBill.com.
            <br />
            Charges through SegPay will appear on your statement as SEGPAY.com*2308829.
            <br />
            {getSiteNameAbbreviatur()} Media Inc. 80 SW 8 ST SUITE 2000, MIAMI FL 33130
         </p>
      </div>

   </div>
);

export default EndUserTermsOfService;
