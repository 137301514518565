import React from "react";
import PropTypes from "prop-types";
import "../style.scss";
import InformationPopup from "./mobile";
import { getCapitalizeSiteName, getSiteLink } from 'common/utils/helper';

const moment = require('moment');

const InformationAndPilices = ({
   showButton,
   isOpen,
   onClose,
   // textCenter,
   viewType,
   modalCenter,
   isFixed,
   isDemoMode,
}) => {
   return (
      <>
         {viewType !== "none" ? (
            <>
               {(viewType === "all" || viewType === "mobile") && (
                  <div className='mb-2 information-and-policies-mobile'>
                     <InformationPopup
                        showButton={ showButton }
                        isOpen={ isOpen }
                        onClose={ onClose }
                        modalCenter={ modalCenter }
                        isDemoMode={ isDemoMode }
                     />
                  </div>
               )}
               {(viewType === "all" || viewType === "desktop") && (
                  <div className={ `pt-3 pb-5 px-5 items-center information-and-policies-desktop ${ isFixed ? 'fixed bottom-0' : '' }` } >
                     <span
                        className='text-placeholder dark:text-placeholder-dark text-sm line-h-18'>Copyright notice { moment().year()} </span>  <a className='line-h-18 text-placeholder dark:text-placeholder-dark text-sm' target='_blank' rel='noopener noreferrer' href={ getSiteLink() }><span dangerouslySetInnerHTML={ { __html: getCapitalizeSiteName() } } /></a>
                  </div>
                  // <div className='information-and-policies-desktop w-full'>
                  //    <InformationAndPoliciesDesktop textCenter={ textCenter } />
                  // </div>
               )}
            </>
         ) : null}
      </>
   );
};

InformationAndPilices.defaultProps = {
   showButton: true,
   isOpen: false,
   viewType: "all",
   modalCenter: false,
   onClose: () => {},
   isDemoMode: false,
};

InformationAndPilices.propTypes = {
   showButton: PropTypes.bool,
   isOpen: PropTypes.bool,
   onClose: PropTypes.func,
   // textCenter: PropTypes.bool,
   viewType: PropTypes.string,
   modalCenter: PropTypes.bool,
   isFixed: PropTypes.bool,
   isDemoMode: PropTypes.bool,
};

export default InformationAndPilices;
