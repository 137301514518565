import React from 'react';
import PropTypes from 'prop-types';
import InformationAndPolicies from 'common/components/modules/information-and-policies/admin';
import './login-content.scss'

const LoginContent = ({ children, className = '' }) => {
   return (
      <div className='flex flex-col items-center'>
         <div style={ { minHeight: 'calc(100vh - 73px)' } } className={ `${ className } flex flex-col` }>
            { children }
         </div>
         <InformationAndPolicies textCenter={ true } />
      </div>
   );
};

LoginContent.propTypes = {
   children: PropTypes.any,
   className: PropTypes.any,
};

export default LoginContent;
