export const SITES_ADMINISTRATOR_AGREEMENT = 'Site Administrator Agreement';
export const CONTENT_POLICY = 'Content Policy';
export const PRIVACY_POLICY = 'Privacy Policy';
export const END_USER_TERMS_OF_SERVICE = 'End-User Terms of Service';
export const PAYOUTS_AND_FEES = 'Payouts and Fees'


export const types = [
   SITES_ADMINISTRATOR_AGREEMENT,
   CONTENT_POLICY,
   PRIVACY_POLICY,
   END_USER_TERMS_OF_SERVICE,
   PAYOUTS_AND_FEES,
];
