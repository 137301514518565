import React from 'react';
import Button from 'common/components/elements/buttons/primary';
import PropTypes from 'prop-types'
import '../style.scss'
import InformationAndPolicies from 'common/components/modules/information-and-policies/sites';

const StateBlock = ({ logo, state }) => {
   return (
      <div className='flex flex-col items-center justify-center w-full h-full site-block-content text-center mx-auto'>
         <div className='flex flex-col my-auto items-center max-w-[100%] px-6 py-5 lg:p-10 lg:max-w-[634]'>
            {
               !!logo && (
                  <div className='mb-5 flex'>
                     <img src={ logo } alt='' className=' object-contain object-center w-auto h-14' />
                  </div>
               )
            }
            <span className='text-major font-bold text-[34px] dark:text-major-dark'>This site is unavailable in your state</span>
            <span className='text-major text-base leading-[20px] dark:text-major-dark mt-5'>
               Due to the law introduced by legislator Laurie Schlegel,<br />
               we are unable to allow access to IP addresses from the state of {state}. <br /> <br />

               VPNs may be able to pass through, however, as we are not able to determine the originating IP address. <br /> <br />
               For more information on this law visit: <br />
               <a
                  target='_blank'
                  href='https://legis.la.gov/legis/ViewDocument.aspx?d=1289498'
                  className='underline'
                  rel='noreferrer'>
                  https://legis.la.gov/legis/ViewDocument.aspx?d=1289498
               </a>
            </span>


            <div className='flex justify-center items-center mt-10 h-11 w-[176px]'>
               <Button
                  text='Contact support'
                  onClick={ () => {} }
                  borderRadius='large'
                  textSize='base'
                  primaryColor
                  iconName='help-outline'
                  iconPosition='left'
                  textColor='major-dark'
                  fontIconColor='major-dark'
                  classNames='h-full text-start intercom-frontend-error'
                  // classNames='h-full text-start intercom-frontend-error'
                  padding='none'
               />
            </div>
         </div>
         <InformationAndPolicies />

      </div>
   );
};

StateBlock.propTypes = {
   logo: PropTypes.string,
   state: PropTypes.string,
}
export default StateBlock;
