export default {
   adaptColor(color) {
      const rgb = this.generateRGBColor(color)
      if(!Array.isArray(rgb)){
         return
      }
      var difference = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000);
      var textColor = (difference > 195) ? 'major' : 'major-dark';
      return textColor
   },

   generateRGBColor(color, opacity) {
      let hex = color;
      if(!hex){
         return
      }
      if(hex.charAt(0) === '#') {
         hex = hex.substr(1);
      } else {
         return
      }
      if(hex.length > 6) {
         hex = hex.substring(0, 6)
      }
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      let rgb = [];
      let r, g, b = ''
      if(result) {
         r = parseInt(result[1], 16)
         g = parseInt(result[2], 16)
         b = parseInt(result[3], 16)
         rgb = [r, g, b]
      }
      if(opacity) {
         return  `rgb(${ r }, ${ g }, ${ b }, ${ opacity })`
      } else {
         return rgb
      }
   },
}
