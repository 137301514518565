import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

import SyncPageScoll from 'common/utils/pageScrollSync';

const reducersMap = {
   [types.SITE_DETAILS_INIT_START]: (state) => {
      return {
         ...state,
         authUser: null,
         isSiteInited: false,
         menu: {
            ...state.menu,
            isMenuOpened: false,
            animationClass: 'default',
         },
      };
   },
   [types.SITE_DETAILS_INIT_COMPLETED]: (state, action) => {
      const {
         payload: {
            user,
            site_info,
            live_stream_details,
            is_adult_verified,
         },
      } = action;
      const notShowPromoNotificationSession = sessionStorage.getItem('notShowPromoNotification')

      return {
         ...state,
         isSiteInited: true,
         authUser: user,
         siteInfo: site_info,
         isOfflineMode: Boolean(site_info.is_offline_mode),
         customPages: site_info ? site_info.custom_pages : null,
         isFakeUser: user && user.fake ? true : false,
         currentLiveStream: { ...live_stream_details, is_show_banner: true },
         promotionNotification: notShowPromoNotificationSession ? false : Boolean(site_info.public_promotion),
         isAdultVerified: is_adult_verified,
         isOpenPenndingPaymentModal: !!user?.pending_payment_data?.length,
         total_order_requests_count: user?.total_order_requests_count,
         new_order_requests_count: user?.new_order_requests_count,
      };
   },
   [types.SITE_DETAILS_INIT_FAILED]: (state) => {
      return {
         ...state,
         isSiteInited: true,
      };
   },
   [types.SITE_DETAILS_SET_IS_INITED]: (state, action) => {
      return {
         ...state,
         isSiteInited: action?.payload,
      };
   },
   [types.RESET_COMMON_DETAILS]: (state) => {
      return {
         ...initialState,
         isSiteInited: false,
      };
   },
   [types.COLLAPSE_MENU]: (state, action) => {
      SyncPageScoll.syncScroll(!state.menu.isMenuOpened)
      return {
         ...state,
         menu: {
            ...state.menu,
            isMenuOpened: !state.menu.isMenuOpened,
            animationClass: !state.menu.isMenuOpened ? 'menu-hidden-animation' : 'menu-block-animation',
         },
      }
   },
   [types.UPDATE_AUTH_USER]: (state, action) => {
      return {
         ...state,
         authUser: {
            ...state.authUser,
            ...action.payload,
         },
      }
   },

   [types.SCREEN_RESIZE]: (state, action) => {
      return {
         ...state,
         screenWidth: action.payload.screenWidth,
      }
   },

   [types.UPDATE_WALLET_BALANCE_START]: (state) => {
      return {
         ...state,
         isFetchingBalance: true,
      }
   },
   [types.UPDATE_WALLET_BALANC]: (state, action) => {
      const { wallet_balance } = action.payload
      let mediaData = { ...action.payload };
      delete mediaData.wallet_balance;
      return {
         ...state,
         walletBalance: wallet_balance,
         walletMediaData: mediaData,
         isFetchingBalance: false,
      }
   },
   [types.UPDATE_WALLET_BALANCE_FAILED]: (state) => {
      return {
         ...state,
         isFetchingBalance: false,
      }
   },
   [types.SET_IS_ONLINE_STREAM]: (state, action) => {
      return {
         ...state,
         isOnlineStream: action.payload,
      }
   },

   [types.SET_VIEW_AS_MODAL]: (state, action) => {
      const { payload } = action;
      let viewAsModal = {
         ...state.viewAsModal,
         ...payload,
      };
      return {
         ...state,
         viewAsModal,
      }
   },
   [types.SET_UPDATE_MEDIA]: (state, action) => {
      return {
         ...state,
         walletMediaData: action.payload,
      }
   },
   [types.UPDATE_LIVE_STERAM_BANNER_STATE]: (state, action) => {
      return {
         ...state,
         currentLiveStream: {
            ...state.currentLiveStream,
            ...action.payload,
         },
      }
   },
   [types.CLOSE_PROMOTION_NOTIFICATION]: (state) => {
      sessionStorage.setItem('notShowPromoNotification', 'true')
      return {
         ...state,
         promotionNotification: false,
      }
   },
   [types.AGE_VERIFICATION_BY_ID_START]: (state) => {
      return {
         ...state,
         ageVerificationStatus: 'pending',
      }
   },
   [types.AGE_VERIFICATION_BY_ID_COMPLETED]: (state, action) => {
      return {
         ...state,
         ageVerificationStatus: action.payload,
      }
   },
   [types.AGE_VERIFICATION_BY_ID_FAILED]: (state) => {
      return {
         ...state,
         ageVerificationStatus: 'failed',
      }
   },
   [types.RESET_AGE_VERIFICATION_STATUS]: (state) => {
      return {
         ...state,
         ageVerificationStatus: null,
      }
   },
   [types.SET_IS_ADULT_VERIFIED]: (state) => {
      return {
         ...state,
         isAdultVerified: 'verified',
      }
   },
   [types.LOGOUT_START]: (state) => {
      return {
         ...state,
         isFetchingLogout: true,
         menu: {
            ...state.menu,
            isMenuOpened: false,
            animationClass: 'default',
         },
      }
   },
   [types.LOGOUT_COMPLETED]: (state) => {
      return {
         ...state,
         isFetchingLogout: false,
         isSiteInited: false,
         authUser: null,
      }
   },
   [types.SET_MENU_DEFAULT_ANIMATION_CLASS]: (state) => {
      return {
         ...state,
         menu: {
            ...state.menu,
            animationClass: 'default',
         },
      }
   },
   [types.OPEN_CLOSE_PENNDING_PAYMENT_MODAL]: (state, action) => {
      return {
         ...state,
         isOpenPenndingPaymentModal: action.payload,
      };
   },

   [types.CHANGE_ORDERS_TOTAL_COUNT]: (state, action) => {
      return {
         ...state,
         total_order_requests_count: action.payload,
      }
   },
   [types.CHANGE_NEW_ORDERS_REQUESTS_COUNT]: (state, action) => {
      return {
         ...state,
         new_order_requests_count: action.payload,
      }
   },

   [types.SET_IS_OFFLINE]: (state, action) => {
      return {
         ...state,
         isOfflineMode: action.payload,
      };
   },
   [types.UPDATE_PENDING_PAYMENT_DATA]: (state, action) => {
      return {
         ...state,
         authUser: {
            ...state.authUser,
            pending_payment_data: [{ ...action.payload }],
         },
      };
   },
   [types.UPDATE_SITEINFO_DATA]: (state, action) => {
      return {
         ...state,
         siteInfo: action.payload,
      };
   },
};

export default createReducer(initialState)(reducersMap);
