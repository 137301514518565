const initialState = {
   membershipsPricings: [],
   isFetching: true,
   activePurchase: {},
   candidatePaymentOptions: {},
   isFetchingJoin: false,
   payment: {},
   paymentDeclinedInfo: {},
   isFetchingDeclined: true,
   paymentError: null,
   fetchPaymentUsingWallet: false,
   contentAvilableMembership: null,

   promoCode: null,
   isFetchingValidatePromoCode: false,
   isCodeApplied: false,
   promoCodeError: null,
   isFetchingPricingsByCode: false,
   isAppliedByLink: false,
   encodedPromoCode: null,
   freeTrailModal: {
      isOpen: false,
      payment_processor: null,
      amount: null,
      date_of_trial_expiry: null,
   },
};

export default initialState;
