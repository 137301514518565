import { isLocalhost } from 'sites/utils/Helpers';
import { getRoutes } from 'sites/site-route.mjs';


let prefix = '/';

const domainType = document.querySelector('meta[name=domainType]') && document.querySelector('meta[name=domainType]').content;
const subdomain = document.querySelector('meta[name=subdomain]') && document.querySelector('meta[name=subdomain]').content;
if(!isLocalhost() && 'site_name_prefix' === domainType) {
   prefix = `/${ subdomain }/`;
}
let homepageRoute = prefix + '';

if('/' !== homepageRoute && '/' === homepageRoute[homepageRoute.length - 1]) {
   homepageRoute = homepageRoute.slice(0, -1);
}

const routes =  getRoutes(prefix);
export default routes;
