const initialState = {
   isFetching: true,
   isFetchingByFilter: false,
   isNextFetching: false,
   isEmpty: false,
   isEmptyByFilter: false,
   data: {},
   errors: null,
};

export default initialState;
