import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'common/components/elements/icons';
import './index.scss';

const CheckBox = ({
   label, 
   isChecked, 
   classNames, 
   textPosition, 
   onChange, 
   isCircle, 
   unCheckedIconColor, 
   name, 
   disabled, 
   hasError, 
   checkboxClass, 
   newStyleForStore, 
   ...rest
}) => {
   return (
      <div className={ cx(
         `flex items-center ${ classNames } select-none`,
         textPosition === 'left' && 'flex-row-reverse'
      ) }
      >
         <label
            htmlFor={ `checkbox_${ name }` }
            className={
               cx({
                  [`flex items-center justify-center cursor-pointer select-none border rounded ${ classNames } h-5 w-5 min-h-[20px] min-w-[20px]`]: true,
                  'bg-hover dark:bg-hover-dark': isChecked && !disabled && !hasError,
                  'bg-panel dark:bg-black-field': !disabled && !isChecked,
                  'shadow-inner': hasError || disabled || !isChecked,
                  'border-secondary-12 dark:border-secondary-dark-12': (!isChecked && !hasError) || disabled,
                  'border-error dark:border-error-dark': !disabled && hasError,
                  'skin-primary-border bg-hover': (isChecked || isCircle) && !disabled && !hasError,
                  'bg-grey-hover dark:bg-grey-hover-dark !border-major-12 dark:!border-major-dark-12': disabled,
               })
            }
         >
            <input
               { ...rest }
               id={ `checkbox_${ name }` }
               type='checkbox'
               className='hidden'
               name={ name }
               checked={ isChecked }
               disabled={ disabled }
               onChange={ (e) => onChange(name, e.target.checked) }
            />
            {
               (isCircle || isChecked) && (
                  <div className='h-5 w-5 flex items-center justify-center'>
                     <Icon
                        style={ {
                           fontWeight: '800',
                        } }
                        className={
                           cx({
                              'skin-primary-text': !disabled && !hasError,
                              'text-disabled dark:text-disabled-dark': disabled,
                              'text-error dark:text-error-dark bg-panel dark:bg-panel-dark': hasError && !disabled,
                           })
                        }
                        color=''
                        darkColor=''
                        name={ isCircle ? 'minus' : 'done' }
                        size='base'
                     />
                  </div>
               )
            }
         </label>
         {
            label && (
               <div 
                  htmlFor={ `checkbox_${ name }` }
                  className={ `mx-2 leading-none  text-major dark:text-major-dark checkbox-label-text ${ newStyleForStore ? '!text-sm text-secondary' : '' }` } 
                  dangerouslySetInnerHTML={ { __html: label } } 
               />
               // <span className='mx-2 leading-none  text-major dark:text-major-dark checkbox-label-text'>{ label }</span>
            )
         }
      </div>
   );
};


CheckBox.defaultProps = {
   classNames: '',
   textPosition: 'right',
   unCheckedIconColor: 'secondary dark:text-secondary-dark',
   isChecked: false,
   disabled: false,
   hasError: false,
   newStyleForStore: false,
};

CheckBox.propTypes = {
   label: PropTypes.string,
   name: PropTypes.string,
   isChecked: PropTypes.bool.isRequired,
   isCircle: PropTypes.bool,
   classNames: PropTypes.string,
   textPosition: PropTypes.string,
   unCheckedIconColor: PropTypes.string,
   onChange: PropTypes.func.isRequired,
   disabled: PropTypes.bool,
   hasError: PropTypes.bool,
   checkboxClass: PropTypes.string,
   newStyleForStore: PropTypes.bool,
};

export default CheckBox;
