import { createSelector } from 'reselect'

const innerStateSelector = state => state.livestream


const recordedStreamsSelector = createSelector(
   innerStateSelector,
   (state) => (state.recordedStreams)
);
const isFetchingRecordFilterSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingRecordFilter)
);
const isFetchingRecordSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingRecord)
);
const isEmptyByFilterRecordSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyByFilterRecord)
);
const isEmptyRecordSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyRecord)
);
const isNewFetchingRecordsSelector = createSelector(
   innerStateSelector,
   (state) => (state.isNewFetchingRecords)
);
// const isFetchingScheduleSelector = createSelector(
//    innerStateSelector,
//    (state) => (state.isFetchingSchedule)
// );
// const isFetchingCreateScheduleSelector = createSelector(
//    innerStateSelector,
//    (state) => (state.isFetchingCreateSchedule)
// );
// const updateErrorsDataSelector = createSelector(
//    innerStateSelector,
//    (state) => (state.updateErrorsData)
// );
// const isFetchingStartStreamSelector = createSelector(
//    innerStateSelector,
//    (state) => (state.isFetchingStartStream)
// );

const isFetchingSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetching)
);

const initDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.initData)
);
const ivsSelector = createSelector(
   innerStateSelector,
   (state) => (state.ivs)
);

const streamConversationSelector = createSelector(
   innerStateSelector,
   (state) => (state.streamConversation)
);

const isFetchingMessagesSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchingMessages)
);
const messageDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.messageData)
);
const conversationSatetSelector = createSelector(
   innerStateSelector,
   (state) => (state.conversationSatet)
);
const isConnectedSelector = createSelector(
   innerStateSelector,
   (state) => (state.isConnected)
);
const onlineUsersSelector = createSelector(
   innerStateSelector,
   (state) => (state.onlineUsers)
);
const isFetchNewMessagesSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchNewMessages)
);
const isOpenActiveUpsellModalSelector = createSelector(
   innerStateSelector,
   (state) => (state.isOpenActiveUpsellModal)
);
const showLargeTipAnimationSelector = createSelector(
   innerStateSelector,
   (state) => (state.showLargeTipAnimation)
);
const offlineStateSelector = createSelector(
   innerStateSelector,
   (state) => (state.offlineState)
);
//////////////
const likedMemberByMessageSelector = createSelector(
   innerStateSelector,
   (state) => {
      let { likedMemberByMessage, onlineUsers } = state

      let data = likedMemberByMessage.data
      if(data) {
         data = [...data].map(i => {
            const { ...elm } = i
            elm.is_online = !!onlineUsers.find(user => user.uuid === elm.uuid)
            return elm
         })
      }
      return { ...likedMemberByMessage, data }
   }
);
const tipGoalAchievedModalSelector = createSelector(
   innerStateSelector,
   (state) => (state.tipGoalAchievedModal)
);
const tipGoalActionsAchievedModalSelector = createSelector(
   innerStateSelector,
   (state) => (state.tipGoalActionsAchievedModal)
);
const openEndedModalSelector = createSelector(
   innerStateSelector,
   (state) => (state.openEndedModal)
);
const disabledLikeButtonSelector = createSelector(
   innerStateSelector,
   (state) => (state.disabledLikeButton)
);
const watchingUsersSelector = createSelector(
   innerStateSelector,
   (state) => (state.watchingUsers)
);

export {
   recordedStreamsSelector,
   isFetchingRecordFilterSelector,
   isEmptyByFilterRecordSelector,
   isNewFetchingRecordsSelector,
   isFetchingRecordSelector,
   // isFetchingScheduleSelector,
   // isFetchingCreateScheduleSelector,
   // updateErrorsDataSelector,
   // isFetchingStartStreamSelector,

   isFetchingSelector,
   initDataSelector,
   ivsSelector,
   streamConversationSelector,

   messageDataSelector,
   isFetchingMessagesSelector,
   conversationSatetSelector,
   isConnectedSelector,
   onlineUsersSelector,
   likedMemberByMessageSelector,
   isFetchNewMessagesSelector,
   isOpenActiveUpsellModalSelector,
   showLargeTipAnimationSelector,
   offlineStateSelector,
   tipGoalAchievedModalSelector,
   tipGoalActionsAchievedModalSelector,
   isEmptyRecordSelector,
   openEndedModalSelector,
   disabledLikeButtonSelector,
   watchingUsersSelector,
}
