import React, { lazy } from 'react';
import { Switch } from 'react-router';
import Authenticated from './guards/Authenticated';
import Guest from './guards/Guest';
import Mixed from './guards/Mixed';
import SimpleMixed from './guards/SimpleMixed';
import Router from 'sites/routes/router';
import { Route, Redirect } from 'react-router-dom';
import suspenser from 'sites/hoc/Suspenser'
import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom';
import { Tooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';
/* Route containers start. */
import HomepageChecker from './containers/HomepageChecker'
import BypassChecker from './containers/BypassChecker'
import NotFoundContainer from 'sites/containers/pages/not-found'

const PhotosetsRouteContainer = lazy(() => import('./containers/photosets'));
const VideosRouteContainer = lazy(() => import('./containers/videos'));
const MyAccountRoutes = lazy(() => import('./containers/my-account'));
const StoreRoutes = lazy(() => import('./containers/store'));
const LiveStreamRoutes = lazy(() => import('./containers/live-stream'));
const LiveStreamRecordedStreamRoutes = lazy(() => import('./containers/recorded-streams'));
const DownloadsUnlocksContainer = lazy(() => import('./containers/downloads-unlocks'));
/* Route containers end. */

/* Page containers start. */
const SignInContainer = lazy(() => import("sites/containers/pages/auth/sign-in"))
const SignInNotRecognizedContainer = lazy(() => import("sites/containers/pages/auth/sign-in/not-recognized"))
const SignInEmailContainer = lazy(() => import("sites/containers/pages/auth/login"))
const ForgotPasswordContainer = lazy(() => import("sites/containers/pages/auth/forgot-password"));
const ResetPasswordContainer = lazy(() => import("sites/containers/pages/auth/reset-password"));
const SignUpContainer = lazy(() => import("sites/containers/pages/auth/sign-up"))
const SignUpEmailContainer = lazy(() => import("sites/containers/pages/auth/sign-up-email"))
const EmailVerificationContainer = lazy(() => import("sites/containers/pages/auth/email-verification"))
const HomepageContainer = lazy(() => import("sites/containers/pages/homepage"));
const PurchaseMembershipContaine = lazy(() => import("sites/containers/pages/purchase/membership"));
const PaymenApprovedContainer = lazy(() => import('sites/containers/pages/purchase/approved'));
const PaymentDeclinedContainer = lazy(() => import('sites/containers/pages/purchase/declined'));
const TemporaryHomepage = lazy(() => import('sites/containers/pages/temporary-homepage'));
const MemberSupportContainer = lazy(() => import('sites/containers/pages/member-support'));
const PurchaseVideoContaine = lazy(() => import('sites/containers/pages/purchase/video'));
const PurchasePhotosetContaine = lazy(() => import('sites/containers/pages/purchase/photoset'));
const PurchaseStoreContaine = lazy(() => import('sites/containers/pages/purchase/store'));
const PurchaseWalletContaine = lazy(() => import('sites/containers/pages/purchase/wallet'));
const PurchaseTipContainer = lazy(() => import('sites/containers/pages/purchase/tip'));
const PurchaseMediaUnlockContainer = lazy(() => import('sites/containers/pages/purchase/media-unlock'));
const PurchaseLiveStreamContainer = lazy(() => import('sites/containers/pages/purchase/live-stream'));
const PurchaseChatPayPerMessageContainer = lazy(() => import('sites/containers/pages/purchase/chat-pay-per-message'));
const JoinRoutes = lazy(() => import('./containers/join'));
const WalletContainer = lazy(() => import('sites/containers/pages/wallet'));
const TipContainer = lazy(() => import('sites/containers/pages/tip'));
const StoriesContainer = lazy(() => import('sites/containers/pages/stories/list'));
const StoriesShowContainer = lazy(() => import('sites/containers/pages/stories/show'));
const CommentsContainer = lazy(() => import('sites/containers/pages/stories/comments'));
const ContactFormContainer = lazy(() => import('sites/containers/pages/contact-form'));
const ContactUsContainer = lazy(() => import('sites/containers/pages/contact-us'));
const CustomPageContainer = lazy(() => import('sites/containers/pages/custom-page'));
const BlockContainer = lazy(() => import('sites/containers/pages/block'));
const UnsubscribeContainer = lazy(() => import('sites/containers/pages/unsubscribe'));
const ChatContentContainer = lazy(() => import('sites/containers/pages/chat-content'));
const PromotionCountainer = lazy(() => import('sites/containers/pages/promotion'));
const AffiliatesDetailsContainer = lazy(() => import('sites/containers/pages/affiliates-details'));
const AutoTweetNotificationCountainer = lazy(() => import('sites/containers/pages/auto-tweet-notification'));
const AgeVerificationContainer = lazy(() => import('sites/containers/pages/age-verification/required'))
const AgeVerificationProcessContainer = lazy(() => import('sites/containers/pages/age-verification/process'))
const MyFavoritesContainer = lazy(() => import('sites/containers/pages/my-favorites'))
const DigitalDownloadableItemDetails = lazy(() => import('sites/containers/pages/my-media/digital-downloadable-item'))
const SignInWithContainer = lazy(() => import('sites/containers/pages/auth/sign-in-with'))
/* Page containers end. */


export default () => {
   return (
      <>
         <Switch>
            <Guest exact path={ Router.route('SIGN_IN').getMask() } component={ suspenser(SignInContainer) } />
            <Guest exact path={ Router.route('SIGN_IN_NOT_RECOGNIZED').getMask() } component={ suspenser(SignInNotRecognizedContainer) } />
            <Guest exact path={ Router.route('SIGN_IN_EMAIL').getMask() } component={ suspenser(SignInEmailContainer) } />
            <Guest exact path={ Router.route('SIGN_UP').getMask() } component={ suspenser(SignUpContainer) } />
            <Guest exact path={ Router.route('SIGN_UP_EMAIL').getMask() } component={ suspenser(SignUpEmailContainer) } />
            <Guest exact path={ Router.route('PASSWORD_RESET').getMask() } component={ suspenser(ForgotPasswordContainer) } />
            <Guest exact path={ Router.route('PASSWORD_RESET_TOKEN').getMask() } component={ suspenser(ResetPasswordContainer) } />
            <Guest exact path={ Router.route('EMAIL_VERIFICATION').getMask() } component={ suspenser(EmailVerificationContainer) } />

            <Mixed exact path={ Router.route('HOMEPAGE').getMask() } component={ suspenser(HomepageContainer) } />
            <Mixed path={ Router.route('PHOTOSETS').getMask() } component={ suspenser(PhotosetsRouteContainer) } />
            <Mixed path={ Router.route('VIDEOS').getMask() } component={ suspenser(VideosRouteContainer) } />
            <Mixed path={ Router.route('MEMBER_SUPPORT').getMask() } component={ suspenser(MemberSupportContainer) } />
            <Mixed path={ Router.route('CONTACT_US').getMask() } component={ suspenser(ContactUsContainer) } />

            <Mixed exact path={ Router.route('STORIES').getMask() } component={ suspenser(StoriesContainer) } />
            <Mixed exact path={ Router.route('STORIES_COMMENTS').getMask() } component={ suspenser(CommentsContainer) } />
            <Mixed exact path={ Router.route('STORIES_SHOW').getMask() } component={ suspenser(StoriesShowContainer) } />
            <Mixed path={ Router.route('STORE').getMask() } component={ suspenser(StoreRoutes) } />
            <Mixed exact path={ Router.route('CUSTOM_PAGE').getMask() } component={ suspenser(CustomPageContainer) } />
            <Mixed path={ Router.route('LIVE_STREAM_STUDIO').getMask() } component={ suspenser(LiveStreamRoutes) } />
            <Mixed path={ Router.route('LIVE_STREAM_RECORDS_STREAM').getMask() } component={ suspenser(LiveStreamRecordedStreamRoutes) } />

            <Authenticated path={ Router.route('MY_ACCOUNT').getMask() } component={ suspenser(MyAccountRoutes) } />
            <Mixed path={ Router.route('JOIN').getMask() } component={ suspenser(JoinRoutes) } />
            <Authenticated path={ Router.route('CONTACT_FORM').getMask() } component={ suspenser(ContactFormContainer) } />
            <Authenticated path={ Router.route('MY_UNLOCKS').getMask() } component={ suspenser(DownloadsUnlocksContainer) } />
            <Authenticated path={ Router.route('PURCHASE_MEMBERSHIP').getMask() } component={ suspenser(PurchaseMembershipContaine) } />
            <Authenticated path={ Router.route('PAYMENT_APPROVED').getMask() } component={ suspenser(PaymenApprovedContainer) } />
            <Authenticated path={ Router.route('PAYMENT_DECLINED').getMask() } component={ suspenser(PaymentDeclinedContainer) } />
            <Authenticated path={ Router.route('PURCHASE_VIDEO').getMask() } component={ suspenser(PurchaseVideoContaine) } />
            <Authenticated path={ Router.route('PURCHASE_LIVE_STREAM_RECORDS').getMask() } component={ suspenser(PurchaseVideoContaine) } />
            <Authenticated path={ Router.route('PURCHASE_PHOTOSET').getMask() } component={ suspenser(PurchasePhotosetContaine) } />
            <Authenticated path={ Router.route('PURCHASE_STORE').getMask() } component={ suspenser(PurchaseStoreContaine) } />
            <Authenticated path={ Router.route('PURCHASE_WALLET').getMask() } component={ suspenser(PurchaseWalletContaine) } />
            <Authenticated exact path={ Router.route('WALLET').getMask() } component={ suspenser(WalletContainer) } />
            <Authenticated path={ Router.route('PURCHASE_TIP').getMask() } component={ suspenser(PurchaseTipContainer) } />
            <Authenticated path={ Router.route('PURCHASE_PHOTO_UNLOCK').getMask() } component={ suspenser(PurchaseMediaUnlockContainer) } />
            <Authenticated path={ Router.route('PURCHASE_VOICE_UNLOCK').getMask() } component={ suspenser(PurchaseMediaUnlockContainer) } />
            <Authenticated path={ Router.route('PURCHASE_VIDEO_UNLOCK').getMask() } component={ suspenser(PurchaseMediaUnlockContainer) } />
            <Authenticated path={ Router.route('PURCHASE_LIVE_STREAM').getMask() } component={ suspenser(PurchaseLiveStreamContainer) } />
            <Authenticated path={ Router.route('PURCHASE_CHAT_PAY_PER_MESSAGE').getMask() } component={ suspenser(PurchaseChatPayPerMessageContainer) } />
            <Authenticated path={ Router.route('PURCHASE_MULTIPLE_ATTACHMENT').getMask() } component={ suspenser(PurchaseMediaUnlockContainer) } />
            <Authenticated exact path={ Router.route('TIP').getMask() } component={ suspenser(TipContainer) } />
            <Authenticated exact path={ Router.route('CHAT_UNLCOKED_CONTENT').getMask() } component={ suspenser(ChatContentContainer) } />
            <Authenticated exact path={ Router.route('BUNDLE_VAULT').getMask() } component={ suspenser(ChatContentContainer) } />
            <Route exact path={ '/' } component={ HomepageChecker } />
            <Route exact path={ Router.route('BYPASS').getMask() } component={ BypassChecker } />
            <Redirect from={ Router.route('FEED').getMask() } to={ Router.route('HOMEPAGE').getMask() } />
            <Redirect from={ Router.route('VIDEOS_GALLERIES').getMask() } to={ Router.route('VIDEOS').getMask() } />
            <Redirect from='/tour' to={ Router.route('HOMEPAGE').getMask() } />
            <Redirect exact={ true } from={ Router.route('JOIN_OLD').getMask() } to={ Router.route('JOIN').getMask() } />
            <SimpleMixed exact path={ Router.route('COMING_SOON').getMask() } component={ suspenser(TemporaryHomepage) } needToInit />
            <SimpleMixed exact path={ Router.route('ERROR_PAGE').getMask() } component={ suspenser(BlockContainer) } needToInit={ false } />
            <SimpleMixed exact path={ Router.route('MAILING_LIST_UNSUBSCRIBE').getMask() } component={ suspenser(UnsubscribeContainer) } needToInit />
            <SimpleMixed exact path={ Router.route('MEMBER_UNSUBSCRIBE').getMask() } component={ suspenser(UnsubscribeContainer) } needToInit />
            <SimpleMixed exact path={ Router.route('NOT_FOUND_PAGE').getMask() } component={ NotFoundContainer } needToInit />
            <Mixed path={ Router.route('PROMOTION').getMask() } component={ suspenser(PromotionCountainer) } />
            <Mixed path={ Router.route('AFFILIATES_DETAILS').getMask() } component={ suspenser(AffiliatesDetailsContainer) } />
            <Mixed path={ Router.route('AUTO_TWEET_NOTIFICATION').getMask() } component={ suspenser(AutoTweetNotificationCountainer) } />
            <Mixed path={ Router.route('TIP_AUTO_TWEET_NOTIFICATION').getMask() } component={ suspenser(AutoTweetNotificationCountainer) } />
            <Mixed path={ Router.route('NEW_JOIN_AUTO_TWEET_NOTIFICATION').getMask() } component={ suspenser(AutoTweetNotificationCountainer) } />
            <Mixed exact path={ Router.route('AGE_VERIFICATION').getMask() } component={ suspenser(AgeVerificationContainer) } />
            <Mixed exact path={ Router.route('AGE_VERIFICATION_PROCESS').getMask() } component={ suspenser(AgeVerificationProcessContainer) } />
            <Authenticated exact path={ Router.route('MY_FAVORITES').getMask() } component={ suspenser(MyFavoritesContainer) } />
            <Authenticated exact path={ Router.route('DIGITAL_DOWNLOADABLE_ITEM_DETAILS').getMask() } component={ suspenser(DigitalDownloadableItemDetails) } />
            <Authenticated exact path={ Router.route('SIGN_IN_WITH').getMask() } component={ suspenser(SignInWithContainer) } />
            <SimpleMixed path={ '*' } component={ NotFoundContainer } needToInit />
            {/* <Redirect from='*' to={ Router.route('NOT_FOUND_PAGE').getMask() } /> */}
         </Switch>
         {
            ReactDOM.createPortal(
               <ToastContainer
                  className='ams-toast-container'
                  autoClose={ 3000 }
                  hideProgressBar
               />,
               document.getElementById('toasts')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-bottom-tooltip'
                  place='bottom'
                  variant={ window.site_dark_bg ? 'light' : 'dark' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-right-tooltip'
                  place='right'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'light' : 'dark' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip'
                  place='top'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'light' : 'dark' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-left-tooltip'
                  place='left'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'light' : 'dark' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-clickable'
                  place='top'
                  delayShow={ 300 }
                  clickable
                  variant={ window.site_dark_bg ? 'dark' : 'light' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-start'
                  place='top-start'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'dark' : 'light' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-end'
                  place='top-end'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'dark' : 'light' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-bottom-tooltip-start'
                  place='bottom-start'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'dark' : 'light' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-bottom-tooltip-end'
                  place='bottom-end'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'dark' : 'light' }
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-clickable-start'
                  place='top-start'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'dark' : 'light' }
                  clickable
               />,
               document.getElementById('tooltips')
            )
         }
         {
            createPortal(
               <Tooltip
                  className='ams-black-tooltips'
                  id='ams-top-tooltip-clickable-end'
                  place='top-end'
                  delayShow={ 300 }
                  variant={ window.site_dark_bg ? 'dark' : 'light' }
                  clickable
               />,
               document.getElementById('tooltips')
            )
         }
      </>
   )
}
