import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { screenWidthSelector } from 'admin/state/modules/common/selectors';
import PropTypes from 'prop-types';
import Content from './content';
import './style.css';

class Modal extends React.Component {
   static defaultProps = {
      closeOnClickOutside: true,
      blurColor: '',
      className: 'h-full',
      marginX: '8',
      isCloseAction: true,
   };

   static propTypes = {
      children: PropTypes.node,
      contentPosition: PropTypes.oneOf([
         'bottom',
         'center',
         'tooltip',
         'left',
         'image',
         'full-screen',
         'responsive',
      ]),
      onClose: PropTypes.func.isRequired,
      closeOnClickOutside: PropTypes.bool,
      blurColor: PropTypes.string,
      contentBgColor: PropTypes.string,
      roundedModal: PropTypes.string,
      className: PropTypes.string,
      fullScreenMode: PropTypes.bool,
      marginX: PropTypes.string,
      animate: PropTypes.oneOf(['up', 'down']),
      contentWidth: PropTypes.any,
      isCloseAction: PropTypes.bool,
      screenWidth: PropTypes.number,
      otherClassName: PropTypes.string,
   };

   constructor(props) {
      super(props);
      const body = document.querySelector('body');
      this.modalRoot = document.getElementById('modals-root');
      body.classList.add('body_non_scroll');
      if(!this.modalRoot) {
         this.modalRoot = document.createElement('div');
         this.modalRoot.id = 'modals-root';
         body.appendChild(this.modalRoot);
      }
      if(!window.activeModals) {
         window.activeModals = {};
      }
      this.id = this.generateUniqueId();
      this.el = document.createElement('div');
      this.el.className = 'relative w-full h-full';
      this.el.style.zIndex = this.getZindex();
      this.el.addEventListener('click', this.onClick, false);
   }

   componentDidMount = () => {
      this.addIdIntoSuperGlobals();
      const { fullScreenMode } = this.props
      const root = document.querySelector('#root')
      root.style.zIndex = 1;
      this.modalRoot.appendChild(this.el);
      if(fullScreenMode) {
         this.openFullscreen();
      }
      // PullToRefresh.destroyAll()
   }

   componentWillUnmount() {
      this.modalRoot.removeChild(this.el)
      const { fullScreenMode } = this.props;
      if(this.getModalsCount() === 1) {
         const body = document.querySelector('body');
         const root = document.querySelector('#root')
         root.removeAttribute('style');
         body.classList.remove('body_non_scroll');
         try {
            body.removeChild(this.modalRoot);
         } catch (e) {}
      }
      if(fullScreenMode) {
         this.closeFullScreen();
      }
      this.removeIdFromSuperGlobals();
   }

   getModalsCount = () => {
      return Object.keys(window.activeModals).length
   }

   removeIdFromSuperGlobals = () => {
      delete window.activeModals[this.id];
   }

   addIdIntoSuperGlobals = () => {
      window.activeModals[this.id] = true;
   }

   getZindex = () => {
      return Object.keys(window.activeModals).length + 1;
   }

   generateUniqueId = () => {
      return `_${ Math.random().toString(36).substr(2, 9) }`;
   };

   openFullscreen = async () => {
      try {
         const elem = document.documentElement;
         if(elem.requestFullscreen) {
            await elem.requestFullscreen();
         } else if(elem.mozRequestFullScreen) { /* Firefox */
            await elem.mozRequestFullScreen();
         } else if(elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            await elem.webkitRequestFullscreen();
         } else if(elem.msRequestFullscreen) { /* IE/Edge */
            await elem.msRequestFullscreen();
         }
      } catch (e) {
         //
         console.log(e)
      }
   };

   closeFullScreen = async () => {
      try {
         if(document.exitFullscreen) {
            await document.exitFullscreen();
         } else if(document.mozCancelFullScreen) { /* Firefox */
            await document.mozCancelFullScreen();
         } else if(document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            await document.webkitExitFullscreen();
         } else if(document.msExitFullscreen) { /* IE/Edge */
            await document.msExitFullscreen();
         }
      } catch (e) {
         //
         console.log(e)
      }
   };

   onClick = e => {
      const { onClose, closeOnClickOutside, screenWidth, isCloseAction } = this.props;
      if(closeOnClickOutside && e.target.closest('.modal-content') === null && typeof onClose === 'function') {
         if(isCloseAction){
            onClose(e);
         }
         if(!isCloseAction && screenWidth < 701) {
            onClose(e);
         }
      }
      // Close modal on click outside
   };

   render() {
      const { children, contentPosition, blurColor, contentBgColor, roundedModal, className, marginX, animate, contentWidth, otherClassName } = this.props;
      this.el.className = `${ blurColor === '18-plus-age-modal' ? `bg-${ blurColor }` : 'bg-modal-cover dark:bg-modal-cover-dark' } absolute w-full  ${ className } backdrop-blur-[8px] `;
      return ReactDOM.createPortal(
         <Content
            bgColor={ contentBgColor }
            position={ contentPosition }
            roundedModal={ roundedModal }
            marginX={ marginX }
            animate={ animate }
            contentWidth={ contentWidth }
            otherClassName={ otherClassName }
         >
            { children }
         </Content>,
         this.el
      );
   }
}

const mapStateToProps = (state) => {
   return {
      screenWidth: screenWidthSelector(state),
   }
}

export default connect(mapStateToProps)(Modal);
