export const PASSWORD_RESET_LINK_REQUEST = 'password-reset/PASSWORD_RESET_LINK_REQUEST';
export const PASSWORD_RESET_LINK_COMPLETED = 'password-reset/PASSWORD_RESET_LINK_COMPLETED';
export const PASSWORD_RESET_LINK_FAILED = 'password-reset/PASSWORD_RESET_LINK_FAILED';

export const RESET_PASSWORD_REQUEST = 'password-reset/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_COMPLETED = 'password-reset/RESET_PASSWORD_COMPLETED';
export const RESET_PASSWORD_FAILED = 'password-reset/RESET_PASSWORD_FAILED';



