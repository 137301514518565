import * as types from "./types";

const fetchDetailsStart = () => ({
   type: types.FETCH_DETAILS_START,
});
const fetchDetailsCompleted = (data) => {
   return {
      type: types.FETCH_DETAILS_COMPLETED,
      payload: data,
   }
};
const clearState = (payload) => ({
   type: types.CLEAR_STATE,
   payload,
})
const fetchDetailsFailed = (err) => ({
   type: types.FETCH_DETAILS_FAILED,
   payload: err,
});
const fetchNextDetailsStart = () => ({
   type: types.FETCH_NEXT_DETAILS_START,
});
const fetchNextDetailsCompleted = (data) => {
   return {
      type: types.FETCH_NEXT_DETAILS_COMPLETED,
      payload: data,
   }
};
const fetchNextDetailsFailed = (err) => ({
   type: types.FETCH_NEXT_DETAILS_FAILED,
   payload: err,
});

const fetchRequestByFilter = () => ({
   type: types.FETCH_BY_FILTER_DETAILS_START,
});
const fetchRequestByFilterCompleted = (data) => {
   return {
      type: types.FETCH_BY_FILTER_DETAILS_COMPLETED,
      payload: data,
   }
};
const fetchRequestByFilterFailed = (err) => ({
   type: types.FETCH_BY_FILTER_DETAILS_FAILED,
   payload: err,
});

const fetchStoreByIdStart = () => ({
   type: types.FETCH_STORE_BY_ID_START,
});
const fetchStoreByIdCompleted = (data) => {
   return {
      type: types.FETCH_STORE_BY_ID_COMPLETED,
      payload: data,
   }
};
const fetchStoreByIdFailed = (err) => ({
   type: types.FETCH_STORE_BY_ID_FAILED,
   payload: err,
});


const fetchCategoriesCompleted = (data) => ({
   type: types.FETCH_CATEGORIES_COMPLETED,
   payload: data,
});
const updateStoreById = (data) => ({
   type: types.UPDATE_STORE_BY_ID,
   payload: data,
});

const openSuccessModal = (data) => ({
   type: types.CHANGE_SUCCESS_MODAL_VALUES,
   payload: data,
})

const fetchDataByFilters = () => ({
   type: types.FETCH_DATA_BY_FILTERS,
})

const subscribeOrUnsubscribeFunction = () => ({
   type: types.SUBSCRIBE_OR_UNSUBSCRIBE_FUNCTION,
})


//orders

const fetchOrdersStart = () => ({
   type: types.FETCH_ORDERS_DATA_START,
})

const fetchOrders = (payload) => ({
   type: types.FETCH_ORDERS_DATA_COMPLETED,
   payload,
})

const fetchNewOrdersStart = () => ({
   type: types.FETCH_NEW_ORDERS_START,
})

const fetchNewOrdersCompleted = (payload) => ({
   type: types.FETCH_NEW_ORDERS_COMPLETED,
   payload,
})

const fetchNewOrdersFailed = () => ({
   type: types.FETCH_NEW_ORDERS_FAILED,
})

const fetchOrdersByFilterStart = () => ({
   type: types.FETCH_ORDERS_BY_FILTER_START,
})

const fetchOrdersByFilterCompleted = (payload) => ({
   type: types.FETCH_ORDERS_BY_FILTER_COMPLETED,
   payload,
})
const  makeBundleItemsWatched = (items) => ({
   type: types.MAKE_BUNDLE_ITEMS_WATCHED,
   payload: items,
})

const fetchActiveOrderRequestStart = () => ({
   type: types.FETCH_ORDER_REQUEST_BY_ID_START,
})

const fetchActiveOrderRequestCompleted = (data) => ({
   type: types.FETCH_ORDER_REQUEST_BY_ID_COMPLETED,
   payload: data,
})

const updateActiveOrderRequest = (payload) => ({
   type: types.UPDATE_ACTIVE_ORDER_REQUEST,
   payload,
})

const updateOrderRequestsData = (payload) => ({
   type: types.UPDATE_ORDER_REQUESTS_DATA,
   payload,
})

const updateStoreListAction = (data, actionType) => ({
   type: types.UPDATE_STORE_LIST,
   payload: { data, actionType },
})

const onChangeFavoriteItemCompleted = (id, isDetailsPage) => ({
   type: types.ON_CHANGE_FAVORITE_ITEM_COMPLETED,
   payload: { id, isDetailsPage },
});

export {
   fetchDetailsStart,
   fetchDetailsFailed,
   fetchDetailsCompleted,
   clearState,

   fetchRequestByFilter,
   fetchRequestByFilterCompleted,
   fetchRequestByFilterFailed,

   fetchNextDetailsStart,
   fetchNextDetailsCompleted,
   fetchNextDetailsFailed,

   fetchStoreByIdStart,
   fetchStoreByIdCompleted,
   fetchStoreByIdFailed,

   fetchCategoriesCompleted,
   updateStoreById,

   openSuccessModal,
   fetchDataByFilters,
   subscribeOrUnsubscribeFunction,


   //orders
   fetchOrdersStart,
   fetchOrders,
   fetchNewOrdersStart,
   fetchNewOrdersCompleted,
   fetchNewOrdersFailed,
   fetchOrdersByFilterStart,
   fetchOrdersByFilterCompleted,
   makeBundleItemsWatched,
   fetchActiveOrderRequestStart,
   fetchActiveOrderRequestCompleted,
   updateActiveOrderRequest,
   updateOrderRequestsData,
   updateStoreListAction,

   onChangeFavoriteItemCompleted,
};
