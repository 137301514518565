const initialState = {
   isFetchingComment: true,
   isNewFetching: false,
   comments: {},
   commentsCount: 0,
   hideReply: false,
   commentsError: false,
};

export default initialState;
