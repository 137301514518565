import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { storiesData } } = action;
      return {
         ...state,
         isFetching: false,
         storiesData,
         isEmpty: storiesData.data.length === 0,

      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // stories count
   [types.FETCH_STORIES_COUNT_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingStoriesCount: true,
      }
   },
   [types.FETCH_STORIES_COUNT_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchingStoriesCount: false,
         storiesCount: { ...action.payload },
      }
   },
   [types.FETCH_STORIES_COUNT_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingStoriesCount: false,
      }
   },

   // NEXT page data
   [types.FETCH_NEXT_REQUEST]: (state, action) => {

      return {
         ...state,
         isNextFetching: true,
      }
   },
   [types.FETCH_NEXT_COMPLETED]: (state, action) => {
      const  { payload } = action;
      const res = payload.storiesData;
      return {
         ...state,
         isNextFetching: false,
         storiesData: {
            ...res,
            data: [...state.storiesData.data, ...res.data],
         },

      }
   },

   // by filter
   [types.FETCH_BY_FILTER_REQUEST]: (state, action) => {
      return {
         ...state,
      }
   },
   [types.FETCH_BY_FILTER_COMPLETED]: (state, action) => {
      const  { payload: { storiesData } } = action;
      return {
         ...state,
         storiesData,
         isEmptyByFilter: storiesData.data.length === 0,

      }
   },
   // [types.FETCH_DETAILS_FAILED]: (state, action) => {
   //    return {
   //       ...state,
   //       isFetching: false,
   //    }
   // },

   //like story
   [types.STORY_LIKE_REQUEST]: (state, action) => {
      return {
         ...state,
      }
   },
   [types.STORY_LIKE_COMPLETED]: (state, action) => {

      const  { payload } = action;
      const stories = state.storiesData.data || [];

      const newStories = stories.map(story => {
         if(story.id === payload) {
            return {
               ...story,
               is_liked: !story.is_liked,
            }
         }
         return {
            ...story,
         }
      });

      return {
         ...state,
         storiesData: {
            ...state.storiesData,
            data: newStories,
         },
         activeStory: {
            ...state.activeStory,
            is_liked: state.activeStory.id === payload ? !state.activeStory.is_liked : state.activeStory.is_liked,
         },

      }
   },


   // get by id
   [types.FETCH_STORY_BY_ID_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingById: true,
      }
   },
   [types.FETCH_STORY_BY_ID_COMPLETED]: (state, action) => {
      const  { payload: { data } } = action;
      return {
         ...state,
         isFetchingById: false,
         activeStory: data,

      }
   },
   [types.FETCH_STORY_BY_ID_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingById: false,
      }
   },

   // add views count
   [types.STORY_ADD_VIEW_START]: (state) => ({
      ...state,
   }),
   [types.STORY_ADD_VIEW_COMPLETED]: (state) => ({
      ...state,
   }),
   [types.STORY_ADD_VIEW_FAILED]: (state) => ({
      ...state,
   }),
   [types.FETCH_SITE_INFO]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         siteInfo: {
            ...state.siteInfo,
            ...payload,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
