import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_REQUEST]: (state) => {
      return {
         ...state,
         isFetching: true,
         isEmptyByFilter: false,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetching: false,
         unlocksData: payload,
         isEmpty: payload.length === 0,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   // filter data
   [types.FETCH_BY_FILTER_REQUEST]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
      }
   },
   [types.FETCH_BY_FILTER_DETAILS_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isFetchingByFilter: false,
         unlocksData: payload,
         isEmptyByFilter: payload.length === 0,

      }
   },
   [types.FETCH_BY_FILTER_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingByFilter: false,
      }
   },

   // go to next page

   [types.FETCH_NEXT_DATA_REQUEST]: (state) => {
      return {
         ...state,
         isNextFetching: true,
      }
   },
   [types.FETCH_NEXT_DATA_COMPLETED]: (state, action) => {
      const  { payload: { unlocksData } } = action;
      return {
         ...state,
         isNextFetching: false,
         unlocksData: {
            ...unlocksData,
            data: [...state.unlocksData.data, ...unlocksData.data],
         },

      }
   },
   [types.FETCH_NEXT_DATA_FAILED]: (state, action) => {
      return {
         ...state,
         isNextFetching: false,
      }
   },
   [types.TOGGLE_LIKE]: (state, action) => {
      const  {
         payload:
          { id, type },
      } = action;
      const data = [...state.unlocksData].map(element => {
         const { ...item } = element
         if(item[type] && item[type].id === id) {
            item[type].is_liked = !item[type].is_liked
         }
         return item
      })
      return {
         ...state,
         unlocksData: data,
      }
   },
};

export default createReducer(initialState)(reducersMap);
