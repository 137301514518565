import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   [types.INIT_MY_ACCOUNT_START]: (state) => {
      return {
         ...state,
         errors: {},
         isFetching: true,
         emailVerified: false,
      };
   },
   [types.INIT_MY_ACCOUNT_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetching: false,
         accountInfo: payload,
      };
   },
   [types.INIT_MY_ACCOUNT_FAILED]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         isFetching: false,
         errors: data,
      };
   },


   [types.CHANGE_PASSWORD_START]: (state) => {
      return {
         ...state,
         passwordChangeError: {},
         isFetchingChangePassword: true,
      };
   },
   [types.CHANGE_PASSWORD_COMPLETED]: (state) => {
      return {
         ...state,
         isFetchingChangePassword: false,
      };
   },

   [types.CHANGE_PASSWORD_FAILED]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         isFetchingChangePassword: false,
         passwordChangeError: data,
      };
   },


   [types.CHANGE_EMAIL_START]: (state) => {
      return {
         ...state,
         errors: {},
         isSendFetching: true,
         emailVerified: false,
      };
   },
   [types.CHANGE_EMAIL_COMPLETED]: (state, action) => {
      return {
         ...state,
         isSendFetching: false,
         emailVerified: true,
         newEmail: action.email,
      };
   },

   [types.CHANGE_EMAIL_FAILED]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         isSendFetching: false,
         errors: data,
         emailVerified: false,
      };
   },

   [types.SEND_VERIFIED_CODE_START]: (state) => {
      return {
         ...state,
         errors: {},
         isSendFetching: true,
      };
   },
   [types.SEND_VERIFIED_CODE_COMPLETED]: (state) => {
      return {
         ...state,
         isSendFetching: false,
         emailVerified: false,
      };
   },

   [types.SEND_VERIFIED_CODE_FAILED]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         isSendFetching: false,
         errors: data,
         emailVerified: false,
      };
   },

   [types.TOGGLE_NOTIFICATION_START]: (state) => {
      return {
         ...state,
         errors: {},
      };
   },
   [types.TOGGLE_NOTIFICATION_COMPLETED]: (state, action) => {
      return {
         ...state,
         accountInfo: {
            ...state.accountInfo,
            ...action.payload,
         },
      };
   },

   [types.TOGGLE_NOTIFICATION_FAILED]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         errors: data,
      };
   },

   [types.UPDATE_PROFILE_FAILED]: (state, action) => {
      const { payload: { data } } = action;
      return {
         ...state,
         errors: data,
      };
   },


   [types.CANCEL_SUBSCRIPTION_START]: (state, action) => {
      return {
         ...state,
         isCancelFetching: true,
         errors: {},
      };
   },
   [types.CANCEL_SUBSCRIPTION_COMPLETED]: (state, action) => {
      return {
         ...state,
         isCancelFetching: false,
      };
   },
   [types.CANCEL_SUBSCRIPTION_FAILED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         errors: payload.data,
         isCancelFetching: false,
      };
   },

   [types.OPEN_EDIT_USERNAME_MODAL]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         openEditUsername: {
            ...state.openEditUsername,
            ...payload,
         },
      };
   },
   [types.NOTIFICATION_PREFERENCES_START]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         isFetchingNotificationPreferences: payload,
      };
   },
   [types.NOTIFICATION_PREFERENCES_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingNotificationPreferences: false,
         notificationPreferences: {
            ...state.notificationPreferences,
            ...payload,
         },
      };
   },
   [types.UPDATE_NOTIFICATION_PREFERENCES_START]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         updatingNotificationPreferences: payload,
      };
   },
   [types.UPDATE_NOTIFICATION_PREFERENCES_COMPLETED]: (state, action) => {
      const { payload: { key, data } } = action;
      const oldState = state.notificationPreferences?.[key] || []
      return {
         ...state,
         notificationPreferences: {
            ...state.notificationPreferences,
            [key]: {
               ...oldState,
               ...data,
            },
         },
         updatingNotificationPreferences: { key: null },
      };
   },
   [types.UNSUBSCRIBE_ALL_NOTIFICATION_PREFERENCES_START]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         unsubscribeAllNotificationsLoading: payload,
      };
   },
   [types.UNSUBSCRIBE_ALL_NOTIFICATION_PREFERENCES_COMPLETED]: (state, action) => {
      const newState = { ...state.notificationPreferences } || {};
      for(const key in newState) {
         if(Object.hasOwnProperty.call(newState, key)) {
            if('email' in newState[key]){
               newState[key].email = false;
            }
            if('in_app' in newState[key]){
               newState[key].in_app = false;
            }

         }
      }
      return {
         ...state,
         notificationPreferences: newState,
         unsubscribeAllNotificationsLoading: false,
      };
   },

};

export default createReducer(initialState)(reducersMap);
