const initialState = {
   showNavigationList: false,

   isFetchingInit: true,
   isFetchingMessage: true,
   isNextFetchingMessage: false,
   likedMemberByMessage: {
      data: [],
      fetching: false,
      fetchingByScroll: false,
   },
   onlineUsers: [],
   isSocketConnected: false,
   privateChat: {
      typers: {},
   },
   groupChat: {
      typers: {},
   },
   privateMessages: {
      data: [],
      inited: false,
   },
   publicMessages: {
      data: [],
      inited: false,
   },
   settings: {},
   activeMessageTab: {},

   mutedInfo: {
      isMuted: false,
   },
   chatPanel: {
      isOpen: false,
      animationClass: null,
      closeChatMessages: true,
   },
   inited: false,
   hiddeChatIcon: false,
};

export default initialState;
