import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { collapseMenu, setMenuDefaultAnimationClassAction } from 'sites/state/modules/common/actions';
import {
   isMenuOpenedSelector,
   isMenuAnimationSelector,
   siteInfoSelector,
   screenWidthSelector,
   customPagesSelector,
} from 'sites/state/modules/common/selectors';
import { currentLocationPathSelector } from 'sites/state/modules/router/selectors';
import { authUserSelector, isDarkBgSelector } from 'sites/state/modules/common/selectors';
import { onUpdateLanguageOperation } from 'sites/state/modules/common/operations';
import Navigation from 'common/components/modules/navigation';
import Router from 'sites/routes/router';
import { push } from 'connected-react-router';
import InformationAndPolicies from 'common/components/modules/information-and-policies/sites/mobile';
import intercomMoreInfo, { MEMBER_SUPPORT } from 'sites/utils/IntercomMoreInfo';
import logout from 'sites/utils/logout';
import SelectLanguages from 'common/components/modules/select-languages';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
import SyncPageScoll from 'common/utils/pageScrollSync';
import { getUserStatusType } from 'sites/utils/utils.js';
import Button from 'common/components/elements/buttons/primary';
import { Link } from 'react-router-dom';
import QueryParams from 'sites/utils/QueryParams';
import { getInformationAndPoliciesQueryType } from 'common/components/modules/information-and-policies/sites/data.js';
import { getProtocol, getSiteDomain } from 'common/utils/utils';
import { updateContentPageTitleForInformationAndPolicies } from 'sites/utils/utils';

class NavigationContainer extends Component {

   static propTypes = {
      collapseMenu: PropTypes.func.isRequired,
      goTo: PropTypes.func.isRequired,
      locationPath: PropTypes.string.isRequired,
      isMenuAnimation: PropTypes.string,
      user: PropTypes.object,
      logout: PropTypes.func.isRequired,
      siteInfo: PropTypes.object,
      screenWidth: PropTypes.number,
      customPages: PropTypes.array,
      isDarkBg: PropTypes.bool,
      onUpdateLanguage: PropTypes.func,
      setMenuDefaultAnimationClass: PropTypes.func,
   };

   state = {
      openModal: false,
      acivelanguages: '',
      informationAndPoliciesModalType: null,
      intercomUnreadCount: 0,
   }

   componentDidMount() {
      const { setMenuDefaultAnimationClass } = this.props
      setMenuDefaultAnimationClass()

      if(window?.Intercom){
         window.Intercom('onUnreadCountChange', (unreadCount) => {
            const intercomUnreadCount = unreadCount > 99 ? '99+' : unreadCount;
            this.setState({ intercomUnreadCount })
         });
      }
   }

   collapseMenu = () => {
      const { collapseMenu } = this.props;
      collapseMenu();
   };

   toggleOpenModal = () => {
      this.setState(state => ({
         openModal: !state.openModal,
      }))
   }

   handleRouteChange = (location, params) =>  {
      const { goTo } = this.props;
      goTo(Router.route(location).getCompiledPath(params));
      this.collapseMenu();
   }

   logOut = () => {
      const { logout,
         locationPath,
      } = this.props;
      // TODO prompt want to logout
      SyncPageScoll.syncScroll(false)
      logout(locationPath, window.location.search);
   };
   openInformationAndPoliciesSecondModal = (type) => {
      updateContentPageTitleForInformationAndPolicies(type)
      const generateQuery = getInformationAndPoliciesQueryType(type);
      if(generateQuery){
         this.setState({ informationAndPoliciesModalType: type });
         QueryParams.setQueryParam('information-and-policies', encodeURIComponent(generateQuery));
      } else {
         this.setState({ informationAndPoliciesModalType: '' });
         if(QueryParams.getParam('information-and-policies')){
            QueryParams.removeQueryParam('information-and-policies');
         }
      }
   }

   render() {
      const { locationPath, user, isMenuAnimation, screenWidth, customPages, isDarkBg, onUpdateLanguage, siteInfo } = this.props;
      const { media, admin_enabled_affiliate } = this.props.siteInfo
      const { intercomUnreadCount } = this.state
      let sum = 0;
      if(!!media) {
         sum = media.videos_count + media.photosets_count + media.store_count + media.stories_count
      }
      const wrapperClasses = classnames(`fixed top-0 left-0 z-50 w-full h-full ${ isMenuAnimation }`)
      const protocol = getProtocol();
      const domain = getSiteDomain();
      const hasAvailablePricings = siteInfo?.suggested_membership_plan_period !== 0;
      return (
         <>
            <div
               className={ wrapperClasses }
               style={ {
                  backgroundColor: isDarkBg ? 'rgba(33, 33, 33, 0.7)' : 'rgba(248, 249, 250, 0.7)',
               } }
               onClick={ () => this.collapseMenu() }
               role='presentation'
            >
               <Navigation
                  type='mobile'
                  onClose={ this.collapseMenu }
                  onClickMyAccount={ () => this.handleRouteChange('MY_ACCOUNT') }
                  user={ user ?  {
                     avatar: user.avatar_compressed_src,
                     screen_name: user.username,
                  } : null }
               >
                  <SelectLanguages
                     screenWidth={ screenWidth }
                     translteLanguage={ user?.translte_language }
                     onUpdateLanguage={ translte_language => {
                        if(user && translte_language !== user.translte_language){
                           onUpdateLanguage({ translte_language })
                           window.google_translte_language = translte_language;

                        }
                     } }
                  />
                  {
                     'public' === getUserStatusType(user?.subscriber) && (
                        <div className='flex flex-col items-center mb-3'>
                           {
                              hasAvailablePricings && (
                                 <div className='flex h-12 w-auto min-w-[106px]'>
                                    <Link
                                       to={ Router.route('JOIN').getCompiledPath() }
                                       className='w-full h-full'
                                    >
                                       <Button
                                          text='Join'
                                          primaryColor
                                          padding='none'
                                          borderRadius='large'
                                          textSize='base'
                                          classNames='h-full px-2'
                                          textMarginX='0'
                                          onClick={ () => this.collapseMenu() }
                                       />
                                    </Link>
                                 </div>
                              )
                           }
                           {
                              !user && (
                                 <div className='flex items-center mt-1'>
                                    <div className='h-9 w-full'>
                                       <Link
                                          to={ Router.route('SIGN_IN').getCompiledPath() }
                                          className='w-full h-full hover:underline hover:decoration-secondary dark:hover:decoration-secondary-dark '
                                       >
                                          <Button
                                             text='Already have an account?'
                                             textSize='small'
                                             padding='none'
                                             classNames='h-full'
                                             backgroundColor='transparent'
                                             textMarginX='0'
                                             textColor='secondary dark:text-secondary-dark '
                                          />
                                       </Link>

                                    </div>
                                 </div>
                              )
                           }
                        </div>

                     )
                  }
                  <Navigation.Group>
                     <Navigation.Link
                        icon='home'
                        title='Home'
                        to={ Router.route('HOMEPAGE').getCompiledPath() }
                        active={ !!Router.route('HOMEPAGE').match(locationPath) }
                        onClick={ () => this.handleRouteChange('HOMEPAGE') }
                     />
                     {
                        0 !== media?.record_streams_count && (
                           <Navigation.Subgroup
                              icon='online-stream'
                              title='Livestream'
                           >
                              <Navigation.Link
                                 font='normal'
                                 title='Studio'
                                 icon=''
                                 to={ Router.route('LIVE_STREAM_STUDIO').getCompiledPath() }
                                 active={ !!Router.route('LIVE_STREAM_STUDIO').match(locationPath) }
                                 onClick={ () => this.handleRouteChange('LIVE_STREAM_STUDIO') }
                              />
                              <Navigation.Link
                                 font='normal'
                                 title='Recorded streams'
                                 to={ Router.route('LIVE_STREAM_RECORDS_STREAM').getCompiledPath() }
                                 active={ !!Router.route('LIVE_STREAM_RECORDS_STREAM').match(locationPath) }
                                 onClick={ () => this.handleRouteChange('LIVE_STREAM_RECORDS_STREAM') }
                              />
                           </Navigation.Subgroup>


                        )
                     }
                     {
                        !media?.record_streams_count && (
                           <Navigation.Link
                              title='Livestream'
                              icon='online-stream'
                              to={ Router.route('LIVE_STREAM_STUDIO').getCompiledPath() }
                              active={ !!Router.route('LIVE_STREAM_STUDIO').match(locationPath) }
                              onClick={ () => this.handleRouteChange('LIVE_STREAM_STUDIO') }
                           />

                        )
                     }
                     {
                        (media && media.stories_count !== 0 && (media.has_site_header || (sum - media.stories_count !== 0))) && (
                           <Navigation.Link
                              icon='photo-camera'
                              title='Stories'
                              to={ Router.route('STORIES').getCompiledPath() }
                              active={ !!Router.route('STORIES').match(locationPath) }
                              onClick={ () => this.handleRouteChange('STORIES') }
                           />
                        )
                     }
                     {
                        media && media.videos_count !== 0 && (media.has_site_header || (sum - media.videos_count !== 0)) && (
                           <Navigation.Link
                              icon='videos'
                              title='Videos'
                              to={ Router.route('VIDEOS').getCompiledPath() }
                              active={ !!Router.route('VIDEOS').match(locationPath) }
                              onClick={ () => this.handleRouteChange('VIDEOS') }
                           />
                        )
                     }
                     {
                        media && media.photosets_count !== 0  && (media.has_site_header || (sum - media.photosets_count !== 0)) && (
                           <Navigation.Link
                              icon='photosets'
                              title='Photosets'
                              to={ Router.route('PHOTOSETS').getCompiledPath() }
                              active={ !!Router.route('PHOTOSETS').match(locationPath) }
                              onClick={ () => this.handleRouteChange('PHOTOSETS') }
                           />
                        )
                     }
                     {
                        media && media.store_count !== 0 && (media.has_site_header || (sum - media.store_count !== 0))  && (
                           <Navigation.Link
                              icon='store'
                              title='Store'
                              to={ Router.route('STORE').getCompiledPath() }
                              active={ !!Router.route('STORE').match(locationPath) }
                              onClick={ () => this.handleRouteChange('STORE') }
                           />
                        )
                     }
                     {
                        !!user && (
                           <Navigation.Link
                              icon='star'
                              title='My favorites'
                              to={ Router.route('MY_FAVORITES').getCompiledPath() }
                              active={ !!Router.route('MY_FAVORITES').match(locationPath) }
                              onClick={ () => this.handleRouteChange('MY_FAVORITES') }
                           />
                        )
                     }
                     {
                        !!user && (
                           <Navigation.Link
                              icon='vault-v2'
                              title='My unlocks'
                              to={ Router.route('MY_UNLOCKS').getCompiledPath() }
                              active={ !!Router.route('MY_UNLOCKS').match(locationPath) }
                              onClick={ () => this.handleRouteChange('MY_UNLOCKS') }
                           />
                        )
                     }
                     {
                        !!user && (
                           <Navigation.Link
                              icon='wallet'
                              title='My wallet'
                              to={ Router.route('WALLET').getCompiledPath() }
                              active={ !!Router.route('WALLET').match(locationPath) }
                              onClick={ () => this.handleRouteChange('WALLET') }
                           />
                        )
                     }
                     {
                        admin_enabled_affiliate && (
                           <Navigation.Link
                              icon='campaign'
                              title='Affiliates'
                              to={ Router.route('AFFILIATES_DETAILS').getCompiledPath() }
                              active={ !!Router.route('AFFILIATES_DETAILS').match(locationPath) }
                              onClick={ () => this.handleRouteChange('AFFILIATES_DETAILS') }
                           />
                        )
                     }

                     {
                        !!user && (
                           <Navigation.Link
                              icon='message'
                              title='Contact'
                              to={ Router.route('CONTACT_US').getCompiledPath() }
                              active={ !!Router.route('CONTACT_US').match(locationPath) }
                              onClick={ () => this.handleRouteChange('CONTACT_US') }
                           />
                        )
                     }
                     {
                        customPages && (
                           customPages.length < 2 && customPages[0] ? (
                              <Navigation.Link
                                 icon='custom-page'
                                 title={ customPages[0].title }
                                 to={ Router.route('CUSTOM_PAGE').getCompiledPath({ id: generateUriSegment(customPages[0].id, customPages[0].title) }) }
                                 active={ !!Router.route('CUSTOM_PAGE').match(locationPath) }
                                 onClick={ () => this.handleRouteChange('CUSTOM_PAGE', { id: generateUriSegment(customPages[0].id, customPages[0].title) }) }
                              />
                           ) : (
                              <Navigation.Subgroup
                                 icon='optons-horizontal'
                                 title='More'
                              >
                                 {
                                    customPages.map((page => {
                                       return (
                                          <Navigation.Link
                                             key={ page.id }
                                             font='normal'
                                             icon=''
                                             title={ page.title }
                                             to={ Router.route('CUSTOM_PAGE').getCompiledPath({  id: generateUriSegment(page.id, page.title) }) }
                                             active={ !!Router.route('CUSTOM_PAGE').match(locationPath) }
                                             onClick={ () => this.handleRouteChange('CUSTOM_PAGE', {  id: generateUriSegment(page.id, page.title) }) }
                                          />
                                       )
                                    }))
                                 }
                              </Navigation.Subgroup>
                           )
                        )
                     }
                     <Navigation.Link
                        icon='help'
                        title='Support'
                        className='launch-intercom'
                        onClick={ () => intercomMoreInfo(MEMBER_SUPPORT) }
                        unreadCount={ intercomUnreadCount }
                     />
                     <Navigation.Link
                        icon='details'
                        title='Information and Policies'
                        onClick={ () => {
                           this.toggleOpenModal();
                           this.collapseMenu();
                        } }
                     />
                     {
                        !!user && (
                           <Navigation.Link
                              icon='logout'
                              title='Logout'
                              onClick={ this.logOut }
                           />
                        )
                     }

                  </Navigation.Group>
               </Navigation>
            </div>
            <InformationAndPolicies
               showButton={ false }
               isOpen={ this.state.openModal }
               onClose={ this.toggleOpenModal }
               isMobile={ screenWidth < 700 }
               refCode={ siteInfo?.referal_code }
               secondModalType={ this.state.informationAndPoliciesModalType }
               openSecondModal={ this.openInformationAndPoliciesSecondModal }
               isAffiliateEnabled={ siteInfo?.admin_enabled_affiliate }
               affiliateLink={ domain === 'mms.loc' ? `http://localhost:7202/sites/${ siteInfo?.unique_number_id }-${ siteInfo?.subdomain }` : `${ protocol }${ `affiliate.mymember.site` }/sites/${ siteInfo?.unique_number_id }-${ siteInfo?.subdomain }` }
               legalPages={ siteInfo?.legal_pages }
               customFooterLinks={ siteInfo?.custom_footer_links }
               footerPoweredByText={ siteInfo?.footer_powered_by_text }
               isBrandingRemoval={ siteInfo?.is_branding_removal }
               whiteLabel={ siteInfo?.white_label }
            />
         </>
      )
   }
}

const mapStateToProps = (state) => {
   return {
      isMenuOpened: isMenuOpenedSelector(state),
      locationPath: currentLocationPathSelector(state),
      user: authUserSelector(state),
      isMenuAnimation: isMenuAnimationSelector(state),
      siteInfo: siteInfoSelector(state),
      screenWidth: screenWidthSelector(state),
      customPages: customPagesSelector(state),
      isDarkBg: isDarkBgSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      collapseMenu: () => {
         dispatch(collapseMenu())
      },
      goTo: (location) => {
         dispatch(push(location))
      },
      logout: (path, search) => dispatch(logout(dispatch, true, path, search)),
      onUpdateLanguage: (data) => dispatch(onUpdateLanguageOperation(data)),
      setMenuDefaultAnimationClass: () => dispatch(setMenuDefaultAnimationClassAction()),
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationContainer);
