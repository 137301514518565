
// START MY ACCOUNT TYPES

// start init account data
export const INIT_MY_ACCOUNT_START = 'myAccount/INIT_MY_ACCOUNT_START';
export const INIT_MY_ACCOUNT_COMPLETED = 'myAccount/INIT_MY_ACCOUNT_COMPLETED';
export const INIT_MY_ACCOUNT_FAILED = 'myAccount/INIT_MY_ACCOUNT_FAILED';

// start change password
export const CHANGE_PASSWORD_START = 'myAccount/CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_COMPLETED = 'myAccount/CHANGE_PASSWORD_COMPLETED';
export const CHANGE_PASSWORD_FAILED = 'myAccount/CHANGE_PASSWORD_FAILED';

// start change email
export const CHANGE_EMAIL_START = 'myAccount/CHANGE_EMAIL_START';
export const CHANGE_EMAIL_COMPLETED = 'myAccount/CHANGE_EMAIL_COMPLETED';
export const CHANGE_EMAIL_FAILED = 'myAccount/CHANGE_EMAIL_FAILED';
export const SEND_VERIFIED_CODE_START = 'myAccount/SEND_VERIFIED_CODE_START';
export const SEND_VERIFIED_CODE_COMPLETED = 'myAccount/SEND_VERIFIED_CODE_COMPLETED';
export const SEND_VERIFIED_CODE_FAILED = 'myAccount/SEND_VERIFIED_CODE_FAILED';


// start toggle notification
export const TOGGLE_NOTIFICATION_START = 'myAccount/TOGGLE_NOTIFICATION_START';
export const TOGGLE_NOTIFICATION_COMPLETED = 'myAccount/TOGGLE_NOTIFICATION_COMPLETED';
export const TOGGLE_NOTIFICATION_FAILED = 'myAccount/TOGGLE_NOTIFICATION_FAILED';

export const UPDATE_PROFILE_FAILED = 'myAccount/UPDATE_PROFILE_FAILED';

// start cancel subscription
export const CANCEL_SUBSCRIPTION_START = 'myAccount/CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_COMPLETED = 'myAccount/CANCEL_SUBSCRIPTION_COMPLETED';
export const CANCEL_SUBSCRIPTION_FAILED = 'myAccount/CANCEL_SUBSCRIPTION_FAILED';

export const OPEN_EDIT_USERNAME_MODAL = 'myAccount/OPEN_EDIT_USERNAME_MODAL';

// start notification preferences

export const NOTIFICATION_PREFERENCES_START = 'NOTIFICATION_PREFERENCES_START'
export const NOTIFICATION_PREFERENCES_COMPLETED = 'NOTIFICATION_PREFERENCES_COMPLETED'

export const UPDATE_NOTIFICATION_PREFERENCES_START = 'UPDATE_NOTIFICATION_PREFERENCES_START'
export const UPDATE_NOTIFICATION_PREFERENCES_COMPLETED = 'UPDATE_NOTIFICATION_PREFERENCES_COMPLETED'
export const UNSUBSCRIBE_ALL_NOTIFICATION_PREFERENCES_START = 'UNSUBSCRIBE_ALL_NOTIFICATION_PREFERENCES_START'
export const UNSUBSCRIBE_ALL_NOTIFICATION_PREFERENCES_COMPLETED = 'UNSUBSCRIBE_ALL_NOTIFICATION_PREFERENCES_COMPLETED'
