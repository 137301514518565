import { Component } from 'react';
import PropTypes from 'prop-types';
import Router from 'sites/routes/router';
import { currentLocationPathSelector } from 'sites/state/modules/router/selectors';
import { push } from "connected-react-router";
import { connect } from "react-redux";


class HomepageChecker extends Component {
   static propTypes = {
      locationPath: PropTypes.string.isRequired,
      goTo: PropTypes.func.isRequired,
   };

   componentDidMount() {
      const { locationPath, goTo } = this.props;
      if('/' === locationPath && locationPath !== Router.route('HOMEPAGE').getMask()) {
         goTo(Router.route('HOMEPAGE').getMask())
      }
   }

   render() {
      return null;
   }
}

const mapStateToProps = state => ({
   locationPath: currentLocationPathSelector(state),
});

const mapDispatchToProps = dispatch => ({
   goTo: location => {
      dispatch(push(location))
   },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomepageChecker);
