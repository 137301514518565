
import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'common/components/modules/modal';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import cx from 'classnames';

const WrongDownloadModal = ({
   header,
   body,
   closeModal,
   isLimited,
}) => {
   let buttonState = {
      borderRadius: 'label',
   }
   if(!isLimited){
      buttonState = {
         backgroundColor: 'transparent',
      }
   }
   return (
      <Modal
         onClose={ () => {
            closeModal({
               isOpen: false,
               body: null,
               header: null,
            })
         } }
         contentPosition='center'
         isCloseAction={ false }
         contentWidth={ isLimited ? '375px' : '463px' }
      >
         {
            isLimited && (
               <div className='h-6 w-6 mt-4 ml-4 flex items-center justify-center'>
                  <Button
                     iconName='close'
                     fontIconSize='extraLarge'
                     fontIconColor='secondary'
                     darkIconColor='secondary-dark'
                     backgroundColor='transparent'
                     onClick={ () => {
                        closeModal({
                           isOpen: false,
                           body: null,
                           header: null,
                        })
                     } }
                     padding='none'
                     classNames='h-full'
                  />
               </div>
            )
         }
         <div
            className={ cx({
               'flex flex-col text-center items-center': true,
               'px-6 pb-6 mt-4': isLimited,
               'p-5': !isLimited,
            }) }
         >

            {
               isLimited && (
                  <div className='w-12 h-12 flex items-center justify-center rounded-full bg-[#ffc37d1f] mb-3'>
                     <Icon name='attention-outline' size='2xl' className='text-[#FFC37D]' />
                  </div>
               )
            }
            <span className='text-major font-medium text-xl dark:text-major-dark leading-[24px]'>{header}</span>
            <span
               className={ cx({
                  'leading-tight text-secondary dark:text-secondary-dark ': true,
                  'mt-3': !isLimited,
                  'mt-4': isLimited,
               }) }
            >
               {body}</span>
            <div
               className={ cx({
                  'w-full': true,
                  'h-9 mt-4': !isLimited,
                  'h-12 mt-8': isLimited,
               }) }
            >
               <Button
                  text='Ok'
                  primaryColor
                  classNames='h-full'
                  padding='none'
                  onClick={  () => {
                     closeModal({
                        isOpen: false,
                        body: null,
                        header: null,
                     })
                  } }
                  { ...buttonState }
               />
            </div>
         </div>
      </Modal>
   )
}

WrongDownloadModal.propTypes = {
   header: PropTypes.string,
   body: PropTypes.string,
   closeModal: PropTypes.func,
   isLimited: PropTypes.bool,
}

export default WrongDownloadModal
