/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import SecondModal from '../../index';
import { getSiteNameAbbreviatur, getSupportEmailText, getSupportEmail  } from 'common/utils/helper';

const openIntercomArticle = (article) => {
   if(window?.Intercom){
      window.Intercom('showArticle', article)
   }
}

const SitesAdministratorAgreement = () => {
   const [openModal, setOpenModal] = useState('');
   return (
      <>
         <div className='mx-6' style={ { lineHeight: '28px' } }>
            <p className='mb-6'>
               This Site Administrator Agreement (this “<b>Agreement</b>”) is a legally binding contract by
               and between {getSiteNameAbbreviatur()} Media Inc. (“<b>{getSiteNameAbbreviatur()}</b>”) and you, and shall be effective immediately upon
               your acceptance hereof, which you signify by clicking the “I Accept” button. If you
               are acting on behalf of a corporation then: (i) this Agreement shall be binding upon
               you, upon such corporation, and upon such corporation’s agents, (ii) you hereby represent
               and warrant that you are authorized to bind yourself and such corporation to this
               Agreement and that such corporation agrees to be so bound, and (iii) hereinafter the
               words “you,” “your” and “yourself” shall refer, collectively, to you, to such
               corporation, and to such corporation’s agents and together you shall be jointly
               and severally liable for your obligations hereunder.
            </p>

            <p className='mb-6'>
               This Agreement governs your access to and use of the Platform as a Site Administrator
               (each as defined below). This Agreement incorporates by reference {getSiteNameAbbreviatur()}’
               <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('3512988') } role='presentation'> &lt;Content Policy&gt;</span>,
               the <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('9005081') } role='presentation'> &lt;Site Administrator Privacy Policy&gt;</span> and all other operating rules, policies, price
               schedules and other supplemental terms and conditions or documents that may from time to
               time be published by {getSiteNameAbbreviatur()} on the Platform. In the event of any inconsistency between this
               Agreement and the Content Policy, the Site Administrator Privacy Policy or any other
               operating rules, policies, price schedules or other supplemental terms and conditions
               or documents published by {getSiteNameAbbreviatur()} on the Platform, this Agreement shall prevail.
            </p>
            <p className='mb-6'>
               In consideration of the covenants contained herein and for other good and valuable consideration,
               the receipt and sufficiency of which are hereby acknowledged, you and {getSiteNameAbbreviatur()} agree as follows:
            </p>

            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>1. Definitions</p>
               <p>
                  (a)  In this Agreement the word “person” shall refer to individuals, corporations and other
                  entities together.
               </p>
               <p>(b) Headings of sections and subsections in this Agreement are inserted for convenience of reference only
                  and shall not affect the construction or interpretation of this Agreement.
               </p>
               <p>(c)  In this Agreement:</p>
               <ul className='mt-1'>
                  <li className='mt-2'>• <b>“Cast Member”</b> means any individual who is visually depicted in any Content;</li><br />
                  <li className='mt-2'>• <b>“Content”</b> means any text, audio, video, images or other visual media that is uploaded to,  published on or streamed from any Site; </li><br />
                  <li className='mt-2'>• <b>“Content Policy”</b> means the policy currently available  <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('3512988') } role='presentation'> &lt;here&gt;</span>, as the same may be updated by {getSiteNameAbbreviatur()} from time to time; </li><br />
                  <li className='mt-2'>• <b>“Creator”</b> means a holder of an account that is administered by you and through which Content may be uploaded to, published on or streamed from a Site or Sites; </li><br />
                  <li className='mt-2'>• <b>“Dashboard”</b> means the internet portal through which you access the features and options of your Site Administrator Account; </li><br />
                  <li className='mt-2'>• <b>“Member”</b> means a holder of a Member Account;</li><br />
                  <li className='mt-2'>• <b>“Member Account”</b> means an account through which a registered user of a Site accesses such Site; </li><br />
                  <li className='mt-2'>• <b>“Member Terms of Use”</b> means the legal agreement containing the terms that govern every Member’s access to and use of any Site; </li><br />
                  <li className='mt-2'>• <b>“Platform”</b> means the internet-based platform, owned and operated by {getSiteNameAbbreviatur()}, for creating and hosting adult-themed e-commerce membership websites, including all related web-applications and services; </li><br />
                  <li className='mt-2'>• <b>“Site”</b> means an e-commerce membership website that is hosted on the Platform and of which you are the Site Administrator; </li><br />
                  <li className='mt-2'>• <b>“Site Administrator”</b> means a person who administers, through the Platform, the Site or Sites of one or more Creators; </li><br />
                  <li className='mt-2'>• <b>“Site Administrator Account”</b> means your acco unt through which you access the Platform as a Site Administrator; and </li><br />
                  <li className='mt-2'>• <b>“Site Administrator Privacy Policy”</b> means the privacy policy currently available <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('9005081') } role='presentation'> &lt;here&gt;</span>, as the same may be updated by {getSiteNameAbbreviatur()} from time to time.</li><br />
               </ul>
            </div>

            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>2. Site Administrator Account; Registration</p>
               <p>(a)  To access and use the Platform as aSite Administrator you must first complete the registration process
                  (which follows your acceptance of this Agreement) that creates your Site Administrator Account. {getSiteNameAbbreviatur()} reserves
                  the right to treat as abandoned and to cancel any incomplete registration. Cancellation of a registration
                  by {getSiteNameAbbreviatur()} will result in the termination of this Agreement (in accordance herewith).
               </p><br />
               <p>(b)  If you are the registrant of a Site Administrator Account or other account type previously terminated or suspended by {getSiteNameAbbreviatur()} you may not register a Site
                  Administrator Account or act as a Site Administrator without the written consent of {getSiteNameAbbreviatur()}. </p><br />
               <p>(c)  By registering a Site Administrator Account you consent to receiving from time to time, by
                  email and by other means, communications from {getSiteNameAbbreviatur()} concerning this Agreement, the Platform, any
                  Site and/or your Site Administrator Account and you consent to the collection and processing of
                  your personal information as detailed in our <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('9005081') } role='presentation'> &lt;Site Administrator Privacy Policy&gt;</span>. </p><br />
               <p>(d)  The information you provide during registration must be complete, accurate and current. You agree to: (i)
                  maintain and promptly update your registration information including, but not limited to, your legal name and
                  your address of residence in order to keep such information true, accurate, current and complete, (ii)
                  maintain an accurate, up to date, valid, and regularly-checked email address for the purposes of receiving
                  Communications (as defined in subsection 23.6 hereof), and (iii) ensure that email messages from {getSiteNameAbbreviatur()} are
                  not filtered, altered or blocked by your email software or service provider. </p><br />
               <p>(e)  Under no circumstances will any person other than yourself be permitted to access your Site Administrator
                  Account, and you cannot assign or otherwise transfer your Site Administrator Account to any other person without
                  the written permission of {getSiteNameAbbreviatur()}. You are responsible for keeping your Site Administrator Account password
                  confidential and secure, and you must not divulge your Site Administrator Account password to anyone under
                  any circumstances.</p><br />
               <p> (f)  In the event that you lose the password to your Site Administrator Account and lose access to the email
                  address associated therewith, {getSiteNameAbbreviatur()} may require you to provide identification and/or other documents or
                  information that {getSiteNameAbbreviatur()} may reasonably require to establish your identity. Notwithstanding the foregoing,
               {getSiteNameAbbreviatur()} may not be able to restore access to your Site Administrator Account in the event that you lose the
                  password thereto and lose access to the email address associated therewith. </p><br />
               <p>(g)  You agree to contact {getSiteNameAbbreviatur()} immediately if you believe an unauthorized third party may be accessing
                  your Site Administrator Account or if your login credentials are lost or stolen. </p><br />
               <p>(h)  You agree that you are solely responsible and liable for all activity that occurs under your Site
                  Administrator Account regardless of whether the activity is undertaken by you, your employees, contractors,
                  agents or a third party (whether or not authorized by you) and, except to the extent caused by a breach of
                  this Agreement by {getSiteNameAbbreviatur()}, neither {getSiteNameAbbreviatur()} nor any of its agents, employees or contractors shall be responsible for
                  any unauthorized access to your Site Administrator Account or for any losses or harm resulting therefrom
                  including, without limitation, any unauthorized payments or distributions of funds. For the purposes of this
                  Agreement any activity undertaken by anyone other than you through your Site Administrator Account shall
                  be attributed to you. </p><br />
            </div>

            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>3. Member Fees, Refunds & Payouts</p>
               <p>
                  Member fees collected by {getSiteNameAbbreviatur()} are, for all intents and purposes, revenues of {getSiteNameAbbreviatur()}. For the license
                  granted to {getSiteNameAbbreviatur()} in section 11 hereof and for all other licenses and rights granted to {getSiteNameAbbreviatur()} hereunder,
                  {getSiteNameAbbreviatur()} shall pay you a fee in accordance with this Agreement and any applicable rules and policies
                  published by {getSiteNameAbbreviatur()} on the Platform. {getSiteNameAbbreviatur()} may withhold any fees payable to you should any Site be
                  flagged for fraudulent activity or if any Site is closed prior to all Member subscriptions thereto
                  having first expired. Notwithstanding any other provision of this Agreement or any other operating
                  rules or policies published by {getSiteNameAbbreviatur()} on the Platform, {getSiteNameAbbreviatur()} shall have the right, at its sole discretion,
                  to refund any amounts collected from any Member for any reason including, but not limited to, for
                  technical failures or limitations of the Platform.
               </p>
            </div>

            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>4. License to Use Platform</p>
               <p>
                  Subject to the terms and conditions of this Agreement, {getSiteNameAbbreviatur()} hereby grants you a limited, personal,
                  revocable, non-exclusive, non-transferable license to access and use the software and services that
                  comprise the Platform. This license is limited by all terms and conditions set forth herein and shall
                  persist until this Agreement is terminated. You may not reproduce, copy, redistribute or misappropriate,
                  in whole or in part, any of the software or any part of the graphic interface that form any part of
                  the Platform or any intellectual property therein. No rights of any kind in any software or other
                  property that comprise the Platform shall accrue to you, to any Creator, Cast Member or Member. No
                  implied licenses or rights are hereby granted to you in respect of the Platform.
               </p>
            </div>

            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>5. Disruptions in Service; Access to Platform</p>
               <p>
                  {getSiteNameAbbreviatur()} will use commercially reasonable efforts to maintain the accessibility of the Platform, however,
                  {getSiteNameAbbreviatur()} cannot guarantee uninterrupted access at all times. Occurrences such as unusually high Platform
                  traffic, power outages, natural disasters, changes in law, strikes, etc. may from time to time prevent
                  access to the Platform by you, by Creators, Members and others. {getSiteNameAbbreviatur()} SHALL NOT BE LIABLE TO YOU OR TO
                  ANY THIRD PARTY FOR ANY LOSS OR DAMAGE THAT RESULTS FROM YOU, ANY CREATOR, ANY MEMBER OR ANY OTHER
                  PERSON BEING UNABLE TO ACCESS THE PLATFORM OR ANY CONTENT STORED THEREON. {getSiteNameAbbreviatur()} makes no assurances
                  that any Site will be compatible with any particular device, browser or operating system.
               </p>
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>6. Members</p>
               <p>
                  {getSiteNameAbbreviatur()} reserves the right to suspend or terminate any Member Account without notice or obligation to
                  you and you agree that {getSiteNameAbbreviatur()} shall not be liable to you or to any person for any harm or loss arising
                  from any such suspension or termination by {getSiteNameAbbreviatur()} of any such Member Account.
               </p>
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>7. Creators & Cast Members</p>
               <p>
                  (a) Prior to the production, publication or streaming of any Content on, to, or from any Site,
                  you must do or have done each of the following:
               </p>
               <p> &nbsp; &nbsp; &nbsp;(i)  obtain or have obtained from each Cast Member depicted or to be depicted in the Content, in a form provided or approved by {getSiteNameAbbreviatur()}, written consent to the Cast Member’s depiction in the Content, to the publication of the Content on Platform and to the public distribution, downloading or streaming of the Content from the Platform and provide or have provided a copy of each such written consent to {getSiteNameAbbreviatur()} via the upload facility provided for such purpose in the Dashboard; </p><br />
               <p>&nbsp; &nbsp; &nbsp;(ii)  obtain or have obtained from each Cast Member depicted or to be depicted in the Content, in a form provided or approved by {getSiteNameAbbreviatur()}, a signed model release or Model Consent & Release Form (as defined below) and provide or have provided to {getSiteNameAbbreviatur()}, via the upload facility provided for such purpose in the Dashboard, a copy of each such model release or Model Consent & Release Form; </p><br />
               <p>&nbsp; &nbsp; &nbsp;(iii)  verify or have verified the identity of each Cast Member depicted or to be depicted in the Content and verify or have verified that the age of each such Cast Member is at least the age of majority in the jurisdiction in which any Content depicting such Cast Member is produced or from which any such Content is streamed; </p><br />
               <p>&nbsp; &nbsp; &nbsp;(iv)  obtain or have obtained from each Cast Member depicted or to be depicted in the Content a scanned copy or high-resolution photograph of a valid identification card that accords with subsection 7(b) below (an <b>“ID Card”</b>) and provide or have provided to {getSiteNameAbbreviatur()}, via the upload facility provided for such purpose in the Dashboard, a copy of each such ID Card; and    </p><br />
               <p>&nbsp; &nbsp; &nbsp;(v) provide or have provided to {getSiteNameAbbreviatur()}, via the facility provided for such purpose in the Dashboard, a list of ‘stage names’ that accords with subsection 7(c) below (a <b>“Names List”</b>) in respect of every Cast Member depicted or to be depicted in the Content.</p><br />
               <p>(b)  The ID Card of a Cast Member must be government-issued, be valid at the time of the production or streaming of the first-produced or streamed Content in which the Cast Member is depicted, bear the Cast Member’s legal name, date of birth and recent and recognizable photograph. Any scanned copy of an ID Card provided to {getSiteNameAbbreviatur()} pursuant to paragraph 7(a)(iv) above must be of sufficient quality that the Cast Member be readily identifiable from the picture thereon and the Cast Member’s name and date of birth thereon be clearly legible. Scanned copies of ID Cards provided to {getSiteNameAbbreviatur()} by you pursuant to paragraph 7(a)(iv) above may be redacted to exclude information not specified above, such as addresses, telephone numbers, and social security numbers. </p><br />
               <p>(c)  The Names List of a Cast Member must include every name, other than the Cast Member’s legal name, by which the Cast Member is, has been or may in the future be, known including every alias, nickname, stage name, professional name and, if applicable, the Cast Member’s maiden name. You are responsible for ensuring that the Names List of every Cast Member provided to {getSiteNameAbbreviatur()} pursuant to paragraph 7(a)(v) above is at all times current and complete.</p><br />
               <p>(d)  You are required to keep and a record of every written consent obtained by you pursuant to paragraph 7(a)(i) above.</p><br />
               <p>(e)  As a producer of visual depictions of human beings engaged in actual or simulated sexually explicit conduct you are subject to Title 18 United States Code Sections 2257 and/or 2257A (as applicable) including, in particular, the obligations of a producer of such depictions as provided therein. These obligations include, but are not limited to, maintaining certain records permanently, including a copy of every ID Card obtained or produced by you pursuant to paragraph 7(a)(iv) above as well as a duplicate copy of every Names List provided to {getSiteNameAbbreviatur()} pursuant to paragraph 7(a)(v) above. </p><br />
               <p>(f)  You acknowledge and agree that, notwithstanding any rights you may possess, whether based in statute, common law or in contract, in the event that the consent referred to in paragraph 7(a)(i) above of any Cast Member should be withdrawn, whether verbally or in writing, you shall forthwith permanently rescind any licenses to, cease all publication of, and delete any Content to which such consent so withdrawn pertains. Any failure by you to abide by this provision shall constitute a material breach of this Agreement and sufficient cause for {getSiteNameAbbreviatur()}, should it so choose, to exercise its right to terminate this Agreement pursuant to subsection 20.3 hereof.</p><br />
               <p>(g)  If you are or will at any time be a Cast Member then prior to the publication on or streaming from the Platform of any Content in which you are depicted as a Cast Member you must comply with subsections 7(a) through 7(c) above in respect of yourself.</p><br />
               <p>(h)  Content in which you are depicted may be viewed by individuals who may recognize you or may be viewed by computers that may ascertain your identity using image-recognition or other technologies. You acknowledge and agree that neither {getSiteNameAbbreviatur()} nor its agents, affiliates, subsidiaries, licensees or assigns shall be liable or bear any responsibility for any harm or loss that may result from your being recognized or your identity being ascertained from Content in which you are depicted or from any disclosure or publication of your personal information (including any address information) by any third party.</p><br />
            </div>

            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>8. Provision of Template Documents</p>
               <p>
                  {getSiteNameAbbreviatur()} makes available from the Dashboard a template model consent & release document, as the same may be updated or amended from time to time by {getSiteNameAbbreviatur()} (the <b>“Model Consent & Release Form”</b>). The Model Consent & Release Form is provided to you for the purposes described in paragraphs 7(a)(i) and 7(a)(ii) above. {getSiteNameAbbreviatur()} makes no representations or warranties as to the correctness, reliability, sufficiency, suitability, relevance or validity of the Model Consent & Release Form as a legal instrument, to its suitability for your particular needs or to any protective value in law that it may provide you. You acknowledge and agree that any use by you of the Model Consent & Release Form is at your own risk and that {getSiteNameAbbreviatur()} shall bear no responsibility for any losses of any kind that may occur as a result of your use of or reliance on the Model Consent & Release Form. You further acknowledge and agree that the provision of the Model Consent & Release Form by {getSiteNameAbbreviatur()} does not constitute the provision of legal advice or legal services to you by {getSiteNameAbbreviatur()} nor that {getSiteNameAbbreviatur()}, in providing the Model Consent & Release Form, is making any representations or warranties that it is qualified or licensed to provide such advice or services, and that the provision of the Model Consent & Release Form by {getSiteNameAbbreviatur()} is in no way a substitute for your obtaining independent legal advice.
               </p>
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>9. Sites</p>
               <p>(a)  {getSiteNameAbbreviatur()} reserves the right to modify or delete any Site, and to modify the Platform for any reason or for no reason without notice, obligation or compensation to you or to any other person.</p><br />
               <p>(b)  The Platform automatically incorporates on each page of every Site a hyperlink to the Member Terms of Use. You must not, by act or omission, purport to amend, append, repeal, supplement, qualify or circumscribe the Member Terms of Use or otherwise alter the terms thereof or make any representations to any Member concerning the Member Terms of Use or the rules governing Members’ access to and use of any Site. </p><br />
               <p>(c)  that are required by law. You must not remove, alter or conceal any such statements or notices. </p><br />
               <p>(d)  The Platform may include links to third-party sites. Such links are provided for your convenience only and the inclusion of any such links on the Platform shall not be construed as an endorsement by {getSiteNameAbbreviatur()} of such third-party sites or of any content available thereon. You acknowledge and agree that your use of any links on the Platform to third-party sites is at your own risk. </p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>10. Content</p>
               <p>(a) {getSiteNameAbbreviatur()} reserves the right to modify or delete any Content for any reason or for no reason without notice, obligation or compensation to you or to any other person. The reservation of these rights by {getSiteNameAbbreviatur()} does not in any way obviate or change your obligations with respect to Content as provided herein.</p><br />
               <p>(b)  You acknowledge and agree that {getSiteNameAbbreviatur()} may prohibit the publishing on or streaming from any Site any Content in which a particular Creator or Cast Member is depicted and may do so without notice, obligation or compensation to you or to any other person. </p><br />
               <p>(c)  You must not re-post to any Site Content previously removed or deleted from any Site by {getSiteNameAbbreviatur()} without {getSiteNameAbbreviatur()}’s written permission. </p><br />
               <p>(d)  In the case of Content that is produced prior to being published on a Site, you are required to review all such Content prior to its publication to the Site to ensure that it is not illegal, that it contains no personally identifiable information of any individual, and that it is not otherwise in violation of the <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('3512988') } role='presentation'> &lt;Content Policy&gt;</span>. </p><br />
               <p>(e)  In the case of Content that is streamed from any Site, you are required to monitor all such Content in real-time to ensure that it is not illegal, that it contains no personally identifiable information of any individual, and that it is not otherwise in violation of the <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('3512988') } role='presentation'> &lt;Content Policy&gt;</span>. </p><br />
               <p>(f)  You are required to comply with all applicable laws and regulations including, where applicable, Title 18 United States Code Sections 2257 and 2257A including the requirement that you keep a backup copy of all Content that is published on or streamed from any Site. </p><br />
               <p>(g)  You acknowledge and agree that {getSiteNameAbbreviatur()} cannot prevent and is not responsible for the unauthorized recording, duplication, downloading or use of any Content by any person and that {getSiteNameAbbreviatur()} is not responsible or liable for any such unauthorized recording, duplication, downloading or use of any Content by any person or for any harm or loss to you or to any person that may result therefrom. </p><br />
               <p>(h)  You acknowledge and agree that {getSiteNameAbbreviatur()} cannot prevent and shall not be responsible for any unauthorized recording, duplication, downloading, use or misappropriation by any person of any trademarks or other intellectual property incorporated by you in any Site and that {getSiteNameAbbreviatur()} is not responsible or liable for any such unauthorized recording, duplication, downloading, use or misappropriation of same by any person or for any harm or loss to you or to any person that may result therefrom. </p><br />
               <p>(i)  You acknowledge and agree that in the event that {getSiteNameAbbreviatur()} receives from or on behalf of a Cast Member, whether verbally or in writing, an indication that such Cast Member does not consent to such Cast Member’s depiction in certain Content or in any Content and {getSiteNameAbbreviatur()} determines, as it may do at its sole discretion, that such indication represents the bona fide intentions of such Cast Member, then {getSiteNameAbbreviatur()} will delete from the Platform any such Content. You acknowledge and agree that {getSiteNameAbbreviatur()} may delete any such Content even though you may posses all the rights required, by statute, common law or contract, to publish such Content. You further acknowledge and agree that you shall not re-upload or re-publish any Content deleted by {getSiteNameAbbreviatur()} pursuant to this paragraph.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>11. License to Content and Marks</p>
               <p>(a) As between you and {getSiteNameAbbreviatur()}, you own all rights, title and interest in and to all Content that is uploaded to, published on or streamed from any Site by you. You hereby grant to {getSiteNameAbbreviatur()} a worldwide, irrevocable, perpetual, royalty-free, non-exclusive license to access, use, copy, modify, sub-license, delete, create derivative works of, make and retain copies of, publish to and broadcast from the Platform, and to otherwise make use of any and all such Content, in whole or in part, and – in respect of such Content in which you are depicted – in conjunction with any aliases, stage or professional names by which you are, have previously been, or may in the future be, known as required by {getSiteNameAbbreviatur()} to provide the Platform and related services, to comply with applicable laws and regulations, and for the exercise by {getSiteNameAbbreviatur()} of its rights under this Agreement (the <b>“Permissible Uses”</b>). This license shall survive any termination of this Agreement and shall persist until no longer required by {getSiteNameAbbreviatur()} for the Permissible Uses and shall terminate thereafter.</p><br />
               <p>(b)  You hereby grant to {getSiteNameAbbreviatur()} a worldwide, irrevocable, perpetual, royalty-free, non-exclusive license to access, delete, sub-license, make and retain copies of, publish to and broadcast from the Platform any trademarks or other intellectual property incorporated by you in any Site as required by {getSiteNameAbbreviatur()} to provide the Platform and related services, to comply with applicable laws and regulations, and for the exercise by {getSiteNameAbbreviatur()} of its rights under this Agreement (the <b>“Permissible IP Uses”</b>). This license shall survive any termination of this Agreement and shall persist until no longer required by {getSiteNameAbbreviatur()} for the Permissible IP Uses and shall terminate thereafter. </p>
               <p>(c)  You hereby waive any moral rights that you may have under any applicable law to object to any derogatory treatment by {getSiteNameAbbreviatur()} of any Content published on or streamed from any Site. The purpose of this waiver is to ensure that {getSiteNameAbbreviatur()} is fully vested of the rights necessary to add watermarks, stickers, disclaimers or to make other alterations to your Content pursuant to the license granted to {getSiteNameAbbreviatur()} under subsection 11(a) above. </p>
               <p>(d)  You hereby grant to {getSiteNameAbbreviatur()} the right to submit notifications of infringement (including of copyright or trademark infringement) to any third-party website or platform that publishes, deals in or otherwise makes infringing copies of any Content and to withdraw any such notifications of infringement at any time at {getSiteNameAbbreviatur()}’ sole discretion. Notwithstanding the foregoing, you acknowledge and agree that {getSiteNameAbbreviatur()} is under no obligation to monitor and/or police infringements of your rights to your Content. </p>
               <p>(e)  YOU UNDERSTAND AND AGREE THAT {getSiteNameAbbreviatur()} SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES OR OTHER HARM THAT MAY RESULT FROM THE LOSS, CORRUPTION OR DELETION OF ANY CONTENT, HOWEVER CAUSED. YOU FURTHER UNDERSTAND AND AGREE THAT {getSiteNameAbbreviatur()} SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY UNAUTHORIZED USE, DUPLICATION, PUBLICATION, THEFT OR MISAPPROPRIATION OF ANY CONTENT OR ANY INTELLECTUAL PROPERTY THEREIN OR ANY RESULTANT HARM OR LOSS TO YOU OR TO ANY PERSON. </p>
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>12. Additional Rights of {getSiteNameAbbreviatur()}</p>
               <p>(a)  {getSiteNameAbbreviatur()} reserves the right to provide third-party access to any Content as contractually required by any credit card payment processor or any government authority. </p>
               <p>(b)  {getSiteNameAbbreviatur()} reserves the right to, at any time without notice or obligation to you, change the credit card payment processor or processors used to process payments on any Site. </p>
               <p>(c) {getSiteNameAbbreviatur()} reserves the right to, without notice or obligation to you, delete any messages sent by you to any Member or sent by any Member to you via the Platform.</p><br />
               <p>(d) {getSiteNameAbbreviatur()} has the right to create analytics, compile and freely use any anonymized data derived from the use of the Platform by you, by Creators and by Members (<b>“Derived Data”</b>). For greater certainty, Derived Data is anonymized and does not include any personally identifiable information. All rights, title and interest in and to Derived Data shall be owned by {getSiteNameAbbreviatur()}, and Derived Data may be used by {getSiteNameAbbreviatur()} for any purpose including for commercial, development and research purposes.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>13. Payment Processor {getSiteNameAbbreviatur()}</p>
               <p>Unless as otherwise agreed in a white-label agreement between you and {getSiteNameAbbreviatur()}, you acknowledge and agree that all payments or other transfers of money between you and any Member must be accomplished using {getSiteNameAbbreviatur()}’ designated payment processor. You acknowledge and agree that you cannot include in any Site any links to or information on any payment facility that is not the payment processor designated by {getSiteNameAbbreviatur()}.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>14. Free Memberships</p>
               <p>{getSiteNameAbbreviatur()} may make available to you promotional access codes with which an end-user may access a Site free- of-charge or on a discounted basis. {getSiteNameAbbreviatur()} shall have complete discretion as to whether or not to provide you with any such promotional access codes and if so how many. {getSiteNameAbbreviatur()} reserves the right to cancel any promotional access codes provided to you for any reason or for no reason without notice or obligation to you or to any person. You acknowledge and agree that you cannot sell or trade any promotional access codes.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>15. Copyright and Trademark Information</p>
               <p>The Platform, the software used thereon and the information and materials that it contains are the property of {getSiteNameAbbreviatur()} and its licensors and are protected from unauthorized copying and dissemination by copyright law, trademark law, international conventions and other intellectual property laws. All {getSiteNameAbbreviatur()} product names and logos and those of its licensors are trademarks or registered trademarks. Nothing in this Agreement shall be construed as granting, by implication, estoppel or otherwise, any license or right to use any part of the Platform, any software used thereon or any materials displayed thereon, through the use of framing or a similar method, except as expressly stated in this Agreement.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>16. Indemnification</p>
               <p>You agree to defend, indemnify and hold {getSiteNameAbbreviatur()} (including its successors and assigns), its directors, officers, employees, agents, contractors, partners and licensors (each an <b>“Indemnified Party”</b>) harmless from any and all claims or demands and harmless with respect to any and all costs, expenses, losses, damages, judgments or settlements arising therefrom, including reasonable attorneys’ fees, made by any third party against any Indemnified Party relating to:</p>
               <p>&nbsp; &nbsp; &nbsp;(i) the use of the Platform by you, by any Creator or by any Member;</p>
               <p>&nbsp; &nbsp; &nbsp;(ii) any Content that is uploaded to, published on or streamed from any Site by you; or</p>
               <p>&nbsp; &nbsp; &nbsp;(iii) any violation by you of this Agreement or of any law or regulation. These obligations shall survive any termination or expiration of this Agreement and your use of the Platform.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>17. Release</p>
               <p>You agree to release, discharge, and hold harmless each Indemnified Party from any and all damages, liabilities, costs, expenses, claims and/or judgments of any kind or nature whatsoever arising from any Permissible Uses or Permissible IP Uses including, but not limited to, claims based on copyright infringement, invasion of privacy, right of publicity, libel, defamation or false light, or claims arising from or relating to any alteration, blurring, optical illusion, use in composite form, distortion, or other modification that may occur, intentionally or otherwise, in connection with any of the Permissible Uses or Permissible IP Uses. You further agree to release, discharge and hold harmless each Indemnified Party from any and all damages, liabilities, costs, expenses, claims and/or judgments of any kind whatsoever arising from any unauthorized use, duplication, publication, theft or misappropriation by any person of any Content or of any intellectual property that forms any part of any Content or Site. You acknowledge and agree that this Agreement is binding upon you, your heirs, legal representatives, and assigns.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>18. Representations and Warranties</p>
               <p>You hereby represent, warrant and covenant that: </p><br />
               <p>(i)  you are of full age and are competent to execute this Agreement;</p><br />
               <p>(ii)  you are not prohibited from using the Platform by any laws or regulations of the jurisdiction in which you reside or from which you access the Platform;</p><br />
               <p>(iii)  the rights granted by you under this Agreement do not and will not conflict with or violate any commitment you have made to any person;</p><br />
               <p>(iv)  you have read this Agreement in its entirety and have fully understood the meaning and effect of its contents; </p><br />
               <p>(v)  you and your licensors together own all rights, title, and interest in and to all Content that is uploaded to, published on or streamed from any Site by you;</p><br />
               <p>(vi)  you possess all rights in and to all Content that is uploaded to, published on or streamed from any Site by you necessary to grant the licenses and rights contemplated by this Agreement and to publish, broadcast, monetize and make such other uses of such Content as are contemplated by this Agreement without violating the rights of any person; </p><br />
               <p>(vii)  in respect of all Content that is uploaded to, published on or streamed from any Site by you, you have the required written consents referred to in paragraph 7(a)(i) above and that no such consents have been withdrawn; and</p><br />
               <p>(viii) you have all the necessary rights in and to all intellectual property that will form any part of any Site and your use of such intellectual property does not and will not violate the rights of any person.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>19. Restrictions and Obligations</p>
               <p>(a)  You hereby covenant that, in the absence of the rights described in paragraphs 18(v) and 18(vi) above or the consents referred to in paragraph 18(vii) above in respect of any Content that is uploaded to, published on or
                  streamed from any Site by you, you will not publish on or stream from the Platform or cause or allow to be published on or streamed from the Platform any such Content.</p><br />
               <p>(b)  {getSiteNameAbbreviatur()} may impose restrictions on your use of and access to certain features of the Platform and may impose additional charges for the use of certain features of the Platform. </p><br />
               <p>(c)  You hereby covenant that, in your capacity as a Site Administrator, a Cast Member (where applicable), a user of the Platform and a party to this Agreement, you will not:</p><br />
               <p>&nbsp; &nbsp; &nbsp;(i)  intentionally or unintentionally violate any applicable local, state, provincial, national or international laws or regulations;</p><br />
               <p>&nbsp; &nbsp; &nbsp;(ii)  modify, translate, make derivative works of, disassemble, decompile, reverse compile or reverse engineer any part of the Platform;</p><br />
               <p>&nbsp; &nbsp; &nbsp;(iii)  violate this Agreement; </p><br />
               <p>&nbsp; &nbsp; &nbsp;(iv)  publish or share publicly via any Site or with any Member privately your personal information or that of any Creator or Member or other user of the Platform; or </p><br />
               <p>&nbsp; &nbsp; &nbsp;(v)  represent yourself, whether by action or omission, as being affiliated or partnered with {getSiteNameAbbreviatur()} or as being in anything other than an arm’s length commercial relationship with {getSiteNameAbbreviatur()} unless explicitly authorized in writing by {getSiteNameAbbreviatur()} and only in the manner and to the extent so authorized. </p><br />
               <p>(d)  You covenant that you will use your best efforts to ensure that none of the actions described in paragraphs 19(c)(i) through 19(c)(v) above will be done by any Creator or Cast Member. </p><br />
               <p>(e)  You understand and agree that you are solely responsible for:</p><br />
               <p>&nbsp; &nbsp; &nbsp;(i) ensuring that all Content that is uploaded to, published on or streamed from any Site by you complies with the <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('3512988') } role='presentation'> &lt;Content Policy&gt;</span> and with all applicable laws and regulations and that the publication to or streaming from any Site of any such Content does not violate the rights of any person; and </p><br />
               <p>&nbsp; &nbsp; &nbsp;    (ii) ensuring that your use of the Platform complies with all laws and regulations of the jurisdiction from which you access the Platform. </p><br />
               <p>(f)  You shall not:</p><br />
               <p>&nbsp; &nbsp; &nbsp;(i)  contact any Member by any means other than by those provided by the Platform;</p><br />
               <p>&nbsp; &nbsp; &nbsp;(ii) use the Platform to engage in or facilitate, without limitation, prostitution or making arrangements for in-person encounters between or among any persons or for any activity other than those for which the Platform is expressly intended; </p><br />
               <p>&nbsp; &nbsp; &nbsp;(iii) acquire, share, record or make any use of any contact information or any personally identifiable information of any Member that may become known to you;</p><br />
               <p>&nbsp; &nbsp; &nbsp;(iv) engage in any activity on the Platform that is illegal or that otherwise violates the Mastercard Standards as defined and set forth in the <a target='_blank' className='text-action' href='https://www.mastercard.us/content/dam/mccom/global/documents/mastercard-rules.pdf' rel='noreferrer'>Mastercard Rules</a>; or</p><br />
               <p>&nbsp; &nbsp; &nbsp;(v) perform any action with the intent of introducing to the Platform any viruses, worms, defects, trojan horses, malware, or any other computer code of a destructive or potentially destructive nature. </p><br />
               <p>    (g) You understand and agree that you have a duty to maintain the confidentiality of any personally identifiable information of any Member, Creator or Cast Member that you come to possess. </p><br />
               <p>    (h) When a hyperlink to any third-party webpage on which a Creator is visually depicted is posted on a Site, a reciprocal hyperlink back to the Site must be posted on such third-party webpage. For greater certainty, a third-party webpage is any webpage that is not hosted on the Platform. </p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>20. Term and Termination; Account Deletion</p>
               <p><b>20.1 TERM OF AGREEMENT:</b> This Agreement shall take effect upon your acceptance hereof and shall, subject to subsection 20.5.5 below, continue in force until terminated in accordance herewith.</p><br />
               <p><b>20.2 TERMINATION BY YOU:</b> You may terminate this Agreement at any time by logging-in to your Site Administrator Account and clicking the “Delete Account” link and completing the procedure outlined.</p><br />
               <p><b>20.3 TERMINATION BY {getSiteNameAbbreviatur()}:</b> {getSiteNameAbbreviatur()} may terminate this Agreement:</p><br />
               <p>(a)  at any time with your written agreement;</p><br />
               <p>(b)  immediately upon the cancellation of any incomplete registration pursuant to section 2 hereof;</p><br />
               <p>(c)  upon fifteen (15) days’ notice to you; or </p><br />
               <p>(d)  at any time and without prior notice for cause, where cause shall include, but not be limited to:</p><br />
               <p>&nbsp; &nbsp; &nbsp;(i)  a material breach of this Agreement by you, the materiality of any such breach being determined by {getSiteNameAbbreviatur()} at its sole discretion;</p><br />
               <p>&nbsp; &nbsp; &nbsp;(ii)  a request or order from law enforcement, a judicial body or other government entity;</p><br />
               <p>&nbsp; &nbsp; &nbsp;(iii)  the publication on or streaming from any Site Content that is unlawful or that violates this Agreement or the <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('3512988') } role='presentation'> &lt;Content Policy&gt;</span>;</p><br />
               <p>&nbsp; &nbsp; &nbsp;(iv)  a valid claim by any third party in respect of any Content that is uploaded to, published on or streamed from any Site by you, the validity of any such claim being determined by {getSiteNameAbbreviatur()} at its sole discretion; </p><br />
               <p>&nbsp; &nbsp; &nbsp;(v)  any failure by you to abide by subsection 7(f) hereof; </p><br />
               <p>&nbsp; &nbsp; &nbsp;(vi)  your inability to establish the legality of any Content that is uploaded to, published on or streamed from any Site by you or of your being vested of sufficient rights to any such Content as required by law or by this Agreement; or</p><br />
               <p>&nbsp; &nbsp; &nbsp;(vii) your having ceased to operate in the ordinary course, having made an assignment for the benefit of creditors or similar disposition of your assets, or having become the subject of any bankruptcy, reorganization, liquidation, dissolution or similar proceeding.</p><br />
               <p><b>20.4 DEEMED TERMINATION:</b> {getSiteNameAbbreviatur()} may, at its sole discretion, deem this Agreement to have been terminated by you on account of, without limitation, any of the following:</p><br />
               <p>(a) your failure to respond to Communications (as defined in subsection 23.6 below) from {getSiteNameAbbreviatur()};</p><br />
               <p>(b) the removal of a substantial portion of the Content from your Site or Sites; or</p><br />
               <p>(c) the sustained inactivity or the sustained diminishment in activity by you and/or by any Creators on your Site or Sites.</p><br />
               <p><b>20.5 EFFECT OF TERMINATION</b></p><br />
               <p><b>20.5.1  Continued Access; Account Deletion:</b> Termination of this Agreement shall result in the immediate suspension of your Site Administrator Account, during which time your access thereto will be barred. Any Site or Sites shall remain live until the expiry of all Member subscriptions thereto that are outstanding on the date this Agreement is terminated. When all Member subscriptions have expired following the termination of this Agreement your Site Administrator Account will be deleted. Upon the deletion of your Site Administrator Account any and all Sites will be deleted and access to all Content, by you or by any Creators or Members, will cease.</p><br />
               <p><b>20.5.2  Loss of Chargeback Protection; Affiliate Revenue:</b> Any termination of this Agreement shall result in the loss of all chargeback protections on payments collected from Members by {getSiteNameAbbreviatur()}. If, at the time this Agreement is terminated pursuant to subsection 20.2 or 20.3 above, you are a registered member of {getSiteNameAbbreviatur()}’ affiliate program then your access to your affiliate account shall remain unaffected. However, {getSiteNameAbbreviatur()} reserves the right to withhold any amounts that would otherwise be payable to you as a registered affiliate that may be needed by {getSiteNameAbbreviatur()} to offset any claims or chargebacks made by Members in respect of any Site or Sites or the closure thereof. </p><br />
               <p><b>20.5.3  Forfeiture on Deemed Termination:</b> Notwithstanding any other provision of this Agreement, in the event of a deemed termination of this Agreement pursuant to subsection 20.4 above:</p><br />
               <p>(a)  your right to any amounts that would otherwise be payable to you pursuant to this Agreement shall be forfeit; and </p><br />
               <p>(b)  if you are a registered member of {getSiteNameAbbreviatur()}’ affiliate program then your affiliate agreement with {getSiteNameAbbreviatur()} shall be immediately terminated, your affiliate account deleted and your right to any amounts that would otherwise be payable to you pursuant to such affiliate agreement shall be forfeit. </p><br />
               <p><b>20.5.4  Outstanding Fees and Amounts Owing:</b> Notwithstanding any other provision of this Agreement, no termination of this Agreement shall relieve you of the obligation to pay any amounts that may be owing by you to {getSiteNameAbbreviatur()} immediately prior to the termination of this Agreement. </p><br />
               <p><b>20.5.5 Surviving Provisions:</b> Notwithstanding the foregoing, the following sections and subsections of this Agreement shall survive any termination thereof: 2(h), 7(h), 8, 10(g), 11(a), 11(b), 11(e), 16, 17, 19(g), 20.5, 21, 23.3, 23.4 and 23.8.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>21. Important Disclaimers and Limitations</p>
               <p>(a)  THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, {getSiteNameAbbreviatur()} (INCLUDING ITS SUBCONTRACTORS, AGENTS, LICENSORS, SUPPLIERS, DIRECTORS AND/OR EMPLOYEES) SPECIFICALLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND IN RESPECT OF THE PLATFORM INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED REPRESENTATIONS AND/OR WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE. </p><br />
               <p>(b)  YOUR USE OF THE PLATFORM IS AT YOUR OWN RISK, AND {getSiteNameAbbreviatur()} SHALL BEAR NO RESPONSIBILITY OR LIABILITY FOR ANY LOSS OR HARM TO YOU OR TO ANY PERSON FOR ANY LOSS OR DAMAGE INCLUDING, BUT NOT LIMITED TO, LOSS OR CORRUPTION OF CONTENT, UNAUTHORIZED DUPLICATION, THEFT OR MISAPPROPRIATION OF CONTENT OR ANY INTELLECTUAL PROPERTY THEREIN, OR HARM TO REPUTATION ARISING OUT OF OR IN CONNECTION WITH ANY ACTIVITY THAT OCCURS THROUGH YOUR SITE ADMINISTRATOR ACCOUNT OR ON ANY SITE, ANY INTERACTIONS BETWEEN MEMBERS AND CREATORS, YOUR USE OF THE PLATFORM OR ANY DELAY OR IMPEDIMENT TO SUCH USE, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IN THE EVENT OF A FUNDAMENTAL BREACH BY {getSiteNameAbbreviatur()} AND EVEN IF {getSiteNameAbbreviatur()} HAS BEEN ADVISED OF THE POSSIBILITY OF LOSS OR DAMAGES. </p><br />
               <p>(c)  UNDER NO CIRCUMSTANCES SHALL {getSiteNameAbbreviatur()}, ITS DIRECTORS, EMPLOYEES, LICENSORS OR SUPPLIERS BE LIABLE FOR LOST INCOME OR PROFITS OR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES WHATSOEVER ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM OR THIS AGREEMENT, HOWEVER ARISING.</p><br />
               <p>(d)  {getSiteNameAbbreviatur()} DISCLAIMS ANY DUTIES OF A BAILEE, AND YOU HEREBY WAIVE ALL RIGHTS AND REMEDIES OF A BAILOR (WHETHER ARISING UNDER STATUTE OR COMMON LAW) IN RELATION TO OR ARISING OUT OF THE POSSESSION OF CONTENT BY {getSiteNameAbbreviatur()} (INCLUDING ITS AGENTS, DIRECTORS AND/OR EMPLOYEES) OR BY ANY OF ITS CONTRACTORS.</p><br />
               <p>(e)  {getSiteNameAbbreviatur()} SHALL NOT BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY PERSON FOR ANY DAMAGES THAT MAY RESULT FROM OR ARISE OUT OF THE SUSPENSION OR TERMINATION OF THIS AGREEMENT OR THE DELETION OF YOUR SITE ADMINISTRATOR ACCOUNT. </p><br />
               <p>(f)  NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, {getSiteNameAbbreviatur()}’ LIABILITY AND THE LIABILITY OF ITS LICENSORS, DIRECTORS, AGENTS AND EMPLOYEES, TO YOU, TO ANY CREATOR, TO ANY CAST MEMBER, TO ANY MEMBER OR TO ANY THIRD PARTY FOR ANY DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION) SHALL AT ALL TIMES BE LIMITED TO THE GREATER OF: (I) FIFTY UNITED STATES DOLLARS (US$50), AND (II) THE SUM OF ALL AMOUNTS YOU HAVE PAID TO {getSiteNameAbbreviatur()} (IF ANY) OVER THE TWELVE (12) MONTHS PRECEDING THE ACTION. THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.</p><br />
               <p>(g) Some jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential damages. As such, to the extent such exclusions or limitations are specifically prohibited by applicable law, some of the aforementioned exclusions or limitations may not apply to you.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>22. Changes to This Agreement</p>
               <p>This Agreement and any other documents, policies and terms and conditions that are incorporated by reference herein may be amended or updated by {getSiteNameAbbreviatur()} from time to time without notice to you. However, if and when {getSiteNameAbbreviatur()} does make any such amendments or updates {getSiteNameAbbreviatur()} will endeavour to notify you of the change. You can access the text of this Agreement that is in effect at any time from the Dashboard. It is your responsibility to keep abreast of any changes to this Agreement, and your continued use of the Platform following any amendments or updates to this Agreement shall signify your assent to and acceptance of this Agreement as amended or updated. Any new features or services that may be added to or made available through the Platform from time to time shall be subject to this Agreement.</p><br />
            </div>
            <div className='mb-6'>
               <p className='text-2xl text-major dark:text-major-dark mb-3 '>23. General</p>

               <p><b>23.1  RIGHTS RESERVED:</b> {getSiteNameAbbreviatur()} retains all rights and licenses not explicitly granted hereunder. </p><br />
               <p><b>23.2  PARAMOUNTCY:</b> In the event of any conflict between this Agreement and any rules or policies posted anywhere on the Platform, this Agreement shall prevail.</p><br />
               <p><b>23.3 TAXES:</b> No Taxes will be withheld by {getSiteNameAbbreviatur()} in respect of any amounts paid to you by {getSiteNameAbbreviatur()}, and no benefits of any kind including, without limitation, pension plan, health, disability or employment insurance benefits will be paid for or provided by {getSiteNameAbbreviatur()}. You are solely responsible for any taxes levied by any governmental or equivalent authority with jurisdiction over the payments made to you pursuant to this Agreement or to any rules or policies as may be published by {getSiteNameAbbreviatur()} on the Platform.</p><br />
               <p><b>23.4 FEEDBACK:</b> {getSiteNameAbbreviatur()} welcomes any suggestions, comments and feedback in respect of the Platform and related services (<b>“Feedback”</b>). You agree that if you provide Feedback to {getSiteNameAbbreviatur()} that: (i) {getSiteNameAbbreviatur()} shall not be subject to any confidentiality obligations in respect of the Feedback; (ii) the Feedback is not confidential or proprietary information belonging to you or to any third party and that you have all of the necessary rights to disclose the Feedback to {getSiteNameAbbreviatur()} in accordance with the terms of this Agreement; (iii) {getSiteNameAbbreviatur()} (including all of its successors and assigns) shall have a paid-up, royalty-free, worldwide, transferable, sublicensable, assignable, irrevocable and perpetual license to use or incorporate into the Platform and any other product and/or service any Feedback provided by you in respect of the Platform or any related service; and (iv) neither you nor any other person shall be entitled to any compensation or reimbursement of any kind in respect of the Feedback.</p><br />
               <p><b>23.5 RELATIONSHIP OF THE PARTIES:</b> Nothing contained herein is intended to, or shall be deemed to, establish a partnership, franchise, joint venture, fiduciary or employment relationship between the parties hereto and neither party is an agent of the other for any purpose or has the authority to bind the other.</p><br />
               <p><b>23.6 NOTICES AND DELIVERIES:</b> Except where otherwise provided in this Agreement, any notice, document, photograph, scanned image or other communication (each a <b>“Communication”</b>) required or permitted to be given or delivered hereunder shall be given or delivered by email as follows:</p><br />
               <p>(i)  Communications by you to {getSiteNameAbbreviatur()} shall be sent to <a href={ `mailto:${ getSupportEmail() }` } className='text-action hover:underline'><span dangerouslySetInnerHTML={ { __html: getSupportEmailText() } } /></a>. </p><br />
               <p>(ii)  Communications by {getSiteNameAbbreviatur()} to you shall be sent to the email address saved to your Site Administrator Account. </p><br />
               <p>Any Communication sent or transmitted as provided above shall be deemed to have been received on the same day it was sent or transmitted. You must ensure that the email address saved in your Site Administrator Account is at all times valid.</p><br />
               <p><b>23.7 ASSIGNMENT:</b> You may not transfer or assign this Agreement (whether voluntarily, by operation of law, or otherwise) or any rights or licenses granted by this Agreement without the prior written consent of {getSiteNameAbbreviatur()}. {getSiteNameAbbreviatur()} may assign this Agreement at any time without notice.</p><br />
               <p><b>23.8 GOVERNING LAW & DISPUTES:</b> This Agreement shall be governed by the laws of the Province of Ontario and the laws of Canada applicable therein. No choice-of-laws rules of any jurisdiction shall apply to this Agreement. The courts of the Province of Ontario shall have jurisdiction over any legal action or proceeding arising out of or relating to this Agreement or your use of the Platform and you consent to the jurisdiction of such courts for any such action or proceeding. You hereby waive all rights that you may have or that may hereafter arise to contest such jurisdiction of such courts. The parties hereto waive any right to a jury trial with respect to any action brought in connection with this Agreement. The application of the United Nations Convention on Contracts for the International Sale of Goods to this Agreement is expressly excluded. You agree that any claim or cause-of-action arising out of or related to this Agreement or your use of the Platform must be filed no later than one (1) year after the cause-of-action arises or be forever barred.</p><br />
               <p><b>23.9 ENTIRE AGREEMENT:</b> This Agreement, including the <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('3512988') } role='presentation'> &lt;Content Policy&gt;</span>, the <span className='text-action dark:text-action-dark hover:underline font-medium cursor-pointer' onClick={ _ => openIntercomArticle('9005081') } role='presentation'> &lt;Site Administrator Privacy Policy&gt;</span> and all other operating rules and policies that are incorporated herein, constitutes the entire agreement between you and {getSiteNameAbbreviatur()} concerning the subject matter hereof and supersedes any and all prior agreements, negotiations, correspondence, understandings and communications between the you and {getSiteNameAbbreviatur()}, whether written or oral, respecting the subject matter hereof.</p><br />
               <p><b>23.10 WAIVER & SEVERABILITY:</b> Failure by {getSiteNameAbbreviatur()} to exercise or enforce any of its rights under this Agreement shall not constitute a waiver of such rights. If any part of this Agreement is held to be unlawful, void, or unenforceable, that part shall be deemed severed and shall not affect the validity and enforceability of the remaining provisions hereof.</p><br />
               <p><b>23.11 QUESTIONS/CONTACT:</b> If you have any questions about this Agreement or if you would like to provide Feedback, please contact {getSiteNameAbbreviatur()} at: <a href={ `mailto:${ getSupportEmail() }` } className='text-action hover:underline'><span dangerouslySetInnerHTML={ { __html: getSupportEmailText() } } /></a>.</p><br />
            </div>
            <p>Last Updated: November 5, 2021</p>
            <p>v3.7</p>
            <br />
            <br />
            <br />
         </div>
         {!!openModal && <SecondModal
            title={ openModal }
            type='admin'
            onClose={ () => setOpenModal('') }
         />}
      </>
   ) };

export default SitesAdministratorAgreement;
