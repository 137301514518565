export const FETCH_REQUEST = "myMedia/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "myMedia/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "myMedia/FETCH_DETAILS_FAILED";

export const FETCH_BY_FILTER_REQUEST = "myMedia/FETCH_BY_FILTER_REQUEST";
export const FETCH_BY_FILTER_DETAILS_COMPLETED = "myMedia/FETCH_BY_FILTER_DETAILS_COMPLETED";
export const FETCH_BY_FILTER_DETAILS_FAILED = "myUnlmyMediaocks/FETCH_BY_FILTER_DETAILS_FAILED";

export const FETCH_NEXT_DATA_REQUEST = "myMedia/FETCH_NEXT_DATA_REQUEST";
export const FETCH_NEXT_DATA_COMPLETED = "myMedia/FETCH_NEXT_DATA_COMPLETED";
export const FETCH_NEXT_DATA_FAILED = "myMedia/FETCH_NEXT_DATA_FAILED";
export const TOGGLE_LIKE = "myMedia/TOGGLE_LIKE";
export const ON_CHANGE_FAVORITE_ITEM_COMPLETED = "myMedia/ON_CHANGE_FAVORITE_ITEM_COMPLETED";
