import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modules/modal';
import './style.scss'
import Icon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';
import { getLanguages, getCurrentLanguageByValue, onTranslteLanguage } from 'common/utils/utils';
import cx from 'classnames'

const languages = getLanguages();

const SelectLanguages = ({
   screenWidth,
   translteLanguage,
   onUpdateLanguage,
}) => {
   const hasElement = Boolean(document.querySelector('#google_translate_element select'));
   const [animate, setAnimate] = useState('up');
   const [openSelectItems, setOpenSelectItems] = useState(false);
   const [language, setLanguage] = useState('Select');
   const [selectDisabeld, setSelectDisabeld] = useState(false);


   let timerFetching = null;
   let languagesRef = createRef();

   const getCurrentLanguages = (bool) => {
      let defValue = document.documentElement.getAttribute('lang');
      let result = document.cookie.match("(^|[^;]+)\\s*googtrans\\s*=\\s*([^;]+)");
      if(bool && result){
         defValue = result.pop().replace('/en/', '');
      }
      let currentName = getCurrentLanguageByValue(defValue);
      return currentName;
   }

   useEffect(() => {
      if(hasElement){
         const value = getCurrentLanguages(true);
         setLanguage(value)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if(screenWidth < 700){
         return
      }
      document.addEventListener('mousedown', (e) => handleClickOutside(e));
      return () => {
         clearTimeout(timerFetching);
         if(screenWidth > 701){
            document.removeEventListener('mousedown', (e) => handleClickOutside(e));
         }
      };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   function handleClickOutside(event) {
      if(languagesRef.current && !languagesRef.current.contains(event.target)) {
         setOpenSelectItems(!openSelectItems)
      }
      if(!event.target.closest(`#languag-dropdown`)) {
         setOpenSelectItems(false)
      }
   }

   const onSelectLanguages = (value, bool = true) => {
      let element = document.querySelector('#google_translate_element .goog-te-combo');
      if(element){
         setSelectDisabeld(true);
         onTranslteLanguage(value)
         if(!element.value) {
            onTranslteLanguage(value)
         }
         let currentLanguage = getCurrentLanguageByValue(element.value);
         if(currentLanguage === 'Select' && value) {
            currentLanguage = getCurrentLanguageByValue(value)
         }
         setLanguage(currentLanguage)
         if(onUpdateLanguage && bool){
            onUpdateLanguage(value)
         }
         if(screenWidth < 701) {
            onCloseContent()
         } else {
            setOpenSelectItems(false)
         }
         setSelectDisabeld(false);
      }

   }

   const onCloseContent = () => {
      setAnimate('down')
      timerFetching = setTimeout(() => {
         setOpenSelectItems(false)
         setAnimate('up')
      }, 800)
   }

   if(!hasElement) return null;
   const view = (
      <div className='flex flex-col py-1 ' id='language'>
         <ul>
            {
               languages.map(((item, index) => {
                  return (
                     <li
                        data-google-lang={ item.value }
                        lang={ item.value }
                        role='presentation'
                        onClick={ (e) => {
                           onSelectLanguages(item.value, true)
                        } }
                        key={ index.toString() }
                        className={ cx({
                           'flex py-2.5 px-4 cursor-pointer notranslate dark:text-major-dark dark:hover:bg-hover-dark hover:bg-hover text-major leading-5': true,
                           'bg-hover dark:bg-hover-dark': item.name === language,
                        }) }>
                        {item.name}
                     </li>
                  )
               }))
            }
         </ul>
      </div>
   )

   return (
      <div
         className='mx-auto mt-4 mb-6 w-[150px]'
      >
         <div
            ref={ languagesRef }
            className='flex ams-select-languages relative justify-center'
            id='languag-dropdown'
         >
            <div
               className={ `flex h-10 items-center px-4 py-2.5 relative cursor-pointer select-none hover:bg-hover dark:hover:bg-hover-dark bg-panel dark:bg-panel-dark border dark:border-divider-dark border-divider rounded-lg ${ selectDisabeld ? 'opacity-50' : '' } w-[150px]` }
               role='presentation'
               onClick={ selectDisabeld ? null : () => {
                  setOpenSelectItems(!openSelectItems)
               } }
            >
               <div
                  id='language-current-value'
                  className='w-full h-full absolute top-0 left-0'

               >

               </div>
               <div className='flex flex-1'>
                  <span
                     className='text-major leading-tight notranslate dark:text-major-dark'
                  >
                     {language}
                  </span>

               </div>
               <div className='h-6 w-6 flex items-center justify-center ml-2'>
                  <Icon
                     name={ `arrow-${ openSelectItems ? 'up' : 'down' }` }
                     size='xl'
                     color='secondary'
                     darkColor='secondary-dark'
                  />
               </div>
            </div>
            {
               openSelectItems && (
                  <>
                     {
                        screenWidth < 701 && (
                           <Modal
                              onClose={ onCloseContent }
                              roundedModal='lg'
                              animate={ animate }
                           >
                              <div className='flex px-3 py-4 border-b dark:border-divider-dark border-divider items-centar'>
                                 <div className='h-6 w-6 mr-4 '>
                                    <Button
                                       iconName='close'
                                       backgroundColor='trasnparent'
                                       onClick={ onCloseContent }
                                       classNames='h-full'
                                       padding='none'
                                       fontIconColor='major'
                                       darkIconcolor='major-dark'
                                    />
                                 </div>
                                 <span className='leading-tight text-major dark:text-major-dark'>Select language </span>
                              </div>
                              {view}

                           </Modal>

                        )
                     }
                     {
                        screenWidth > 700 && (
                           <div
                              className='absolute desktop-languages-content border border-divider dark:border-divider-dark bg-panel dark:bg-panel-dark shadow dark:shadow-dark'>
                              {view}
                           </div>
                        )
                     }
                  </>
               )
            }

         </div>
      </div>
   )
};
SelectLanguages.propTypes = {
   screenWidth: PropTypes.number,
   translteLanguage: PropTypes.string,
   onUpdateLanguage: PropTypes.func,
}
export default SelectLanguages;
