import * as types from "./types";

export const fetchInitRequest = (data) => {
   return {
      type: types.FETCH_INIT_REQUEST,
      payload: data,
   }
}
export const fetchInitCompleted = (data, initiator) => {
   return {
      type: types.FETCH_INIT_COMPLETED,
      payload: { data, initiator },
   }
}

// export const updateDataCompleted = (data) => {
//    return {
//       type: types.UPDATE_DATA_COMPLETED,
//       payload: data,
//    }
// }
//////////////
export const fetchLiveStreamMessagesRequest = bool => {
   return {
      type: types.FETCH_LIVE_STREAM_MESSAGES,
      payload: bool,
   }
}
export const fetchLiveStreamMessagesRequestCompleted = data => {
   return {
      type: types.FETCH_LIVE_STREAM_MESSAGES_COMPLETED,
      payload: data,
   }
}
export const fetchNewMessagesRequest = bool => {
   return {
      type: types.FETCH_LIVE_STREAM_NEW_MESSAGES,
      payload: bool,
   }
}
export const fetchNewMessagesCompleted = data => {
   return {
      type: types.FETCH_LIVE_STREAM_NEW_MESSAGES_COMPLETED,
      payload: data,
   }
}
export const fetchLikedMemberByMessageCompleted = data => {
   return {
      type: types.FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED,
      payload: data,
   }
}
export const fetchLikedMemberByMessageRequest = bool => {
   return {
      type: types.FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST,
      payload: bool,
   }
}

//////////////////

export const setLiveStreamConversationState = data => {
   return {
      type: types.SET_LIVE_STREAM_CONVERSATION_STATE,
      payload: data,
   }
}
export const addNewMessage = (data, info) => {
   return {
      type: types.ADD_MESSAGE,
      payload: { data, info },
   }
}
export const updateMessage = data => {
   return {
      type: types.UPDATE_MESSAGE,
      payload: data,
   }
}
export const setTyperAction = (typers) => ({
   type: types.SET_TYPER_ACTION,
   payload: { typers },
});

export const deleteMessageAction = (id) => ({
   type: types.DELETE_MESSAGE_ACTION,
   payload: id,
});

export const likeMessageAction = data => ({
   type: types.LIKE_MESSAGE_ACTION,
   payload: data,
});

export const setIsConnectedAction = bool => ({
   type: types.SET_IS_CONNECTED_ACTION,
   payload: bool,
});

export const setOnlineUsersAction = (data, action) => ({
   type: types.SET_ONLINE_USERS_ACTION,
   payload: { data, action },
});

export const setStreamConversationInfo = data => ({
   type: types.SET_STREAM_CONVERASTION_INFO,
   payload: data,
});
export const setOpenActiveUpsellModal = bool => ({
   type: types.SET_OPEN_ACTIVE_UPSELL_MODAL,
   payload: bool,
});
export const setOpenEndedModal = bool => ({
   type: types.SET_OPEN_ENDED_MODAL,
   payload: bool,
});
export const setLottieModalAction = bool => ({
   type: types.SET_LOTTIE_MODAL,
   payload: bool,
});
export const setOfflineStateAction = data => ({
   type: types.SET_OFFLINE_STATE,
   payload: data,
});
export const seTipGoalAchievedModal = bool => ({
   type: types.SET_TIP_GOALL_ACHIEVED_MODAL,
   payload: bool,
});
export const seTipActionsGoalAchievedModal = bool => ({
   type: types.SET_TIP_ACTIONS_GOALL_ACHIEVED_MODAL,
   payload: bool,
});
export const fetchRecordsInitRequest = (bool, isByFilter = false) => {
   return {
      type: types.FETCH_RECORDS_REQUEST,
      payload: { bool, isByFilter },
   }
}
export const fetchRecordsCompleted = (data, isByFilter = false) => {
   return {
      type: types.FETCH_RECORDS_COMPLETED,
      payload: { data, isByFilter },
   }
}
export const fetchNextRecordsRequest = bool => {
   return {
      type: types.FETCH_NEX_RECORDS_REQUEST,
      payload: bool,
   }
}
export const fetchNextRecordsCompleted = (data) => {
   return {
      type: types.FETCH_NEX_RECORDS_COMPLETED,
      payload: data,
   }
}
export const updateRecordoByIdAction = (data, id) => {
   return {
      type: types.UPDATE_RECORD_BY_ID,
      payload: { data, id },
   }
}
export const liveStreamLikeRequest = bool => {
   return {
      type: types.LIVE_STREAM_LIKE_REQUEST,
      payload: bool,
   }
}
export const liveStreamLikeCompleted = _ => {
   return {
      type: types.LIVE_STREAM_LIKE_COMPLETED,
   }
}
export const setWatchingUsers = data => {
   return {
      type: types.SET_WARCHING_USERS,
      payload: data,
   }
}
export const updateInitedData = data => {
   return {
      type: types.UPDATE_INITED_DATA,
      payload: data,
   }
}
export const likeRecordStream = data => {
   return {
      type: types.LIVE_RECORD_STREAM,
      payload: data,
   }
}

export const toggleWatchedCompleted = id => {
   return {
      type: types.TOGGLE_WATCHED_COMPLETED,
      payload: id,
   }
}

export const updateDataAfterPurchaseCompleted = (data) => {
   return {
      type: types.UPDATE_DATA_AFTER_PURCHASE_COMPLETED,
      payload: data,
   }
}

export const onChangeFavoriteItemCompleted = (id, isDetailsPage) => ({
   type: types.ON_CHANGE_FAVORITE_ITEM_COMPLETED,
   payload: { id, isDetailsPage },
})
