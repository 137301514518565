const initialState = {
   isFetching: true,
   isFetchingByFilter: false,
   isNextFetching: false,
   isEmpty: false,
   isEmptyByFilter: false,
   videosData: {},
   activeVideo: {},
   isFetchingById: true,

   errors: null,
   downloadButtonLoading: false,
   isInited: false,
   filters: {},
   pricingUpgradeList: [],
};

export default initialState;
