const initialState = {
   isFetching: true,
   isFetchingByFilter: false,
   isNextFetching: false,
   isEmpty: true,
   isEmptyByFilter: false,
   isFetchingById: true,
   emptyData: false,
   favoritesData: {},
   categories: [],
   inited: false,
   filters: {},
};

export default initialState;
