export const FETCH_REQUEST = "photosets/FETCH_REQUEST";
export const FETCH_DETAILS_COMPLETED = "photosets/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "photosets/FETCH_DETAILS_FAILED";

export const FETCH_BY_FILTER_REQUEST = "photosets/FETCH_BY_FILTER_REQUEST";
export const FETCH_BY_FILTER_DETAILS_COMPLETED = "photosets/FETCH_BY_FILTER_DETAILS_COMPLETED";
export const FETCH_BY_FILTER_DETAILS_FAILED = "photosets/FETCH_BY_FILTER_DETAILS_FAILED";

export const FETCH_PHOTOSET_BY_ID_REQUEST = 'photosets/FETCH_PHOTOSET_BY_ID_REQUEST';
export const FETCH_PHOTOSET_BY_ID_COMPLETED = 'photosets/FETCH_PHOTOSET_BY_ID_COMPLETED';
export const FETCH_PHOTOSET_BY_ID_FAILED = 'photosets/FETCH_PHOTOSET_BY_ID_FAILED';


export const FETCH_NEXT_PHOTOSETS_REQUEST = 'photosets/FETCH_NEXT_PHOTOSETS_REQUEST';
export const FETCH_NEXT_PHOTOSETS_COMPLETED = 'photosets/FETCH_NEXT_PHOTOSETS_COMPLETED';
export const FETCH_NEXT_PHOTOSETS_FAILED = 'photosets/FETCH_NEXT_PHOTOSETS_FAILED';

export const TOGGLE_LIKE_COMPLETED = 'photosets/TOGGLE_LIKE_COMPLETED';
export const SET_FILTERS = 'photosets/SET_FILTERS';
export const CLEAR_STATE = 'photosets/CLEAR_STATE';
export const UPDATE_PHOTOSET_BY_ID = 'photosets/UPDATE_PHOTOSET_BY_ID';

export const TOGGLE_WATCHED_COMPLETED = 'photosets/TOGGLE_WATCHED_COMPLETED';
export const RESET_ACTIVE_PHOTOSET = 'photosets/RESET_ACTIVE_PHOTOSET';

export const UPDATE_DATA_AFTER_PURCHASE_COMPLETED = 'photosets/UPDATE_DATA_AFTER_PURCHASE_COMPLETED';

export const SET_IS_INITED_STATE = 'photosets/SET_IS_INITED_STATE';
export const ON_CHANGE_FAVORITE_ITEM_COMPLETED = "photosets/ON_CHANGE_FAVORITE_ITEM_COMPLETED";
