import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.FETCH_WALLET_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_WALLET_COMPLETED]: (state, action) => {

      return {
         ...state,
         amountData: action.payload,
         isFetching: false,
      }
   },
   [types.FETCH_WALLET_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.FETCH_WALLET_PAYMENT_HISTORY_START]: (state) => {
      return {
         ...state,
         isFetchingHistory: true,
      }
   },
   [types.FETCH_WALLET_PAYMENT_HISTORY_COMPLETED]: (state, action) => {

      return {
         ...state,
         history: action.payload,
         isEmptyHistory: action.payload.data.length === 0,
         isFetchingHistory: false,
      }
   },
   [types.FETCH_WALLET_PAYMENT_HISTORY_FAILED]: (state) => {
      return {
         ...state,
         isFetchingHistory: false,
      }
   },

   [types.FETCH_NEXT_WALLET_PAYMENT_HISTORY_START]: (state) => {
      return {
         ...state,
         isNextFetching: true,
      }
   },
   [types.FETCH_NEXT_WALLET_PAYMENT_HISTORY_COMPLETED]: (state, action) => {
      const  { payload } = action;
      return {
         ...state,
         isNextFetching: false,
         history: {
            ...payload,
            data: [...state.history.data, ...payload.data],
         },
      }
   },
   [types.FETCH_NEXT_WALLET_PAYMENT_HISTORY_FAILED]: (state) => {
      return {
         ...state,
         isNextFetching: false,
      }
   },

   [types.WALLET_PURCHASE_START]: (state) => {
      return {
         ...state,
         purchaseStatus: 'progress',
      }
   },
   [types.WALLET_PURCHASE_COMPLETED]: (state, action) => {
      return {
         ...state,
         purchaseStatus: 'success',
      }
   },
   [types.WALLET_PURCHASE_START_FAILED]: (state) => {
      return {
         ...state,
         purchaseStatus: 'error',
      }
   },

   [types.CHECK_WALLET_LIMIT_START]: (state) => {
      return {
         ...state,
         walletLimitModalData: {
            ...state.walletLimitModalData,
            isFetching: true,
         },
      }
   },
   [types.CHECK_WALLET_LIMIT_COMPLETED]: (state, action) => {
      const { payload } = action;
      let walletLimitModalData = {
         isLimitModal: false,
         isFetching: false,
         header: '',
         body: '',
      }
      if(+payload.status === 406) {
         walletLimitModalData = {
            ...walletLimitModalData,
            isLimitModal: true,
            header: payload.data.header,
            body: payload.data.body,
         }
      }
      return {
         ...state,
         walletLimitModalData: walletLimitModalData,
      }
   },
   [types.CHECK_WALLET_LIMIT_FAILED]: (state, action) => {
      const { payload } = action;
      const walletLimitModalData = {
         ...state.walletLimitModalData,
         isLimitModal: true,
         isFetching: false,
         header: payload.data.header,
         body: payload.data.body,
      }
      return {
         ...state,
         walletLimitModalData: walletLimitModalData,
      }
   },

   [types.SET_WALLET_LIMIT_MODAL_STATE]: (state, action) => {
      const { payload } = action;
      let  walletLimitModalData = {
         ...state.walletLimitModalData,
         ...payload,
      }
      return {
         ...state,
         walletLimitModalData: walletLimitModalData,
      }
   },

   [types.SET_WALLET_PURCHASE_STATUS]: (state, action) => {
      return {
         ...state,
         purchaseStatus: action.payload,
      }
   },
   [types.SET_MODAL_STATE]: (state, action) => {
      return {
         ...state,
         purchaseStatus: action.payload.isOpen ? state.purchaseStatus : 'default',
         modalState: {
            ...state.modalState,
            ...action.payload,
         },
      }
   },

}

export default createReducer(initialState)(reducersMap);
