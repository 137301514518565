import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.SEND_EMAIL_START]: (state) => {
      return {
         ...state,
         isFetching: true,
         hasBeenSent: false,
      }
   },
   [types.SEND_EMAIL_COMPLETED]: (state) => {
      return {
         ...state,
         hasBeenSent: true,
         isFetching: false,
      }
   },
   [types.SEND_EMAIL_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
         hasBeenSent: false,
         error: action.payload,
      }
   },
   [types.SET_HAS_BEEN_SENT]: (state, action) => {
      return {
         ...state,
         hasBeenSent: action.payload,
      }
   },
}

export default createReducer(initialState)(reducersMap);
