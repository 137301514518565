import axios from 'axios';
import Auth from 'sites/utils/Auth';
import store from 'sites/state/store';
import { push } from 'connected-react-router';
import Router from 'sites/routes/router';
import { isLocalhost } from 'sites/utils/Helpers';
import { getProtocol } from 'common/utils/utils';
import logout from 'sites/utils/logout';

let sitesUrl;
const protocol = getProtocol();

if(isLocalhost()) {
   sitesUrl = `${ protocol }${ process.env.REACT_APP_SITES_API_ENDPOINT_FOR_DEVELOPMENT }/api`;
} else {
   const subdomain = document.querySelector('meta[name=subdomain]').content;
   sitesUrl = `${ protocol }${ subdomain }.${ process.env.REACT_APP_AMS_DOMAIN }/api`;
}

const amsUrl = `${ protocol }/${ process.env.REACT_APP_AMS_DOMAIN }`;
// instance for auth requests
const client = axios.create();
let controller = null;

client.interceptors.request.use((config) => {
   const result = Object.assign({}, config);
   result.headers.Authorization = `Bearer ${ Auth.getToken() }`;

   return config;
}, (error) => {
   return Promise.reject(error);
});

client.interceptors.response.use(
   response => {
      if(response.status === 200){
         window.isBlocked = false;
         window.isVpnBlocked = false;
      }
      if(response.request.responseURL.includes('/api/auth/init') && response.status === 200){
         window.blockedAdditionalData = null
      }
      return response;
   },
   error => {
      if(error.response) {
         switch (error.response.status) {
            case 401: {
               store.dispatch(logout(store.dispatch, false, null, null, true, 'SIGN_IN'))
               break
            }
            case 403: {
               const { response } = error
               if(response && response.data) {
                  ForbiddenError(response.data)
               }
               break;
            }
            default:{}
         }
      }
      throw error;
   }
);

function ForbiddenError(data) {
   const { error } = data
   switch (error) {
      case 'profile_blocked':{
         window.memberBanned = true;
         window.isBlocked = true
         const { data: { additional_info } } = data
         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(logout(store.dispatch, false, null, null, true, 'ERROR_PAGE'))
         break
      }
      case 'ip_blocked':{
         window.isBlocked = true;
         const { data: { ip, additional_info } } = data

         let providerQuery = `?ip=${ ip }`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break

      }
      case 'vpn_blocked':{
         window.isBlocked = true;
         window.isVpnBlocked = true;
         const { data: { ip, additional_info  } } = data

         let providerQuery = `?ip=${ ip }&vpn_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      case 'country_blocked':{
         window.isBlocked = true;
         window.isCountryBlocked = true;
         const { data: { ip, additional_info  } } = data

         let providerQuery = `?ip=${ ip }&country_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      case 'region_blocked':{
         window.isBlocked = true;
         window.isRegionBlocked = true;
         const { data: { ip, additional_info  } } = data


         let providerQuery = `?ip=${ ip }&region_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info }

         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      default:{}
   }
}

function cancelApiConfig(){
   let signal = {};
   if(controller){
      controller.abort();
   }
   controller = new AbortController();
   if(controller){
      signal = { signal: controller.signal };
   }
   return signal;
}

export function myAccountInfo() {
   return client.get(`${ sitesUrl }/account/info`);
}

export function changePassword(data) {
   return client.post(`${ sitesUrl }/account/update-password`, data);
}

export function changeNewEmail(data) {
   return client.put(`${ sitesUrl }/account/email`, data);
}
export function memberToggleNotifications(data) {
   return client.post(`${ sitesUrl }/account/toggle-notifications`, data);
}
export function uploadMemberAvatar(file) {
   return client.post(`${ amsUrl }/image-uploader/member-avatar-image-upload`, file);
}

export function updateAccoountProfile(data) {
   return client.put(`${ sitesUrl }/account/avatar`, data);
}
export function updateAccountUsername(data) {
   return client.put(`${ sitesUrl }/account/username`, data);
}
export function cancelSubscription() {
   return client.post(`${ sitesUrl }/account/cancel-subscription`);
}
export function deleteMemberAccount() {
   return client.delete(`${ sitesUrl }/account/delete`);
}
export function updateTranslteLanguage(data) {
   return client.put(`${ sitesUrl }/account/translte-language`, data);
}
export function initMemberships(params = '') {
   return client.get(`${ sitesUrl }/membership-pricings${ params && '?' }${ params }`);
}

export function initMembershipById(id, params = '') {
   return client.get(`${ sitesUrl }/membership-pricings/${ id }${ params && '?' }${ params }`);
}

export function getMembershipUpgradeInfoById(id) {
   return client.get(`${ sitesUrl }/account/upgrade-membership-payment-info/${ id }`);
}
export function getNotificationPreferences() {
   return client.get(`${ sitesUrl }/account/notification-preferences`);
}
export function updateNotificationPreferences(data) {
   return client.post(`${ sitesUrl }/account/notification-preferences`, data);
}
export function unsubscribeAllNotificationPreferences() {
   return client.post(`${ sitesUrl }/account/notification-preferences/unsubscribe-all`);
}

export function purchaseMembershipUpgrade(data) {
   return client.post(`${ sitesUrl }/account/upgrade-membership`, data);
}

export function getMembershipUpgradeInfo() {
   return client.get(`${ sitesUrl }/account/upgrade=true`);
}

export function initMyDownloads(params, canceled) {
   let config = {};
   if(canceled){
      config = cancelApiConfig();
   }
   return client.get(`${ sitesUrl }/my-downloads?${ params.join('&') }`, config);
}
export function downloadMedia(id, type, payload = null) {
   return client.post(`${ sitesUrl }/${ type }/${ id }/download`, payload);
}
export function sendContactUsMessage(data) {
   return client.post(`${ sitesUrl }/contact-us/send-message`, data);
}
export function initCandidatePayment(id, type) {
   if(!id){
      return client.get(`${ sitesUrl }/${ type }/candidate-payment-options`);
   }
   return client.get(`${ sitesUrl }/${ type }/${ id }/candidate-payment-options`);
}
export function paymentPurchase(data) {
   return client.post(`${ sitesUrl }/payments/purchase`, data);
}
export function initPaymentDeclined(token) {
   return client.get(`${ sitesUrl }/payments/payment-declined/${ token }`);
}
export function getPaymentApproved(token) {
   return client.get(`${ sitesUrl }/payments/payment-approved/${ token }`);
}

export function oneClickPurchase(data) {
   return client.post(`${ sitesUrl }/payments/one-click-purchase`, data);
}
// start chat api
export function chatInit() {
   return client.get(`${ sitesUrl }/chat/init`);
}
export function chatSettingsInfo() {
   return client.get(`${ sitesUrl }/chat/settings-info`);
}
export function fetchMessages(id, page = 1) {
   return client.get(`${ sitesUrl }/chat/conversations/${ id }/messages?page=${ page }`);
}
export function messageLikedMembers(id, page = 1) {
   return client.get(`${ sitesUrl }/chat/messages/${ id }/liked-members?page=${ page }`);
}
export function createPrivateMessage(data) {
   return client.post(`${ sitesUrl }/chat/conversations/create-private`, data);
}
export function getChatMessage(id) {
   return client.get(`${ sitesUrl }/chat/messages/${ id }`);
}
export function chatAvailablePrices() {
   return client.get(`${ sitesUrl }/chat/available-prices`);
}
export function openClosedPrivateConversation(conversationId) {
   return client.get(`${ sitesUrl }/chat/conversation/${ conversationId }/open`);
}

// mass message

export function clickedInChat(){
   return client.get(`${ sitesUrl }/chat/is-clicked-in-chat`)
}

// start wallet api
export function initWallet() {
   return client.get(`${ sitesUrl }/wallet`);
}
export function initWalletPaymentHistory(params = []) {
   return client.get(`${ sitesUrl }/wallet/history?${ params.join('&') } `);
}

export function getWalletBalance() {
   return client.get(`${ sitesUrl }/wallet/get-balance`);
}

export function checkWalletLimit() {
   return client.post(`${ sitesUrl }/wallet/check-limit`);
}

export function paymentPurchaseUsingWallet(data) {
   return client.post(`${ sitesUrl }/payments/purchase-using-wallet`, data);
}

export function getPurchaseMethods(type, resourceId, query = '') {
   if(!resourceId){
      return client.get(`${ sitesUrl }/${ type }/purchase-methods?${ query }`);
   }
   return client.get(`${ sitesUrl }/${ type }/${ resourceId }/purchase-methods?${ query }`);
}

// start tip api
export function initTip() {
   return client.get(`${ sitesUrl }/tip/candidates`);
}
export function checkTipLimit() {
   return client.post(`${ sitesUrl }/tip/check-limit`);
}

// start media unlock api

export function initMediaUnlock(id) {
   return client.get(`${ sitesUrl }/chat/messages/${ id }`);
}
export function getChatUnlcokedItem(id) {
   return client.get(`${ sitesUrl }/chat/get-unlocked-content/${ id }`);
}
export function getChatUnlcokedItemByPathHash(id, hash) {
   return client.get(`${ sitesUrl }/chat/get-unlocked-content/${ id }/vault/${ hash }`);
}
export function getChatUnlcokedItemByResourceId(id, type, resourceId) {
   return client.get(`${ sitesUrl }/chat/get-unlocked-content/${ id }/${ type }/${ resourceId }`);
}
export function initMyUnlocks(params) {
   let config = cancelApiConfig();
   return client.get(`${ sitesUrl }/my-media?${ params.join('&') }`, config);
}
export function getBundleVaultitem(id) {
   return client.get(`${ sitesUrl }/my-media/bundle-vault/${ id }`);
}
export function getDigitalDownloadableItem(id) {
   return client.get(`${ sitesUrl }/my-media/attachments/${ id }`);
}

// start live stream api
export function getLiveStreamMessages(params = ['page=1']) {
   return client.get(`${ sitesUrl }/live-stream/chat-messages?${ params.join('&') }`);
}
export function getLiveStreamMessageLikedMembers(id) {
   return client.get(`${ sitesUrl }/live-stream/chat-messages/${ id }/liked-members`);
}
export function likeStreamLike() {
   return client.post(`${ sitesUrl }/live-stream/toggle-like`);
}
export function likeStreamMemberActivity() {
   return client.post(`${ sitesUrl }/live-stream/member-activity`);
}

// end live stream api

//store api start
export function getCountryInStoreChange(id, countryCode){
   return client.get(`${ sitesUrl }/products/${ id }/shipping-options/${ countryCode }`)
}

export function getAddressInStore(data){
   let config = cancelApiConfig();
   return client.post(`${ sitesUrl }/products/autocomplete`, data, config)
}

export function getItemAddressInStore(id){
   let config = cancelApiConfig();
   return client.get(`${ sitesUrl }/products/autocomplete/${ id }`, config)
}

export function storeAddressValidate(data){
   return client.post(`${ sitesUrl }/products/store-shipping-details`, data)
}

export function deleteStoreAddress(){
   return client.delete(`${ sitesUrl }/products/store-shipping-details`)
}

export function storePurchaseValidation(data){
   return client.post(`${ sitesUrl }/products/validate-product-details`, data)
}

// orders api start

export function getOrderRequests(params = []){
   let config = cancelApiConfig();
   return client.get(`${ sitesUrl }/products/orders?${ params.join('&') }`, config)
}

export function getOrderRequestById(id){
   return client.get(`${ sitesUrl }/products/orders/${ id }`)
}

export function changeOrderStatusById(id, data){
   return client.put(`${ sitesUrl }/products/orders/${ id }`, data)
}

// orders api end


//store api end

// start notification api
export function notificationsInit(query = []) {
   return client.get(`${ sitesUrl }/notifications/init?${ query.join('&') }`);
}
export function markAllAsSeenNotifications() {
   return client.post(`${ sitesUrl }/notifications/mark-all-as-seen`);
}
export function markAllAsReadNotifications(id) {
   return client.post(`${ sitesUrl }/notifications/mark-all-as-read`, { id });
}
export function markAllAsUnReadNotifications(id) {
   return client.post(`${ sitesUrl }/notifications/mark-all-as-unread`, { id });
}

// end notification api

export function uploadAudioRecord(file) {
   return client.post(`${ amsUrl }/image-uploader/upload-audio`, file);
}

export function logOutMember(preventApiCall) {
   if(preventApiCall) {
      return new Promise((resolve, reject) => {
         resolve()
      })
   } else {
      const config = cancelApiConfig();
      return client.get(`${ sitesUrl }/auth/logout`, config);
   }
}

export function getS3Policy() {
   return client.get(`${ sitesUrl }/get-s3-policy`);
}

export function getUploadedFileUrl(data) {
   return client.get(`${ sitesUrl }/get-object-url-v2?paths[]=${ data }`);
}

// Connect to mylist.site
export function connectToMylist() {
   return client.post(`${ sitesUrl }/account/connect-to-my-list`)
}
