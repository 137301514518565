import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ children, className = '' }) => {
   return (
      <div className={ `h-full ${ className } layout-content relative `  } style={ { paddingTop: '58px', zIndex: 0 } }>
         { children }
      </div>
   );
};

Content.propTypes = {
   children: PropTypes.any,
   className: PropTypes.any,
};

export default Content;
