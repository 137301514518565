// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

import { Workbox } from 'workbox-window';

import { isLocalhost } from 'sites/utils/Helpers';


export function register() {
   if('serviceWorker' in navigator === false) {
      return;
   }
   let swUrl = '/sw.js';
   let scope = '/';
   if(!isLocalhost() && 'site_name_prefix' === document.querySelector('meta[name=domainType]').content) {
      const prefix = `${ document.querySelector('meta[name=subdomain]').content }`;
      swUrl = `/${ prefix }/sw.js`;
      scope = `/${ prefix }`;
   }
   const wb = new Workbox(swUrl, {
      scope,
   });
   wb.register().then(() => {}).catch(() => {});
}

export function unregister() {
   if('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
         registration.unregister();
      });
   }
}
