import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/elements/buttons/primary';
import Modal from 'common/components/modules/modal';

const EndedModal = ({
   onEnded,
}) => {
   const [disabled, setDisabled] = useState(false);
   const onClick = () => {
      setDisabled(true);
      onEnded(() => {
         setDisabled(false);
      })
   }
   return (
      <Modal
         contentWidth='335px'
         contentPosition='center'
         // contentPosition={ isMobile ? 'bottom' : 'center' }
         // animate={ isMobile ? animate : null }
         isCloseAction={ false }
         onClose={ () => {} }
      >
         <div className='flex flex-col items-center px-5 py-5 pt-7'>
            <span
               style={ {
                  lineHeight: '24px',
               } }
               className='text-major dark:text-major-dark gont-bold text-xl'>
               Stream ended
            </span>
            <div className='h-9 mt-6'>
               <Button
                  text='Ok'
                  borderRadius='large'
                  backgroundColor='transparent'
                  textColor='secondary dark:text-secondary-dark '
                  textSize='base'
                  onClick={ onClick }
                  padding='none'
                  classNames={ `h-full w-full ${ disabled ? 'opacity-50' : '' }` }
                  disabled={ disabled }
               />
            </div>

         </div>
      </Modal>
   );
};

EndedModal.propTypes = {
   onEnded: PropTypes.func,
};

export default React.memo(EndedModal);
