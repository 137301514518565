import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';
import { isEmpty, isEqual } from "lodash";
let reducersMap = {

   [types.ADD_MESSAGE]: (state, action) => {
      const { payload } = action;
      const {
         data: { message, isMyMessage },
      } = payload;
      let newState = {};
      let user = {};
      let tipAnimation = false;
      const info = payload.info;
      if(info){
         tipAnimation = info.tipAnimation;
         user = info.user;
      }
      if(!isMyMessage) {
         let oldUnreamMessagesCount = state.streamConversation.unread_messages_count || 0
         newState = {
            streamConversation: {
               ...state.streamConversation,
               unread_messages_count: ++oldUnreamMessagesCount,
            },
         }
      }
      if(tipAnimation){
         newState = {
            showLargeTipAnimation: {
               isOpen: true,
               username: user.username,
               price: message?.text?.price,
            },
         }
      }
      return {
         ...state,
         ...newState,
         messageData: {
            ...state.messageData,
            data: [
               message,
               ...state.messageData.data,
            ],
         },
      }
   },
   [types.UPDATE_MESSAGE]: (state, action) => {
      const {
         payload: { messageId, message },
      } = action;
      let updateData = [...state.messageData.data].map(i => {
         let{ ...el } = i
         if(el.id === messageId){
            el = {
               ...el,
               ...message,
            }
         }
         return el
      })

      return {
         ...state,
         messageData: {
            ...state.messageData,
            data: updateData,
         },
      }
   },
   [types.SET_TYPER_ACTION]: (state, action) => {
      const {
         typers,
      } = action.payload;
      let newState = { ...state };
      newState = {
         ...newState,
         streamConversation: {
            ...state.streamConversation,
            typers: typers,
            user_is_typing: !isEmpty(typers),
         },
      }
      return newState;
   },

   [types.DELETE_MESSAGE_ACTION]: (state, action) => {
      const {
         payload,
      } = action;
      let updateData = [...state.messageData.data].filter(message => message.id !== payload);
      return {
         ...state,
         messageData: {
            ...state.messageData,
            data: updateData,
         },
      };
   },
   [types.LIKE_MESSAGE_ACTION]: (state, action) => {
      const {
         isLiked,
         messageId,
         isMyLiked,
      } = action.payload
      let data = [...state.messageData.data].map(elm => {
         let { ...i } = elm
         if(i.id === messageId){
            let count = elm.likes_count_from_other_users || 0
            if(isLiked) {
               count = ++count
            }
            if(!isLiked && count > 0) {
               count = --count
            }
            if(!isMyLiked) {
               i = {
                  ...i,
                  likes_count_from_other_users: count,
               }
            }
            if(isMyLiked) {
               i = {
                  ...i,
                  is_liked_by_user: isLiked,
               }
            }
         }
         return i
      })
      return {
         ...state,
         messageData: {
            ...state.messageData,
            data: data,
         },
      };
   },
   [types.SET_IS_CONNECTED_ACTION]: (state, action) => {
      return {
         ...state,
         isConnected: action.payload,
      }
   },
   [types.SET_ONLINE_USERS_ACTION]: (state, action) => {
      const {
         data, action: memberAction,
      } = action.payload;
      let users = [...state.onlineUsers]
      switch (memberAction) {
         case 'push':
            users = [...users, { ...data }]
            break;
         case 'pop':
            users = [...users].filter(item => item.uuid !== data.uuid)
            break;

         default:
            users = [...data]
            break;
      }
      return {
         ...state,
         onlineUsers: users,
      };
   },

   //////////////////////////////////
   [types.FETCH_INIT_REQUEST]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         isFetching: payload,
      };
   },
   [types.FETCH_INIT_COMPLETED]: (state, action) => {
      const {
         data,
         // socketEmitData,
         initiator,
      } = action.payload;
      let updateState = {};
      const oldData = state.initData
      // if(!!socketEmitData?.is_update){
      for(const key in data) {
         if(Object.hasOwnProperty.call(data, key)) {

            const newValue = data[key];
            const oldValue = state.initData[key];
            if(key === 'cover_full_src'){
               continue;
            }
            if(newValue !== oldValue){
               if(key === 'cover'){
                  updateState = {
                     ...updateState,
                     cover_full_src: data.cover_full_src,
                     [key]: newValue,
                  }
               } else if('object' !== typeof newValue){
                  updateState = {
                     ...updateState,
                     [key]: newValue,
                  }
               } else {
                  if(!isEqual(newValue, oldValue)){
                     updateState = {
                        ...updateState,
                        [key]: newValue,
                     }
                  }
               }

            }

         }
      }

      let achievedState = {}
      if(initiator === 'tip') {
         const {
            tip_goal_price,
            total_tipped,
            tip_goal_is_on,
            show_tip_goal_to_member,

            // tip_actions_data,
         } = data.tip_goal;
         const { tip_actions_data } = data
         if((oldData && oldData.tip_goal.total_tipped < tip_goal_price) && (total_tipped >= tip_goal_price)){
            achievedState = {
               ...achievedState,
               tipGoalAchievedModal: show_tip_goal_to_member && tip_goal_is_on,
            }
         }
         const achievedActions = [];
         tip_actions_data.forEach((element) => {
            const oldAction = oldData.tip_actions_data.find(el => el.id === element.id);
            const { total, action_goal_price, action_goal_is_on } = element;
            if(action_goal_is_on && (oldAction && +oldAction.total < +action_goal_price) && (+total >= +action_goal_price)){
               achievedActions.push(element)
            }
         });

         if(achievedActions.length > 0){
            achievedState = {
               ...achievedState,
               tipGoalActionsAchievedModal: { isOpen: true, actions: achievedActions },
            }
         }
      }

      // } else {
      //    updateState = {
      //       ...data,
      //    }
      // }
      return {
         ...state,
         initData: {
            ...state.initData,
            ...updateState,
         },
         ...achievedState,
         isFetching: false,
      };
   },

   [types.FETCH_LIVE_STREAM_MESSAGES]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         isFetchingMessages: payload,
      };
   },
   [types.FETCH_LIVE_STREAM_MESSAGES_COMPLETED]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         messageData: payload,
         isFetchingMessages: false,
      };
   },
   [types.FETCH_LIVE_STREAM_NEW_MESSAGES]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         isFetchNewMessages: payload,
      };
   },
   [types.FETCH_LIVE_STREAM_NEW_MESSAGES_COMPLETED]: (state, action) => {
      const {
         payload,
      } = action;
      return {
         ...state,
         messageData: {
            ...payload,
            data: [...state.messageData.data, ...payload.data],
         },
         isFetchNewMessages: false,
      };
   },
   [types.FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST]: (state, action) => {
      let newData = {}
      if(!!action.payload) {
         newData = {
            fetchingScroll: false,
         }
      } else {
         newData = {
            fetching: false,
         }
      }
      return {
         ...state,
         likedMemberByMessage: {
            ...newData,
            data: [],
         },
      };
   },
   [types.FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED]: (state, action) => {
      const {
         data,
         isPagination,
      } = action.payload

      let newData = {
         ...data,
      }
      if(isPagination) {
         newData = {
            ...newData,
            fetchingScroll: false,
         }
      } else {
         newData = {
            ...newData,
            fetching: false,
         }
      }
      return {
         ...state,
         likedMemberByMessage: {
            ...newData,
         },
      };
   },

   [types.SET_STREAM_CONVERASTION_INFO]: (state, action) => {
      return {
         ...state,
         streamConversation: {
            ...state.streamConversation,
            ...action.payload,
         },
      };
   },
   [types.SET_OPEN_ACTIVE_UPSELL_MODAL]: (state, action) => {
      return {
         ...state,
         isOpenActiveUpsellModal: action.payload,
      };
   },
   [types.SET_LOTTIE_MODAL]: (state, action) => {
      return {
         ...state,
         showLargeTipAnimation: action.payload,
      };
   },
   [types.SET_OPEN_ENDED_MODAL]: (state, action) => {
      return {
         ...state,
         openEndedModal: action.payload,
      };
   },
   [types.SET_OFFLINE_STATE]: (state, action) => {
      return {
         ...state,
         offlineState: {
            ...action.offlineState,
            ...action.payload,
         },
      };
   },
   [types.SET_TIP_GOALL_ACHIEVED_MODAL]: (state, action) => {
      return {
         ...state,
         tipGoalAchievedModal: !!action.payload,
      };
   },
   [types.SET_TIP_ACTIONS_GOALL_ACHIEVED_MODAL]: (state, action) => {
      return {
         ...state,
         tipGoalActionsAchievedModal: {
            ...state.tipGoalActionsAchievedModal,
            ...action.payload,
         },
      };
   },
   [types.FETCH_RECORDS_REQUEST]: (state, action) => {
      const {
         bool,
         isByFilter,
      } = action.payload;
      let newState = {};
      if(isByFilter){
         newState = {
            isFetchingRecordFilter: bool,
         }
      } else {
         newState = {
            isFetchingRecord: bool,
         }
      }
      return {
         ...state,
         ...newState,
         isEmptyByFilterRecord: false,
         isEmptyRecord: false,
      };
   },
   [types.FETCH_RECORDS_COMPLETED]: (state, action) => {
      const {
         data,
         isByFilter,
      } = action.payload;
      let newState = {};
      if(isByFilter){
         newState = {
            isEmptyByFilterRecord: data?.data && data.data.length === 0,
            isEmptyRecord: false,
         }
      } else {
         newState = {
            isEmptyByFilterRecord: false,
            isEmptyRecord: data?.data && data.data.length === 0,
         }
      }
      return {
         ...state,
         ...newState,
         isFetchingRecordFilter: false,
         isFetchingRecord: false,
         recordedStreams: data,
      };
   },
   [types.FETCH_NEX_RECORDS_REQUEST]: (state, action) => {
      return {
         ...state,
         isNewFetchingRecords: action.payload,
      };
   },
   [types.FETCH_NEX_RECORDS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isNewFetchingRecords: false,
         recordedStreams: {
            ...action.payload,
            data: [
               ...action.payload.data,
               ...state.recordedStreams.data,
            ],
         },
      };
   },

   [types.UPDATE_RECORD_BY_ID]: (state, action) => {
      const { data, id } = action.payload;
      return {
         ...state,
         recordedStreams: {
            ...state.recordedStreams,
            data: [...state.recordedStreams.data].map(el => {
               let { ...item } = el
               if(id === item.id) item = { ...item, ...data }
               return item
            }),
         },
      }
   },
   [types.LIVE_STREAM_LIKE_REQUEST]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         disabledLikeButton: !!payload,
      }
   },
   [types.LIVE_STREAM_LIKE_COMPLETED]: (state, action) => {
      let isliked = !state.initData.is_livestream_liked;
      return {
         ...state,
         initData: {
            ...state.initData,
            is_livestream_liked: isliked,
         },
         disabledLikeButton: false,
      }
   },
   [types.UPDATE_INITED_DATA]: (state, action) => {
      return {
         ...state,
         initData: {
            ...state.initData,
            ...action.payload,
         },
      }
   },
   [types.SET_WARCHING_USERS]: (state, action) => {
      return {
         ...state,
         watchingUsers: action.payload,
      }
   },

   [types.LIVE_RECORD_STREAM]: (state, action) => {
      const  { payload } = action;
      const data = state.recordedStreams.data || []
      const findIndex = data.findIndex(story => story.id === payload);
      if(findIndex >= 0){
         data[findIndex].is_liked = !data[findIndex].is_liked;
      }
      return {
         ...state,
         recordedStreams: {
            ...state.recordedStreams,
            data: [...data],
         },
      }
   },
   [types.TOGGLE_WATCHED_COMPLETED]: (state, action) => {
      const id = action.payload;
      const data = state.recordedStreams.data || []
      const findIndex = data.findIndex(vid => vid.id === id);
      if(findIndex !== -1) {
         data[findIndex].watched = !data[findIndex].watched
         data[findIndex].new = false
      }
      return {
         ...state,
         recordedStreams: {
            ...state.recordedStreams,
            data: [...data],
         },
      }
   },
   [types.UPDATE_DATA_AFTER_PURCHASE_COMPLETED]: (state, action) => {
      const updatableData = action.payload;
      updatableData.watched = true;
      updatableData.new = false;
      let newData = []
      if(state.recordedStreams?.data) {
         newData = state.recordedStreams.data.map(item => {
            if(item.id === updatableData.id) {
               return { ...updatableData }
            }
            return item
         })
      }
      return {
         ...state,
         recordedStreams: {
            ...state.recordedStreams,
            data: newData,
         },
      }
   },
   [types.ON_CHANGE_FAVORITE_ITEM_COMPLETED]: (state, action) => {
      const  { payload: { id } } = action;
      let tempData = state.recordedStreams.data || []
      // let activeVideo = state?.activeVideo

      // if(isDetailsPage) {
      //    activeVideo = {
      //       ...activeVideo,
      //       is_favorite: !activeVideo.is_favorite,
      //    }
      // }

      if(!!tempData?.length) {
         const findIndex = tempData.findIndex(item => item.id === id)
         if(findIndex >= 0){
            tempData[findIndex].is_favorite = !tempData[findIndex].is_favorite
         }
      }

      return {
         ...state,
         recordedStreams: {
            ...state.recordedStreams,
            data: tempData,
         },
         // activeVideo,
      }
   },
}

export default createReducer(initialState)(reducersMap);
