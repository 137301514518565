import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import Menu from './menu';
import Content from './content';
import LoginContent from './login-content';
import InformationAndPoliciesLayoutContent from './InformationAndPolicies';

class Layout extends Component {

   static propTypes = {
      children: PropTypes.any,
   };

   static Header = Header;

   static Menu = Menu;

   static Content = Content;

   static LoginContent = LoginContent;

   static InformationAndPoliciesLayoutContent = InformationAndPoliciesLayoutContent;

   render() {
      const {
         children,
      } = this.props;
      return (
         <div className='layout min-h-full flex bg-[#F9FAFB] dark:bg-[#000000]'>
            { children }
         </div>
      );
   }
}

export {
   Header,
   Content,
   Menu,
   LoginContent,
   InformationAndPoliciesLayoutContent,
};
export default Layout;
