
import queryString from 'query-string';
import { history } from 'sites/state/store';
import { isEmpty } from 'lodash'

export default class QueryParams {
   static getParam(key = '') {
      const parsedParams = QueryParams.getParsedSearchParams();
      return parsedParams[key];
   }

   static getParsedSearchParams() {
      const querySearchParams = history.location.search;
      return queryString.parse(querySearchParams, { decode: false });
   }

   static setQueryParam(key, value) {
      const parsedParams = QueryParams.getParsedSearchParams();
      parsedParams[key] = value;
      const stringify = queryString.stringify(parsedParams, { encode: false });

      history.replace({
         path: window.location.pathname,
         search: stringify,
      });
   }
   static clearQueryParam() {
      window.history.pushState('', '', window.location.pathname);
   }

   static addQueryParam(key, value, except = []) {
      const parsedParams = QueryParams.getParsedSearchParams();
      const constructedParams = {};
      Object.keys(parsedParams).forEach((key) => {
         if(!except.includes(key)) {
            constructedParams[key] = parsedParams[key];
         }
      })
      constructedParams[key] = value;
      const stringify = queryString.stringify(constructedParams, { encode: false });
      history.replace({
         path: window.location.pathname,
         search: stringify,
      });
   }
   static pushQueryParam(key, value, except = []) {
      const parsedParams = QueryParams.getParsedSearchParams();
      const constructedParams = {};
      Object.keys(parsedParams).forEach((key) => {
         if(!except.includes(key)) {
            constructedParams[key] = parsedParams[key];
         }
      })
      constructedParams[key] = value;
      const stringify = queryString.stringify(constructedParams, { encode: false });
      history.push({
         path: window.location.pathname,
         search: stringify,
      });
   }

   static removeQueryParam(param) {
      const parsedParams = QueryParams.getParsedSearchParams();
      const constructedParams = {};
      Object.keys(parsedParams).forEach((key) => {
         if(key !== param) {
            constructedParams[key] = parsedParams[key];
         }
      })
      const stringify = queryString.stringify(constructedParams, { encode: false });
      history.replace({
         path: window.location.pathname,
         search: stringify,
      });
   }
   static removeAllQueryParam() {
      history.replace({
         path: window.location.pathname,
      });
   }
   static setMultipleQueryParam(constructedParams) {
      const stringify = queryString.stringify(constructedParams, { encode: false });
      history.replace({
         path: window.location.pathname,
         search: stringify,
      });
   }

   static defineFilters(currentFilters) {
      if(!isEmpty(currentFilters)) {
         const currentFiltersJson = encodeURIComponent(JSON.stringify(currentFilters));
         const parsedQueryParams = queryString.parse(window.location.search);
         parsedQueryParams.q = currentFiltersJson;
         history.replace({
            path: window.location.pathname,
            search: queryString.stringify(parsedQueryParams, {
               encode: false,
            }),
         });
      } else {
         history.replace({
            path: window.location.pathname,
         });
      }
   }

   static getFilters() {
      const parsedQueryParams = queryString.parse(window.location.search);
      return parsedQueryParams.q ? JSON.parse(decodeURIComponent(parsedQueryParams.q)) : {};
   }
}
