const initialState = {
   isFetching: true,
   isEmpty: true,
   isNewFetching: false,
   isFetchingById: true,
   storiesData: {},
   activeStory: {},
   isFetchingStoriesCount: true,
   storiesCount: {},
   errors: null,
   siteInfo: {},
};

export default initialState;
