
import { createSelector } from 'reselect'

const innerStateSelector = state => state.resources;

const isFetchingSelector = createSelector(
   innerStateSelector,
   state => state.isFetching
);

const resourcesSelector = createSelector(
   innerStateSelector,
   state => state.resources
);
const mediaDownloadButtonSelector = createSelector(
   innerStateSelector,
   state => state.mediaDownloadButton
);
const wrongDownloadModalSelector = createSelector(
   innerStateSelector,
   state => state.wrongDownloadModal
);

export {
   isFetchingSelector,
   resourcesSelector,

   mediaDownloadButtonSelector,
   wrongDownloadModalSelector,
}
