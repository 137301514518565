import React from 'react';
import PropTypes from 'prop-types';
import Filter from 'common/components/responsive/filter';
import Button from 'common/components/elements/buttons/primary';
import './style.scss';

const ViewAsSelect = ({
   isMobile,
   activeSelectedValue,
   onSimpleFilter,
   name,
   onCloseViewAs,
   onClickSelect,
   data,
   filterClassNames,
   isPublished,
   iconColor,
}) => {
   return (
      <div className='flex items-center justify-between h-full'>
         <div className='flex flex-col'>
            <span className='text-xs config-title text-major dark:text-major-dark leading-tight mb-px'>Viewing as</span>
            <div className='w-full z-20 view-as-filter'>
               <Filter
                  name={ name }
                  data={ data }
                  height='10'
                  filterValueKey='slug'
                  desktopType={ isMobile ? 'mobile' : 'desktop' }
                  onFilterChange={ onSimpleFilter }
                  activeSelectedValue={ activeSelectedValue }
                  onClickSelect={ onClickSelect }
                  className={ ` w-full${ filterClassNames }` }
                  labelIcon={ !isPublished ? 'unpublish' : null }
                  labelIconColor={ iconColor }
                  isNewLabel
               />
            </div>
         </div>
         <div className='w-6 h-6 ml-4'>
            <Button
               iconName='close'
               fontIconColor='major-dark'
               classNames='w-full h-full'
               padding='none'
               backgroundColor='transparent'
               onClick={ onCloseViewAs }
               waveColor='rgba(33, 33, 33, 0.8)'
            />
         </div>
      </div>
   );
};

ViewAsSelect.propTypes = {
   onSimpleFilter: PropTypes.func,
   activeSelectedValue: PropTypes.object,
   data: PropTypes.array,
   name: PropTypes.string,
   isMobile: PropTypes.bool,
   onCloseViewAs: PropTypes.func,
   onClickSelect: PropTypes.func,
   filterClassNames: PropTypes.string,
   isPublished: PropTypes.bool,
   iconColor: PropTypes.string,
};

export default ViewAsSelect;
