const initialState = {
   isFetching: false,
   tipData: [],
   isCheckLimitFetching: false,
   tipModalData: {
      isInitTip: true,
      isLimitModal: false,
      isTipModal: false,
      header: '',
      body: '',
   },
};

export default initialState;
