import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modules/modal'
import Button from 'common/components/elements/buttons/primary'
import amsLogo from 'assets/images/ams-logo.png';
import mmsLogo from 'assets/images/membersite-big-logo.png'
import mmsDarkLogo from 'assets/images/membersite-dark-logo.png'

import cx from 'classnames';

const RebrandingModal = ({
   onCloseModal,
}) => {
   const [animate, setAnimate] = useState('up');
   const [isMobile, setIsMobile] = useState(window.innerWidth < 1025)

   useEffect(() => {
      window.addEventListener('resize', onResize);
      return () => {
         window.addEventListener('resize', onResize);
      }
   });

   const onResize = () => {
      const screenWidth = window.innerWidth;
      const isMobileScreen = screenWidth < 1025;
      if(isMobileScreen !== isMobile){
         setIsMobile(isMobileScreen);
      }
   }
   let timer = null
   useEffect(() => {
      return () => {
         clearTimeout(timer)
      }
   }, [timer])


   const closeModal = (e) => {
      if(isMobile) {
         setAnimate('down')
         timer = setTimeout(() => {
            setAnimate('up')
            onCloseModal(e)
         }, 800)
      } else {
         onCloseModal(e)
      }
   }
   let svgFill = '#041527';
   if(window.site_dark_bg){
      svgFill = '#FFFFFF';
   }


   return (
      <Modal
         contentPosition={ isMobile ? 'bottom' : 'center' }
         contentWidth={ isMobile ? '100%' : 742 }
         onClose={ () => closeModal() }
         isCloseAction={ false }
         animate={ isMobile ? animate : null }
         otherClassName='modal-wrapper-container'
      >
         <div
            className={ cx(
               {
                  'h-10 w-10 m-2': true,
               }
            ) }
         >
            <Button
               iconName='close'
               padding='none'
               borderRadius='large'
               classNames='h-full'
               onClick={ () => closeModal() }
               backgroundColor='transparent'
               textSize='small'
               fontIconColor='secondary'
               darkIconColor='secondary-dark'
            />
         </div>
         <div className='flex flex-col px-6 pb-6'>
            <div
               className={ cx(
                  {
                     'flex w-full items-center justify-center gap-6 mb-8': true,
                     'flex-col': isMobile,
                  }
               ) }
            >
               <img alt='' src={ amsLogo } className='h-[43px] w-[93px] object-contain object-center' />
               {
                  !isMobile && (
                     <svg xmlns='http://www.w3.org/2000/svg' width='49' height='13' viewBox='0 0 49 13' fill='none'>
                        <path d='M48.5303 7.03033C48.8232 6.73744 48.8232 6.26256 48.5303 5.96967L43.7574 1.1967C43.4645 0.903806 42.9896 0.903806 42.6967 1.1967C42.4038 1.48959 42.4038 1.96447 42.6967 2.25736L46.9393 6.5L42.6967 10.7426C42.4038 11.0355 42.4038 11.5104 42.6967 11.8033C42.9896 12.0962 43.4645 12.0962 43.7574 11.8033L48.5303 7.03033ZM0 7.25H48V5.75H0V7.25Z' fill={ svgFill } />
                     </svg>
                  )
               }

               {
                  isMobile && (
                     <svg xmlns='http://www.w3.org/2000/svg' width='13' height='28' viewBox='0 0 13 28' fill='none'>
                        <path d='M5.96967 27.0303C6.26256 27.3232 6.73744 27.3232 7.03033 27.0303L11.8033 22.2574C12.0962 21.9645 12.0962 21.4896 11.8033 21.1967C11.5104 20.9038 11.0355 20.9038 10.7426 21.1967L6.5 25.4393L2.25736 21.1967C1.96447 20.9038 1.48959 20.9038 1.1967 21.1967C0.903806 21.4896 0.903806 21.9645 1.1967 22.2574L5.96967 27.0303ZM5.75 0L5.75 26.5H7.25L7.25 0L5.75 0Z' fill={ svgFill } />
                     </svg>

                  )
               }
               <img alt='' src={ window.site_dark_bg ? mmsDarkLogo : mmsLogo } className='h-[43px] w-[302px] object-contain object-center' />
            </div>

            <span className='text-center text-major dark:text-major-dark'>
               AdultMemberSites.com is now my<span className='font-bold'>member</span>.site
               <br />
               <br />
               You will be automatically forwarded to the new address.
               <br />
               <br />
               Please update your bookmarks to avoid seeing this message in the future.

            </span>

            {/* <a className='' target='_blank' rel='noopener noreferrer' href='https://help.mymember.site/en/articles/8098019-we-are-rebranding'>

               <div
                  className={ cx(
                     {
                        'h-9': true,
                        'mt-[72px]': isMobile,
                        'mt-8': !isMobile,
                     }
                  ) }
               >
                  <Button
                     text='Learn more about the rebrand'
                     padding='none'
                     borderRadius='large'
                     classNames='h-full'
                     primaryColor
                     onClick={ () => {} }
                     backgroundColor='transparent'
                     textSize='small'
                  />
               </div>
            </a> */}
            <div
               className={ cx(
                  {
                     'h-9': true,
                     'mt-6': isMobile,
                     'mt-8': !isMobile,
                  }
               ) }
            >
               <Button
                  text='Ok'
                  padding='none'
                  borderRadius='large'
                  classNames='h-full'
                  primaryColor
                  textSize='small'
                  onClick={ () => closeModal() }
               />
            </div>
         </div>
      </Modal>
   );
}


RebrandingModal.propTypes = {
   onCloseModal: PropTypes.func,
};


export default RebrandingModal;
