
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import './style.scss'
import DateFormatter from 'common/utils/DateFormatter';

const LiveLabels = ({
   viewsCount,
   isStreaming,
   showViewsCount,
   startTime,
   totalLikes,
   showlikedCount,
   streamEnded,
}) => {

   const [duration, setDuration] = useState(getDuration());
   let interval = null;
   useEffect(() => {
      if(startTime && isStreaming) {
         setDuration(getDuration());
      }
      return () => clearInterval(interval);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [startTime]);

   useEffect(() => {
      if(streamEnded){
         clearInterval(interval);
         return
      }
      if(startTime && isStreaming) {
         calculateDurration();
      } else {
         clearInterval(interval);
      }
      return () => clearInterval(interval);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [startTime, isStreaming, streamEnded]);
   function getDuration() {
      let result = '00:00'
      if(!startTime)  return result;
      const asd = DateFormatter.getDiff(startTime);
      const { seconds, hours } = asd
      if(hours < 1){
         result = new Date(seconds * 1000).toISOString().slice(14, 19);
      } else {
         result = new Date(seconds * 1000).toISOString().slice(11, 19);
      }

      return result;
   }

   const calculateDurration = () => {
      interval = setInterval(function() {
         setDuration(getDuration())
      }, 1000);
   }
   const kFormatter = (num) => {
      let result = 0;
      if(!!num && num > 0) {
         result = Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num);
      }
      return result;
   }
   return (
      <div className='flex livestream-labels flex-wrap'>
         {isStreaming && (
            <>
               <div className='px-2 bg-error dark:bg-error-dark rounded h-6 flex items-center justify-items-center mx-1 mt-1'>
                  <span
                     style={ {
                        lineHeight: '16px',
                        letterSpacing: '0.0087em',
                     } }
                     className='text-major-dark text-sm font-medium'>LIVE</span>
               </div>
               <div className='flex items-center text-major-dark px-2 py-1 streaming-time rounded mx-1 mt-1'>
                  <span
                     className='text-sm'
                     style={ {
                        lineHeight: '18px',
                     } }>
                     {duration}
                  </span>
               </div>
               {
                  showViewsCount && (
                     <div className='flex items-center text-major-dark px-2 py-1 view-count rounded mx-1 mt-1'>
                        <div className='h-5 w-5 flex items-center justify-center mr-1'>
                           <Icon name='views' color='major-dark' />
                        </div>
                        <span className='text-sm'
                           style={ {
                              lineHeight: '18px',
                           } }>
                           {viewsCount}
                        </span>
                     </div>

                  )
               }
               {
                  showlikedCount && (
                     <div className='flex items-center text-major-dark px-2 py-1 view-count rounded mt-1 mx-1'>
                        <div className='h-5 w-5 flex items-center justify-center mr-1'>
                           <Icon name='heart-filled' color='major-dark' />
                        </div>
                        <span className='text-sm'
                           style={ {
                              lineHeight: '18px',
                           } }>
                           {kFormatter(totalLikes)}
                        </span>
                     </div>

                  )
               }
            </>

         )}
      </div>
   );
}


LiveLabels.propTypes = {
   showViewsCount: PropTypes.bool,
   viewsCount: PropTypes.any,
   isStreaming: PropTypes.bool,
   showlikedCount: PropTypes.bool,
   totalLikes: PropTypes.any,
   startTime: PropTypes.string,
   streamEnded: PropTypes.bool,
};


export default LiveLabels;
