import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_TIP_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_TIP_COMPLETED]: (state, action) => {
      return {
         ...state,
         tipData: action.payload,
         tipModalData: {
            ...state.tipModalData,
            isInitTip: false,
         },
         isFetching: false,
      }
   },
   [types.FETCH_TIP_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },

   [types.CHECK_TIP_LIMIT_START]: (state) => {
      return {
         ...state,
         isCheckLimitFetching: true,
      }
   },
   [types.CHECK_TIP_LIMIT_COMPLETED]: (state, action) => {
      const { payload } = action;
      let tipModalData = {
         isLimitModal: false,
         isTipModal: true,
         isInitTip: true,
         header: '',
         body: '',
      }
      if(+payload.status === 406) {
         tipModalData = {
            ...tipModalData,
            isInitTip: true,
            isLimitModal: true,
            header: payload.data.header,
            body: payload.data.body,
         }
      }
      return {
         ...state,
         tipModalData: tipModalData,
         isCheckLimitFetching: false,
      }
   },
   [types.CHECK_TIP_LIMIT_FAILED]: (state, action) => {
      const { payload } = action;
      const tipModalData = {
         ...state.tipModalData,
         isLimitModal: true,
         header: payload.data.header,
         body: payload.data.body,
      }
      return {
         ...state,
         tipModalData: tipModalData,
         isCheckLimitFetching: false,
      }
   },

   [types.SET_MODAL_STATE]: (state, action) => {
      const { payload } = action;
      let tipModalData = {
         ...state.tipModalData,
         ...payload,
      }
      return {
         ...state,
         tipModalData,
      }
   },
   [types.SET_TIP_DATA_STATE]: (state, action) => {
      const { payload } = action;

      return {
         ...state,
         tipData: payload,
      }
   },
}

export default createReducer(initialState)(reducersMap);
