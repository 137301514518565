import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss'

const DraggableContent = ({
   style,
   children,
   onMouseUp,
   onClick,
   onMouseDown,
   onMouseMove,
   className,
}) => {
   const draggableCard = useRef(null);

   useEffect(() => {
      if(!window.draggableState){
         window.draggableState = {
            isDragging: false,
            originalX: 0,
            originalY: 0,
            translateX: 0,
            translateY: 0,
            lastTranslateX: 0,
            lastTranslateY: 0,
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   const handleMouseDown = event => {
      event.preventDefault();
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      const { clientX, clientY } = event;
      window.draggableState = {
         ...window.draggableState,
         isDragging: true,
         originalX: clientX,
         originalY: clientY,
      }
      if(draggableCard && draggableCard.current){
         draggableCard.current.isDragging = true;
         draggableCard.current.style.cursor = 'grabbing';
      }
      if(onMouseDown){
         onMouseDown(event)
      }

   };
   const handleMouseMove = event => {
      const { clientX, clientY } = event
      if(!window.draggableState.isDragging) {
         return;
      }
      if(draggableCard && draggableCard.current){
         const x = clientX - window.draggableState.originalX + window.draggableState.lastTranslateX;
         const y = clientY - window.draggableState.originalY + window.draggableState.lastTranslateY;
         if(
            x > -draggableCard.current.offsetWidth / 2 && x < window.innerWidth - (draggableCard.current.offsetWidth / 2) &&
            y < 24 && y > (draggableCard.current.offsetHeight / 2) - window.innerHeight
         ){
            window.draggableState = {
               ...window.draggableState,
               translateX: x,
               translateY: y,
            }
            draggableCard.current.style.transform = `translate(${ x }px, ${ y }px)`
         }
      }
      if(onMouseMove){
         onMouseMove(event)
      }
   };

   const handleMouseUp = e => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);

      if(draggableCard && draggableCard.current){
         window.draggableState = {
            ...window.draggableState,
            isDragging: false,
            originalX: 0,
            originalY: 0,
            lastTranslateX: window.draggableState.translateX,
            lastTranslateY: window.draggableState.translateY,
         }
         draggableCard.current.isDragging = false
         draggableCard.current.style.cursor = 'grab';
         if(onMouseUp){
            onMouseUp(e);
         }
      }
   };

   return (
      <div
         ref={ draggableCard }
         className={ `fixed bottom-0 m-5 bg-panel dark:bg-panel-dark border-2 dark:border-divider-dark border-divider box-border live-stream-notification-card rounded-lg ${ className }` }
         role='presentation'
         style={ {
            zIndex: 99,
            ...style,
            cursor: 'grab',
         } }
         onMouseDown={ handleMouseDown }
         onClick={ event => { if(onClick) onClick(event) } }
      >
         {children}
      </div>
   )
}



DraggableContent.propTypes = {
   style: PropTypes.any,
   children: PropTypes.any,
   onClick: PropTypes.func,
   onMouseMove: PropTypes.func,
   onMouseUp: PropTypes.func,
   onMouseDown: PropTypes.func,
   className: PropTypes.string,
};

export default DraggableContent
