/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import Link from '../link';
import Group from '../group';
import { Consumer } from '../context';
import { Tooltip } from 'react-tooltip';
import { createPortal } from 'react-dom';

const Subgroup = ({
   children,
   title,
   icon,
   avatarURL,
   isActiveOneOfLinks,
   unreadCount,
   src,
   useAvatar,
   avatarSrc,
   containerWidth,
   count,
   className,
}) => {

   return (
      <Consumer>
         { ({
            type,
            activeGroupTitle,
            onChangeActiveGroup,
         }) => {
            const active = activeGroupTitle === title;

            return (
               <>
                  {
                     createPortal(
                        <Tooltip
                           id={ title }
                           place='right'
                           clickable
                           style={ { backgroundColor: '#FFFFFF', color: "#041527", fontSize: '16px', fontWeight: 500, padding: 0, minWidth: containerWidth } }
                           className={ `dk-submenu shadow dark:shadow-dark !rounded-lg` }
                        >
                           { children }
                        </Tooltip>,
                        document.getElementById('tooltips')
                     )
                  }

                  <div
                     data-tooltip-id={ 'mobile' === type ? null : title }
                     className={ `flex relative ${ title === 'Content' ? 'intercom-content' : '' } ${ useAvatar ? `w-full` : `` } group ${ className }` }
                  >
                     {
                        true === Boolean(unreadCount) && (
                           <div
                              className='absolute h-5 w-5 flex items-center justify-center bg-error dark:bg-error-dark rounded-full right-[6px] top-[6px] circle-shandow z-[31]'
                           >
                              <span className='leading-[14px] text-major-dark text-xs whitespace-nowrap'>{unreadCount}</span>
                           </div>

                        )
                     }
                     {
                        'mobile' !== type && Boolean(count) &&
                        <div
                           className='absolute h-5 min-w-[20px] flex items-center justify-center bg-error dark:bg-error-dark rounded-full right-[6px] top-[2px] px-[5px] z-60'
                        >
                           <span className='leading-[14px] text-major-dark text-[11px] text-center whitespace-nowrap font-medium'>{count > 99 ? '99+' : count}</span>
                        </div>
                     }
                     {
                        src && (
                           <div
                              className='w-full h-12 flex items-center justify-center hover:bg-hover cursor-pointer'
                           >
                              <img
                                 src={ src }
                                 alt='Avatar'
                                 className='object-center object-cover rounded-full w-10 h-10'
                              />
                           </div>
                        )
                     }
                     {
                        useAvatar && avatarSrc && (
                           <div
                              className='w-full h-16 flex items-center justify-center hover:bg-hover cursor-pointer py-3 pl-2'
                           >
                              <img
                                 src={ avatarSrc }
                                 alt='Avatar'
                                 className='object-center object-cover rounded-full w-10 h-10'
                              />
                              <Link
                                 // icon={ icon }
                                 title={ title }
                                 onClick={ () => {
                                    onChangeActiveGroup(title)
                                 } }
                                 showTitle={ type === 'mobile' }
                                 avatarURL={ avatarURL }
                                 active={ isActiveOneOfLinks }
                                 useAvatar
                                 count={ count }

                              />
                           </div>
                        )
                     }
                     {
                        !useAvatar && icon && (
                           <Link
                              icon={ icon }
                              title={ title }
                              onClick={ () => {
                                 onChangeActiveGroup(title)
                              } }
                              showTitle={ type === 'mobile' }
                              avatarURL={ avatarURL }
                              active={ isActiveOneOfLinks }
                              count={ count }
                              showCount={ !!active }
                           />
                        )
                     }
                     {
                        'mobile' === type && (
                           <div
                              onClick={ () => {
                                 onChangeActiveGroup(title)
                              } }
                              role='presentation'
                              className={ `group-icon w-5 h-5 flex items-center justify-center z-30 ${ useAvatar ? `mt-3` : `` }` }
                           >
                              <Icon
                                 color='secondary'
                                 darkcolor='secondary-dark'
                                 size='xl'
                                 name={ active ? 'arrow-up' : 'arrow-down' }
                                 className=''
                              />
                           </div>
                        )
                     }
                  </div>
                  {
                     'mobile' === type && (
                        <Group isSubGroup isOpen={ !!active }>
                           { children }
                        </Group>
                     )
                  }
               </>

            )

         } }
      </Consumer>
   )
}

Subgroup.propTypes = {
   children: PropTypes.any,
   title: PropTypes.string,
   icon: PropTypes.string,
   avatarURL: PropTypes.string,
   src: PropTypes.string,
   isActiveOneOfLinks: PropTypes.bool,
   unreadCount: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
   ]),
   useAvatar: PropTypes.bool,
   avatarSrc: PropTypes.string,
   containerWidth: PropTypes.number,
   count: PropTypes.number,
   className: PropTypes.string,
};

Subgroup.defaultProps = {
   isActiveOneOfLinks: false,
   containerWidth: 200,
   count: 0,
   className: '',
}

Subgroup.displayName = 'Subgroup'

export default Subgroup;
