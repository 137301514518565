import Router from 'sites/routes/router';
import { push } from 'connected-react-router';
import Auth from 'sites/utils/Auth';
import { logoutCompletedAction, logoutStartAction, setIsSiteInited } from 'sites/state/modules/common/actions';
import { resetGlobalStateOperation } from 'sites/state/modules/common/operations';
import { clearChatInfo } from 'sites/state/modules/chat/actions';
import LocalStorage from 'sites/utils/LocalStorage';
import { logOutMember } from 'sites/api/AuthApi';

export default (dispatch, redirect = true, path = '', search, preventApiCall = false, systemGoToRoute = null) => async () => {
   window.sharedData.stories.lastUpdateDate = undefined;
   dispatch(logoutStartAction())
   try {
      await logOutMember(preventApiCall);
      logout(redirect, dispatch, path, search, systemGoToRoute)
   } catch (error) {
      logout(redirect, dispatch, path, search, systemGoToRoute)
   }
}


function logout(redirect, dispatch, path, search, systemGoToRoute){
   Auth.logout();
   dispatch(clearChatInfo());
   LocalStorage.removeItem('hide-login-as');
   dispatch(resetGlobalStateOperation(path));
   dispatch(logoutCompletedAction())
   if(systemGoToRoute){
      dispatch(push(Router.route(systemGoToRoute).getCompiledPath()));
      return;
   }
   if(redirect){
      if(!Router.route('HOMEPAGE').match(path) && !path.includes('/videos') && !path.includes('/photosets') && !path.includes('/stories') && !path.includes('/store')){
         dispatch(setIsSiteInited(false));
         if(!!path) {
            let goBack = `go-back=${ path }`;
            if(!!search) {
               goBack = `${ goBack }${ encodeURIComponent(search) }`
            }
            dispatch(push({
               pathname: Router.route('SIGN_IN').getCompiledPath(),
               search: goBack,
            }));
         } else {
            dispatch(push(Router.route('HOMEPAGE').getCompiledPath()));
         }
      } else {
         window.location.reload()
      }
   }
}
