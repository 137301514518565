const initialState = {
   inputs: {
      email: '',
      password: '',
   },
   isInProgress: false,
   errors: {},
   magicLoginUCProgrees: false,
   magicLoginUCErrors: {},
   openWrongLoginPageModalState: {
      isOpen: false,
      token: null,
      searchType: null,
   },
};

export default initialState;
