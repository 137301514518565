import React from "react";
import PropTypes from "prop-types";
import Icon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';
import constructorLogo from 'assets/images/constructor-logo.svg';
import drakConstructorLogo from 'assets/images/dark-constructor-logo.svg';

const ErrorPage = ({
   status,
   goTo,
}) => {

   const getState = () => {
      const state = {
         not_available: {
            title: 'Limited access',
            description: "You don't have access to this page",
            buttonText: 'Get membership to view',
            iconName: 'membership',
            haseContactButton: true,
            action: goTo,
            intercomClass: 'intercom-member-error-page',
         },
         not_found: {
            title: '404 Page not found',
            description: "Sorry, something went wrong :(",
            buttonText: 'Go to homepage',
            haseContactButton: true,
            action: goTo,
            isConstructorLogo: true,
            intercomClass: 'intercom-frontend-error',
         },
      }

      return state[status];
   }
   const state = getState();

   return (
      <div className='flex flex-col items-center justify-center h-full px-4 py-8'>
         {
            state.isConstructorLogo && (
               <div className='lg:h-[192px] lg:w-[310px] h-[93px] w-[150px] flex items-center justify-center mb-2 lg:mb-6'>
                  <img src={ window.site_dark_bg ? drakConstructorLogo : constructorLogo } alt='constructor-logo' />
               </div>
            )
         }
         {
            'not_available' === status && (
               <div className='h-[130px] w-[130px] flex items-center justify-center'>
                  <Icon
                     name='lock'
                     style={ { fontSize: '130px' } }
                     color='disabled'
                     darkColor='disabled-dark'
                  />
               </div>
            )
         }
         {
            state.title && (
               <span className='text-major dark:text-major-dark leading-[32px] lg:leading-[44px] text-2xl lg:text-4xl font-semibold mt-6 text-center'>{state.title}</span>
            )
         }
         {
            state.description && (
               <span className='text-secondary dark:text-secondary-dark leading-[24px] text-xl mt-6 text-center'>{state.description}</span>
            )
         }
         {
            state.action  && (
               <div className='flex justify-center items-center mt-8 h-9'>
                  <Button
                     text={ state.buttonText }
                     iconPosition='left'
                     iconName={ state.iconName }
                     classNames='h-full px-[14px]'
                     borderRadius='large'
                     textSize='small'
                     fontIconSize='extraLarge'
                     primaryColor
                     onClick={ state.action }
                     textMarginX='0 ml-2'
                  />

               </div>
            )
         }
         {
            state.haseContactButton && state.intercomClass &&  (
               <div className='flex justify-center items-center mt-3 h-9'>
                  <Button
                     text='Contact support'
                     onClick={ () => {} }
                     textSize='small'
                     primaryColor
                     iconName='help-outline'
                     iconPosition='left'
                     classNames={ `h-full px-[14px] ${ state.intercomClass }` }
                     padding='none'
                     backgroundColor='transparent'
                     textMarginX='0 ml-2'
                  />
               </div>

            )
         }
      </div>
   )
};

ErrorPage.defaultProps = {
   status: 'not_available',
};

ErrorPage.propTypes = {
   status: PropTypes.oneOf(['not_available', 'not_found']),
   goTo: PropTypes.func,
};

export default ErrorPage;




