import React from 'react';
import { getCapitalizeSiteName, getSiteNameAbbreviatur } from 'common/utils/helper';

const bonuses = [
   {
      price: '$1000 - $2000',
      percentage: '1%',
   },
   {
      price: '$6000 - $7000',
      percentage: '6%',
   },
   {
      price: '$2000 - $3000',
      percentage: '2%',
   },
   {
      price: '$7000 - $8000',
      percentage: '7%',
   },
   {
      price: '$3000 - $4000',
      percentage: '3%',
   },
   {
      price: '$8000 - $9000',
      percentage: '8%',
   },
   {
      price: '$4000 - $5000',
      percentage: '4%',
   },
   {
      price: '$9000 - $10000',
      percentage: '9%',
   },
   {
      price: '$5000 - $6000',
      percentage: '5%',
   },
   {
      price: '$10000 +',
      percentage: '10%',
   },
];

const PayoutsAndFees = () => (
   <>
      <p className='text-2xl text-major dark:text-major-dark mb-3'>Payout Percentage</p>
      <p>
         We’ll take the greater of 25 USD or 22% per pay period, after payment processing.
         Your total payout percentage (all percentage bonuses combined) will be capped at 95%
         Processing fees by gateways:
         <br /><br />
         • Segpay: 10%
         <br />
         • CCBill: 15%
      </p>
      <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6'>Bonuses</p>
      <p>
         <span className='text-major dark:text-major-dark'>Referral bonus</span> - Your will receive an additional 1% on your payout for each referred website which has revenues over 500 USD in that same pay period.
         <br /><br />
         <span className='text-major dark:text-major-dark'>Testimonial bonus</span> - Earn an additional 1% if your testimonial is featured on <span dangerouslySetInnerHTML={ { __html: getCapitalizeSiteName() } } />.
         <br /><br />
         <span className='text-major dark:text-major-dark'>Site revenue bonus</span> - Site revenue bonus Within a given payout period, your payout percentage will be increased according to the following scale:
      </p>
      <div className='flex flex-wrap mt-4 '>
         {bonuses.map((bonus, index) => {
            const borderRight = index % 2 === 0 ? 'border-r' : '';
            const borderBottom = index === bonuses.length - 1 || index === bonuses.length - 2 ? 'border-b' : '';
            return (
               <div className={ `flex justify-between py-2 pl-2 w-2/4 border-t border-solid dark:border-divider-dark border-divider ${ borderRight } ${ borderBottom }` } key={ index.toString() }>
                  <p>{bonus.price}</p>
                  <p className='text-major dark:text-major-dark pr-2'>{bonus.percentage}</p>
               </div>
            )
         })}
      </div>
      <p className='mt-5'>
         <span className='text-major dark:text-major-dark'>Referral Percentage</span> - You will receive 23% of what we make on any site that you refer to {getSiteNameAbbreviatur()}.
      </p>
      <p className='text-2xl text-major dark:text-major-dark mt-6 mb-3'>Chargebacks</p>
      <p>
         We provide insurance for up to 250 USD every month.
         <br /><br />
         In the event of a chargeback, we cover the payment gateway fees associated with the chargeback, AND the cost of the purchased item or service.
         <br /><br />
         Example: Member purchases $150 Skype show and charges back. You keep the full amount of your original earnings and your chargeback insurance balance drops to $75. The $75 would be topped up to $250 at the beginning of the next month. The math: 250 - (150 + 25 chargeback fee) = 75
         <br /><br />
         Chargeback fees per gateway are as follows:
         <br />
         • Segpay: 0%
         <br />
         • CCBill: 25%
      </p>
      <p className='text-2xl text-major dark:text-major-dark mt-6 mb-3'>Payment Returns</p>
      <p>
         Payment returned due to incorrect banking information will result in the following fees:
         <br />
         • ACH return: 10%
         <br />
         • Wire return: 50%
      </p>
      <p className='text-2xl text-major dark:text-major-dark mt-6 mb-3'>Payment Fees</p>
      <p>
         • U.S. direct deposit (ACH): 5 USD
         <br />
         • Non-U.S. direct deposit (ACH): 7 USD
         <br />
         • Paxum: 5 USD
         <br />
         • Non-U.S. wire: 12 USD
         <br /><br />
         Payout fees may change in the future.
      </p>
   </>
);

export default PayoutsAndFees;
