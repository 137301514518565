/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from '../context';
import classnames from 'classnames';

const Group = ({
   children,
   isOpen,
   isSubGroup,
}) => {

   return (
      <Consumer>
         { ({
            type,
         }) => {
            const elements = React.Children.map(children, element => {
               if(element) {
                  const { displayName } = element.type;
                  let active = false
                  if(element.props.children && !!element.props.children.length) {
                     active = element.props.children.find((elm) => {
                        let result = false;
                        if(elm.props.to) {
                           result = window.location.pathname.startsWith(elm.props.to)
                        }
                        window.location.pathname.startsWith('/memberships') && (result = false)
                        return result
                     })
                  }
                  const liClasses = classnames(
                     'highlight-color',
                     'w-full',
                     'list-none',
                     'relative dark:text-major-dark',
                     'overflow-hidden',
                     {
                        submenu: 'Subgroup' === displayName,
                        'skin-primary-text dark:opasity00.5': 'Subgroup' === displayName && active,
                     }
                  );
                  const el = React.cloneElement(element, {
                     ...element.props,
                     showTitle: type === 'mobile',
                  });
                  return (
                     <li
                        className={ liClasses }
                     >
                        { el }
                     </li>
                  )
               }
            });
            return (
               <ul
                  className={ `${ isSubGroup ? (isOpen ? `dropdown-opened` : `dropdown-closed`) : '' } ` }
                  style={ {
                     maxHeight: isSubGroup ? (isOpen ? `${ elements?.length * 48 }px` : `0px`) : 'auto',
                  } }
               >
                  { elements }
               </ul>
            )
         } }

      </Consumer>
   )

}

Group.propTypes = {
   children: PropTypes.any,
   isOpen: PropTypes.bool,
   isSubGroup: PropTypes.bool,
};

Group.defaultProps = {
   showLinkTitle: true,
}

Group.displayName = 'Group'

export default Group;
