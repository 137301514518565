import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import _ from "lodash";
import './style.css';

const Search = ({
   onChange,
   placeholder,
   value,
   isOutSideControll,
   height,
   isDebounce,
   maxLength,
   // isHideShadow,
   isHideBorder,
   version,
   newShadow,
   searchIconSize,
   clearIcon,
   className,
   // borderColor,
   disabled,
   inputId,
   searchRef,
   showTooltip,
}) => {
   const [text, setText] = useState(value);
   useEffect(() => {
      setText(value)
   }, [value])

   const handleOnChange = (e) => {
      const value = e.target.value;

      setText(value);
      onChange(value);
   };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   const delayedQuery = useCallback(_.debounce(value => onChange(value), 200), []);

   const handleDebounceOnChange = e => {
      const value = e.target.value;
      setText(value);
      delayedQuery(value);
   };

   const clearInput = (e) => {
      e.stopPropagation()
      setText('');
      onChange('');
   };

   return (
      <div
         className={ `${ newShadow ? 'shadow dark:shadow-dark' : '' } w-full ${ '1' === version ? ' px-2 py-3' : 'py-[10px] px-[14px]' } flex items-center overflow-hidden rounded-lg bg-panel dark:bg-black-field ${ height } ${ isHideBorder ? '' : 'border dark:border-divider-dark border-divider' } ${ className }` }
         id='search'
      >
         {
            '1' === version && (
               <div className='w-5 h-5'>
                  <Icon name='search' size='base' color={ disabled ? 'disabled' : 'secondary' } darkColor='secondary-dark' />
               </div>
            )
         }
         {
            '2' === version  && (
               <div className='flex items-center justify-center w-5 h-5 min-w-[20px] search-icon'>
                  <Icon name='search' size={ searchIconSize } color={ disabled ? 'disabled' : 'placeholder' } darkColor='placeholder-dark'  />
               </div>
            )
         }
         <input
            ref={ searchRef }
            value={ isOutSideControll ? value : text }
            onChange={ (e) => {
               if(!!maxLength && e.target.value?.length > maxLength) {
                  return;
               }
               isDebounce ?  handleDebounceOnChange(e) : handleOnChange(e)
            } }
            className={ `ml-2 outline-none w-full text-major text-base dark:text-major-dark leading-[20px] placeholder:text-placeholder placeholder:dark:text-placeholder ${ disabled ? 'placeholder:!text-disabled' : '' }` }
            type='text'
            placeholder={ placeholder }
            disabled={ disabled }
            id={ Boolean(inputId) ? inputId : null }
         />
         {
            text.length > 0
               ? (
                  <Icon
                     onClick={ (e) => { clearInput(e); } }
                     className='cursor-pointer'
                     name='cancel'
                     size={ clearIcon ? clearIcon : '2xl' }
                     color='secondary'
                     darkColor='secondary-dark'
                     data-tooltip-id={ showTooltip ? 'ams-top-tooltip' : null }
                     data-tooltip-content={ showTooltip ? 'Clear' : null }
                  />
               )
               : null
         }
      </div>
   );
};

Search.defaultProps = {
   placeholder: 'search',
   onChange: () => {},
   value: '',
   isOutSideControll: false,
   // height: '12',
   isDebounce: false,
   maxLength: null,
   // isHideShadow: false,
   isHideBorder: false,
   iconSize: '2xl',
   classNames: '',
   height: 'h-10',
   version: '2',
   newShadow: false,
   searchIconSize: 'lg',
   className: '',
   // borderColor: 'grey-arya',
   disabled: false,
};

Search.propTypes = {
   onChange: PropTypes.func,
   placeholder: PropTypes.string,
   value: PropTypes.string,
   isOutSideControll: PropTypes.bool,
   height: PropTypes.string,
   isDebounce: PropTypes.bool,
   maxLength: PropTypes.number,
   // isHideShadow: PropTypes.bool,
   isHideBorder: PropTypes.bool,
   version: PropTypes.string,
   newShadow: PropTypes.bool,
   searchIconSize: PropTypes.string,
   clearIcon: PropTypes.string,
   className: PropTypes.string,
   // borderColor: PropTypes.string,
   disabled: PropTypes.bool,
   inputId: PropTypes.string,
   searchRef: PropTypes.any,
   showTooltip: PropTypes.bool,
};

export default Search;
