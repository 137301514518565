import React, { Suspense, useEffect } from "react";
import PropTypes from 'prop-types';
import { pageTitileList } from 'sites/utils/pageTitle';
import Router from 'sites/routes/router';
import { currentLocationPathSelector } from 'sites/state/modules/router/selectors';
import { useSelector } from 'react-redux';
import { isLocalhost } from 'sites/utils/Helpers';
import QueryParams from "sites/utils/QueryParams";
import { getInformationAndPoliciesType } from 'common/components/modules/information-and-policies/sites/data.js';

function Suspenser({
   children,
}) {
   return (
      <Suspense fallback={ <div></div> }>
         { children }
      </Suspense>
   );
}

function setPageTitle(props, currentLocationPath) {
   let path = props?.match?.path;
   const url = props?.match?.url;
   if(url && currentLocationPath && currentLocationPath !== url){
      return;
   }
   let siteScreenName =  document.querySelector('meta[property="og:site_long_name"]') && document.querySelector('meta[property="og:site_long_name"]').content;
   let pageTitileSuffix = document.title.replace(`${ siteScreenName } - `, '');
   if(!siteScreenName){
      let titleSplitArr = document.title.split(/-(?=[^-]+$)/);
      siteScreenName = titleSplitArr?.[0]
      pageTitileSuffix = titleSplitArr?.[1];
   }
   if(siteScreenName){
      siteScreenName = siteScreenName.trim();
   }
   const domainType = document.querySelector('meta[name=domainType]') && document.querySelector('meta[name=domainType]').content;
   const subdomain = document.querySelector('meta[name=subdomain]') && document.querySelector('meta[name=subdomain]').content;
   if(!isLocalhost() && 'site_name_prefix' === domainType) {
      path = path.replace(`/${ subdomain }`, '');
   }
   const informationAndPoliciesQueryParamsType = QueryParams.getParam('information-and-policies');
   if(informationAndPoliciesQueryParamsType && getInformationAndPoliciesType(informationAndPoliciesQueryParamsType)){
      document.title = siteScreenName + ' - ' + getInformationAndPoliciesType(informationAndPoliciesQueryParamsType);
      return
   }
   if(path && path in pageTitileList){
      const routeHasContent = [
         'VIDEOS_INDIVIDUAL',
         'PHOTOSETS_INDIVIDUAL',
         'STORE_DETAILS',
         'LIVE_STREAM_RECORD',
         'CUSTOM_PAGE',
         'ORDER_DETAILS',
      ];
      const isMatchRouteHasContent = routeHasContent.find(route => Router.route(route).match(window.location.pathname));
      if(isMatchRouteHasContent){
         const pathSegments = window.location.pathname.split('/');
         const titleSegment = pathSegments[pathSegments.length - 1];
         if(titleSegment){
            let titleSegmentRpl = titleSegment.slice(titleSegment.indexOf('-') + 1)
            const contentTitle = decodeURIComponent(titleSegmentRpl.replace(/-/g, ' '));
            if(pageTitileSuffix && contentTitle && pageTitileSuffix.toLocaleLowerCase().trim() === contentTitle.toLocaleLowerCase()){
               return;
            }
         }
      }
      pageTitileSuffix = pageTitileList?.[path] || '';
      if(pageTitileSuffix){
         document.title = siteScreenName + ' - ' + pageTitileSuffix;
      } else {
         document.title = siteScreenName;
      }
   } else {
      document.title = siteScreenName;
   }
}

Suspenser.propTypes = {
   children: PropTypes.any,
};

export default (WrappedComponent) => props => {
   const currentLocationPath = useSelector(currentLocationPathSelector)
   useEffect(() => {
      setPageTitle(props, currentLocationPath);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   useEffect(() => {
      const head = document.head;
      const canonicalLink = document.querySelector('link[rel=canonical]');
      let pathname = window.location.pathname;
      const href = window.location.origin;
      if('/' === pathname.slice(pathname.length - 1)){
         pathname = pathname.slice(0, pathname.length - 1);
      }
      const curronetHref = href + pathname;
      if(!canonicalLink){
         const link = document.createElement('link');
         link.href = curronetHref;
         link.rel = 'canonical';
         head.appendChild(link);
      } else if(curronetHref !== canonicalLink?.href){
         canonicalLink.setAttribute('href', curronetHref);
      }
   }, [])
   return (
      <Suspenser>
         <WrappedComponent { ...props } />
      </Suspenser>
   )
};
