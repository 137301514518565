import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';

let reducersMap = {
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         data: payload,
         isFetching: false,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state) => {
      return {
         ...state,
         isFetching: false,
      }
   },
}

export default createReducer(initialState)(reducersMap);
