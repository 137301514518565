import { getProtocol } from 'common/utils/utils';
import socketIOClient from 'socket.io-client';

const getSocketBaseUrl = (siteName) => {
   let url;
   url = `${ getProtocol() }${ siteName }.${ process.env.REACT_APP_AMS_DOMAIN }`;

   return url
}

const initCommentsSocket = (siteName, autoConnect = true) => {

   const socketUrl = `${ getSocketBaseUrl(siteName) }/comments-events`
   if(!window.commentsSocket) {
      window.commentsSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
      });
   }
   if(autoConnect) {
      if(window.commentsSocket.disconnected) {
         window.commentsSocket.connect()
      }
   }
   return window.commentsSocket;
}

const initUploadsSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/uploads-events`
   if(!window.uploadsSocket) {
      window.uploadsSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         transports: ['websocket', 'polling'],
         forceNew: true,
      });
   }
   if(autoConnect) {
      if(window.uploadsSocket.disconnected) {
         window.uploadsSocket.connect()
      }
   }
   return window.uploadsSocket;
}

const initMemberAreaSocket = (siteName, autoConnect = true) => {
   const socketUrl = `${ getSocketBaseUrl(siteName) }/member-area`
   if(!window.memberAreaSocket) {
      window.memberAreaSocket = socketIOClient(socketUrl, {
         autoConnect,
         path: '/websocket',
         forceNew: true,
         transports: ['websocket', 'polling'],
      });
   }
   if(autoConnect) {
      if(window.memberAreaSocket.disconnected) {
         window.memberAreaSocket.connect()
      }
   }
   return window.memberAreaSocket;
}

export {
   initCommentsSocket,
   initUploadsSocket,
   initMemberAreaSocket,
}
